import React from 'react';
import { useProofOfAddressVerificationContext } from '../../../../../contexts/proof-of-address-verification/ProofOfAddressVerificationContext';
import AccountVerificationMultiStepFooter from '../../multi-step/footer/AccountVerificationMultiStepFooter';

interface Props {
    nextButtonText?: string;
}

export default function AccountVerificationProofOfAddressFooter(props: Props) {
    const { activeStep, setActiveStep, stepCompletionState } = useProofOfAddressVerificationContext();
    const LAST_STEP = 4;

    return (
        <AccountVerificationMultiStepFooter
            {...props}
            isNextButtonDisabled={!stepCompletionState[activeStep]}
            isPreviousButtonVisible={activeStep > 1}
            onPreviousButtonClick={() => setActiveStep(Math.max(activeStep - 1, 0))}
            onNextButtonClick={() => setActiveStep(Math.min(activeStep + 1, LAST_STEP))}
        />
    );
}
