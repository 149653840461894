import { Country } from '@staycool/location';
import moment from 'moment';
import { getServiceUrl, httpGet } from '../services/api';
import { storeRecentlyPlayedGames } from '../services/casino/casino-statistics';
import {
    CasinoDateFilter,
    HighestDailyWins,
    RecentlyPlayed,
    UserActivity,
    UserHighestWin,
    UserStatistics,
} from '../services/casino/types';
import { logger } from '../services/logger';
import { storageGet } from '../services/storage';
import { Currency } from '../services/wallet/types';
import { stores } from '../stores';
import { casino } from '../stores/casino';
import { getStoreValue } from '../stores/store/utils';

const getUrl = (url) => getServiceUrl('casino-statistics', url);

export async function loadRecentlyPlayedGames(): Promise<void> {
    try {
        const isAuthenticated = getStoreValue(stores.isAuthenticated);
        const url = getUrl('fo/recently-played');
        const recentlyPlayed: RecentlyPlayed[] = isAuthenticated ? await httpGet(url) : storageGet('recentlyPlayed');
        storeRecentlyPlayedGames(recentlyPlayed);
    } catch (error) {
        logger.error('CasinoStatisticsMicroservice', 'loadRecentlyPlayedGames', error);
    }
}

export async function loadUserStatistics(dateFilter: CasinoDateFilter): Promise<void> {
    const properFilter = {
        period: dateFilter.period,
        startDate: (dateFilter.startDate as any) ? moment(dateFilter.startDate as any).format('YYYY-MM-DD') : undefined,
        endDate: (dateFilter.endDate as any) ? moment(dateFilter.endDate as any).format('YYYY-MM-DD') : undefined,
    };
    try {
        const url = getUrl('fo/statistics');
        const userStatistics: UserStatistics = await httpGet(url, properFilter);

        casino.userStatistics.set(userStatistics);
    } catch (error) {
        logger.error('CasinoStatisticsMicroservice', 'loadUserStatistics', error);
    }
}

export async function loadUserActivity(dateFilter: CasinoDateFilter): Promise<void> {
    const properFilter = {
        period: dateFilter.period,
        startDate: (dateFilter.startDate as any) ? moment(dateFilter.startDate as any).format('YYYY-MM-DD') : undefined,
        endDate: (dateFilter.endDate as any) ? moment(dateFilter.endDate as any).format('YYYY-MM-DD') : undefined,
    };
    try {
        const url = getUrl('fo/activity');
        const userActivity: UserActivity = await httpGet(url, properFilter);

        casino.userActivity.set(userActivity);
    } catch (error) {
        logger.error('CasinoStatisticsMicroservice', 'loadUserActivity', error);
    }
}

export async function loadUserBiggestWins(dateFilter: CasinoDateFilter) {
    const properFilter = {
        period: dateFilter.period,
        startDate: (dateFilter.startDate as any) ? moment(dateFilter.startDate as any).format('YYYY-MM-DD') : undefined,
        endDate: (dateFilter.endDate as any) ? moment(dateFilter.endDate as any).format('YYYY-MM-DD') : undefined,
    };
    try {
        const url = getUrl('fo/biggest-wins');
        const userBiggestWins: UserHighestWin[] = (await httpGet(url, properFilter)) || [];
        casino.highestWins.set(userBiggestWins);
    } catch (error) {
        logger.error('CasinoStatisticsMicroservice', 'loadUserBiggestWins', error);
    }
}

export async function loadCasinoHighestWins(country: Country, currency: Currency, language: string) {
    try {
        const url = getUrl('fo/highest-daily-wins');
        return httpGet<HighestDailyWins[]>(url, { country, currency, language });
    } catch (error) {
        logger.error('CasinoStatisticsMicroservice', 'loadCasinoHighestWins', error);
        return [];
    }
}
