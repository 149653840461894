import { useState } from 'react';
import { pollSmartIdSession, startSmartIdAuthentication } from '../microservices/auth';
import { stores } from '../stores';
import { handleAuthenticationFailure, handleAuthenticationSuccess } from './auth';
import { LoginMethod, RegistrationFlow, SmartIdSessionResult, SmartIdSessionState } from './auth/types';
import { useInterval } from './hooks';
import { logger } from './logger';
import { getRoute, useRouter } from './router';
import { translate } from './translate';

const DEFAULT_ERROR_MESSAGE = translate('Failed to authenticate with Smart ID, please try again', 'ui.account');

const ERROR_MESSAGES_BY_SESSION_RESULT: Partial<Record<SmartIdSessionResult, string>> = {
    [SmartIdSessionResult.TIMEOUT]: translate('Session timeout', 'ui.account'),
    [SmartIdSessionResult.USER_REFUSED_CERT_CHOICE]: translate('Session cancelled by user', 'ui.account'),
    [SmartIdSessionResult.USER_REFUSED_CONFIRMATIONMESSAGE_WITH_VC_CHOICE]: translate(
        'Session cancelled by user',
        'ui.account',
    ),
    [SmartIdSessionResult.USER_REFUSED_CONFIRMATIONMESSAGE]: translate('Session cancelled by user', 'ui.account'),
    [SmartIdSessionResult.USER_REFUSED_DISPLAYTEXTANDPIN]: translate('Session cancelled by user', 'ui.account'),
    [SmartIdSessionResult.USER_REFUSED_VC_CHOICE]: translate('Session cancelled by user', 'ui.account'),
    [SmartIdSessionResult.USER_REFUSED]: translate('Session cancelled by user', 'ui.account'),
};

export function useSmartIdAuth(onSuccess: () => void) {
    const { navigateTo } = useRouter();
    const [verificationCode, setVerificationCode] = useState<string>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [sessionId, setSessionId] = useState('');

    async function startAuthentication(personalId: string) {
        setErrorMessage(undefined);
        clearSession();
        try {
            const { sessionId, verificationCode } = await startSmartIdAuthentication(personalId);
            setVerificationCode(verificationCode);
            setSessionId(sessionId);
        } catch (error) {
            setErrorMessage(DEFAULT_ERROR_MESSAGE);
            clearSession();
        }
    }

    async function poll() {
        if (!sessionId) {
            return;
        }

        try {
            const { endResult, state, loginData, signupData } = await pollSmartIdSession(sessionId);

            if (state !== SmartIdSessionState.COMPLETE) {
                return;
            }

            clearSession();

            if (endResult !== SmartIdSessionResult.OK) {
                setErrorMessage(
                    ERROR_MESSAGES_BY_SESSION_RESULT[endResult as SmartIdSessionResult] || DEFAULT_ERROR_MESSAGE,
                );
                return;
            }

            if (signupData) {
                stores.registration.form.set((form) => {
                    Object.assign(form, signupData);
                    form.eVerificationMethod = RegistrationFlow.SMART_ID;
                    form.sessionId = sessionId;
                });
                stores.modals.isLoginModalOpen.set(false);
                navigateTo(getRoute('registration'));
                onSuccess();
            } else if (loginData) {
                try {
                    const result = await handleAuthenticationSuccess(loginData, LoginMethod.SMART_ID);
                    if (result?.navigateTo) {
                        navigateTo(result.navigateTo);
                    }
                    onSuccess();
                } catch (error: any) {
                    setErrorMessage(error.message);
                }
            }
        } catch (error: any) {
            if (error.code) {
                try {
                    handleAuthenticationFailure(error, LoginMethod.SMART_ID);
                } catch (error: any) {
                    setErrorMessage(error.message);
                }
                clearSession();
            } else {
                logger.error('SmartIdService', 'poll', error);
            }
        }
    }

    useInterval(poll, sessionId ? 5000 : 0, [sessionId]);

    function clearSession() {
        setVerificationCode(undefined);
        setSessionId('');
    }

    return { startAuthentication, verificationCode, errorMessage };
}
