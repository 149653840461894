import some from 'lodash/some';
import React, { useEffect } from 'react';
import { translate } from '../../../../services/translate';
import { stores, useStoreWithSelector } from '../../../../stores';
import Wrapper from './styles';
import SportSettingsMenuButton from '../../settings-menu/button/SportSettingsMenuButton';
import { clearBetslipSelection } from '../../../../services/sports/betslip';
import { isB2B } from '../../../../services/environment';
import UiTabs from '../../../ui/tabs/UiTabs';
import UiTabsItem from '../../../ui/tabs/item/UiTabsItem';
import { useStore } from '../../../../hooks/useStore';
import { BET_TYPE, BetslipMode } from '../../../../services/sports/types';

interface Props {
    clearSelection: () => void;
}

export default function SportBetslipHeader({ clearSelection }: Props) {
    const [marketInfoById] = useStore(stores.sports.marketInfoById);
    const [betSlipMarketIdToOutcomeId] = useStore(stores.sports.betSlipMarketIdToOutcomeId);
    const [betSlipUserState, setBetSlipUserState] = useStore(stores.sports.betSlipUserState);
    const [bonusBetsSelection] = useStore(stores.sports.bonusBetsSelection);
    const [isInactivityModalVisible] = useStore(stores.modals.isInactivityModalVisible);
    const [selectedCampaign] = useStoreWithSelector(stores.sports.campaigns, (state) => state.selected);
    const [isLoading] = useStoreWithSelector(stores.sports.betSlipPlacingState, (state) => state.isLoading);
    const marketsCount = Object.keys(betSlipMarketIdToOutcomeId).length;
    const { betType } = betSlipUserState;
    const isBetbuilder = betType === BET_TYPE.BETBUILDER;
    const betSlipHasBetbuilderMarket = Object.values(marketInfoById).some(
        ({ view_type }) => view_type === 'bet_builder',
    );
    const [isTeasersAvailable] = useStore(stores.sports.isTeasersAvailable);
    const [teaserMainLineErrorMarketIds] = useStore(stores.sports.teaserMainLineErrorMarketIds);
    const [teaserInvalidMainLineErrorMarketIds] = useStore(stores.sports.teaserInvalidMainLineErrorMarketIds);
    const numberOfBets = Object.values(betSlipMarketIdToOutcomeId).length;

    useEffect(() => {
        if (isInactivityModalVisible) {
            clearSelection();
        }
    }, [isInactivityModalVisible]);

    function isSystemAllowedNoCampaign() {
        return !selectedCampaign && !some(bonusBetsSelection);
    }

    function handleBetTypeTabClick(type: BET_TYPE) {
        setBetSlipUserState((state) => ({ ...state, betType: type, userBetTypeSelection: type }));
    }

    const isSingleDisabled = isLoading || isBetbuilder;
    const isComboDisabled = marketsCount < 2 || isSingleDisabled;
    const isSystemDisabled =
        marketsCount < 3 ||
        marketsCount > 15 ||
        !isSystemAllowedNoCampaign() ||
        isSingleDisabled ||
        betSlipHasBetbuilderMarket;
    const isTeasersButtonVisible =
        betType === BET_TYPE.TEASER ||
        (isTeasersAvailable && !teaserMainLineErrorMarketIds.length && !teaserInvalidMainLineErrorMarketIds.length);

    return (
        <Wrapper>
            <div className="betslip-header">
                <div className="header-title">
                    <div className="bets-count">{numberOfBets}</div>
                    <div className="betslip-title">{translate('Betslip', 'ui.sportsbook')}</div>
                </div>
                <div className="clear-all" onClick={() => clearBetslipSelection(BetslipMode.Betslip)}>
                    {translate('Clear all', 'ui.sportsbook')}
                </div>
            </div>

            <div className="bet-type-controls">
                {!isBetbuilder ? (
                    <>
                        <UiTabs>
                            <UiTabsItem
                                onClick={() => handleBetTypeTabClick(BET_TYPE.SINGLE)}
                                selected={betType === BET_TYPE.SINGLE}
                                isDisabled={isSingleDisabled}
                                size="small"
                                dataTest="single"
                            >
                                {translate('Single', 'ui.sportsbook')}
                            </UiTabsItem>
                            <UiTabsItem
                                onClick={() => handleBetTypeTabClick(BET_TYPE.COMBO)}
                                selected={betType === BET_TYPE.COMBO}
                                isDisabled={isComboDisabled}
                                size="small"
                                dataTest="combo"
                            >
                                {translate('Combo', 'ui.sportsbook')}
                            </UiTabsItem>
                            {isTeasersButtonVisible && (
                                <UiTabsItem
                                    onClick={() => handleBetTypeTabClick(BET_TYPE.TEASER)}
                                    selected={betType === BET_TYPE.TEASER}
                                    isDisabled={isLoading}
                                    size="small"
                                    dataTest="teaser"
                                >
                                    {translate('Teasers', 'ui.sportsbook.teasers')}
                                </UiTabsItem>
                            )}
                            <UiTabsItem
                                onClick={() => handleBetTypeTabClick(BET_TYPE.SYSTEM)}
                                selected={betType === BET_TYPE.SYSTEM}
                                isDisabled={isSystemDisabled}
                                size="small"
                                dataTest="system"
                            >
                                {translate('System', 'ui.sportsbook')}
                            </UiTabsItem>
                        </UiTabs>

                        {!isB2B() && (
                            <div className="betslip-settings">
                                <SportSettingsMenuButton settings={['WAGER_TO_WIN']} isCompact />
                            </div>
                        )}
                    </>
                ) : (
                    <UiTabs>
                        <UiTabsItem
                            size="small"
                            isDisabled={isLoading || !isBetbuilder}
                            onClick={() => setBetSlipUserState((state) => ({ ...state, betType: BET_TYPE.BETBUILDER }))}
                            selected={isBetbuilder}
                        >
                            {translate('Betbuilder', 'ui.sportsbook')}
                        </UiTabsItem>
                    </UiTabs>
                )}
            </div>
        </Wrapper>
    );
}
