import { parse } from 'query-string';
import React, { useEffect } from 'react';
import { useStore } from '../../../hooks/useStore';
import { translate } from '../../../services/translate';
import { stores } from '../../../stores';
import Logo from '../../logo/Logo';
import AuthAppeal from '../appeal/AuthAppeal';
import AuthTwoFactorEmail from '../two-factor/email/AuthTwoFactorEmail';
import AuthTwoFactorSms from '../two-factor/sms/AuthTwoFactorSms';
import AuthVerification from '../verification/AuthVerification';
import AuthLoginForm from './form/AuthLoginForm';
import Wrapper from './styles';

interface Props {
    onClose?: () => void;
}

export default function AuthLogin({ onClose }: Props) {
    const queryParams = parse(window.location.search);
    const { loginFooterMessage, loginHeaderMessage, loginRedirectUrl } = queryParams;

    const [isAppealFormOpen, setIsAppealFormOpen] = useStore(stores.appeal.isFormOpen);
    const [isLoginFormOpen, setIsLoginFormOpen] = useStore(stores.modals.isLoginFormOpen);
    const [isTwoFactorSmsActive, setIsTwoFactorSmsActive] = useStore(stores.twoFactor.isTwoFactorSmsActive);
    const [isTwoFactorEmailActive, setIsTwoFactorEmailActive] = useStore(stores.twoFactor.isTwoFactorEmailActive);
    const [isVerificationActive, setIsVerificationActive] = useStore(stores.verification.isVerificationActive);

    useEffect(
        () => () => {
            setIsLoginFormOpen(true);
            setIsAppealFormOpen(false);
            setIsTwoFactorSmsActive(false);
            setIsTwoFactorEmailActive(false);
            setIsVerificationActive(false);
        },
        [],
    );

    return (
        <Wrapper>
            <div className="login-header">
                <Logo size={35} />
            </div>
            <div className="login-content">
                {isLoginFormOpen && (
                    <>
                        {loginHeaderMessage && (
                            <p className="login-pnp-header">{translate(loginHeaderMessage as string)}</p>
                        )}
                        <AuthLoginForm
                            onClose={onClose}
                            onSuccess={
                                loginRedirectUrl
                                    ? () => {
                                          window.location.href = loginRedirectUrl as string;
                                      }
                                    : undefined
                            }
                        />
                        {loginFooterMessage && (
                            <p
                                className="login-pnp-footer"
                                dangerouslySetInnerHTML={{
                                    __html: translate(loginFooterMessage as string),
                                }}
                            />
                        )}
                    </>
                )}
                {isTwoFactorSmsActive && <AuthTwoFactorSms />}
                {isTwoFactorEmailActive && <AuthTwoFactorEmail />}
                {isVerificationActive && <AuthVerification />}
                {isAppealFormOpen && <AuthAppeal />}
            </div>
        </Wrapper>
    );
}
