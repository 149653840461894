import React from 'react';
import Wrapper from './styles';
import UiNavLink from '../../ui/nav-link/UiNavLink';
import Logo from '../../logo/Logo';
import { getRoute } from '../../../services/router';
import { isFeatureAvailable } from '../../../services/feature';
import { FEATURE } from '../../../services/types';

export default function ByodHeader() {
    const isTwoToneTheme = !isFeatureAvailable(FEATURE.THEME_SINGLE_TONE);

    return (
        <Wrapper isTwoToneTheme={isTwoToneTheme}>
            <div className="header-info">
                <div className="header-logo">
                    <UiNavLink to={getRoute('sport')}>
                        <Logo size={30} />
                    </UiNavLink>
                </div>
            </div>
        </Wrapper>
    );
}
