import React from 'react';
import { stores, useStoreWithSelector } from '../../../../stores';
import Wrapper from './styles';
import { BG_GAME_SPORTS, SPORT_ID } from '../../../../services/sports/constants';
import isNil from 'lodash/isNil';
import { useInplayScores } from '../../../../services/sports/inplay-helpers';
import { useSportsMatchContext } from '../../../../services/sports/match-helpers';
import { useSportsUserSettings } from '../../../../services/sports/user-settings';
import { filterStyleProps } from '../../../../styles/utils';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    sportId: number;
    sportCategoryId: number;
    isLivebetOur: boolean;
    matchId: number;
    isCompact?: boolean;
    forceIsAmericanLayout?: boolean | null;
}

export default function SportMatchScore({
    isLivebetOur,
    sportId,
    sportCategoryId,
    matchId,
    isCompact,
    forceIsAmericanLayout = null,
    ...rest
}: Props) {
    const [inplayData] = useStoreWithSelector(stores.sports.inplayMatchData, (state) => state[matchId], [matchId]);
    const { home_team_points, away_team_points, home_team_games, away_team_games, custom = {} } = inplayData || {};

    const hasGameScores = home_team_games !== undefined && home_team_games !== null;
    const hasPoints = !isNil(custom.home_team_points) || !isNil(home_team_points);
    const isBadminton = sportId === SPORT_ID.BADMINTON;
    const isVolleyball = sportId === SPORT_ID.VOLLEYBALL;
    const showRoundsAndPoints = !isLivebetOur && (isBadminton || isVolleyball || (hasGameScores && hasPoints));
    const showRoundsOrPoints = !showRoundsAndPoints && (hasGameScores || hasPoints);
    const { isAmericanLayout } = useSportsUserSettings({ sportCategoryId });
    const [isMobileCompactView] = useStore(stores.sports.isMobileCompactView);
    const useAmericanLayout = forceIsAmericanLayout ?? isAmericanLayout;
    const isAmericanCompactLayout = useAmericanLayout && isMobileCompactView && isCompact;
    const { isOpen } = useSportsMatchContext();

    let roundType = 'S';
    let pointsOrGames = 'P';
    if (BG_GAME_SPORTS.includes(sportId)) {
        roundType = 'G';
    }
    if (sportId === SPORT_ID.TENNIS) {
        pointsOrGames = 'G';
    }

    const homeTeamPoints = custom.home_team_points || home_team_points || 0;
    const awayTeamPoints = custom.away_team_points || away_team_points || 0;
    const homeTeamGames = home_team_games || 0;
    const awayTeamGames = away_team_games || 0;

    useInplayScores(matchId);

    return (
        <Wrapper
            {...filterStyleProps({ ...rest })}
            $isCompact={isCompact}
            $isAmericanCompactLayout={isAmericanCompactLayout && !isOpen}
        >
            {showRoundsOrPoints && !useAmericanLayout && (
                <div className="match-score">
                    <div className="score-box">
                        <span className="score">{hasGameScores ? homeTeamGames : homeTeamPoints}</span>
                    </div>
                    {!isCompact && <span>-</span>}
                    <div className="score-box">
                        <span className="score">{hasGameScores ? awayTeamGames : awayTeamPoints}</span>
                    </div>
                </div>
            )}

            {showRoundsOrPoints && useAmericanLayout && (
                <div className="match-score">
                    <div className="score-box away">
                        <span className="score">{hasGameScores ? awayTeamGames : awayTeamPoints}</span>
                    </div>
                    {!isCompact && <span>-</span>}
                    <div className="score-box home">
                        <span className="score">{hasGameScores ? homeTeamGames : homeTeamPoints}</span>
                    </div>
                </div>
            )}

            {showRoundsAndPoints && (
                <div className="match-score sets">
                    {isCompact && (
                        <div className="score-description">
                            <span>{roundType}</span>
                            <span>{pointsOrGames}</span>
                        </div>
                    )}
                    <div className="home-scores">
                        {!isCompact && (
                            <div className="score-description">
                                <span>{roundType}</span>
                                <span>{pointsOrGames}</span>
                            </div>
                        )}
                        <div className="score-box">
                            <span className="score">{homeTeamGames}</span>
                        </div>
                        <div className="score-box">
                            <span className="score">{homeTeamPoints}</span>
                        </div>
                    </div>
                    {!isCompact && <span className="score-divider"> - </span>}
                    <div className="away-scores">
                        {!isCompact && (
                            <div className="score-description">
                                <span>{roundType}</span>
                                <span>{pointsOrGames}</span>
                            </div>
                        )}
                        <div className="score-box">
                            <span className="score">{awayTeamGames}</span>
                        </div>
                        <div className="score-box">
                            <span className="score">{awayTeamPoints}</span>
                        </div>
                    </div>
                </div>
            )}
        </Wrapper>
    );
}
