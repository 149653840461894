import React, { useEffect, useState } from 'react';
import { preventEvent } from '../../../services/util';
import { useUiFormContext } from '../../../contexts/ui-form/UiFormContext';
import { withUiFormContext } from '../../../higher-order-components/context/UiFormContext';
import classNames from 'classnames';
import { filterStyleProps } from '../../../styles/utils';

interface Props {
    className?: string;
    onSubmit?: (isFormValid: boolean) => Promise<void | null | undefined> | void;
    onValuesChange?: (args?: any) => void;
    isLoading?: boolean;
    onFormValidityChange?: (args?: any) => void;
    onFormInteractedChange?: (args?: any) => void;
    validators?: ((form: unknown) => { name: string; error: string } | undefined)[];
    children?: false | React.ReactNode | React.ReactNode[];
}

/**
 * @deprecated The component should be replaced with Ui2Form
 */
function UiForm(props: Props) {
    const {
        className,
        onSubmit = () => {},
        onValuesChange = () => {},
        isLoading = false,
        onFormValidityChange = () => {},
        onFormInteractedChange = () => {},
        validators = [],
        children,
    } = props;

    const {
        isFormValid,
        isFormInteractedWith,
        triggerValidation,
        setIsFormLoading,
        inputValueByName,
        setFormMessagesByNames,
    } = useUiFormContext();

    const [isInitialValidityAnnouncement, setIsInitialValidityAnnouncement] = useState(true);

    async function runValidators(validators) {
        setFormMessagesByNames({});

        if (validators.length === 0) {
            return;
        }

        const messagesByNames: Record<string, any> = {};

        for (const validator of validators) {
            const result = await validator(inputValueByName);

            if (result) {
                const { name, ...other } = result;
                messagesByNames[name] = other;
            }
        }

        setFormMessagesByNames(messagesByNames);
    }

    useEffect(() => {
        if (validators.length === 0) {
            return;
        }

        const timeout = setTimeout(() => runValidators(validators), 500);
        return () => clearTimeout(timeout);
    }, [inputValueByName, validators]);

    useEffect(() => {
        onValuesChange(inputValueByName);
    }, [inputValueByName]);

    useEffect(() => {
        if (isInitialValidityAnnouncement) {
            setIsInitialValidityAnnouncement(false);
            return;
        }

        onFormValidityChange(isFormValid);
    }, [isFormValid]);

    useEffect(() => {
        onFormInteractedChange(isFormInteractedWith);
    }, [isFormInteractedWith]);

    useEffect(() => {
        setIsFormLoading(isLoading);
    }, [isLoading]);

    function handleSubmit(event) {
        if (!isFormValid) {
            triggerValidation();
        }

        if (onSubmit) {
            preventEvent(event);
            onSubmit(isFormValid);
        }
    }

    return (
        <div {...filterStyleProps(props)} className={classNames(className)}>
            <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                {children}
            </form>
        </div>
    );
}

export default withUiFormContext<Props>(UiForm);
