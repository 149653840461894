import styled, { css } from 'styled-components';

type StyledProps = {
    $withChatBubble?: boolean;
};

export default styled.div<StyledProps>`
    padding-bottom: env(safe-area-inset-bottom);
    position: fixed;
    bottom: ${({ $withChatBubble }) => `${$withChatBubble ? 9 : 5}rem`};
    right: 0.6rem;
    z-index: 10;
    animation-duration: 200ms;
    border-radius: 100px;
    > *:not(:last-child) {
        margin-bottom: 1rem;
    }
    .back-link,
    .sport-link {
        height: 45px;
        width: 45px;
        background: var(--surface-level-2-bg);
        border: var(--surface-level-2-border);
        box-shadow: var(--ui-shadow);
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--font-color-primary);
    }
    .safe-area {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .sport-link {
        background: var(--sport-menu-select-bg);
        color: var(--sport-menu-select-font-color);
        border-color: var(--sport-menu-select-font-color);
    }
    .betslip-link {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        background: var(--odds-bg-active);
        border: var(--odds-border-active);
        box-shadow: var(--ui-shadow);
        border-radius: 100px;
        width: 45px;
        height: 45px;

        ${({ hidden }) =>
            hidden &&
            css`
                display: none !important;
            `};

        &::after {
            opacity: 0;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            content: '';
            height: 100%;
            width: 100%;
            border: 12px solid var(--odds-bg-active);
            border-radius: 100%;
            animation-name: ripple;
            animation-duration: 3s;
            animation-delay: 0s;
            animation-iteration-count: infinite;
            animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
            z-index: -1;
        }

        &::before {
            opacity: 0;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            content: '';
            height: 100%;
            width: 100%;
            border: 8px solid var(--odds-bg-active);
            border-radius: 100%;
            animation-name: ripple;
            animation-duration: 3s;
            animation-delay: 0.5s;
            animation-iteration-count: infinite;
            animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
            z-index: -1;
        }
        a {
            color: var(--font-color-primary);
        }
        .bets-count {
            color: var(--odds-text-active);
            font-weight: var(--font-weight-bold);
            font-size: 1.25rem;
        }
    }
    @keyframes ripple {
        from {
            opacity: 1;
            transform: scale3d(0.75, 0.75, 1);
        }

        to {
            opacity: 0;
            transform: scale3d(1.5, 1.5, 1);
        }
    }
`;
