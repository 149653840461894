import { CanadaProvince, Country, MarketsByRegion } from '@staycool/location';
import * as ibantools from 'ibantools';
import deburr from 'lodash/deburr';
import moment from 'moment';
import isEmail from 'validator/es/lib/isEmail';
import isMobilePhone from 'validator/es/lib/isMobilePhone';
import { validateEmail } from '../microservices/users';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import {
    validateChileanPersonalId,
    validateEstonianPersonalId,
    validateFinnishPersonalId,
    validateSwedishPersonalId,
} from './auth';
import { getActiveCurrency } from './currency';
import { PERU_SHARED_BANKS } from './payments/payments';
import { REGEX_BITCOIN_WALLET_ADDRESS, REGEX_TETHER_WALLET_ADDRESS } from './regex';
import { validateDepositLimitsMinimumAmount } from './responsible-gaming';
import { translate } from './translate';
import { getSignupAgeLimit, getUnderageError, getUserWithExtra, isFieldsValid } from './user';
import { FEATURE, User } from './types';
import { isFeatureAvailable } from './feature';
import { validateEnteredBonusCode, validatePublicBonusCode } from './bonuses/bonus';
import { LIMIT_TYPE } from './responsible-gaming/types';
import { Validator } from '../types/components/form/types';

export const validator: Validator = {
    alias: (value: string) => {
        if (value.length < 6 || value.length > 18) {
            return translate('User name must be 6-18 characters long', 'ui.registration');
        }
        return true;
    },
    bancoDeChileRutAccount: (_, form) => {
        const BANCO_DE_CHILE_BANK_CODE = '001';
        if (form.bankCode !== BANCO_DE_CHILE_BANK_CODE || form.accountType !== 'Salary Account') {
            return true;
        }
        const user = getStoreValue<User | undefined>(stores.user);
        if (!user?.personalId) {
            return true;
        }
        const [rutBeforeDash, rutAfterDash] = user?.personalId.split('-') || [];
        const rutRegex = new RegExp(`^${rutBeforeDash}(-?${rutAfterDash || '.+'})?`);
        const shouldBeBancoDelEstadoDeChile =
            form.bankCode === BANCO_DE_CHILE_BANK_CODE &&
            form.accountType === 'Salary Account' &&
            rutRegex.test(form.number);
        if (!shouldBeBancoDelEstadoDeChile) {
            return translate(
                'Please keep in mind that if you are trying to withdraw to a Banco de Chile Vista/RUT bank account, then the correct bank is "Banco del Estado de Chile"',
                'ui.payments',
            );
        }
        return true;
    },
    bankAccountCanada: (value) => {
        const isValidLength = value >= 7 && value <= 12;
        if (!isValidLength) {
            return translate(
                'Bank account number length must be between {{ minAmount }} and {{ maxAmount }} characters. Please check entered data',
                'ui.payments',
                {
                    minAmount: 7,
                    maxAmount: 12,
                },
            );
        }
        return true;
    },
    bankAccountMexico: (number) => {
        if (!number || number.length !== 18) {
            return true;
        }
        let sum = 0;
        const clabeWithoutCheckDigit = number.substring(0, 17).split('');
        const factors = [3, 7, 1];
        clabeWithoutCheckDigit.forEach(
            (checkDigitToVerify, index) => (sum += (+checkDigitToVerify * factors[index % 3]) % 10),
        );
        const calculatedCheckDigit = (10 - (sum % 10)) % 10;
        const checkDigit = +number.substring(17);
        if (calculatedCheckDigit !== checkDigit) {
            return translate('Invalid bank account number. Please check the entered data', 'ui.payments');
        }
        return true;
    },
    bankAccountPeru: (number) => {
        if (!number || number.length < 3) {
            return true;
        }
        if (!PERU_SHARED_BANKS.includes(number.substring(0, 3))) {
            return translate('Bank payouts to this bank are not supported', 'ui.payments');
        }
        return true;
    },
    bitcoinWalletAddress: (value) => {
        if (!REGEX_BITCOIN_WALLET_ADDRESS.test(value)) {
            return translate('Bitcoin Wallet Address is invalid.', 'ui.deposit');
        }
        return true;
    },
    email: (value) => {
        if (!isEmail(value)) {
            return translate('Oops! Seems, you have mistyped your email.', 'ui.registration');
        }
        return true;
    },
    iban: (value) => {
        const iban = ibantools.electronicFormatIBAN(value);
        if (!ibantools.isValidIBAN(iban)) {
            return translate('Invalid IBAN number', 'ui.common');
        }
        return true;
    },
    ibanSupportedNorway: (value) => {
        if (!value || value.length < 8) {
            return true;
        }
        if (!value.startsWith('NO')) {
            return true;
        }
        if (['9355', '9356'].includes(value.substring(4, 8))) {
            return translate('Payouts to Bank Norwegian not supported', 'ui.payments');
        }
        return true;
    },
    institutionNumberCanada: (value) => {
        if (value.length !== 3) {
            return translate('Institution number can only be {{ maxDigits }} digits', 'ui.payments', {
                maxDigits: 3,
            });
        }
        return true;
    },
    mobilePhone: (value) => {
        if (!isMobilePhone(value)) {
            return translate('invalid phone number', 'ui.common');
        }
        return true;
    },
    tetherWalletAddress: (value) => {
        if (!REGEX_TETHER_WALLET_ADDRESS.test(value)) {
            return translate('Tether Wallet Address is invalid.', 'ui.deposit');
        }
        return true;
    },
    transitNumberCanada: (value) => {
        if (value.length !== 5) {
            return translate('Transit number can only be {{ maxDigits }} digits', 'ui.payments', {
                maxDigits: 5,
            });
        }
        return true;
    },
    address: (value) => validateAddress(value)?.error || true,
    password: (value) => {
        if (isFeatureAvailable(FEATURE.REGISTRATION_B2B || isFeatureAvailable(FEATURE.REGISTRATION_B2B_BASIC))) {
            const error = getB2bPasswordError(value);
            return error || true;
        }
        const error = validators.password(value);
        return error?.error || true;
    },
    passwordDifferentThanEmail: (_, form) => {
        return form.newPassword === form.email
            ? translate('Password can not be the same as email', 'ui.registration')
            : true;
    },
    passwordConfirm: (_, form) =>
        form.passwordConfirm !== form.newPassword ? translate(`Passwords don't match`, 'ui.registration') : true,
    personalIdEstonian: (value) => {
        const { error } = validators.personalIdEstonian(value) || {};
        return error || true;
    },
    personalIdPeru: (value) => {
        const { error } = validators.personalIdPeru(value) || {};
        return error || true;
    },
    personalIdPeruForeigner: (value) => {
        const { error } = validators.personalIdPeruForeigner(value) || {};
        return error || true;
    },
    personalIdSwedish: (value) => {
        const { error } = validators.personalIdSwedish(value) || {};
        return error || true;
    },
    promoCode: async (value: string) => {
        const res = await validators.registrationPromoCode(value);

        return res?.error || true;
    },
};

export function getFieldValidators(keys: (keyof typeof validator)[]) {
    const validations: {
        validate: Validator;
    } = {
        validate: {},
    };
    keys.forEach((key) => {
        if (!validator[key]) {
            return;
        }
        validations.validate[key] = validator[key];
    });
    return validations;
}

export const validators = {
    chain(...validators) {
        return async (value) => {
            for (const validator of validators) {
                const err = await validator(value);

                if (err) {
                    return err;
                }
            }
        };
    },

    bankAccountCanada(number: string) {
        if (number.length < 7) {
            return {
                error: translate(
                    'Bank account number length must be between {{ minAmount }} and {{ maxAmount }} characters. Please check entered data',
                    'ui.payments',
                    {
                        minAmount: 7,
                        maxAmount: 12,
                    },
                ),
            };
        }
    },

    bankAccountChile(number) {
        if (number.length < 6 || number.length > 12) {
            return {
                error: translate(
                    'Bank account number length must be between {{ minLength }} and {{ maxLength }} characters. Please check entered data',
                    'ui.payments',
                    {
                        minLength: 6,
                        maxLength: 12,
                    },
                ),
            };
        }
    },

    bankAccountEcuador(number) {
        if (number.length < 7 || number.length > 13) {
            return {
                error: translate(
                    'Bank account number length must be between {{ minLength }} and {{ maxLength }} characters. Please check entered data',
                    'ui.payments',
                    {
                        minLength: 7,
                        maxLength: 13,
                    },
                ),
            };
        }
    },

    bankAccountMexico(number: string) {
        const maxLength = 18;
        if (number.length !== maxLength) {
            return {
                error: translate(
                    'Bank account number length must be 18 characters. Please check the entered data',
                    'ui.payments',
                ),
            };
        }
        let sum = 0;
        const clabeWithoutCheckDigit = number.substring(0, 17).split('');
        const factors = [3, 7, 1];
        clabeWithoutCheckDigit.forEach(
            (checkDigitToVerify, index) => (sum += (+checkDigitToVerify * factors[index % 3]) % 10),
        );
        const calculatedCheckDigit = (10 - (sum % 10)) % 10;
        const checkDigit = +number.substring(17);
        if (calculatedCheckDigit !== checkDigit) {
            return {
                error: translate('Invalid bank account number. Please check the entered data', 'ui.payments'),
            };
        }
    },

    bankAccountPeru(number) {
        if (number.length < 18 || number.length > 20) {
            return {
                error: translate(
                    'Bank account number length must be of 18-20 characters in length. Please check the entered data',
                    'ui.payments',
                ),
            };
        }
        if (!PERU_SHARED_BANKS.includes(number.substring(0, 3))) {
            return {
                error: translate('Bank payouts to this bank are not supported', 'ui.payments'),
            };
        }
    },

    email(value) {
        if (!isEmail(value)) {
            return { error: translate('Oops! Seems, you have mistyped your email.', 'ui.registration') };
        }
    },

    institutionNumberCanada(number: string) {
        if (number.length !== 3) {
            return {
                error: translate('Institution number can only be {{ maxDigits }} digits', 'ui.payments', {
                    maxDigits: 3,
                }),
            };
        }
    },

    transitNumberCanada(number: string) {
        if (number.length !== 5) {
            return {
                error: translate('Transit number can only be {{ maxDigits }} digits', 'ui.payments', {
                    maxDigits: 5,
                }),
            };
        }
    },

    async registrationEmail(value) {
        if (!isEmail(value)) {
            return { error: translate('Oops! Seems, you have mistyped your email.', 'ui.registration') };
        }
        const { isDisposableEmail } = await validateEmail(value);
        if (isDisposableEmail) {
            return { error: translate('Disposable email addresses are not allowed', 'ui.registration') };
        }
    },

    async registrationEmailB2b(
        value,
        regex: string | RegExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
    ) {
        const isValidEmail = value.match(regex);
        if (!isValidEmail) {
            return {
                error: translate('Please enter a valid email', 'ui.registration'),
            };
        }
        const { isDisposableEmail } = await validateEmail(value);
        if (isDisposableEmail) {
            return { error: translate('Disposable email addresses are not allowed', 'ui.registration') };
        }
    },

    iban(value) {
        const iban = ibantools.electronicFormatIBAN(value);
        if (!ibantools.isValidIBAN(iban)) {
            return {
                error: translate('Invalid IBAN number', 'ui.common'),
            };
        }
        if (iban.startsWith('NO') && ['9355', '9356'].includes(iban.substring(4, 8))) {
            return { error: translate('Payouts to Bank Norwegian not supported', 'ui.payments') };
        }
    },

    password(value: string, email?: string, regex: string | RegExp = /^(?=.*[0-9])(?=.*[a-zA-Z!@#$%^&*]).{8,25}$/) {
        if (!value.match(regex)) {
            return {
                error: translate(
                    'Password must contain numbers and letters and should be between 8 and 25 characters long.',
                    'ui.registration',
                ),
            };
        }
        if (email && value === email) {
            return { error: translate('Password can not be the same as email', 'ui.registration') };
        }
    },

    passwordB2b(value: string, email: string) {
        const error = getB2bPasswordError(value);

        if (error) {
            return { error };
        }

        if (value === email) {
            return { error: translate('Password can not be the same as email', 'ui.registration') };
        }
    },

    passwordConfirm(value: string, password: string) {
        if (value !== password) {
            return { error: translate('Passwords do not match', 'ui.registration') };
        }
    },

    terms(value) {
        return !value && { error: translate('Terms and conditions must be accepted!', 'ui.registration') };
    },
    smsPin(value) {
        return !value && { error: translate('Please enter sms pin', 'ui.registration') };
    },
    emailPin(value) {
        return !value && { error: translate('Please enter email pin', 'ui.registration') };
    },
    personalIdChile(value) {
        const { isValidFormat } = validateChileanPersonalId(value);

        if (!isValidFormat) {
            return {
                error: translate('RUT code is not valid.', 'ui.common'),
            };
        }
    },
    personalIdEcuadorian(value) {
        if (!value) {
            return { error: translate('Oops! You forgot to enter your Personal code.', 'ui.registration') };
        }

        if (!/^[0-9]{10}$/.test(value)) {
            return { error: translate('Personal code is invalid!', 'ui.registration') };
        }

        const provinceCode = parseInt(value.substring(0, 2));
        if (provinceCode !== 30 && (provinceCode < 1 || provinceCode > 24)) {
            return { error: translate('Personal code is invalid!', 'ui.registration') };
        }
        let total = 0;
        const coefficients = [2, 1, 2, 1, 2, 1, 2, 1, 2];
        for (let i = 0; i < 9; i++) {
            let proceeds = parseInt(value[i]) * coefficients[i];
            if (proceeds >= 10) {
                proceeds = proceeds
                    .toString()
                    .split('')
                    .map((digit) => parseInt(digit))
                    .reduce((sum, digit) => sum + digit);
            }
            total += proceeds;
        }
        const residue = total % 10;
        const verifiedDigitValue = residue === 0 ? residue : 10 - residue;
        if (verifiedDigitValue !== parseInt(value.slice(-1))) {
            return { error: translate('Personal code is invalid!', 'ui.registration') };
        }
    },
    personalIdEstonian(value) {
        if (!value) {
            return { error: translate('Oops! You forgot to enter your Personal code.', 'ui.registration') };
        }

        const { isValidFormat, isValidAge } = validateEstonianPersonalId(value.toString());

        if (!isValidFormat) {
            return { error: translate('Personal code is invalid!', 'ui.registration') };
        }

        if (!isValidAge) {
            return {
                error: getUnderageError(Country.ESTONIA),
            };
        }
    },
    personalIdFinnish(value) {
        if (!value) {
            return { error: translate('Oops! You forgot to enter your Personal code.', 'ui.registration') };
        }
        if (value === '000000-0000') {
            return;
        }
        const { isValidFormat, isValidAge } = validateFinnishPersonalId(value);

        if (!isValidFormat) {
            return { error: translate('Personal code is invalid!', 'ui.registration') };
        }

        if (!isValidAge) {
            return {
                error: getUnderageError(Country.FINLAND),
            };
        }
    },
    personalIdPeru(value) {
        if (!value) {
            return { error: translate('Oops! You forgot to enter your CUI number.', 'ui.registration') };
        }

        // ask payments team for source of algorithm
        if (!/^\d{8}[0-9A-Z]*$/.test(value)) {
            return { error: translate('CUI number is invalid', 'ui.registration') };
        }

        if (value.length === 8) {
            return;
        }

        const weight = [3, 2, 7, 6, 5, 4, 3, 2];
        let sum = 0;
        for (let i = 0; i < 8; i++) {
            sum += weight[i] * parseInt(value.charAt(i), 10);
        }
        const key = sum % 11;
        const controlDigit = [6, 5, 4, 3, 2, 1, 1, 0, 9, 8, 7][key];
        const controlCharacter = 'KJIHGFEDCBA'.charAt(key);

        const isValid = value.charAt(8) === `${controlDigit}` || value.charAt(8) === controlCharacter;
        if (!isValid) {
            return { error: translate('CUI number is invalid!', 'ui.registration') };
        }
    },
    personalIdPeruForeigner(value) {
        if (!value) {
            return { error: translate('Oops! You forgot to enter your Card number.', 'ui.registration') };
        }
        const isValid = /^\d{9}[0-9]*$/.test(value) && value.length === 9;
        if (!isValid) {
            return { error: translate('Card number is invalid!', 'ui.registration') };
        }
    },
    personalIdSwedish(value) {
        const { isValidFormat, isValidAge } = validateSwedishPersonalId(value);

        if (!isValidFormat) {
            return {
                error: translate(
                    'Personal identity number must be 12 digits long and formatted as YYYYMMDDXXXX.',
                    'ui.registration',
                ),
            };
        }

        if (!isValidAge) {
            return {
                error: getUnderageError(Country.SWEDEN),
            };
        }
    },

    personalIdMexican(value?: string) {
        if (!value) {
            return { error: translate('Oops! You forgot to enter your Personal code.', 'ui.registration') };
        }
        if (value.length !== 18) {
            return { error: translate('Personal code is invalid!', 'ui.registration') };
        }

        const REGEX = /^[A-Z][AEIOUX][A-Z]{2}[0-9]{6}[HM][A-Z]{2}[B-DF-HJ-NP-TV-Z]{3}[A-Z\d]\d$/;

        if (!REGEX.test(value)) {
            return { error: translate('Personal code is invalid!', 'ui.registration') };
        }

        const ALPHANUMERICS = '0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ';
        let checksum = value
            .substring(0, 17)
            .split('')
            .reduce((sum, char, index) => sum + ALPHANUMERICS.indexOf(char) * (18 - index), 0);
        checksum = (10 - (checksum % 10)) % 10;

        if (Number(value.slice(-1)) !== checksum) {
            return { error: translate('Personal code is invalid!', 'ui.registration') };
        }

        const year = value.substring(4, 6);
        const month = value.substring(6, 8);
        const day = value.substring(8, 10);
        const millennia = isNaN(Number(value.charAt(16))) ? '20' : '19';
        const birthDate = new Date(`${millennia}${year}-${month}-${day}`);

        if (!moment(birthDate).isValid()) {
            return { error: translate('Personal code is invalid!', 'ui.registration') };
        }

        if (moment().diff(birthDate, 'years') < getSignupAgeLimit(Country.MEXICO)) {
            return { error: getUnderageError(Country.MEXICO) };
        }
    },

    firstNameMatchesWithMexicanPersonalId(firstName: string, personalId?: string) {
        firstName = getSuitableNameForMexicanPersonalId(firstName);

        if (!firstName || !personalId) {
            return;
        }

        const [nameFirstChar, ...restOfName] = firstName.split('');
        const nameFirstConsonant = restOfName.find(isConsonant) || 'X';

        const pidNameFirstChar = personalId.charAt(3);
        const pidNameFirstConsonant = personalId.charAt(15);

        if (
            ![nameFirstChar.toUpperCase(), 'X'].includes(pidNameFirstChar) ||
            ![nameFirstConsonant.toUpperCase(), 'X'].includes(pidNameFirstConsonant)
        ) {
            return { error: translate('Your name is not matching with your personal id!', 'ui.registration') };
        }
    },

    mothersNameMatchesWithMexicanPersonalId(mothersName: string, personalId?: string) {
        mothersName = getSuitableNameForMexicanPersonalId(mothersName);

        if (!mothersName || !personalId) {
            return;
        }

        const [nameFirstChar, ...restOfName] = mothersName.split('');
        const nameFirstConsonant = restOfName.find(isConsonant) || 'X';

        const pidNameFirstChar = personalId.charAt(2);
        const pidNameFirstConsonant = personalId.charAt(14);

        if (
            ![nameFirstChar.toUpperCase(), 'X'].includes(pidNameFirstChar) ||
            ![nameFirstConsonant.toUpperCase(), 'X'].includes(pidNameFirstConsonant)
        ) {
            return { error: translate('Your name is not matching with your personal id!', 'ui.registration') };
        }
    },

    fathersNameMatchesWithMexicanPersonalId(fathersName: string, personalId?: string) {
        fathersName = getSuitableNameForMexicanPersonalId(fathersName);

        if (!fathersName || !personalId) {
            return;
        }

        const [nameFirstChar, ...restOfName] = fathersName.split('');
        const nameFirstVowel = restOfName.find(isVowel) || 'X';
        const nameFirstConsonant = restOfName.find(isConsonant) || 'X';

        const pidNameFirstChar = personalId.charAt(0);
        const pidNameFirstVowel = personalId.charAt(1);
        const pidNameFirstConsonant = personalId.charAt(13);

        if (
            ![nameFirstChar.toUpperCase(), 'X'].includes(pidNameFirstChar) ||
            ![nameFirstVowel.toUpperCase(), 'X'].includes(pidNameFirstVowel) ||
            ![nameFirstConsonant.toUpperCase(), 'X'].includes(pidNameFirstConsonant)
        ) {
            return { error: translate('Your name is not matching with your personal id!', 'ui.registration') };
        }
    },

    nameValidCharacters(value: string) {
        if (!/^([A-Za-zÀ-ÖØ-öø-ÿĀ-ž\s.'-]*)$/.test(value)) {
            return {
                error: translate('Oops! Name should only contain latin alphabetical characters.', 'ui.registration'),
            };
        }
    },
    minLength(value: string, length: number = 2) {
        if (value.length < length) {
            return {
                error: translate(`Oops! Minimum required field length is {{ minLength }} characters.`, 'ui.common', {
                    minLength: length,
                }),
            };
        }
    },
    firstName(value: string) {
        if (!value) {
            return { error: translate('Oops! You forgot to enter your first name.', 'ui.registration') };
        }
    },
    lastName(value: string) {
        if (!value) {
            return { error: translate('Oops! You forgot to enter your last name.', 'ui.registration') };
        }
    },
    fathersName(value: string) {
        if (!value) {
            return { error: translate("Oops! You forgot to enter your father's name.", 'ui.registration') };
        }
    },
    mothersName(value: string) {
        if (!value) {
            return { error: translate("Oops! You forgot to enter your mother's name.", 'ui.registration') };
        }
    },
    birthDate(birthDate, country) {
        const { year, month, day } = birthDate;
        if (!year || !month || !day) {
            return {
                error: translate('Oops! You forgot to enter your Birth Date.', 'ui.registration'),
            };
        }

        const birthDateMoment = moment().year(year).month(month).date(day);
        const legalAge = getSignupAgeLimit(country);
        const isLegalAge = moment().diff(birthDateMoment, 'years') >= legalAge;
        if (!isLegalAge) {
            return {
                error: getUnderageError(country),
            };
        }
    },
    uniqueFields(form: any, fieldMap: Record<string, string>) {
        return function (value) {
            for (const [field, translation] of Object.entries(fieldMap)) {
                if (value === form[field]?.value) {
                    return {
                        error: translate('This field cannot be the same as {{ field }}', 'ui.registration', {
                            field: translation,
                        }),
                    };
                }
            }
        };
    },
    address(value: string) {
        if (!value || (value && value.length < 2)) {
            return {
                error: translate('Oops! You forgot to enter your Address.', 'ui.registration'),
            };
        }
        return validateAddress(value);
    },
    address2(value: string) {
        return validateAddress(value);
    },
    zip(value: string, country: Country) {
        if (!value) {
            return { error: translate('Oops! You forgot to enter your Zip code.', 'ui.registration') };
        }
        const alphaNumericZipCountries = [...MarketsByRegion.Latam, Country.CANADA];
        if (!alphaNumericZipCountries.includes(country) && !/^[0-9 ]+$/.test(value)) {
            return { error: translate('Zip code must be numeric.', 'ui.registration') };
        }
    },
    city(value: string) {
        if (value.length < 2) {
            return { error: translate('Oops! You forgot to enter your City.', 'ui.registration') };
        }
    },
    required(value: string) {
        if (!value) {
            return { error: translate('Oops! You forgot this field', 'ui.registration') };
        }
    },

    isNotOntarioProvince(province: string) {
        if (province === CanadaProvince.ONTARIO || province?.includes(', ON,')) {
            return { error: translate('Oops! We do not provide our services in Ontario', 'ui.registration') };
        }
    },

    async alias(value: string) {
        if (value.length < 6) {
            return { error: translate('User name must be 6-18 characters long', 'ui.registration') };
        }
        const isAliasAvailable = await isFieldsValid({ alias: value });

        if (!isAliasAvailable) {
            return { error: translate('taken-username', 'ui.registration') };
        }
    },
    bonusCodeDeposit(depositAmount, selectedProvider) {
        return async (bonusCode) => {
            if (!bonusCode) {
                return;
            }

            const { isError, message, isSuccess, rawResponse, bonusValidationMessage } = await validateEnteredBonusCode(
                bonusCode,
                selectedProvider,
            );
            if (isError) {
                return { error: message };
            }
            const minBonusAmount = rawResponse.min_bonus && rawResponse.min_bonus[getActiveCurrency()];

            if (depositAmount && depositAmount < minBonusAmount) {
                return {
                    error: translate('Minimum amount for bonus "%1" is %2', 'ui.account', [
                        rawResponse.name,
                        minBonusAmount,
                    ]),
                };
            }

            if (isSuccess) {
                return { success: bonusValidationMessage ? `${message} ${bonusValidationMessage}` : message };
            }
            return { error: message };
        };
    },
    async bonusCode(bonusCode) {
        if (!bonusCode) {
            return { error: translate('Please check your code and try again', 'ui.bonus') };
        }
        const { isError, message, isSuccess, bonusValidationMessage } = await validateEnteredBonusCode(bonusCode);

        if (isError) {
            return { error: message };
        }
        if (isSuccess) {
            return { success: bonusValidationMessage ? `${message} ${bonusValidationMessage}` : message };
        }
    },

    async registrationPromoCode(promoCode: string) {
        if (!promoCode) {
            return;
        }

        const { isError, message, isSuccess, bonusValidationMessage } = await validatePublicBonusCode(promoCode);

        if (isError) {
            return { error: message };
        }
        if (isSuccess) {
            return { success: bonusValidationMessage ? `${message} ${bonusValidationMessage}` : message };
        }
    },

    rut(rut) {
        if (!rut) {
            return { error: translate('Oops! You forgot to enter your personal ID code.', 'ui.common') };
        }

        const { isValidFormat } = validateChileanPersonalId(rut);
        if (!isValidFormat) {
            return { error: translate('RUT code is not valid.', 'ui.common') };
        }
    },

    limit(limit: number, type: LIMIT_TYPE) {
        if (!limit) {
            return { error: translate('You must set a limit to continue the registration', 'ui.registration') };
        }
        if (type === LIMIT_TYPE.DEPOSIT) {
            const error = validateDepositLimitsMinimumAmount([limit]);
            if (error) {
                return { error };
            }
        }
    },
    maxSessionReminderTime: (value) => {
        if (value > 120) {
            return translate('Maximum session reminder period is {{ period }}', 'ui.responsible-gaming', {
                period: 120,
            });
        }
        return true;
    },
    mobilePhone(number: string) {
        if (!isMobilePhone(number)) {
            return { error: translate('invalid phone number', 'ui.common') };
        }
    },
    jobTitle(value: string) {
        if (value.length < 3) {
            return {
                error: translate('Job Title needs to be atleast 3 characters', 'ui.account'),
            };
        }
    },
    bitcoinWalletAddress(value: string) {
        const BITCOIN_WALLET_ADDRESS_REGEX = /^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$/;
        if (!value) {
            return { error: translate('Oops! You forgot to enter your Bitcoin Wallet Address.', 'ui.deposit') };
        }
        if (!BITCOIN_WALLET_ADDRESS_REGEX.test(value)) {
            return { error: translate('Bitcoin Wallet Address is invalid.', 'ui.deposit') };
        }
    },
    tetherWalletAddress(value: string) {
        const TETHER_WALLET_ADDRESS_REGEX = /T[A-Za-z1-9]{33}/;
        if (!value) {
            return { error: translate('Oops! You forgot to enter your Tether Wallet Address.', 'ui.deposit') };
        }
        if (!TETHER_WALLET_ADDRESS_REGEX.test(value)) {
            return { error: translate('Tether Wallet Address is invalid.', 'ui.deposit') };
        }
    },
    async bancoDeChileWithRutAccount({ number: accountNumber, accountType, bankCode }) {
        const user = await getUserWithExtra({ personalId: true });

        if (user?.country !== Country.CHILE) {
            return;
        }

        const [rutBeforeDash, rutAfterDash] = user.personalId.split('-');
        const rutRegex = new RegExp(`^${rutBeforeDash}(-?${rutAfterDash || '.+'})?`);
        const BANCO_DE_CHILE = '001';

        const shouldBeBancoDelEstadoDeChile =
            bankCode === BANCO_DE_CHILE && accountType === 'Salary Account' && rutRegex.test(accountNumber);

        if (shouldBeBancoDelEstadoDeChile) {
            const message =
                'Please keep in mind that if you are trying to withdraw to a Banco de Chile Vista/RUT bank account, then the correct bank is "Banco del Estado de Chile"';
            return {
                name: 'bancoDeChileWithRutAccount',
                error: translate(message, 'ui.payments'),
            };
        }
    },

    otpOnly6Digits(value: string | number) {
        if (isNaN(value as number) || String(value).length !== 6) {
            return { error: translate('Oops! You must enter 6 digits.', 'ui.registration') };
        }
    },
};

function validateAddress(value: string) {
    const invalidAddresses = ['Poste restante', 'PO box'];
    const cleanedAddress = value.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
    for (const invalidAddress of invalidAddresses) {
        if (
            invalidAddress
                .toLowerCase()
                .split(' ')
                .every((item) => cleanedAddress.includes(item))
        ) {
            return {
                error: translate(
                    `Oops! "{{ address }}" is not acceptable address by compliance reasons. Please provide real address`,
                    'ui.registration',
                    {
                        address: invalidAddress,
                    },
                ),
            };
        }
    }
}

function getB2bPasswordError(value: string) {
    if (value.length < 8) {
        return translate('Password must contain minimum {{ minLength }} characters long.', 'ui.registration', {
            minLength: 8,
        });
    }
    if (!value.match(/[a-z]/g)) {
        return translate('Password must contain 1 lowercase letter.', 'ui.registration');
    }
    if (!value.match(/[A-Z]/g)) {
        return translate('Password must contain 1 uppercase letter.', 'ui.registration');
    }
    if (!value.match(/[0-9]/g)) {
        return translate('Password must contain 1 digit.', 'ui.registration');
    }
    if (!value.match(/[^\w\s]/g)) {
        return translate('Password must contain 1 special character.', 'ui.registration');
    }
}

function isVowel(char: string) {
    return /^[aeiou]$/i.test(deburr(char));
}

function isConsonant(char: string) {
    return /^[a-z]$/i.test(char) && !isVowel(char);
}

function getSuitableNameForMexicanPersonalId(name: string) {
    if (!name) {
        return '';
    }

    const IGNORED_NAMES = [
        'jose',
        'maria',
        'ma.',
        'ma',
        'j',
        'j.',
        'da',
        'das',
        'de',
        'del',
        'der',
        'di',
        'die',
        'dd',
        'el',
        'la',
        'los',
        'las',
        'le',
        'les',
        'mac',
        'mc',
        'van',
        'von',
        'y',
    ];
    const nameParts = name.split(' ');
    return nameParts.find((part) => !IGNORED_NAMES.includes(deburr(part).toLowerCase())) || nameParts[0];
}
