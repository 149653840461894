import React, { useState } from 'react';
import Wrapper from './styles';
import { useStore } from '../../../../hooks/useStore';
import { stores } from '../../../../stores';
import { isB2B } from '../../../../services/environment';
import AccountMobileSidebar from '../../../account/mobile-sidebar/AccountMobileSidebar';
import { getRoute, useRouter } from '../../../../services/router';
import { media } from '../../../../stores/media/media';
import UiGroup from '../../../ui/group/UiGroup';
import { logout } from '../../../../services/auth';
import Svg from '../../../svg/Svg';
import { translate } from '../../../../services/translate';
import UiNavLink from '../../../ui/nav-link/UiNavLink';
import { isFeatureAvailable } from '../../../../services/feature';
import { FEATURE } from '../../../../services/types';

export default function SimHeaderUser() {
    const [user] = useStore(stores.user);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [{ isPhone, isTablet }] = useStore(media);
    const isMobileDevice = isPhone || isTablet;

    const { navigateTo } = useRouter();

    function toggleMenuOpen() {
        if (isMobileDevice) {
            setIsMenuOpen(!isMenuOpen);
        } else {
            navigateTo(getRoute('account'));
        }
    }

    type Link = { label: string; route: string; icon: string };

    const links: (Link | boolean)[] = [
        {
            label: translate('My Account', 'ui.header'),
            route: getRoute('account'),
            icon: 'user-icon',
        },
        {
            label: translate('Buy credits', 'ui.header'),
            route: getRoute('purchase-packages'),
            icon: 'deposit-ico',
        },
        {
            label: translate('Account History', 'ui.header'),
            route: getRoute('account.access-history'),
            icon: 'clock',
        },
        {
            label: translate('My messages', 'ui.header'),
            route: getRoute('inbox'),
            icon: 'email-icon',
        },
        {
            label: translate('Marketing preferences', 'ui.header'),
            route: getRoute('account.profile'),
            icon: 'more-ico',
        },
        isFeatureAvailable(FEATURE.REFER_A_FRIEND) && {
            label: translate('Refer a friend', 'raf.ui'),
            route: getRoute('refer-a-friend'),
            icon: 'refer-a-friend-ico',
        },
        isFeatureAvailable(FEATURE.LOYALTY_CLUB_LINKING) && {
            label: translate('Loyalty link', 'ui.account'),
            route: getRoute('account.loyalty-link'),
            icon: 'loyalty-card',
        },
        {
            label: translate('Self exclusion', 'ui.header'),
            route: getRoute('responsible-gaming.set-limits'),
            icon: 'rg-self-exclusion',
        },
        {
            label: translate('Change password', 'ui.header'),
            route: getRoute('account.profile'),
            icon: 'menu-dots-horizontal',
        },
    ];

    function getLinks() {
        return links.filter(Boolean).map((link) => {
            const { label, route, icon } = link as Link;
            const dataTest = label.toLowerCase().replace(/\s/g, '-');

            return (
                <UiNavLink key={route} to={route} data-test={dataTest} className="button">
                    <Svg icon={icon} className="icon" />
                    <span>{label}</span>
                </UiNavLink>
            );
        });
    }

    return (
        <>
            <Wrapper>
                <div onClick={toggleMenuOpen} className="user-avatar">
                    {user?.alias[0]}
                </div>
                <div className="account-button-dropdown-container">
                    <UiGroup vertical className="account-button-dropdown">
                        <div className="user-name">
                            Hi, <strong>{user?.alias || user?.firstName}</strong>
                        </div>
                        {getLinks()}
                        <div className="button" data-test="logout" onClick={() => logout()}>
                            <Svg icon="logout-ico" className="icon" />
                            <span>{translate('Logout', 'ui.header')}</span>
                        </div>
                    </UiGroup>
                </div>
            </Wrapper>
            <AccountMobileSidebar
                position={isB2B() ? 'left' : 'right'}
                open={isMenuOpen}
                onClose={toggleMenuOpen}
                onOpen={toggleMenuOpen}
            />
        </>
    );
}
