import styled from 'styled-components';
import UiModal from '../../../ui/modal/UiModal';

export default styled(UiModal)`
    position: relative;
    min-width: 320px;

    .close-button {
        position: absolute;
        top: 0.8rem;
        right: 0.8rem;
    }

    h2 {
        padding: 0 2rem 0 0;
    }

    form {
        text-align: left;
        .form-control {
            padding: 0.25rem 0;
        }
        button {
            margin: 0.25rem auto;
        }
    }

    .consent-loader {
        width: 100%;
        height: 100%;
        background-color: red;
        > div {
            position: absolute;
            top: 50%;
            left: 50%;
        }
    }
`;
