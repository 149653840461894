import React from 'react';
import Wrapper from './styles';
import { filterStyleProps } from '../../../styles/utils';
import UiDotsLoader from '../../ui/dots-loader/UiDotsLoader';

interface Props extends React.PropsWithChildren, React.HTMLAttributes<HTMLButtonElement> {
    onClick: () => void;
    dataTest: string;
    isLoading?: boolean;
}

export default function AuthButton({ onClick, children, dataTest, isLoading, ...rest }: Props) {
    return (
        <Wrapper data-test={dataTest} onClick={onClick} {...filterStyleProps(rest)}>
            {isLoading ? <UiDotsLoader /> : children}
        </Wrapper>
    );
}
