import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { loadKycUserSettings } from '../../../../microservices/kyc';
import { getOS } from '../../../../services/device';
import { useTimeout } from '../../../../services/hooks';
import { SourceOfIncomeStatus } from '../../../../services/kyc/types';
import { isMobileApp } from '../../../../services/mobile-app';
import { useSharedStore } from '../../../../services/persisted-shared-storage';
import { getRoute, useRouter } from '../../../../services/router';
import { translate } from '../../../../services/translate';
import { stores } from '../../../../stores';
import Svg from '../../../svg/Svg';
import UiModal from '../../../ui/modal/UiModal';
import AccountVerificationProofOfAddress from '../proof-of-address/AccountVerificationProofOfAddress';
import AccountVerificationProofOfId from '../proof-of-id/AccountVerificationProofOfId';
import AccountVerificationProofOfPayment from '../proof-of-payment/AccountVerificationProofOfPayment';
import AccountVerificationReopenedPrompt from '../reopened-prompt/AccountVerificationReopenedPrompt';
import AccountVerificationSourceOfFunds from '../source-of-funds/AccountVerificationSourceOfFunds';
import { media } from '../../../../stores/media/media';
import { useStore } from '../../../../hooks/useStore';

export default function AccountVerificationKyc() {
    const { navigateTo } = useRouter();
    const [kycToken, setKycToken] = useSharedStore('kycToken', stores.kyc.kycToken);
    const [kycTokenExpiry, setKycTokenExpiry] = useSharedStore('kycTokenExpiry', stores.kyc.kycTokenExpiry);
    const [, setIsLoginModalOpen] = useStore(stores.modals.isLoginModalOpen);
    const [kycSettings] = useStore(stores.kyc.settings);
    const [isLoading, setIsLoading] = useState(true);
    const [isAccountReopenedPromptVisible, setIsAccountReopenedPromptVisible] = useState(false);
    const [{ isPhone }] = useStore(media);

    useEffect(() => {
        load();
    }, [kycToken]);

    const kycTokenExpiryTimeout = kycTokenExpiry
        ? moment.utc(kycTokenExpiry).diff(moment.utc(), 'milliseconds')
        : undefined;

    useTimeout(
        () => {
            closeKycModal();
            navigateToLogin();
        },
        kycTokenExpiryTimeout,
        [kycTokenExpiryTimeout],
    );

    async function load() {
        if (!kycToken) {
            return;
        }
        await loadKycUserSettings();
        setIsLoading(false);
    }

    useEffect(() => {
        if (!isLoading && kycToken && !kycSettings.isClosed) {
            setIsAccountReopenedPromptVisible(true);
        }
    }, [isLoading, kycToken, kycSettings.isClosed]);

    function closeKycModal() {
        setKycToken(undefined);
        setKycTokenExpiry(undefined);
    }

    function navigateToLogin() {
        if (isMobileApp()) {
            setIsLoginModalOpen(true);
        } else {
            navigateTo(getRoute('login'));
        }
    }

    if (isLoading) {
        return null;
    }

    function handleOnReopenedPromptClose() {
        setIsAccountReopenedPromptVisible(false);
        closeKycModal();
        navigateToLogin();
    }

    return (
        <>
            <UiModal
                open={!!kycToken && !isAccountReopenedPromptVisible}
                onClose={closeKycModal}
                maxWidth={isPhone ? '80vw' : 500}
                icon={<Svg icon="info" size={3} />}
                className="new-modal"
                title={translate('kyc-closed-account', 'ui.account')}
                mode={isPhone ? 'drawer' : 'default'}
                shouldDisplayCloseButton
                disableBackdropClick
                disableEscapeKeyDown
            >
                {isMobileApp() && getOS() === 'iOS' ? (
                    <p>
                        {translate(
                            [
                                'login-via-desktop',
                                'Please login via desktop or your mobile browser to verify your identity',
                            ],
                            'ui.account',
                        )}
                    </p>
                ) : (
                    <>
                        <p>{translate('please-upload-id-documents', 'ui.account')}</p>
                        <p>{translate('following-documents-required', 'ui.account')}</p>
                        <div className="flex column gap-16">
                            <AccountVerificationProofOfId isCompactView />
                            <AccountVerificationProofOfAddress isCompactView />
                            <AccountVerificationProofOfPayment isCompactView />
                            {kycSettings.sourceOfIncomeStatus !== SourceOfIncomeStatus.NOT_CHECKED && (
                                <AccountVerificationSourceOfFunds isCompactView />
                            )}
                        </div>
                        <p>{translate('scanned-documents-not-accepted', 'ui.account')}</p>
                        <p>{translate('contact-support-for-info', 'ui.account')}</p>
                    </>
                )}
            </UiModal>
            <AccountVerificationReopenedPrompt
                isOpen={isAccountReopenedPromptVisible}
                onClose={handleOnReopenedPromptClose}
            />
        </>
    );
}
