import { Country } from '@staycool/location';
import React from 'react';
import { useStore } from '../../../../../hooks/useStore';
import { startFtnAuthentication } from '../../../../../microservices/auth';
import { isVisitorFromCountry } from '../../../../../services/auth';
import { logger } from '../../../../../services/logger';
import { stores } from '../../../../../stores';
import Svg from '../../../../svg/Svg';
import AuthButton from '../../../button/AuthButton';
import UiButton from '../../../../ui/button/UiButton';

interface Props {
    isPreferred?: boolean;
}

export default function AuthLoginButtonFinnishBankId({ isPreferred = false }: Props) {
    const [language] = useStore(stores.language);

    async function login() {
        try {
            const url = await startFtnAuthentication(language);
            window.location.href = url;
        } catch (error) {
            logger.error('AuthLoginButtonFinnishBankId', 'login', error);
        }
    }

    if (isPreferred) {
        return (
            <UiButton
                block
                color="primary"
                type="button"
                onClick={login}
                icon={<Svg icon="bank" />}
                iconPosition="left"
            >
                Pankkitunnukset
            </UiButton>
        );
    }

    if (!isVisitorFromCountry(Country.FINLAND)) {
        return null;
    }

    return (
        <AuthButton dataTest="ftn-button" onClick={login}>
            <Svg icon="bank" size={1.5} />
            <span>
                Pankki
                <br />
                tunnukset
            </span>
        </AuthButton>
    );
}
