import styled from 'styled-components';

export default styled.div`
    column-gap: ${({ theme }) => theme.layout.gutterHalf};
    display: grid;
    grid-template-columns: auto 1fr;

    &:not(.declined) {
        grid-template-areas: 'type details';
        &:not(.compact) {
            row-gap: ${({ theme }) => theme.layout.gutterHalf};
            grid-template-rows: 1fr;
            grid-template-columns: none;
            grid-template-areas:
                'type'
                'details';
        }
    }

    &.declined {
        grid-template-areas:
            'type details'
            'decline-reason decline-reason';
        &:not(.compact) {
            row-gap: ${({ theme }) => theme.layout.gutterHalf};
            grid-template-rows: 1fr;
            grid-template-columns: none;
            grid-template-areas:
                'type'
                'details'
                'decline-reason'
                'decline-reason';
        }
    }

    .type {
        align-self: center;
        color: var(--font-color-subtle);
        grid-area: type;
        justify-self: start;
        text-align: left;
        text-transform: uppercase;
        &:not(.compact) {
            text-align: center;
            width: 100%;
        }
    }

    .details {
        align-self: center;
        display: flex;
        flex-direction: column;
        grid-area: details;
        justify-self: end;
        overflow: hidden;
        text-align: right;
        width: 100%;
        &:not(.compact) {
            text-align: center;
        }

        .description {
            color: var(--font-color-primary);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .date {
            color: var(--font-color-faded);
            font-size: 0.8rem;
            padding-right: 1px;
        }
    }

    .decline-reason {
        align-self: center;
        grid-area: decline-reason;
        overflow: hidden;
        width: 100%;
    }
` as React.ComponentType<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>>;
