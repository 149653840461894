import React, { useState } from 'react';
import { LicenceRequirementSteps as Steps } from '../../../../../services/licence';
import { AuthProfileValidatorLimitsProps as Props } from '../../../../../services/responsible-gaming';
import { translate } from '../../../../../services/translate';
import AuthProfileValidatorLimits from '../../limits/AuthProfileValidatorLimits';
import AuthProfileValidatorRequiredFields from '../../required-fields/AuthProfileValidatorRequiredFields';
import { LIMIT_TYPE } from '../../../../../services/responsible-gaming/types';

export default function AuthProfileValidatorLicenceRequirementPeru(props: Props) {
    const { onAfterSubmit, isModal } = props;
    const [step, setStep] = useState<Steps>(Steps.REQUIRED_FIELDS);
    const modals = {
        [Steps.DEPOSIT_LIMIT]: (
            <AuthProfileValidatorLimits
                {...props}
                key="deposit-limit"
                heading={translate('Deposit limits', 'ui.account')}
                onAfterSubmit={goToNextStep}
                isOfferOnly
                limitType={LIMIT_TYPE.DEPOSIT}
                snippetKey={`auth.update-malta-license-fields-deposit`}
            />
        ),
        [Steps.REQUIRED_FIELDS]: (
            <AuthProfileValidatorRequiredFields isModal={isModal} onAfterSubmit={goToNextStep} onExit={goToNextStep} />
        ),
    };
    function goToNextStep() {
        if (step === Steps.REQUIRED_FIELDS) {
            setStep(Steps.DEPOSIT_LIMIT);
        } else {
            onAfterSubmit();
        }
    }
    return modals[step];
}
