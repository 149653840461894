import React, { createContext } from 'react';
import { ModalState } from '../../types/components/global-modal/types';

export const GlobalModalContext = createContext<Context>({
    showModal: () => {},
    hideModal: () => {},
    store: {} as ModalState,
});

export type ShowModalHandlerOptions = {
    params?: Record<string, unknown>;
    onOpen?: () => void;
    onClose?: () => void;
    mode?: 'drawer' | 'default';
    className?: string | null;
};

type ShowModalHandler = (component: React.ElementType, options?: ShowModalHandlerOptions) => void;

interface Context {
    showModal: ShowModalHandler;
    hideModal: () => void;
    store: ModalState;
}

interface Props extends React.PropsWithChildren {
    value: Context;
}

export function GlobalModalContextProvider({ children, value }: Props) {
    return <GlobalModalContext.Provider value={value}>{children}</GlobalModalContext.Provider>;
}
