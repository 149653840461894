import React, { useState } from 'react';
import { environment } from '../../../../../../stores/environment/environment';
import Wrapper from './styles';
import { stores } from '../../../../../../stores';
import CoolbetHeaderAnonymous from '../../../anonymous/CoolbetHeaderAnonymous';
import { getHomeRoute, getRoute } from '../../../../../../services/router';
import { updateSiteLanguage } from '../../../../../../services/language';
import Logo from '../../../../../logo/Logo';
import UiNavLink from '../../../../../ui/nav-link/UiNavLink';
import { isB2B } from '../../../../../../services/environment';
import { isFeatureAvailable } from '../../../../../../services/feature';
import UiLanguageSelectMini from '../../../../../ui/language-select/mini/UiLanguageSelectMini';
import Svg from '../../../../../svg/Svg';
import AccountMobileSidebar from '../../../../../account/mobile-sidebar/AccountMobileSidebar';
import { translate } from '../../../../../../services/translate';
import { formattedAmountWithCurrency } from '../../../../../../services/currency';
import { useStore } from '../../../../../../hooks/useStore';
import { FEATURE } from '../../../../../../services/types';

export default function CoolbetHeaderMobileLayoutVariant1() {
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [language] = useStore(stores.language);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [wallet] = useStore(stores.wallet);
    const [{ CLIENT_NAME }] = useStore(environment);

    function toggleMenuOpen() {
        setIsMenuOpen(!isMenuOpen);
    }
    return (
        <Wrapper isAuthenticated={isAuthenticated}>
            <div className="logo-menu-wrapper">
                <div className="hamburger-menu" onClick={toggleMenuOpen}>
                    <Svg icon="hamburger-ico" size={1.25} />
                </div>
                <UiNavLink to={getHomeRoute()}>
                    <Logo size={isB2B() ? 35 : 50} className={`logo-${CLIENT_NAME}`} compact />
                </UiNavLink>
            </div>
            <div className="mobile-profile-wrapper">
                {isFeatureAvailable(FEATURE.LANGUAGE_SELECTION) && !isAuthenticated && (
                    <UiLanguageSelectMini
                        currentLanguage={language}
                        shift="center"
                        onValueChange={updateSiteLanguage}
                    />
                )}

                {isAuthenticated ? (
                    <UiNavLink to={getRoute('deposit')} className="balance-info-wrapper">
                        <div className="balance-info">
                            <div className="balance-label">{translate('Balance', 'ui.common')}</div>
                            {wallet && (
                                <div className="balance-amount">{formattedAmountWithCurrency(wallet.balance_uc)}</div>
                            )}
                        </div>
                        <Svg icon="plus-sign" size={1.25} />
                    </UiNavLink>
                ) : (
                    <CoolbetHeaderAnonymous />
                )}
            </div>
            <AccountMobileSidebar
                position={isB2B() ? 'left' : 'right'}
                open={isMenuOpen}
                onClose={toggleMenuOpen}
                onOpen={toggleMenuOpen}
            />
        </Wrapper>
    );
}
