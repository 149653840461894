import React from 'react';
import { getRoute } from '../../../../services/router';
import { translate } from '../../../../services/translate';
import { stores } from '../../../../stores';
import UiButton from '../../../ui/button/UiButton';
import Svg from '../../../svg/Svg';
import UiNavLink from '../../../ui/nav-link/UiNavLink';
import { bonusLabelByType } from '../../../../services/sports/bonus-bets';
import { formattedAmountWithCurrency } from '../../../../services/currency';
import { BonusBet, BonusType } from '../../../../services/bonuses/types';
import { ObjectValues } from '../../../../services/ts-utils';

interface Props {
    bonusType: ObjectValues<typeof BonusType>;
    bonus: BonusBet;
    isList?: boolean;
}

export default function SportBonusBetLinks({ bonusType, bonus, isList }: Props) {
    const bonusTypeToRoute: Record<ObjectValues<typeof BonusType>, string> = {
        free_bet: 'free-bet',
        free_bet_v2: 'free-bet',
        risk_free_bet: 'risk-free-bet',
        multiplier: 'multiplier',
    };
    return (
        <>
            {isList ? (
                <UiNavLink
                    to={`${getRoute(`sport.${bonusTypeToRoute[bonusType]}`)}/${bonus.id}`}
                    onClick={() => stores.sports.isSportSideMenuOpen.set(false)}
                >
                    {translate(bonusLabelByType[bonusType], 'ui.bonus', {
                        amount: bonus?.amount ? formattedAmountWithCurrency(bonus.amount) : '',
                        minAmount: bonus?.minAmount ? formattedAmountWithCurrency(bonus.minAmount) : '',
                    })}
                </UiNavLink>
            ) : (
                <UiButton
                    type="menu"
                    url={`${getRoute(`sport.${bonusTypeToRoute[bonusType]}`)}/${bonus.id}`}
                    onClick={() => stores.sports.isSportSideMenuOpen.set(false)}
                    icon={<Svg icon="freebet-icon" />}
                >
                    {translate(bonusLabelByType[bonusType], 'ui.bonus', {
                        amount: bonus?.amount ? formattedAmountWithCurrency(bonus.amount) : '',
                        minAmount: bonus?.minAmount ? formattedAmountWithCurrency(bonus.minAmount) : '',
                    })}
                </UiButton>
            )}
        </>
    );
}
