import moment from 'moment';
import React, { useState } from 'react';
import { TICKET_STATUS } from '../../../../services/bet-history';
import { getActiveCurrency } from '../../../../services/currency';
import { convertOdds } from '../../../../services/odds-format';
import { useOddsFormat } from '../../../../services/sports/user-settings';
import { translate } from '../../../../services/translate';
import SportBadgeTicketPlatform from '../../../sport/badge/ticket-platform/SportBadgeTicketPlatform';
import SportBadgeTicketStatus from '../../../sport/badge/ticket-status/SportBadgeTicketStatus';
import UiBox from '../../../ui/box/UiBox';
import UiButton from '../../../ui/button/UiButton';
import Wrapper from './styles';
import { formattedAmountWithCurrency } from '../../../../services/currency';

interface Props {
    numberOfFolds: string;
    selectionsMeta: any;
    systemsMeta: any;
    currency: string;
    isCompactView?: boolean;
}

export default function VirtualSportsTicketSelections({
    systemsMeta,
    selectionsMeta,
    numberOfFolds,
    isCompactView,
}: Props) {
    const activeCurrency = getActiveCurrency();
    const [isTicketSelectionVisible, setIsTicketSelectionVisible] = useState(false);
    useOddsFormat();

    const foldGroupTitle = {
        1: translate('Singles', 'ui.sportsbook'),
        2: translate('Doubles', 'ui.sportsbook'),
        3: translate('Trebles', 'ui.sportsbook'),
        4: translate('Fourfolds', 'ui.sportsbook'),
        5: translate('Fivefolds', 'ui.sportsbook'),
        6: translate('Sixfolds', 'ui.sportsbook'),
        7: translate('Sevenfolds', 'ui.sportsbook'),
        8: translate('Eightfolds', 'ui.sportsbook'),
        9: translate('Ninefolds', 'ui.sportsbook'),
        10: translate('Tenfolds', 'ui.sportsbook'),
    }[numberOfFolds];

    return (
        <Wrapper $isCompactView={isCompactView} className="system-groups">
            <UiButton
                block
                onClick={() => {
                    setIsTicketSelectionVisible(!isTicketSelectionVisible);
                }}
            >
                {systemsMeta.betsCount}&nbsp;&times;&nbsp;{translate(foldGroupTitle as string, 'ui.betslip')} (
                {systemsMeta.totalStake.toFixed(2)} {activeCurrency})
            </UiButton>

            {isTicketSelectionVisible &&
                systemsMeta.bets &&
                systemsMeta.bets.map((bet) => (
                    <UiBox light isCompactView wide key={bet.id} className="bet-box">
                        <div className="totals-label">
                            <span className="ticket-id">#{bet.display_id}</span>
                            <span>{translate('Total-odds', 'ui.betslip')} / </span>
                            <span>{translate('Stake', 'ui.betslip')} / </span>
                            <span>{translate('potential-return', 'ui.betslip')}</span>
                        </div>
                        <div>
                            <div className="ticket-totals">
                                <div className="bet-totals">
                                    <div className="total-odds active">{bet.total_odds.toFixed(2)}</div>
                                    <div className="total-stake">{formattedAmountWithCurrency(bet.stake)}</div>
                                    &rarr;
                                    <div className="possible-return">
                                        {formattedAmountWithCurrency(
                                            bet.status === TICKET_STATUS.LOST ? 0 : bet.max_win,
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {bet.outcomeIds.map((outcomeId) => (
                            <div key={outcomeId} className="ticket-details">
                                <div className="bet-sport">
                                    <div className="league">
                                        <span>{translate('Virtual Sports', 'ui.common')}</span>
                                    </div>
                                </div>

                                <div className="bet-match">
                                    <div className="match-name">{selectionsMeta[outcomeId].match_name}</div>

                                    <div className="bet-market">
                                        {selectionsMeta[outcomeId].market_name}
                                        <span className="bet-outcome">{selectionsMeta[outcomeId].outcome_name}</span>
                                    </div>
                                </div>
                                <div className="total-odds">
                                    {convertOdds(selectionsMeta[outcomeId].odds.toFixed(2))}
                                </div>

                                <div className="bet-info">
                                    <SportBadgeTicketPlatform ticket={selectionsMeta[outcomeId]} />
                                    <SportBadgeTicketStatus ticket={selectionsMeta[outcomeId]} />
                                    <div className="bet-dates">{moment(bet.created_at).format('DD.MM.YYYY HH:mm')}</div>
                                </div>
                            </div>
                        ))}
                    </UiBox>
                ))}
        </Wrapper>
    );
}
