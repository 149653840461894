export const LocalStorage = {
    AVAILABLE_LICENCES: 'app-licences',
    BETSLIP_SETTINGS: 'betSlipSettings',
    CASINO_BOOSTER_IS_COMPACT: 'casino.booster.isCompact',
    IS_COMPACT_VIEW: 'isCompactView',
    LANGUAGE: 'language',
    PREFER_LOGIN: 'preferLogin',
    PREMATCH_SELECTED_MENU: 'prematchSelectedMenu',
    REPORTED_MISSING_TRANSLATIONS: 'reportedMissingTranslations',
    SHOW_ANNUAL_REPORT: 'show_annual_report',
} as const;
