import React, { useState } from 'react';
import Wrapper from './styles';
import { stores } from '../../../stores';
import { getClient, isBYOD } from '../../../services/environment';
import { translate } from '../../../services/translate';
import { getRoute } from '../../../services/router';
import UiBottomNavigationTab from './tab/UiBottomNavigationTab';
import { isProduction } from '../../../services/util';
import { isFeatureAvailable } from '../../../services/feature';
import UiModal from '../modal/UiModal';
import SportProductLauncher from '../../sport/product-launcher/SportProductLauncher';
import { TAG_MANAGER_EVENT, trackGoogleTagManagerEvent } from '../../../services/analytics';
import { ClientName } from '../../../services/utils/types';
import { useStore } from '../../../hooks/useStore';
import { FEATURE } from '../../../services/types';
import { AppType } from '../../../services/mobile-app/types';

interface UiBottomNavigationLink {
    label: string;
    route: string;
    icon: string;
}

interface Props {
    links?: UiBottomNavigationLink[];
}

function UiBottomNavigation({ links: customLinks }: Props) {
    const [appType] = useStore(stores.appType);
    const [isGameLauncherOpen, setIsGameLauncherOpen] = useState(false);

    let isCasinoLiveAvailable = true;
    if (isProduction() && appType === AppType.IOS) {
        isCasinoLiveAvailable = false;
    }

    function openGameLauncher() {
        trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.GAME_LAUNCHER_OPENED);
        setIsGameLauncherOpen(true);
    }

    const productLinks = [
        {
            label: translate('Sports', 'ui.header'),
            route: getRoute('sport'),
            icon: 'sports-ico',
            isAvailable: true,
        },
        {
            label: translate('Horse Racing', 'ui.header'),
            route: getRoute('horse-racing'),
            icon: 'horse-racing-ico',
            isAvailable: isFeatureAvailable(FEATURE.HORSE_RACING),
        },
        {
            label: translate('Casino', 'casino.ui'),
            route: getRoute('casino.slots'),
            icon: 'casino-lobby-home',
            isAvailable: isFeatureAvailable(FEATURE.CASINO),
        },
        {
            label: translate('Live Casino', 'casino.ui'),
            route: appType !== AppType.IOS ? getRoute('casino.live') : '',
            icon: 'casino-live',
            isAvailable: isCasinoLiveAvailable && isFeatureAvailable(FEATURE.CASINO),
        },
        {
            label: translate('Poker', 'casino.ui'),
            route: getRoute('poker'),
            icon: 'poker-ico',
            isAvailable: isFeatureAvailable(FEATURE.POKER),
        },
    ].filter((link) => link.isAvailable);

    const mixedLinks = [
        {
            label: translate('Sports', 'ui.header'),
            route: getRoute('sport'),
            icon: 'sports-ico',
            isAvailable: true,
        },
        {
            label: translate('Horse Racing', 'ui.header'),
            route: getRoute('horse-racing'),
            icon: 'horse-racing-ico',
            isAvailable: isFeatureAvailable(FEATURE.HORSE_RACING),
        },
        {
            label: translate('Bet history', 'ui.account'),
            route: getRoute('bet-history'),
            icon: 'bethistory-ico',
            isAvailable: !isBYOD(),
        },
        {
            label: translate('Promotions', 'ui.header'),
            route: getRoute('promotions'),
            icon: 'promotions-ico',
            isAvailable: !isBYOD(),
        },
        {
            label: translate('Deposit', 'ui.common'),
            route: getRoute('deposit'),
            icon: 'deposit-ico',
            isAvailable: !isBYOD(),
        },
    ].filter((link) => link.isAvailable);

    const client = getClient();

    const linksByClient = {
        [ClientName.COOLBET]: productLinks,
        [ClientName.STATION]: mixedLinks,
        [ClientName.IVC]: mixedLinks,
        [ClientName.WYNNBET_NEVADA]: mixedLinks,
    };

    const links = customLinks ?? getLayout(client);

    function getLayout(clientName) {
        if (clientName in linksByClient) {
            return linksByClient[clientName];
        }

        return productLinks;
    }

    if (!productLinks.length) {
        return null;
    }

    return (
        <Wrapper>
            <div className="mobile-tabs">
                {links.map((link) => (
                    <UiBottomNavigationTab key={link.route} to={link.route} icon={link.icon} label={link.label} />
                ))}

                {productLinks.length > 3 && (
                    <UiBottomNavigationTab
                        label={translate('More', 'ui.header')}
                        icon="more-ico"
                        onClick={openGameLauncher}
                    />
                )}
            </div>

            <UiModal
                open={isGameLauncherOpen}
                onClose={() => setIsGameLauncherOpen(false)}
                onOpen={() => setIsGameLauncherOpen(true)}
                mode="drawer"
            >
                <div className="game-launcher-content">
                    <SportProductLauncher onClose={() => setIsGameLauncherOpen(false)} />
                </div>
            </UiModal>
        </Wrapper>
    );
}

export default UiBottomNavigation;
