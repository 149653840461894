import styled from 'styled-components';
import UiGroup from '../../../ui/group/UiGroup';

export default styled(UiGroup)`
    display: flex;
    flex-wrap: wrap;
    color: var(--font-color-subtle);
    font-size: 12px;
    gap: var(--spacing-4);
    .title,
    > * {
        flex: 1;
    }
    em {
        color: var(--color-highlight);
        font-style: normal;
        &:empty {
            display: none;
        }
    }
`;
