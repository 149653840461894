import React, { useEffect, useMemo, useState } from 'react';
import Wrapper from './styles';
import UiFormInput from '../../ui/form/input/UiFormInput';
import UiFormGroup from '../../ui/form/group/UiFormGroup';
import { stores } from '../../../stores';
import { translate } from '../../../services/translate';
import { delay } from '../../../services/util';
import { tryBonusCode } from '../../../services/bonuses/bonus';
import UiAlert from '../../ui/alert/UiAlert';
import { getRoute, useRouter } from '../../../services/router';
import { useForm } from '../../../services/form';
import UiForm from '../../ui/form/UiForm';
import { validators } from '../../../services/validators';
import { getBonusCodeFromUrl } from '../../../services/bonuses/bonus';
import UiButton from '../../ui/button/UiButton';
import DangerousHtml from '../../dangerous-html/DangerousHtml';
import { filterStyleProps } from '../../../styles/utils';
import { useStore } from '../../../hooks/useStore';

interface Props {
    bonusCode?: string;
    depositAmount?: number;
    isShowingLinkInitially?: boolean;
    selectedProvider?: string;
    hasValidation?: boolean;
    onBonusCodeValidityChange?: (valid: any) => void;
    onBonusCodeChange?: (code: string) => void;
    onBonusClaimed?: () => void;
    retrieveBonusFromUrl?: boolean;
}

export default function PromotionBonusEntry({
    bonusCode = '',
    depositAmount,
    selectedProvider,
    hasValidation = false,
    isShowingLinkInitially = false,
    retrieveBonusFromUrl,
    onBonusCodeValidityChange = () => {},
    onBonusCodeChange = () => {},
    onBonusClaimed = () => {},
    ...rest
}: Props) {
    const { navigateTo } = useRouter();
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [isShowingLink, setIsShowingLink] = useState(Boolean(isShowingLinkInitially) && !bonusCode);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitButtonVisible, setIsSubmitButtonVisible] = useState(false);
    const [bonusResponse, setBonusResponse] = useState<{
        bonusValidationMessage: any;
        status: any;
        isSuccess: boolean;
        isError: boolean;
        message: string;
        rawResponse: any;
    } | null>(null);
    const form = useForm({ bonusCode });
    const bonusCodeValidator = useMemo(
        () =>
            Boolean(selectedProvider)
                ? validators.bonusCodeDeposit(depositAmount, selectedProvider)
                : validators.bonusCode,
        [depositAmount, selectedProvider],
    );

    useEffect(() => {
        if (bonusCode) {
            form.setInputValue({ bonusCode });
        }
    }, [bonusCode]);

    useEffect(() => {
        if (retrieveBonusFromUrl) {
            const bonusCode = getBonusCodeFromUrl();
            form.setInputValue({ bonusCode });
            if (bonusCode && isShowingLink) {
                setIsShowingLink(false);
            }
            setIsSubmitButtonVisible(true);
            return () => stores.bonuses.prefilledBonusCode.set(null);
        }
    }, [retrieveBonusFromUrl]);

    const [isDepositCallToActionActive, setIsDepositCallToActionActive] = useState(false);

    async function trySubmitBonusCode(isFormValid) {
        if (!isFormValid) {
            return;
        }

        setIsSubmitting(true);
        const response = await tryBonusCode(
            form.getValues().bonusCode,
            Boolean(depositAmount) && Boolean(selectedProvider),
        );
        setBonusResponse(response);
        setIsSubmitting(false);

        if (response.isSuccess) {
            if (response.status === 'NEED_DEPOSIT') {
                setIsDepositCallToActionActive(true);
            } else {
                await delay(1000);
                reset();
                onBonusClaimed();
                navigateTo(getRoute('promotions.bonuses'));
            }
        }
    }

    function reset() {
        setBonusResponse(null);
        form.setInputValue({ bonusCode: '' });
        stores.bonuses.prefilledBonusCode.set(null);
        setIsDepositCallToActionActive(false);
    }

    function handleGoToDepositPage() {
        reset();
        navigateTo(`${getRoute('deposit')}?bonus=${form.getValues().bonusCode}`);
    }

    if (bonusResponse && isDepositCallToActionActive) {
        return (
            <Wrapper {...filterStyleProps({ ...rest })}>
                <div className="bonus-entry">
                    <UiAlert success>
                        <DangerousHtml content={bonusResponse.message} />
                    </UiAlert>

                    {!isShowingLinkInitially && (
                        <UiButton color="primary" onClick={handleGoToDepositPage}>
                            {translate('Go to deposit', 'ui.bonus')}
                        </UiButton>
                    )}
                </div>
            </Wrapper>
        );
    }

    return (
        <Wrapper {...filterStyleProps({ ...rest })}>
            <div className="bonus-entry">
                {isAuthenticated && isShowingLink && (
                    <p className="bonus-code-link" onClick={() => setIsShowingLink(false)}>
                        {translate('Have a bonus code?', 'ui.account')}
                    </p>
                )}
                {isAuthenticated && !isShowingLink && (
                    <UiForm
                        className="bonus-code-entry"
                        onSubmit={trySubmitBonusCode}
                        isLoading={isSubmitting}
                        onFormValidityChange={onBonusCodeValidityChange}
                        onValuesChange={(formValues) => {
                            onBonusCodeChange(formValues.bonusCode);
                        }}
                    >
                        <UiFormInput
                            name="bonusCode"
                            {...form.bonusCode}
                            className="bonus-code-input"
                            placeholder={translate('Bonus code', 'ui.account')}
                            validator={bonusCodeValidator}
                            disableValidation={!hasValidation || form.getValues().bonusCode === ''}
                            hideMobileTabOnFocus
                            onFocus={() => setIsSubmitButtonVisible(true)}
                        />
                        {isSubmitButtonVisible && (
                            <UiButton type="button" color="primary" isFormSubmitButton isLoading={isSubmitting}>
                                {translate('Submit', 'ui.bonus')}
                            </UiButton>
                        )}
                    </UiForm>
                )}

                {bonusResponse && (
                    <UiFormGroup row>
                        {bonusResponse && bonusResponse.isSuccess && (
                            <UiAlert success>
                                <DangerousHtml content={bonusResponse.message} />
                            </UiAlert>
                        )}

                        {bonusResponse && bonusResponse.isError && (
                            <UiAlert failure>
                                <DangerousHtml content={bonusResponse.message} />
                            </UiAlert>
                        )}
                    </UiFormGroup>
                )}
            </div>
        </Wrapper>
    );
}
