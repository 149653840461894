import React, { useEffect, useState } from 'react';
import { skipLimits } from '../../../../../microservices/responsible-gaming';
import { LicenceRequirementSteps as Steps } from '../../../../../services/licence';
import { AuthProfileValidatorLimitsProps as Props, getLimits } from '../../../../../services/responsible-gaming';
import { LIMIT_TYPE, UserLimit } from '../../../../../services/responsible-gaming/types';
import { translate } from '../../../../../services/translate';
import AuthProfileValidatorLimits from '../../limits/AuthProfileValidatorLimits';
import AuthProfileValidatorRequiredFields from '../../required-fields/AuthProfileValidatorRequiredFields';

export default function AuthProfileValidatorLicenceRequirementMexico(props: Props) {
    const { isModal, onAfterSubmit } = props;
    const [step, setStep] = useState<Steps>(Steps.REQUIRED_FIELDS);
    const [isCompleted, setIsCompleted] = useState<boolean>(false);
    const [skippedLimits, setSkippedLimits] = useState<LIMIT_TYPE[]>([]);
    const [depositLimits, setDepositLimits] = useState<UserLimit[]>([]);

    const modals = {
        [Steps.REQUIRED_FIELDS]: (
            <AuthProfileValidatorRequiredFields isModal={isModal} onAfterSubmit={goToNextStep} onExit={goToNextStep} />
        ),
        [Steps.DEPOSIT_LIMIT]: (
            <AuthProfileValidatorLimits
                {...props}
                key="deposit-limit"
                heading={translate('Deposit limits', 'ui.account')}
                isOfferOnly={true}
                limitType={LIMIT_TYPE.DEPOSIT}
                currentLimits={depositLimits}
                onAfterSubmit={goToNextStep}
                onExit={goToNextStep}
                onSkip={onSkip}
                snippetKey={'auth.update-mexico-license-fields-deposit-limit'}
            />
        ),
    };

    useEffect(() => {
        getCurrentLimits();
    }, []);

    useEffect(() => {
        if (isCompleted) {
            save();
        }
    }, [isCompleted]);

    async function getCurrentLimits() {
        const depositLimitsResponse = await getLimits(LIMIT_TYPE.DEPOSIT);
        setDepositLimits(depositLimitsResponse);
    }

    function onSkip(limitType: LIMIT_TYPE) {
        setSkippedLimits([...skippedLimits, limitType]);
        goToNextStep();
    }

    function goToNextStep() {
        if (step === Steps.REQUIRED_FIELDS) {
            setStep(Steps.DEPOSIT_LIMIT);
        } else if (step === Steps.DEPOSIT_LIMIT) {
            setIsCompleted(true);
        }
    }

    async function save() {
        await Promise.all(skippedLimits.map((limit) => skipLimits(limit)));
        onAfterSubmit();
    }

    return modals[step];
}
