import styled from 'styled-components';
import { mediaQuery } from '../../../../../styles/utils';
import UiModal from '../../../../ui/modal/UiModal';

export default styled(UiModal)`
    .pop-instruction-image {
        border: 2px solid #4b4b59;
        background-color: #34343d;
        border-radius: 1.5rem;
        margin-bottom: 1.25rem;
    }

    .pop-image {
        width: 50%;
        margin-top: 1rem;
    }

    ul {
        text-align: left;
        padding-left: 1.25rem;
        list-style: none;
        @media ${mediaQuery.isPhone} {
            padding-left: 0.3rem;
        }
        li {
            margin-bottom: 0.625rem;
            position: relative;
            padding-left: 1.75rem;
            font-size: 0.875rem;
            @media ${mediaQuery.isPhone} {
                font-size: 0.65rem;
                padding-left: 1.25rem;
            }
        }
        li::before {
            content: '✔';
            position: absolute;
            left: 0;
            color: #7ee629;
            font-weight: bold;
            font-size: 1rem;
            @media ${mediaQuery.isPhone} {
                font-size: 0.875rem;
            }
        }
    }

    li.bullets::before {
        content: '•';
        position: absolute;
        left: 0.3rem;
        color: inherit;
        font-size: 1rem;
        @media ${mediaQuery.isPhone} {
            font-size: 0.875rem;
        }
    }

    .documents {
        margin-left: 1rem;
    }
    .button-section {
        margin-top: 1.5rem;
        display: flex;
        gap: var(--spacing-8);
    }
`;
