import React, { useCallback, useState } from 'react';
import UiModal from '../ui/modal/UiModal';
import { GlobalModalContextProvider, ShowModalHandlerOptions } from '../../contexts/global-modal/GlobalModalContext';
import { media } from '../../stores/media/media';
import { useStore } from '../../hooks/useStore';
import { ModalState } from '../../types/components/global-modal/types';
import classNames from 'classnames';

export default function GlobalModal({ children }: React.PropsWithChildren) {
    const [store, setStore] = useState<ModalState>({} as ModalState);
    const [{ isPhone }] = useStore(media);
    const { Component, params, onOpen, onClose, mode, className, open } = store;
    const [stack, setStack] = useState<ModalState[]>([]);

    const showModal = useCallback(
        function (component: React.ElementType, options: ShowModalHandlerOptions = {}) {
            const { params, onOpen, onClose, mode, className } = options;

            setStore({
                ...store,
                Component: component,
                params,
                onOpen,
                onClose,
                mode,
                className,
                open: true,
            });

            setStack([...stack, { Component: component, params, onOpen, onClose, mode, open: true }]);
        },
        [stack],
    );

    const hideModal = useCallback(
        function () {
            const previousState = stack[stack.length - 1];
            setStack([...stack.slice(-1)]);

            if (previousState) {
                const { Component, params, open, onOpen, onClose, mode } = previousState;
                setStore({
                    ...store,
                    Component,
                    params,
                    onOpen,
                    onClose,
                    mode,
                    open,
                });
            } else {
                setStore({
                    ...store,
                    open: false,
                });

                setTimeout(() => {
                    setStore({
                        ...store,
                        Component: undefined,
                        params: undefined,
                        onOpen: () => {},
                        onClose: () => {},
                        mode: undefined,
                        open: false,
                    });
                }, 500);
            }
        },
        [stack],
    );

    function renderComponent() {
        if (!Component) {
            return null;
        }

        return <Component {...params} />;
    }

    return (
        <GlobalModalContextProvider value={{ store, showModal, hideModal }}>
            <UiModal
                open={Boolean(open)}
                className={className !== null && classNames('new-modal', className)}
                onClose={onClose}
                onOpen={onOpen}
                mode={mode ? mode : isPhone ? 'drawer' : 'default'}
            >
                {renderComponent()}
            </UiModal>
            {children}
        </GlobalModalContextProvider>
    );
}
