import { Country } from '@staycool/location';
import keyBy from 'lodash/keyBy';
import snakeCase from 'lodash/snakeCase';
import { getServiceUrl, httpGet } from '../services/api';
import { getDefaultQueryParams } from '../services/casino/casino';
import { CasinoGameLabel } from '../services/casino/game-label';
import { CasinoGameFull, loadFilteredCasinoGames } from '../services/casino/games';
import { CasinoLobbySettings } from '../services/casino/lobby-setting';
import { CasinoGameOperatingLanguage } from '../services/casino/operating-language';
import { LiveLobbyLauncherByType } from '../services/casino/provider-live-lobby-types';
import { CasinoStudio } from '../services/casino/studios';
import {
    CasinoCategory,
    CasinoCategoryCode,
    CasinoCategorySubprovider,
    CasinoFeature,
    CasinoGame,
    CasinoJackpot,
    CasinoMaintenance,
    CasinoProvider,
    GameType,
} from '../services/casino/types';
import { getDeviceCategory } from '../services/device';
import { isFeatureAvailable } from '../services/feature';
import { getLicence } from '../services/licence';
import { logger } from '../services/logger';
import { AppType } from '../services/mobile-app/types';
import { FEATURE } from '../services/types';
import { isTestUser } from '../services/user';
import { getUserCountry } from '../services/users/country';
import { stores } from '../stores';
import { casino } from '../stores/casino';
import { getStoreValue } from '../stores/store/utils';
import { isSIM } from '../services/environment';

const getUrl = (url) => getServiceUrl('casino', url);

export async function loadIsCasinoBlocked() {
    let countryCode = getStoreValue(stores.ipCountry);
    const user = getStoreValue(stores.user);
    if (user?.id && ['CndWLHdq', 'HKYhQVWJ'].includes(user.id)) {
        // Google Play and App Store test accounts
        countryCode = Country.MEXICO;
    }
    const url = getUrl('fo/is-casino-blocked');
    try {
        const { isCasinoBlocked } = await httpGet<{ isCasinoBlocked: boolean }>(url, { countryCode });
        casino.isCasinoBlocked.set(isCasinoBlocked);
    } catch (error) {
        casino.isCasinoBlocked.set(false);
        logger.error('CasinoMicroservice', 'loadIsCasinoBlocked', error);
    }
}

export async function loadCasinoGames(appType: AppType): Promise<void> {
    try {
        const url = getUrl('fo/games/');
        const defaultParams = getDefaultQueryParams(appType);
        const params = isTestUser() ? { ...defaultParams, isTestUser: true } : defaultParams;
        const games = await httpGet<CasinoGame[]>(url, params);

        casino.allGames.set(games);
        loadFilteredCasinoGames();
    } catch (error) {
        logger.error('CasinoMicroservice', 'loadCasinoGames', error);
    }
}

export async function getCasinoGameFull(gameId: number, appType: AppType): Promise<CasinoGameFull | undefined> {
    try {
        const url = getUrl(`fo/games/${gameId}`);
        return await httpGet<CasinoGameFull>(url, getDefaultQueryParams(appType));
    } catch (error) {
        logger.error('CasinoMicroservice', 'getCasinoGameFull', error);
    }
}

export async function loadCasinoCategories(appType: AppType): Promise<void> {
    try {
        let url = 'fo/categories/by-country';

        if (getStoreValue(stores.isAuthenticated) && isSIM()) {
            url = 'fo/categories/by-country/by-segment';
        }

        const categories: CasinoCategory[] = await httpGet(getUrl(url), {
            device: getDeviceCategory(appType).toLowerCase(),
            userCountry: getUserCountry(),
        });

        casino.categories.set(
            categories.map((category) => {
                return { ...category, code: snakeCase(category.name).toUpperCase() as CasinoCategoryCode };
            }),
        );
    } catch (error) {
        logger.error('CasinoMicroservice', 'loadCasinoCategories', error);
    }
}

export async function loadSubproviderOrder() {
    try {
        const orderedSubprovidersUrl = getUrl('fo/lobby/subproviders');
        const country = getStoreValue(stores.isAuthenticated)
            ? (getStoreValue(stores.user) as { country: string }).country
            : getStoreValue(stores.ipCountry);
        const subproviders: CasinoCategorySubprovider[] = await httpGet(orderedSubprovidersUrl, {
            country,
        });

        return subproviders;
    } catch (error) {
        logger.error('CasinoMicroservice', 'loadSubproviderOrder', error);
        return [];
    }
}

export async function loadCasinoActiveBonus() {
    try {
        const url = getServiceUrl('bonuses', 'user/has-active-casino-bonus');
        const hasActiveCasinoBonus: boolean = await httpGet(url);
        casino.hasActiveBonus.set(hasActiveCasinoBonus);
    } catch (error) {
        casino.hasActiveBonus.set(false);
        logger.error('CasinoMicroservice', 'loadCasinoActiveBonus', error);
    }
}

export async function loadCasinoJackpots(appType: AppType) {
    if (!isFeatureAvailable(FEATURE.CASINO)) {
        return;
    }

    try {
        const url = getUrl('fo/jackpots');
        const jackpots: { [key: string]: CasinoJackpot } = await httpGet(url, getDefaultQueryParams(appType));
        casino.jackpots.set(jackpots);
    } catch (error) {
        logger.error('CasinoMicroservice', 'loadCasinoJackpots', error);
    }
}

export async function loadCasinoDisabledProviders() {
    const licence = getLicence();
    const url = getUrl('fo/maintenance');
    try {
        return await httpGet<CasinoMaintenance[]>(url, { licence });
    } catch (error) {
        logger.error('CasinoMicroservice', 'loadCasinoDisabledProviders', error);
        return [] as CasinoMaintenance[];
    }
}

export async function loadCasinoGameForSeoById(
    gameId: string,
): Promise<Pick<CasinoGame, 'name' | 'imageName' | 'gameType' | 'type'>> {
    const url = getUrl(`fo/games/seo/${gameId}`);
    return httpGet<any>(url).catch((error) => logger.error('CasinoMicroservice', 'loadCasinoGameForSeoById', error));
}

export async function loadCasinoProviders(appType: AppType) {
    try {
        const [providers, subproviders] = await Promise.all([
            httpGet<CasinoProvider[]>(getUrl('fo/providers/'), getDefaultQueryParams(appType)),
            httpGet<CasinoProvider[]>(getUrl('fo/subproviders/')),
        ]);

        providers.forEach((provider) => {
            provider.type = 'provider';
        });
        subproviders.forEach((subprovider) => {
            subprovider.type = 'subProvider';
        });
        casino.providers.set([...providers, ...subproviders]);
    } catch (error) {
        logger.error('CasinoMicroservice', 'loadCasinoProviders', error);
    }
}

export async function loadCasinoFeatures() {
    try {
        const url = getUrl('fo/features/');
        const features: CasinoFeature[] = await httpGet(url);
        casino.features.set(features);
        casino.featuresById.set(keyBy(features, 'id'));
    } catch (error) {
        logger.error('CasinoMicroservice', 'loadCasinoFeatures', error);
    }
}

export async function loadCasinoGameLabels(): Promise<void> {
    try {
        const url = getUrl('fo/labels/');
        const gameLabels: CasinoGameLabel[] = await httpGet(url);
        casino.gameLabels.set(gameLabels);
    } catch (error) {
        logger.error('CasinoMicroservice', 'loadCasinoGameLabels', error);
    }
}

export async function loadCasinoGameOperatingLanguages(): Promise<void> {
    try {
        const url = getUrl('fo/operating-languages/');
        const operatingLanguages: CasinoGameOperatingLanguage[] = await httpGet(url);
        casino.operatingLanguages.set(operatingLanguages);
    } catch (error) {
        logger.error('CasinoMicroservice', 'loadCasinoGameOperatingLanguages', error);
    }
}

export async function loadCasinoLobbySettings(): Promise<void> {
    try {
        const url = getUrl('fo/lobby-settings/');
        const casinoLobbySettings: CasinoLobbySettings = await httpGet(url);
        casino.lobbySettings.set(casinoLobbySettings);
    } catch (error) {
        logger.error('CasinoMicroservice', 'loadCasinoLobbySettings', error);
    }
}

export async function loadCasinoStudios(): Promise<void> {
    try {
        const url = getUrl('fo/studios/');
        const studios: CasinoStudio[] = await httpGet(url);
        casino.studios.set(studios);
        casino.studiosById.set(keyBy(studios, 'id'));
    } catch (error) {
        logger.error('CasinoMicroservice', 'loadCasinoStudios', error);
    }
}

export async function loadCasinoGamesThemes() {
    try {
        const url = getUrl('fo/themes/');
        return httpGet<{ name: string; id: number }[]>(url);
    } catch (error) {
        logger.error('CasinoMicroservice', 'loadCasinoGamesThemes', error);
        return [];
    }
}

export async function loadCasinoGameTypes(): Promise<void> {
    try {
        const url = getUrl('fo/types');
        const gameTypes: GameType[] = await httpGet(url);

        casino.gameTypes.set(gameTypes);
        casino.gameTypesById.set(keyBy(gameTypes, 'id'));
    } catch (error) {
        logger.error('CasinoMicroservice', 'loadCasinoGameTypes', error);
    }
}

export async function loadCasinoProviderLiveLobbyTypes(appType: AppType): Promise<void> {
    try {
        const url = getUrl('fo/providers/live-lobby-types');
        const lobbyTypes: LiveLobbyLauncherByType = await httpGet(url, getDefaultQueryParams(appType));
        casino.liveLobbyLauncherByType.set(lobbyTypes);
    } catch (error) {
        logger.error('CasinoMicroservice', 'loadCasinoProviderLiveLobbyTypes', error);
    }
}
