import styled from 'styled-components';

export default styled.div`
    background-color: var(--surface-level-2-bg);
    border-radius: var(--ui-radius);
    box-shadow: var(--ui-shadow);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-8);
    margin-bottom: var(--spacing-8);
`;
