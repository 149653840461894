import styled from 'styled-components';
import { mediaQuery } from '../../../../styles/utils';

export default styled.div`
    .line-separator {
        padding: 0.5rem;
    }

    .other-options {
        text-transform: uppercase;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        color: var(--font-color-primary);

        & > div {
            flex: 1;
            text-align: center;
        }
    }

    .open-account-button {
        margin-bottom: var(--spacing-4);
    }

    .email-login-button {
        min-width: 100%;
    }

    .other-options-button {
        font-size: 0.6rem;
    }

    .form-group {
        position: relative;
        margin-bottom: var(--spacing-8);

        input {
            margin: 0;
        }

        .forgot-password {
            color: var(--font-color-subtle);
            font-size: 0.8rem;
            position: absolute;
            top: 0;
            right: var(--spacing-12);
            cursor: pointer;
            line-height: 40px;

            @media ${mediaQuery.isPhone} {
                line-height: 50px;
            }
        }
    }
`;
