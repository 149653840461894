import {
    loadCasinoActiveBonus,
    loadCasinoCategories,
    loadCasinoFeatures,
    loadCasinoGameLabels,
    loadCasinoGameOperatingLanguages,
    loadCasinoGameTypes,
    loadCasinoGames,
    loadCasinoLobbySettings,
    loadCasinoProviderLiveLobbyTypes,
    loadCasinoProviders,
    loadCasinoStudios,
} from '../../microservices/casino';
import { loadRecentlyPlayedGames } from '../../microservices/casino-statistics';
import { stores } from '../../stores';
import { casino } from '../../stores/casino';
import { getStoreValue } from '../../stores/store/utils';
import { isFeatureAvailable } from '../feature';
import { AppType } from '../mobile-app/types';
import { FEATURE } from '../types';
import { loadCasinoPaybackProgram } from './casino-payback';
import { getGamesFromRace, initializeCasinoRace } from './casino-race';
import { loadThrilltechJackpots } from './casino-thrilltech-jackpot';
import { loadFavoriteGames, mapCasinoCategories } from './categories';
import { loadFilteredCasinoGames, mapCasinoGames, setInitialLiveGameMetaData } from './games';
import { setCasinoJackpots } from './jackpots';
import { updateCasinoMaintenance } from './maintenance';
import { mapCasinoProviders } from './providers';
import { CasinoGame } from './types';

let casinoInitializationPromise: Promise<void>;

export function initCasino(appType: AppType): Promise<void> {
    return casinoInitializationPromise || loadCasino(appType);
}

export function loadCasino(appType: AppType): Promise<void> {
    casino.playForReal.set(getStoreValue(stores.isAuthenticated));
    casino.shouldReload.set(false);
    const race = getStoreValue(stores.casinoRace.active.race);
    const raceGames = getGamesFromRace(race);

    casinoInitializationPromise = Promise.all([
        loadCasinoGames(appType),
        loadCasinoCategories(appType),
        loadCasinoProviders(appType),
        loadCasinoStudios(),
        loadCasinoProviderLiveLobbyTypes(appType), // TODO: remove this from init and use in place
    ])
        .then(() => setCasinoJackpots(appType))
        .then(loadFavoriteGames)
        .then(() => {
            if (isFeatureAvailable(FEATURE.RECENTLY_PLAYED_GAMES)) {
                return loadRecentlyPlayedGames();
            }
        })
        .then(() => mapCasinoCategories(raceGames))
        .then(mapCasinoGames)
        .then(mapCasinoProviders)
        .then(loadFilteredCasinoGames)
        .then(() => {
            casino.isInitialized.set(true);
        })
        .then(() => loadSecondaryData(raceGames));

    checkCasinoActiveBonus(); // TODO Should be updated with pusher, when user gets bonus or when lock is removed.
    updateCasinoMaintenance(); // TODO Should be implemented with pusher.
    initializeCasinoRace();

    return casinoInitializationPromise;
}

async function loadSecondaryData(raceGames: CasinoGame[]) {
    return Promise.all([
        loadCasinoFeatures(),
        loadCasinoGameLabels(),
        loadCasinoGameOperatingLanguages(),
        loadCasinoLobbySettings(),
        loadCasinoGameTypes(),
        loadCasinoPaybackProgram(),
        loadThrilltechJackpots(),
        setInitialLiveGameMetaData(raceGames),
    ])
        .then(() => mapCasinoCategories(raceGames))
        .then(mapCasinoGames)
        .then(() => casino.isSecondaryDataInitialized.set(true));
}

export async function checkCasinoActiveBonus() {
    const isAuthenticated = getStoreValue(stores.isAuthenticated);

    if (!isAuthenticated) {
        casino.hasActiveBonus.set(false);
        return;
    }

    await loadCasinoActiveBonus();
}
