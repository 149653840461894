import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import React, { useMemo } from 'react';
import Wrapper from './styles';
import { isBetslipButtonDisabled, isWarning } from '../../../../services/sports/betslip';
import { getErrorCode } from '../../../../services/sports/betslip-errors';
import {
    COMBO_MARKET_ID,
    INSUFFICIENT_FUNDS_ERROR,
    MA_ENABLED_ERROR,
    TICKET_LIMIT_ERROR,
} from '../../../../services/sports/constants';
import { hasBetslipManualAcceptanceError } from '../../../../services/sports/manual-acceptance-helpers';
import { getSystemCombinations } from '../../../../services/sports/system-bet-helpers';
import { translate } from '../../../../services/translate';
import { stores } from '../../../../stores';
import UiAlert from '../../../ui/alert/UiAlert';
import UiLinearProgress from '../../../ui/linear-progress/UiLinearProgress';
import SportBetslipButtonAndErrorsConfirm from './confirm/SportBetslipButtonAndErrorsConfirm';
import SportBetslipButtonAndErrorsManualAcceptance from './manual-acceptance/SportBetslipButtonAndErrorsManualAcceptance';
import UiButton from '../../../ui/button/UiButton';
import { isBYOD } from '../../../../services/environment';
import { formattedAmountWithCurrency } from '../../../../services/currency';
import { useStore } from '../../../../hooks/useStore';
import { BET_TYPE } from '../../../../services/sports/types';
import { useCampaignOrNormalBetSlipFunctions } from '../../../../hooks/useCampaignOrNormalBetslipFunctions';
import {
    useBetBuilderState,
    useOddsChangeConfirmRequired,
    useTotalStake,
    useUpdateBetSlipPlacingStateOnGeocomplyModalOpenState,
} from '../../../../hooks/betslip';

interface Props {
    clearSelection: () => void;
}

export default function SportBetslipButtonAndErrors({ clearSelection }: Props) {
    const [{ betType }] = useStore(stores.sports.betSlipUserState);
    const [betSlipErrorByMarketId] = useStore(stores.sports.betSlipErrorByMarketId);
    const [betSlipMarketIdToOutcomeId] = useStore(stores.sports.betSlipMarketIdToOutcomeId);
    const [betSlipPlacingState, setBetSlipPlacingState] = useStore(stores.sports.betSlipPlacingState);
    const { isLoading, receiptById, needsConfirm, needsConfirmDuplicate } = betSlipPlacingState;

    const systemBets = useMemo(() => {
        if (betType === BET_TYPE.SYSTEM) {
            return getSystemCombinations(betSlipMarketIdToOutcomeId);
        }
    }, [betSlipErrorByMarketId]);

    const { isBetBuilderAllowed } = useBetBuilderState();
    const { placeBet, placeBetTextPrefix, getErrorMessageByError } = useCampaignOrNormalBetSlipFunctions();
    const { isOddsChangeConfirmRequired, setIsOddsChangeConfirmRequired } = useOddsChangeConfirmRequired();
    const { totalStake } = useTotalStake();

    useUpdateBetSlipPlacingStateOnGeocomplyModalOpenState();

    if (!isEmpty(receiptById) && isEmpty(betSlipErrorByMarketId)) {
        return null;
    }

    const genericErrors = betSlipErrorByMarketId[String(COMBO_MARKET_ID)] || [];

    const isManualAcceptanceError = betType !== BET_TYPE.BETBUILDER && hasBetslipManualAcceptanceError();
    const normalStakeAllowed = totalStake > 0 && isManualAcceptanceError;
    const manualAcceptanceAllowed = some(genericErrors, (error) => getErrorCode(error) === MA_ENABLED_ERROR);
    const keepValidBecauseMA =
        manualAcceptanceAllowed &&
        normalStakeAllowed &&
        !some(genericErrors, (error) => [TICKET_LIMIT_ERROR, INSUFFICIENT_FUNDS_ERROR].includes(getErrorCode(error)));

    function handleAcceptOddsChanges() {
        setIsOddsChangeConfirmRequired(false);
    }

    if (isBYOD()) {
        return null;
    }

    if (!isManualAcceptanceError && (needsConfirm || needsConfirmDuplicate)) {
        return (
            <Wrapper>
                {genericErrors.map((genericError) => (
                    <UiAlert
                        warning={isWarning(genericError)}
                        failure={!isWarning(genericError)}
                        key={getErrorCode(genericError)}
                    >
                        {getErrorMessageByError(genericError)}
                    </UiAlert>
                ))}
                {needsConfirmDuplicate && isLoading && (
                    <UiLinearProgress
                        className="progress"
                        fakeDuration={8500}
                        showAfterPercentage={20}
                        showPercentage
                    />
                )}
                <SportBetslipButtonAndErrorsConfirm
                    onPlaceBet={placeBet}
                    isForceDuplicate={needsConfirmDuplicate}
                    clearSelection={clearSelection}
                    betSlipPlacingState={betSlipPlacingState}
                    setBetSlipPlacingState={setBetSlipPlacingState}
                />
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            {genericErrors.map((genericError) => (
                <UiAlert
                    info={isWarning(genericError)}
                    failure={!isWarning(genericError)}
                    key={getErrorCode(genericError)}
                >
                    {getErrorMessageByError(genericError)}
                </UiAlert>
            ))}

            {manualAcceptanceAllowed && (
                <SportBetslipButtonAndErrorsManualAcceptance
                    onPlaceBet={placeBet}
                    systemBets={systemBets}
                    totalStake={totalStake}
                />
            )}
            {isLoading && <UiLinearProgress className="progress" fakeDuration={8500} showAfterPercentage={20} />}

            {isOddsChangeConfirmRequired && (
                <UiButton color="primary" size="large" block isLoading={isLoading} onClick={handleAcceptOddsChanges}>
                    {translate('Accept odds changes', 'ui.sportsbook')}
                </UiButton>
            )}

            {!isOddsChangeConfirmRequired && (!genericErrors.length || !isManualAcceptanceError || normalStakeAllowed) && (
                <>
                    {normalStakeAllowed && manualAcceptanceAllowed && (
                        <div className="or">{translate('OR', 'ui.betslip')}</div>
                    )}
                    <UiButton
                        color="primary"
                        size="large"
                        block
                        isLoading={isLoading}
                        onClick={() => placeBet(false, normalStakeAllowed && manualAcceptanceAllowed)}
                        disabled={
                            isLoading || (isBetslipButtonDisabled() && !keepValidBecauseMA) || !isBetBuilderAllowed
                        }
                        data-test="button-place-bet"
                    >
                        <div>{translate(`Place ${placeBetTextPrefix}bet`, 'ui.sportsbook')}</div>
                        {normalStakeAllowed && (
                            <div className="extra-text">
                                {translate('Place the bet of %1 and disregard the remaining amount.', 'ui.sportsbook', [
                                    `${formattedAmountWithCurrency(totalStake)}`,
                                ])}
                            </div>
                        )}
                    </UiButton>
                </>
            )}
        </Wrapper>
    );
}
