import React, { useState } from 'react';
import Wrapper from './styles';
import { media } from '../../../../../stores/media/media';
import { useStore } from '../../../../../hooks/useStore';
import { translate } from '../../../../../services/translate';
import { ProofOfAddressStatus } from '../../../../../services/kyc/types';
import { stores } from '../../../../../stores';
import UiButton from '../../../../ui/button/UiButton';
import { environment } from '../../../../../stores/environment/environment';
import Snippet from '../../../../snippet/Snippet';

interface Props {
    isOpen: boolean;
    onSubmit: () => void;
    onClose: () => void;
}

export default function AccountVerificationIdenfyModal({ isOpen, onSubmit, onClose }: Props) {
    const [step, setStep] = useState(0);
    const [{ isPhone }] = useStore(media);
    const [kycSettings] = useStore(stores.kyc.settings);
    const [{ IMAGE_BASE_URL }] = useStore(environment);

    function nextStep() {
        if (kycSettings.proofOfAddress === ProofOfAddressStatus.REQUESTED && step === 0) {
            setStep(1);
        } else {
            onSubmit();
        }
    }

    return (
        <Wrapper
            open={isOpen}
            className="new-modal"
            shouldDisplayCloseButton={false}
            title={
                step === 0 ? translate('ID Verification', 'ui.account') : translate('Proof of Address', 'ui.account')
            }
            maxWidth={isPhone ? '80vw' : 400}
        >
            {step === 0 && (
                <>
                    <div className="verification">
                        <img
                            src={`${IMAGE_BASE_URL}common/ID-photo.png`}
                            alt="id-verification"
                            className="verification-image"
                        />
                    </div>
                    <Snippet snippetKey="ui.account.id-verification" />
                </>
            )}

            {step === 1 && (
                <>
                    <div className="verification">
                        <img
                            src={`${IMAGE_BASE_URL}common/POA-proof.png`}
                            alt="poa-verification"
                            className="verification-image"
                        />
                    </div>
                    <Snippet snippetKey="ui.account.poa-verification" />
                </>
            )}

            <div className="button-section">
                <UiButton block onClick={onClose}>
                    {translate('Cancel', 'ui.common')}
                </UiButton>
                <UiButton color="primary" block onClick={nextStep}>
                    {translate('Continue', 'ui.account')}
                </UiButton>
            </div>
        </Wrapper>
    );
}
