import styled from 'styled-components';
import { mediaQuery } from '../../styles/utils';

export default styled.div`
    .main-container {
        z-index: 1;
        position: relative;
        max-width: 1920px;
        margin: var(--spacing-8) auto;
        padding: 0 ${({ theme }) => theme.layout.gutterHalf};

        @media ${mediaQuery.isPhone} {
            padding: 0 ${({ theme }) => theme.layout.gutterMobile} ${({ theme }) => theme.layout.gutterMobile}
                ${({ theme }) => theme.layout.gutterMobile};
        }
    }
`;
