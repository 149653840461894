import styled from 'styled-components';

type StyledProps = {
    $isCompactView: boolean;
    $badgeColor: string;
};

const badgeHeight = 20;
const iconWidth = 80;

export default styled.div<StyledProps>`
    display: flex;
    flex-direction: ${(props) => (props.$isCompactView ? 'column' : 'row')};
    align-items: center;
    position: relative;
    background: var(--surface-level-2-bg);
    border: var(--surface-level-2-border);
    border-radius: var(--ui-radius);
    width: 100%;
    max-width: 35rem;
    padding: var(--spacing-16);
    gap: var(--spacing-12);

    .badge-container {
        position: absolute;
        left: 0;
        right: 0;
        top: -${badgeHeight / 2}px;
        height: ${badgeHeight}px;
        display: flex;
        align-items: center;
        justify-content: center;

        .badge {
            padding: 0.2rem 0.5rem;
            background-color: ${({ $badgeColor }) => `var(--color-${$badgeColor}, var(--color-on-highlight))`};
            color: var(--color-on-highlight);
            font-size: 0.75rem;
            text-transform: capitalize;
            border-radius: var(--ui-radius);
        }
    }

    .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: var(--spacing-12);

        .icon {
            width: ${iconWidth}px;
            margin-bottom: var(--spacing-12);
            color: var(--font-color-subtle);
        }

        .description {
            width: ${iconWidth * 2}px;
            text-align: center;
            font-size: 0.8rem;
        }
    }

    .content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: var(--spacing-12);
        flex-grow: 1;

        > * {
            align-self: center;
        }

        .title {
            color: var(--font-color-primary);
            margin-bottom: var(--spacing-12);
            text-align: center;
            display: block;
            width: 100%;
            font-size: 1.125rem;
            font-weight: var(--font-weight-bold);
            ${({ theme }) => theme.optimizeFontRendering}
        }
    }
`;
