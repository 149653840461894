import range from 'lodash/range';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useProofOfIdVerificationContext } from '../../../../../contexts/proof-of-id-verification/ProofOfIdVerificationContext';
import { Country } from '@staycool/location';
import { translate } from '../../../../../services/translate';
import { isUserFromCountry } from '../../../../../services/users/country';
import UiAlert from '../../../../ui/alert/UiAlert';
import UiFormInput from '../../../../ui/form/input/UiFormInput';
import Wrapper from './styles';

export default function AccountVerificationProofOfIdExpiryDate() {
    const { updateSubmissionState, submissionState } = useProofOfIdVerificationContext();
    const { expiryDate } = submissionState;
    const days = range(1, 32);
    const months = moment.months();
    const yearNow = isUserFromCountry(Country.CHILE) ? 2020 : moment().year();
    const years = range(yearNow, yearNow + 100);
    const [error, setError] = useState('');
    const [selectedDay, setSelectedDay] = useState(0);
    const [selectedMonth, setSelectedMonth] = useState('0');
    const [selectedYear, setSelectedYear] = useState(0);

    useEffect(() => {
        if (!expiryDate) {
            return;
        }
        const [year, month, day] = expiryDate.split('-');
        setSelectedDay(Number(day));
        setSelectedMonth(months[Number(month) - 1]);
        setSelectedYear(Number(year));
    }, []);

    useEffect(() => {
        setError('');
        composeExpiryDate();
    }, [selectedDay, selectedMonth, selectedYear]);

    function composeExpiryDate() {
        if (selectedDay && selectedMonth && selectedYear) {
            const currentDate = isUserFromCountry(Country.CHILE) ? moment('2020-01-01') : moment();
            const selectedExpiryDate = moment().year(selectedYear).month(selectedMonth).date(selectedDay);

            if (selectedExpiryDate.isBefore(currentDate)) {
                updateSubmissionState({ expiryDate: '' });
                setError(translate('selected-expiration-date-is-in-the-past-', 'ui.account'));
                return;
            }
            updateSubmissionState({ expiryDate: selectedExpiryDate.format('YYYY-MM-DD') });
        }
    }

    return (
        <Wrapper>
            <div className="description">{translate('Document expiry date', 'ui.account')}</div>
            <div className="date-selectors">
                <span className="document-expiry-field day">
                    <UiFormInput onValueChange={setSelectedDay} value={selectedDay} select name="documentExpiryDay">
                        <option disabled value={0}>
                            {translate('Day', 'ui.common')}
                        </option>
                        {days.map((day) => (
                            <option key={day} value={day}>
                                {day}
                            </option>
                        ))}
                    </UiFormInput>
                </span>
                <span className="document-expiry-field month">
                    <UiFormInput
                        onValueChange={setSelectedMonth}
                        value={selectedMonth}
                        select
                        name="documentExpiryMonth"
                    >
                        <option disabled value={0}>
                            {translate('Month', 'ui.common')}
                        </option>
                        {months.map((month) => (
                            <option key={month} value={month}>
                                {month}
                            </option>
                        ))}
                    </UiFormInput>
                </span>
                <span className="document-expiry-field year">
                    <UiFormInput onValueChange={setSelectedYear} value={selectedYear} select name="documentExpiryYear">
                        <option disabled value={0}>
                            {translate('Year', 'ui.common')}
                        </option>
                        {years.map((year) => (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        ))}
                    </UiFormInput>
                </span>
            </div>
            {error && (
                <UiAlert failure className="invalid-date-alert">
                    {error}
                </UiAlert>
            )}
        </Wrapper>
    );
}
