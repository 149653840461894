import styled from 'styled-components';
import { mediaQuery } from '../../styles/utils';

export default styled.div`
    .sport-content {
        display: grid;
        grid-template-columns: 280px minmax(0, 1fr) 280px;
        grid-column-gap: 1rem;
        width: 100%;

        @media (max-width: 1600px) {
            grid-template-columns: 230px minmax(0, 1fr) 280px;
        }

        @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
            display: block;
        }

        .sport-menu {
            h2 {
                display: flex;
                align-items: center;
                justify-content: space-between;

                font-size: 0.6rem;
                text-transform: uppercase;
                color: var(--font-color-secondary);
                font-weight: var(--font-weight-bold);
                letter-spacing: 1px;
                user-select: none;
                ${({ theme }) => theme.optimizeFontRendering}

                margin: 0.8rem 1.2rem;

                svg {
                    width: 12px;
                }
            }
        }

        .sport-matches {
            ${({ theme }) => theme.contentBoxStyle};
            padding: 0.8rem;
            width: 100%;
            min-height: 100vh;
            @media ${mediaQuery.isPhone} {
                padding: 0.8rem 0.4rem;
            }
        }

        .sport-sidebar {
            position: sticky;
            top: 10px;
            overflow-y: scroll;
            height: 100vh;
            padding: 0.8rem;
            background: var(--surface-level-1-bg);
            border-radius: 8px;
        }
    }
`;
