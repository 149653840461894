import React, { ReactEventHandler, ReactNode } from 'react';
import { Modal, SwipeableDrawer } from '@mui/material';
import Wrapper from './styles';
import UiCloseButton from '../close-button/UiCloseButton';
import { filterStyleProps } from '../../../styles/utils';

interface Props {
    children?: any;
    disableEnforceFocus?: boolean;
    disableBackdropClick?: boolean;
    disableEscapeKeyDown?: boolean;
    shouldDisplayCloseButton?: boolean;
    maxHeight?: number | string;
    maxWidth?: number | string;
    minWidth?: number | string;
    onClose?: () => void;
    onOpen?: () => void;
    open?: boolean;
    className?: any;
    mode?: 'default' | 'drawer';
    title?: string;
    icon?: ReactNode;
    paperProps?: any;
    centered?: boolean;
}

export default function UiModal(props: Props) {
    const {
        open = true,
        children,
        onClose = () => {},
        onOpen = () => {},
        mode = 'default',
        icon,
        shouldDisplayCloseButton = true,
        title,
        disableEnforceFocus = false,
        disableBackdropClick,
        disableEscapeKeyDown,
    } = props;
    const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

    function handleOnClose(_, reason: 'backdropClick' | 'escapeKeyDown') {
        if (disableBackdropClick && reason === 'backdropClick') {
            return;
        }
        if (disableEscapeKeyDown && reason === 'escapeKeyDown') {
            return;
        }
        onClose();
    }

    if (mode === 'drawer') {
        return (
            <SwipeableDrawer
                open={open}
                onClose={handleOnClose as ReactEventHandler}
                anchor="bottom"
                swipeAreaWidth={0}
                disableBackdropTransition={!iOS}
                disableDiscovery={iOS}
                disableSwipeToOpen={true}
                onOpen={onOpen}
                PaperProps={{
                    sx: {
                        '&&': {
                            backgroundColor: props.paperProps?.backgroundColor || 'var(--surface-level-2-bg)',
                        },
                        backgroundImage: 'none',
                        ...props.paperProps,
                    },
                }}
            >
                <Wrapper {...filterStyleProps(props)} $mode={mode}>
                    <div className="cutout">
                        <div className="holder" />
                    </div>
                    {onClose && shouldDisplayCloseButton && (
                        <UiCloseButton onClick={onClose} className="new-close-button" />
                    )}
                    {icon && <div className="ui-modal-icon">{icon}</div>}
                    {title && <div className="ui-modal-title">{title}</div>}
                    {open && children}
                </Wrapper>
            </SwipeableDrawer>
        );
    } else {
        return (
            <Modal open={open} onClose={handleOnClose} disableEnforceFocus={disableEnforceFocus}>
                <Wrapper {...filterStyleProps(props)} $mode={mode}>
                    {onClose && shouldDisplayCloseButton && (
                        <UiCloseButton onClick={onClose} className="new-close-button" />
                    )}
                    {icon && <div className="ui-modal-icon">{icon}</div>}
                    {title && <div className="ui-modal-title">{title}</div>}
                    {children && <div className="ui-modal-content">{children}</div>}
                </Wrapper>
            </Modal>
        );
    }
}
