import { KycDocumentType, KycSettings } from './types';

export const DEFAULT_KYC_SETTINGS: KycSettings = {
    hasReachedLimits: {
        [KycDocumentType.ADDRESS]: false,
        [KycDocumentType.ID]: false,
        [KycDocumentType.INCOME]: false,
        [KycDocumentType.PAYMENT]: false,
    },
    isIdenfyEnabled: false,
    isEVerificationEnabled: false,
    isKycBannerEnabled: false,
    isKycCaptureEnabled: false,
    isKycDocsEnabled: false,
    shouldAskProofOfId: false,
    kycAddressDeclineReason: '',
    kycIdDeclineReason: '',
    kycIncomeDeclineReason: '',
    kycPaymentDeclineReason: '',
    kycStatus: '',
    isClosed: false,
    limits: {
        [KycDocumentType.ADDRESS]: 0,
        [KycDocumentType.ID]: 0,
        [KycDocumentType.INCOME]: 0,
        [KycDocumentType.PAYMENT]: 0,
    },
    proofOfAddress: '',
    proofOfId: '',
    proofOfPaymentStatus: '',
    sourceOfIncomeStatus: '',
};
