import { useEffect } from 'react';
import { useStore } from './useStore';
import { logger } from '../services/logger';
import { loyaltyStore } from '../stores/loyalty';
import { getLoyaltyProgress } from '../microservices/loyalty';
import { useSocketSubscribeUnsubscribe, useSocketTopicEvents } from '../microservices/pusher';
import { stores } from '../stores';

export const useLoyalty = (userId?: string) => {
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [loyalty, setLoyalty] = useStore(loyaltyStore);
    const [user] = useStore(stores.user);

    useEffect(() => {
        if (userId) {
            load();
        }
    }, [userId]);

    useSocketSubscribeUnsubscribe('private', {
        params: {
            service: 'loyalty-consumer',
            channel: 'loyalty-xp-update',
        },
        guardFunction: () => isAuthenticated,
        watchParams: [isAuthenticated],
    });

    useSocketTopicEvents(
        `loyalty-xp-update-${user?.id}`,
        (event) => {
            setLoyalty(event);
        },
        [user?.id],
    );

    async function load() {
        try {
            const response = await getLoyaltyProgress();
            setLoyalty(response);
        } catch (error: any) {
            if (error.code !== 404) {
                logger.error('useLoyalty', 'load', error);
            }
        }
    }

    return {
        level: loyalty.level.level,
        progress: loyalty.level.percent,
    };
};
