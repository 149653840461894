import React, { ReactNode } from 'react';
import { useStore } from '../../../../hooks/useStore';
import { media } from '../../../../stores/media/media';
import { filterStyleProps } from '../../../../styles/utils';
import Svg from '../../../svg/Svg';
import Wrapper from './styles';

interface Props {
    children?: ReactNode;
    icon?: string;
    badgeColor?: string;
    description?: string;
    title?: string;
    badgeText?: string;
    dataTest?: string;
    isCompactView?: boolean;
}

export default function AccountVerificationBox({
    children,
    icon,
    description,
    title,
    dataTest,
    badgeColor = 'success',
    badgeText,
    isCompactView,
    ...rest
}: Props) {
    const [{ isPhone }] = useStore(media);

    return (
        <Wrapper $badgeColor={badgeColor} $isCompactView={isCompactView || isPhone} {...filterStyleProps(rest)}>
            {(icon || description) && (
                <header className="header">
                    {icon && <Svg className="icon" icon={icon} />}
                    {description && <span className="description">{description}</span>}
                </header>
            )}

            <div className="content-container">
                {!!title && <span className="title">{title}</span>}
                {badgeText && (
                    <div className="badge-container" data-test={dataTest}>
                        <span className="badge">{badgeText}</span>
                    </div>
                )}
                {children}
            </div>
        </Wrapper>
    );
}
