import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useStore } from '../../../../hooks/useStore';
import { savePoliticallyExposedPersonMatch } from '../../../../microservices/pep';
import { acceptPeruTermsAndConditions } from '../../../../microservices/users';
import { logout } from '../../../../services/auth';
import { logger } from '../../../../services/logger';
import { translate } from '../../../../services/translate';
import { stores } from '../../../../stores';
import Ui2Form from '../../../ui-2/form/Ui2Form';
import Ui2FormCheckbox from '../../../ui-2/form/checkbox/Ui2FormCheckbox';
import UiAlert from '../../../ui/alert/UiAlert';
import UiBox from '../../../ui/box/UiBox';
import UiButton from '../../../ui/button/UiButton';
import UiCloseButton from '../../../ui/close-button/UiCloseButton';
import UiHeading from '../../../ui/heading/UiHeading';
import Wrapper from './styles';
import Ui2FormRadio from '../../../ui-2/form/radio/Ui2FormRadio';
import Ui2FormSelect from '../../../ui-2/form/select/Ui2FormSelect';
import Ui2FormTextInput from '../../../ui-2/form/text-input/Ui2FormTextInput';
import UiPrompt from '../../../ui/prompt/UiPrompt';

export default function AuthProfileValidatorPeruTermsAndConditionsAcceptanceModal() {
    const [user, setUser] = useStore(stores.user);
    const [isPeruTermsAndConditionsAcceptanceModalOpen, setIsPeruTermsAndConditionsAcceptanceModalOpen] = useStore(
        stores.modals.isPeruTermsAndConditionsAcceptanceModalOpen,
    );
    const [isPepConfirmationAcceptedPromptOpen, setIsPepConfirmationAcceptedPromptOpen] = useState(false);
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const useFormMethods = useForm({
        defaultValues: {
            ...user?.consents,
            pepRelationship: '',
            pepPosition: '',
            pepOrganization: '',
        },
    });
    const [isPrivacyConfirmationAccepted, areTermsAndConditionsAccepted] = useFormMethods.watch([
        'JUEGOS_PRIVACY_CONFIRMATION',
        'JUEGOS_TERMS_AND_CONDITIONS_CONFIRMATION',
        'PEP_CONFIRMATION',
    ]);
    const possibleRelationshipWithPep = ['Myself', 'Family member', 'Close associate'];
    const possiblePositionWithPep = [
        'Heads of state, heads of government, ministers, and deputy ministers',
        'Members of the Congress of the Republic of Peru or similar legislative bodies',
        'Members of governing bodies of political parties',
        'Senior government, judicial, or military officials',
        'Senior executives of state-owned corporations',
        'Important political party officials',
    ];

    const { formState } = useFormMethods;

    async function tryAcceptTermsAndConditions() {
        if (!user) {
            return;
        }
        setIsLoading(true);
        setError('');
        setIsPepConfirmationAcceptedPromptOpen(false);

        try {
            useFormMethods.setValue(
                'PEP_CONFIRMATION',
                String(useFormMethods.getValues('PEP_CONFIRMATION')) === 'true',
            );
            const {
                POLAR_KYC_MIGRATION,
                JUEGOS_TERMS_AND_CONDITIONS_CONFIRMATION,
                JUEGOS_PRIVACY_CONFIRMATION,
                PEP_CONFIRMATION,
                pepRelationship,
                pepPosition,
                pepOrganization,
            } = useFormMethods.getValues();

            await acceptPeruTermsAndConditions({
                POLAR_KYC_MIGRATION,
                JUEGOS_TERMS_AND_CONDITIONS_CONFIRMATION,
                JUEGOS_PRIVACY_CONFIRMATION,
                PEP_CONFIRMATION,
            });
            if (useFormMethods.getValues().hasOwnProperty('PEP_CONFIRMATION')) {
                await savePoliticallyExposedPersonMatch({
                    politicallyExposedPersonText: PEP_CONFIRMATION
                        ? `${pepRelationship}, ${pepPosition}, ${pepOrganization}`
                        : '',
                });
            }
            setIsPeruTermsAndConditionsAcceptanceModalOpen(false);
            setUser({ ...user, consents: {} });
        } catch (error: any) {
            logger.error(
                'AuthProfileValidatorPeruTermsAndConditionsAcceptanceModal',
                'tryAcceptTermsAndConditions',
                error.message,
            );

            setError(translate('Failed to accept terms and conditions', 'ui.common'));
        } finally {
            setIsLoading(false);
        }
    }

    async function submit() {
        if (String(useFormMethods.getValues().PEP_CONFIRMATION) === 'true') {
            setIsPepConfirmationAcceptedPromptOpen(true);
        } else {
            await tryAcceptTermsAndConditions();
        }
    }

    const LABEL_BY_CONSENT_TYPE = {
        JUEGOS_TERMS_AND_CONDITIONS_CONFIRMATION: translate('ui.terms-and-conditions-confirmation'),
        POLAR_KYC_MIGRATION: translate('pe.polar-kyc-migration'),
        JUEGOS_PRIVACY_CONFIRMATION: translate('pe.privacy-confirmation'),
    };

    return (
        <Wrapper open={isPeruTermsAndConditionsAcceptanceModalOpen && isAuthenticated}>
            {user && (
                <UiBox>
                    <UiCloseButton className="close-button" onClick={logout} />

                    <UiHeading title={translate('Self declaration form', 'ui.common')} />

                    <Ui2Form onSubmit={submit} useFormMethods={useFormMethods}>
                        {Object.keys(user.consents).map((consentType) => {
                            return consentType !== 'PEP_CONFIRMATION' ? (
                                <div className="form-control" key={consentType}>
                                    <Ui2FormCheckbox name={consentType} label={LABEL_BY_CONSENT_TYPE[consentType]} />
                                </div>
                            ) : (
                                <div className="form-control" key={consentType}>
                                    <Ui2FormRadio
                                        name={consentType}
                                        label={translate('Politically Exposed Person (PEP) declaration', 'ui.common')}
                                        options={[
                                            {
                                                value: 'true',
                                                label: translate('ui.registration.yes.pep'),
                                            },
                                            {
                                                value: 'false',
                                                label: translate('ui.registration.no.pep'),
                                                defaultChecked: true,
                                            },
                                        ]}
                                        error={formState.errors.PEP_CONFIRMATION}
                                        required
                                    />
                                    {String(useFormMethods.getValues('PEP_CONFIRMATION')) === 'true' && (
                                        <>
                                            <Ui2FormSelect
                                                name="pepRelationship"
                                                label={translate('Who it applies to', 'ui.account')}
                                                options={possibleRelationshipWithPep.map((relationship) => ({
                                                    label: translate(relationship, 'ui.account'),
                                                    value: relationship,
                                                }))}
                                                required
                                                error={useFormMethods.formState.errors.pepRelationship}
                                            />
                                            <Ui2FormSelect
                                                name="pepPosition"
                                                label={translate('Position', 'ui.account')}
                                                options={possiblePositionWithPep.map((position) => ({
                                                    label: translate(position, 'ui.account'),
                                                    value: position,
                                                }))}
                                                required
                                                error={useFormMethods.formState.errors.pepPosition}
                                            />
                                            <Ui2FormTextInput
                                                name="pepOrganization"
                                                maxLength={100}
                                                placeholder={translate('Political organization', 'ui.account')}
                                                label={translate('Political organization', 'ui.account')}
                                                required
                                                error={useFormMethods.formState.errors.pepOrganization}
                                            />
                                        </>
                                    )}
                                </div>
                            );
                        })}

                        <UiButton
                            type="button"
                            disabled={
                                isLoading ||
                                isPrivacyConfirmationAccepted === false ||
                                areTermsAndConditionsAccepted === false
                            }
                            block
                            color="primary"
                            isFormSubmitButton
                        >
                            {translate('ui.common.consent')}
                        </UiButton>
                    </Ui2Form>
                    <UiPrompt
                        open={isPepConfirmationAcceptedPromptOpen}
                        message={`${translate('Are you sure you are PEP', 'ui.account')}`}
                        acceptPhrase={translate('Confirm', 'ui.account')}
                        dismissPhrase={translate('Cancel', 'ui.account')}
                        onAccept={tryAcceptTermsAndConditions}
                        onDismiss={() => setIsPepConfirmationAcceptedPromptOpen(false)}
                    />
                    {error && <UiAlert failure>{error}</UiAlert>}
                </UiBox>
            )}
        </Wrapper>
    );
}
