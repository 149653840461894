import styled from 'styled-components';

export default styled.div`
    position: relative;
    height: 38px;
    .progress-level {
        display: block;
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 900;
        font-size: 18px;
        text-shadow: 0 0 10px black;
    }
    .progress-bar {
        .background {
            fill: var(--color-primary);
            stroke: var(--surface-level-3-bg);
        }
        .foreground {
            stroke: var(--color-secondary);
            stroke-dasharray: 100;
            stroke-dashoffset: calc(100 - var(--progress));
        }
    }
`;
