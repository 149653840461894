import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import ReactPixel from 'react-facebook-pixel';
import * as TagManager from 'react-gtm-module';
import { reportAffiliateBtag, setTrackingIDs } from '../microservices/analytics';
import { stores } from '../stores';
import { logger } from './logger';
import Cookies from 'browser-cookies';
import { isFeatureAvailable } from './feature';
import { Country } from '@staycool/location';
import { getStoreValue } from '../stores/store/utils';
import { environment } from '../stores/environment/environment';
import { FEATURE } from './types';
import { TrackingType } from '../microservices/analytics/types';

export const TAG_MANAGER_EVENT = {
    SIGNUP_OPENED: 'signup_opened',
    SIGNUP_STARTED: 'signup_started',
    SIGNUP_SUCCESS: 'signup_success',
    SIGNUP_VERIFIED: 'signup_verified',

    SIGNUP_VERIFICATION_STARTED: 'signup_verification_started',

    LOGIN: 'login',

    PAGE_VIEW: 'Pageview',

    INCOMING_BETS_OPENED: 'betsfeed_clicked',
    INCOMING_BETS_COPIED: 'betsfeed_copy',

    SEARCH_CLICKED: 'sport_search_clicked',

    RDC_DEPOSIT: 'rdc_deposit',
    NDC_DEPOSIT: 'ndc_deposit',

    MATCH_TURNOVER_OPENED: 'match_turnover_opened',
    SPORT_TIME_FILTER_CLICKED: 'sport_time_filter_clicked',
    SPORT_CATEGORY_FILTER_CLICKED: 'sport_category_filter_clicked',
    SPORT_MOBILE_COMPACT_VIEW_CLICKED: 'sport_mobile_compact_view_clicked',

    SPORT_LEADERBOARD_VISIT: 'sport_leaderboard_visit',
    SPORT_LEADERBOARD_USER_STATS_OPENED: 'sport_leaderboard_user_stats_opened',
    SPORT_LEADERBOARD_USER_TICKETS_OPENED: 'sport_leaderboard_user_tickets_opened',

    SPORT_HOT_EVENT_CLICKED: 'sport_hot_event_clicked',
    SPORT_MARKET_SWITCH_CLICKED: 'sport_market_switch_clicked',

    SPORT_MENU_ADVANCED_CLICKED: 'sport_menu_advanced_clicked',
    SPORT_MENU_SIMPLE_CLICKED: 'sport_menu_simple_clicked',

    SPORT_MATCH_SETTINGS_OPENED: 'sport_match_settings_opened',

    SPORT_MATCH_IN_COMPACT_VIEW: 'sport_match_in_compact_view',
    SPORT_MATCH_IN_EXPANDED_VIEW: 'sport_match_in_expanded_view',

    SPORT_MOTD_IN_COMPACT_VIEW: 'sport_motd_in_compact_view',
    SPORT_MOTD_IN_EXPANDED_VIEW: 'sport_motd_in_expanded_view',

    SPORT_BETTING_PERFORMANCE_VISIT: 'sport_betting_performance_visit',
    PRODUCT_INTRO_REGISTER_CLICKED: 'product_intro_register_clicked',

    SPORT_RECOMMENDATIONS_LOCAL_EVENTS_ONLY: 'sport_recommendations_local_events_only',
    SPORT_RECOMMENDATIONS_LIVE_EVENTS_ONLY: 'sport_recommendations_live_events_only',

    SPORT_SIDEBETS_SEARCH_CLOSE: 'sport_sidebets_search_close',
    SPORT_SIDEBETS_SEARCH_OPEN: 'sport_sidebets_search_open',

    RAF_BANNER_CLICKED: 'raf_banner_clicked',

    SPORT_USER_FAVORITES_VISIT: 'sport_user_favorites_visit',
    SPORT_USER_FAVORITES_ADDED: 'sport_user_favorites_added',

    SPORT_BREADCRUMBS_CLICKED: 'sport_breadcrumbs_clicked',
    SPORT_BREADCRUMBS_SPORT_SELECT_CLICKED: 'sport_breadcrumbs_sport_select_clicked',
    SPORT_BREADCRUMBS_CATEGORY_SELECT_CLICKED: 'sport_breadcrumbs_category_select_clicked',
    SPORT_QUICKLINKS_CLICKED: 'sport_quicklinks_clicked',

    SPORT_BETBUILDER_OPENED: 'sport_betbuilder_opened',

    SPORT_MOBILE_MENU_OPENED: 'sport_mobile_menu_opened',

    BETGENIUS_STREAM_ON: 'betgenius_stream_on',
    BETGENIUS_STREAM_OFF: 'betgenius_stream_off',

    UPSELL_BET_IN_BETSLIP_SHOWN: 'upsell_bet_in_betslip_shown',
    UPSELL_BET_IN_BETSLIP_COPIED: 'upsell_bet_in_betslip_copied',

    SPORT_LINE_MARKET_SELECT_OPENED: 'sport_line_market_select_opened',
    SPORT_LINE_MARKET_LINE_CHANGED: 'sport_line_market_line_changed',

    BETSLIP_QUICK_STAKE_CLICKED: 'betslip_quick_stake_clicked',
    BETSLIP_QUICK_STAKE_REMOVE_CLICKED: 'betslip_quick_stake_remove_clicked',

    CASINO_LOBBY_VISIT: 'casino_lobby_visit',
    CASINO_LIVE_LOBBY_VISIT: 'casino_live_lobby_visit',
    CASINO_RACE_VISIT: 'casino_race_visit',
    CASINO_WINNERS_FEED_VISIT: 'casino_winners_feed_visit',
    CASINO_PROVIDER_VISIT: 'casino_provider_visit',
    CASINO_CATEGORY_VISIT: 'casino_category_visit',
    CASINO_SEARCH_ACTIVATED: 'casino_search_activated',
    CASINO_RACE_WIDGET_CLICKED: 'casino_race_widget_clicked',
    CASINO_FILTER_OPENED: 'casino_filter_opened',
    CASINO_CUSTOM_FILTER_CLICKED: 'casino_custom_filter_clicked',
    CASINO_FEATURED_GAME_CLICKED: 'casino_featured_game_clicked',
    CASINO_GAME_INFO_OPENED: 'casino_game_info_opened',
    CASINO_GAME_INFO_PLAY_CLICKED: 'casino_game_info_play_clicked',
    CASINO_SETTINGS_CLICKED: 'casino_settings_clicked',
    CASINO_MYCASINO_VISIT: 'casino_mycasino_visit',
    CASINO_MYCASINO_GAME_OPENED: 'casino_mycasino_game_opened',
    CASINO_MYCASINO_PRESET_DATE_CLICKED: 'casino_mycasino_preset_date_clicked',
    CASINO_MYCASINO_CUSTOM_DATE_CLICKED: 'casino_mycasino_custom_date_clicked',

    CASINO_GAME_PAGE_MYGAMES_DRAWER_OPENED: 'casino_game_page_mygames_drawer_opened',
    CASINO_GAME_PAGE_SEARCH_DRAWER_OPENED: 'casino_game_page_search_drawer_opened',
    CASINO_GAME_PAGE_MAKE_DEPOSIT_CLICKED: 'casino_game_page_make_deposit_clicked',
    CASINO_GAME_PAGE_RACE_WIDGET_CLICKED: 'casino_game_page_race_widget_clicked',
    CASINO_GAME_PAGE_PAYBACK_BOOSTER_WIDGET_CLICKED: 'casino_game_page_payback_booster_widget_clicked',
    CASINO_GAME_PAGE_LIMITS_CLICKED: 'casino_game_page_limits_clicked',

    SPORT_PODCAST_WIDGET_ACTIVATED: 'sport_podcast_widget_activated',

    RESPONSIBLE_GAMING_TEST_CLICKED: 'responsible_gaming_test_clicked',

    CRM_INBOX_VISIT: 'crm_inbox_visit',
    CRM_MESSAGE_SHOWN: 'on_site_message_opened_event',
    CRM_CTA_CLICKED: 'on_site_message_click_event',
    CRM_CLOSE_BUTTON_CLICKED: 'on_site_message_closed_event',

    MARKETING_PROMOTION_PRINT: 'marketing_promotion_print',
    MARKETING_PROMOTION_CLICK: 'marketing_promotion_click',

    MOBILE_HAMBURGER_MENU_CLICKED: 'mobile_hamburger_menu_clicked',

    COMBO_GENERATOR_VISIT: 'combo_generator_visit',
    COMBO_GENERATOR_GENERATE_CLICKED: 'combo_generator_generate_clicked',
    COMBO_GENERATOR_COMBO_ADDED_TO_BETSLIP: 'combo_generator_combo_added_to_betslip',

    HORSE_RACING_REPLAY_STREAMING_OPENED: 'horse_racing_replay_streaming_opened',
    HORSE_RACING_REPLAY_STREAMING_CLOSED: 'horse_racing_replay_streaming_closed',

    GAME_LAUNCHER_OPENED: 'game_launcher_opened',

    BETSLIP_TWITTER_SHARE_CLICKED: 'betslip_twitter_share_clicked',
    BETSLIP_LINK_SHARE_CLICKED: 'betslip_link_share_clicked',
    BETSLIP_WHATS_APP_SHARE_CLICKED: 'betslip_whats_app_share_clicked',
    BETSLIP_TELEGRAM_SHARE_CLICKED: 'betslip_whats_telegram_clicked',
    BETSLIP_FACEBOOK_SHARE_CLICKED: 'betslip_whats_facebook_clicked',
    BETSLIP_FACEBOOK_MESSENGER_SHARE_CLICKED: 'betslip_facebook_messenger_share_clicked',
};

export function initGoogleTagManager() {
    const { GOOGLE_GTM_ID } = getStoreValue(environment);
    try {
        if (GOOGLE_GTM_ID) {
            TagManager.initialize({ gtmId: GOOGLE_GTM_ID });
        }
    } catch (error) {
        logger.error('AnalyticsService', 'initGoogleTagManager', error);
    }
}

export function updateGoogleTagManager(options: {
    userId?: string;
    country?: Country;
    visitorLoginState?: 'logged-in' | 'logged-out';
}) {
    const dataLayer = pickBy(options, identity);

    try {
        TagManager.dataLayer({ dataLayer });
    } catch (error) {
        logger.error('AnalyticsService', 'updateGoogleTagManager', error);
    }
}

export function handlePageViewAnalytics() {
    trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.PAGE_VIEW);
}

function getGoogleAnalyticsClientId(): string | undefined {
    try {
        return (window as any).ga.getAll()[0].get('clientId');
    } catch (error) {
        return undefined;
    }
}

export function updateTrackingIDs(): Promise<void> | undefined {
    if (!isFeatureAvailable(FEATURE.SYSTEM_ANALYTICS)) {
        return;
    }
    const googleAnalyticsId = getGoogleAnalyticsClientId();

    const isAuthenticated = getStoreValue(stores.isAuthenticated);
    const type: TrackingType = isAuthenticated ? 'private' : 'public';

    return setTrackingIDs(type, { googleAnalyticsId });
}

export function trackGoogleTagManagerEvent(event, variables = {}) {
    if (!isFeatureAvailable(FEATURE.SYSTEM_ANALYTICS)) {
        return;
    }

    const googleTagManagerEventsLayer = window.dataLayer;

    if (googleTagManagerEventsLayer) {
        googleTagManagerEventsLayer.push({ ...variables, event });
    }
}

export function trackFacebookPixelEvent(event, data, isCustomEvent = false) {
    const facebookPixel = (window as any).fbq;

    if (facebookPixel) {
        isCustomEvent ? ReactPixel.trackCustom(event, data) : ReactPixel.track(event, data);
    }
}

export function setAffiliateBTag(bTag: string) {
    if (!isFeatureAvailable(FEATURE.MARKETING_INCOME_ACCESS_AFFILIATE)) {
        return;
    }
    const expiryDate = new Date();
    expiryDate.setFullYear(expiryDate.getFullYear() + 10);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Cookies.set('btag', bTag, { expires: expiryDate, path: '/', samesite: 'None', secure: true });
}

export async function sendAffiliateBTag(userId: string) {
    if (!isFeatureAvailable(FEATURE.MARKETING_INCOME_ACCESS_AFFILIATE)) {
        return;
    }

    const bTag = Cookies.get('btag');
    if (bTag) {
        const response = await reportAffiliateBtag(userId, bTag);
        if (response) {
            Cookies.erase('btag');
        }
    }
}
