import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { RequestedDocument } from '../../../../../services/kyc/types';
import { translate } from '../../../../../services/translate';
import AccountVerificationProofOfPaymentDocumentDeclineReason from './decline-reason/AccountVerificationProofOfPaymentDocumentDeclineReason';
import Wrapper from './styles';

interface Props {
    className?: string;
    displayDeclineReasonOnlyForSide?: 'front' | 'back';
    document: RequestedDocument;
    isCompact?: boolean;
}

export default function AccountVerificationProofOfPaymentDocument({
    document,
    className,
    isCompact,
    ...restOfProps
}: Props) {
    const isDeclined = document.status === 'Declined';
    const { type, date, description } =
        document.documentType === 'DEPOSIT'
            ? {
                  type: translate(document.type, 'ui.account'),
                  date: moment(document.date).format('DD MMM YYYY HH:mm'),
                  description: `${document.amount} ${document.currency}`,
              }
            : {
                  type: translate(document.type?.replace(/_/g, '-'), 'ui.account'),
                  date: document.expirationDate,
                  description: document.number,
              };
    return (
        <Wrapper
            className={classNames(className, {
                compact: isCompact,
                declined: isDeclined,
            })}
        >
            <strong className="type">{type}</strong>
            <div className={classNames('details', { compact: isCompact })}>
                <strong className="description">{description}</strong>
                {date && <em className="date">{date}</em>}
            </div>
            {isDeclined && (
                <AccountVerificationProofOfPaymentDocumentDeclineReason
                    className="decline-reason"
                    document={document}
                    isCompact={isCompact}
                    {...restOfProps}
                />
            )}
        </Wrapper>
    );
}
