import React, { useState } from 'react';
import Wrapper from '../../styles-designed';
import UiButton from '../../../../ui/button/UiButton';
import { translate } from '../../../../../services/translate';
import { isBonusEligibleAndSelected, getRemainingTimeText } from '../../../../../services/sports/bonus-bets';
import classNames from 'classnames';

import Svg from '../../../../svg/Svg';
import SportBonusBetDescription from '../../../bonus-bet/description/SportBonusBetDescription';

import { BonusBet } from '../../../../../services/bonuses/types';

interface Props {
    bonus: BonusBet;
    onBonusToggle: () => void;
}

export default function SportBonusBetMultiplierDesignedLayout({ bonus, onBonusToggle }: Props) {
    const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);

    return (
        <Wrapper>
            {bonus.multiplierSettings && bonus.multiplierSettings.multiplier > 0 && (
                <div
                    className={classNames('campaign-bet animated fadeIn', {
                        selected: isBonusEligibleAndSelected(bonus),
                    })}
                >
                    <div className="campaign-icon">
                        <img
                            src={`${window.coolb2b?.baseUrl || ''}/assets/images/general/odds-boost-ico.png`}
                            alt="odds boost"
                        />
                        {(bonus.quantity ?? 1) > 1 && <div className="campaign-quantity">{`X${bonus.quantity}`}</div>}
                    </div>
                    <div className="campaing-description">
                        <div className="description-text">
                            {Math.round(bonus.multiplierSettings.multiplier * 100)}%{' '}
                            {translate(`Multiplier`, 'ui.bonus')}
                        </div>
                        {bonus.expires_at && (
                            <div className="description-remaining flex gap-8">
                                {getRemainingTimeText(bonus.expires_at)}
                            </div>
                        )}
                        <div
                            className="description-more flex gap-8"
                            onClick={() => setIsDescriptionVisible(!isDescriptionVisible)}
                        >
                            <Svg icon="info" size={0.75} /> {translate('Learn more', 'ui.bonus')}
                        </div>
                    </div>

                    {!isBonusEligibleAndSelected(bonus) ? (
                        <UiButton size="small" color="primary" onClick={onBonusToggle}>
                            {translate('Apply?', 'ui.bonus')}
                        </UiButton>
                    ) : (
                        <UiButton size="small" onClick={onBonusToggle}>
                            {translate('Remove', 'ui.bonus')}
                        </UiButton>
                    )}
                </div>
            )}
            {isDescriptionVisible && <SportBonusBetDescription bonus={bonus} />}
        </Wrapper>
    );
}
