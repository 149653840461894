import React from 'react';
import { isVisitorFromCountry } from '../../../../../services/auth';
import { Country } from '@staycool/location';
import { translate } from '../../../../../services/translate';
import UiAlert from '../../../../ui/alert/UiAlert';
import { getClientType, POKER_CLIENT_TYPE } from '../../../../../services/poker';
import { getDocumentHost } from '../../../../../services/util';
import UiButton from '../../../../ui/button/UiButton';

interface Props {
    onClick: () => void;
    isPokerLogin?: boolean;
}

export default function AuthLoginButtonBankId({ onClick, isPokerLogin }: Props) {
    const device = getClientType();

    if (!isVisitorFromCountry(Country.SWEDEN)) {
        return null;
    }

    if (isPokerLogin && [POKER_CLIENT_TYPE.IOS, POKER_CLIENT_TYPE.ANDROID].includes(device)) {
        return (
            <UiAlert warning>
                {translate('BankID login is not possible please use email and password', 'poker', {
                    host: getDocumentHost(),
                })}
            </UiAlert>
        );
    }

    return (
        <UiButton block color="primary" dataTest="bank-id-login" className="bank-id-button" onClick={onClick}>
            {translate('bank-id', 'ui.account')}
        </UiButton>
    );
}
