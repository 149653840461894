import { Licence } from '@staycool/location';
import React from 'react';
import { MatchOfTheDay } from '../../../../microservices/match-of-the-day/types';
import { isFeatureAvailable } from '../../../../services/feature';
import { isWithLicence } from '../../../../services/licence';
import { useSportsMatchContext } from '../../../../services/sports/match-helpers';
import { translate } from '../../../../services/translate';
import { FEATURE } from '../../../../services/types';
import Svg from '../../../svg/Svg';
import UiButton from '../../../ui/button/UiButton';
import SportOddsLoad from '../../odds/load/SportOddsLoad';
import SportMatchOfTheDayCardTurnoverModal from '../turnover-modal/SportMatchOfTheDayCardTurnoverModal';
import Wrapper from './styles';

interface Props {
    market: MatchOfTheDay['market'] & { in_play: boolean; betting_end: Date };
    motd: MatchOfTheDay['motd'];
    matchId: number;
}
export default function SportMatchOfTheDayCardContent({
    market,
    motd: { match_id, isEligibleForMultiplier, multiplier, isEligibleForBoringMatch },
}: Props) {
    const isBoringMatchRefundVisible = !isWithLicence(Licence.SWEDEN);
    const { turnoverVisible, setContext } = useSportsMatchContext();
    const toggleTurnoverVisibility = () => setContext?.({ turnoverVisible: !turnoverVisible });

    return (
        <>
            <Wrapper>
                <ul>
                    {isEligibleForBoringMatch && (
                        <>
                            {isBoringMatchRefundVisible && (
                                <li>
                                    <Svg icon="top-20" className="list-item-icon" size={1} />
                                    <span>{translate('sports.mod.usp2')}</span>
                                </li>
                            )}
                            {isEligibleForMultiplier && (
                                <li>
                                    <Svg icon="multiplier-bonus-ico" className="list-item-icon" size={1} />
                                    <span>
                                        {multiplier}% {translate('multiplier-bonus', 'sports.mod')}
                                    </span>
                                </li>
                            )}
                        </>
                    )}
                    {isFeatureAvailable(FEATURE.MATCH_TURNOVER) && (
                        <li>
                            <Svg icon="turnover-ico" className="list-item-icon" size={1} />
                            <UiButton
                                type="button"
                                iconPosition="left"
                                className="turnover-btn"
                                onClick={toggleTurnoverVisibility}
                                size="small"
                            >
                                {translate('ui.sportsbook.turnover')}
                            </UiButton>
                        </li>
                    )}
                </ul>
                <div className="motd-odds">
                    <div className="odds">
                        {market?.outcomes.map((outcome) => (
                            <SportOddsLoad
                                key={outcome.id}
                                outcomeId={outcome.id}
                                market={market}
                                isOutcomeNameVisible
                            />
                        ))}
                    </div>
                </div>
            </Wrapper>
            <SportMatchOfTheDayCardTurnoverModal
                isOpen={turnoverVisible}
                onClose={() => setContext?.({ turnoverVisible: false })}
                market={market}
                matchId={match_id}
            />
        </>
    );
}
