import styled from 'styled-components';

type StyledProps = {
    bottomSpacing?: string;
};

export default styled.div<StyledProps>`
    position: relative;
    margin-bottom: ${({ bottomSpacing }) => bottomSpacing || 'var(--spacing-12)'};

    .input-wrapper {
        display: flex;
        overflow: hidden;
        textarea {
            border: none;
            box-shadow: none;
            height: 100%;
            margin: 0;
        }
    }
    textarea:focus,
    textarea:active,
    .input-wrapper:active,
    .input-wrapper:active textarea,
    .input-wrapper:focus-within {
        background-color: var(--form-input-bg);
        border-color: var(--form-input-border);
    }
    textarea {
        padding: 0 0.4rem;
        outline: 0;
    }

    textarea:disabled,
    .input-wrapper.disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }

    .input-wrapper,
    textarea {
        resize: none;
        width: 100%;

        color: var(--form-input-text);
        padding: var(--spacing-12);
        font-size: 0.875rem;

        border: var(--form-input-border);
        border-radius: var(--ui-radius);
        background-color: var(--form-input-bg);
    }
    textarea::-webkit-input-placeholder {
        color: var(--font-color-subtle);
    }
    textarea:-moz-placeholder {
        color: var(--font-color-subtle);
    }

    textarea.error {
        border-color: var(--color-error);
    }

    .input-error {
        margin-top: var(--spacing-4);
    }
`;
