import React, { useState, useEffect } from 'react';
import { stores } from '../../../../stores';
import Snippet from '../../../snippet/Snippet';
import UiGroup from '../../../ui/group/UiGroup';
import UiForm from '../../../ui/form/UiForm';
import UiFormInput from '../../../ui/form/input/UiFormInput';
import { translate } from '../../../../services/translate';
import { validators } from '../../../../services/validators';
import {
    getMaximumLimit,
    getLimitTooHighErrorMessage,
    RG_ERROR_CODE,
    AuthProfileValidatorLimitsProps as Props,
} from '../../../../services/responsible-gaming';
import UiAlert from '../../../ui/alert/UiAlert';
import { useForm } from '../../../../services/form';
import { logger } from '../../../../services/logger';
import Svg from '../../../svg/Svg';
import UiSubHeading from '../../../ui/sub-heading/UiSubHeading';
import Wrapper from './styles';
import { setLimitByType, skipLimits } from '../../../../microservices/responsible-gaming';
import UiButton from '../../../ui/button/UiButton';
import ResponsibleGamingLimitRow from '../../../responsible-gaming/limit/row/ResponsibleGamingLimitRow';
import ResponsibleGamingLimitStatus from '../../../responsible-gaming/limit/status/ResponsibleGamingLimitStatus';
import { useStore } from '../../../../hooks/useStore';
import { LIMIT_PERIOD, LIMIT_TYPE } from '../../../../services/responsible-gaming/types';
import { getActiveCurrency } from '../../../../services/currency';

export default function AuthProfileValidatorLimits({
    heading,
    isModal = false,
    isOfferOnly = false,
    limitType,
    currentLimits = [],
    onAfterSubmit,
    onExit = () => {},
    onSkip,
    snippetKey,
}: Props) {
    const limitTypeToStoreBooleanName = {
        [LIMIT_TYPE.DEPOSIT]: 'askDepositLimit',
        [LIMIT_TYPE.LOSS]: 'askLossLimit',
    };
    const storeBooleanName = limitTypeToStoreBooleanName[limitType];
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [askLimit] = useStore(stores.responsibleGaming[storeBooleanName]);
    const form = useForm({
        period: LIMIT_PERIOD.DAY,
        amount: '',
    });

    useEffect(() => {
        if (!askLimit) {
            stores.modals.isRequiredProfileInformationInquiryModalOpen.set(false);
            onExit();
        } else if (isModal) {
            stores.modals.isRequiredProfileInformationInquiryModalOpen.set(true);
        }
    }, [askLimit]);

    async function onSkipDefault(limitType: LIMIT_TYPE) {
        await skipLimits(limitType);
        await onAfterSubmit();
    }

    async function onSkipWithLoading(limitType: LIMIT_TYPE) {
        setIsLoading(true);

        try {
            onSkip ? await onSkip(limitType) : await onSkipDefault(limitType);
        } catch (error: any) {
            logger.error('AuthProfileValidatorLimits', 'onSkipWithLoading', error.message);
        }

        setIsLoading(false);
    }

    async function save(isFormValid: boolean) {
        if (!isFormValid) {
            return;
        }
        const { amount, period } = form.getValues();

        if (!amount || !period) {
            return;
        }

        setIsLoading(true);

        try {
            await setLimitByType({
                type: limitType,
                amount,
                currency: getActiveCurrency(),
                period,
            });
            onAfterSubmit();
        } catch (error: any) {
            logger.error('AuthProfileValidatorLimits', 'save', error.message);

            if (error.code === RG_ERROR_CODE.LIMIT_AMOUNT_TOO_HIGH) {
                setError(getLimitTooHighErrorMessage(error));
            } else {
                setError(translate('Failed to set the limit', 'ui.common'));
            }
        }

        setIsLoading(false);
    }

    if (!askLimit) {
        return null;
    }

    return (
        <Wrapper>
            <UiForm onSubmit={save} isLoading={isLoading}>
                <div className="responsible-gaming-header">
                    <div>
                        <Svg icon="rg-deposit-limit" className="icon" />
                    </div>

                    <div>
                        <UiSubHeading>{heading}</UiSubHeading>
                        <Snippet snippetKey={snippetKey} />
                    </div>
                </div>

                <div style={{ marginBottom: '1rem' }}>
                    {currentLimits.length > 0 &&
                        currentLimits.map((limit) => (
                            <ResponsibleGamingLimitRow key={limit.activeLimit.period}>
                                <ResponsibleGamingLimitStatus
                                    limits={currentLimits}
                                    limit={limit}
                                    onLimitScheduledToEnd={() => {}}
                                    onError={() => {}}
                                    onResetError={() => {}}
                                />
                            </ResponsibleGamingLimitRow>
                        ))}
                </div>
                <UiGroup>
                    <UiFormInput {...form.period} select label={translate('Period', 'ui.account')}>
                        <option value={LIMIT_PERIOD.DAY}>{translate('24 hours', 'ui.account')}</option>
                        <option value={LIMIT_PERIOD.WEEK}>{translate('1 week', 'ui.account')}</option>
                        <option value={LIMIT_PERIOD.MONTH}>{translate('1 month', 'ui.account')}</option>
                    </UiFormInput>

                    <UiFormInput
                        {...form.amount}
                        typeNumeric
                        numberFrom={1}
                        numberTo={getMaximumLimit(LIMIT_PERIOD.MONTH, limitType)}
                        placeholder={translate('Amount', 'ui.account')}
                        label={translate('Amount', 'ui.account')}
                        validator={(limit) => validators.limit(limit, limitType)}
                    />
                </UiGroup>

                <UiGroup>
                    {isOfferOnly && (
                        <UiButton onClick={() => onSkipWithLoading(limitType)} isLoading={isLoading}>
                            {translate('Skip', 'ui.common')}
                        </UiButton>
                    )}
                    <UiButton type="button" color="primary" isFormSubmitButton isLoading={isLoading}>
                        {translate('Set limits', 'ui.common')}
                    </UiButton>
                </UiGroup>
            </UiForm>

            {error && <UiAlert failure>{error}</UiAlert>}
        </Wrapper>
    );
}
