import React from 'react';
import { isVisitorFromCountry } from '../../../../../services/auth';
import { Country } from '@staycool/location';
import { POKER_CLIENT_TYPE, getClientType } from '../../../../../services/poker';
import UiAlert from '../../../../ui/alert/UiAlert';
import { translate } from '../../../../../services/translate';
import Svg from '../../../../svg/Svg';
import AuthButton from '../../../button/AuthButton';

interface Props {
    onClick: () => void;
    isPokerLogin?: boolean;
}

export default function AuthLoginButtonSmartId({ onClick, isPokerLogin }: Props) {
    const device = getClientType();

    if (!isVisitorFromCountry(Country.ESTONIA)) {
        return null;
    }

    if (isPokerLogin && [POKER_CLIENT_TYPE.IOS, POKER_CLIENT_TYPE.ANDROID].includes(device)) {
        return (
            <UiAlert warning>
                {translate('SmartID login is not possible please use email and password', 'poker')}
            </UiAlert>
        );
    }

    return (
        <AuthButton dataTest="smart-id-button" onClick={onClick}>
            <Svg icon="smart-id" size={1.5} />
            <span>Smart ID</span>
        </AuthButton>
    );
}
