import React, { useEffect } from 'react';
import { getRegistrationCountries } from '../../../../services/auth';
import { logger } from '../../../../services/logger';
import { translate } from '../../../../services/translate';
import { validators } from '../../../../services/validators';
import { stores } from '../../../../stores';
import { getStoreValue } from '../../../../stores/store/utils';
import UiFormInput from '../../../ui/form/input/UiFormInput';
import UiFormLabel from '../../../ui/form/label/UiFormLabel';
import UiGroup from '../../../ui/group/UiGroup';
import Wrapper from './styles';

interface Props {
    form?: any;
    isUseValidPhoneTextVisible?: boolean;
    phoneCodeByCountryCode?: any;
    allowChangePrefix?: boolean;
}

export default function RegistrationFieldsPhoneNumber({
    form,
    isUseValidPhoneTextVisible = true,
    phoneCodeByCountryCode,
    allowChangePrefix,
}: Props) {
    const { country, phonePrefix } = form.getValues();
    const user = getStoreValue(stores.user);

    useEffect(() => {
        if (!phoneCodeByCountryCode) {
            loadRegistrationPhones();
        }
    }, [phoneCodeByCountryCode]);

    async function loadRegistrationPhones() {
        try {
            const { phoneCodeByCountryCode } = await getRegistrationCountries();
            form.setInputValue({
                phonePrefix: phoneCodeByCountryCode[(user && user.country) || country],
            });
        } catch (error) {
            logger.error('RegistrationFieldsPhoneNumber', 'loadRegistrationPhones', error);
        }
    }

    return (
        <Wrapper>
            <UiFormLabel>{translate('Mobile phone', 'ui.registration')}</UiFormLabel>

            <UiGroup className="form-group">
                <UiFormInput
                    value={phonePrefix}
                    validator={validators.required}
                    {...form.phonePrefix}
                    disabled={!allowChangePrefix}
                    type="tel"
                    className="select-prefix"
                    data-test="phonePrefix"
                />

                <UiFormInput
                    {...form.phoneNumber}
                    type="tel"
                    placeholder={translate('Mobile number', 'ui.account')}
                    validator={validators.required}
                    className="input-phone"
                    typeNumeric
                    minLength={4}
                    maxLength={10}
                    data-test="phoneNumber"
                />
            </UiGroup>

            {isUseValidPhoneTextVisible && <p>{translate('ui.registration.use-valid-phone')} </p>}
        </Wrapper>
    );
}
