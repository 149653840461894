import React from 'react';
import Wrapper from './styles';

export default function SimHeaderWheelOfFortune() {
    return (
        <Wrapper>
            <div className="wheel" />
            <div className="wheel-text">collect</div>
        </Wrapper>
    );
}
