import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { translate } from '../../../../../../services/translate';
import UiAlert from '../../../../../ui/alert/UiAlert';
import { stores } from '../../../../../../stores';
import UiButton from '../../../../../ui/button/UiButton';
import { useStore } from '../../../../../../hooks/useStore';
import { updateProfile } from '../../../../../../microservices/users';
import isEmpty from 'lodash/isEmpty';
import { getTranslatedBackendErrors } from '../../../../../../services/user';
import Ui2FormTextInput from '../../../../../ui-2/form/text-input/Ui2FormTextInput';
import Ui2Form from '../../../../../ui-2/form/Ui2Form';
import { getFieldValidators } from '../../../../../../services/validators';

interface Props {
    isModal?: boolean;
    onAfterSubmit: () => void;
    onExit: () => void;
}

export default function AuthProfileValidatorLicenceRequirementSwedenPersonalId({
    isModal = false,
    onAfterSubmit,
    onExit,
}: Props) {
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isPersonalIdAsked, setIsPersonalIdAsked] = useState(false);
    const [user] = useStore(stores.user);
    const useFormMethods = useForm({
        defaultValues: {
            personalId: undefined,
        },
    });

    useEffect(() => {
        const askPersonalId = user?.askPersonalId || false;
        setIsPersonalIdAsked(Boolean(askPersonalId));
        if (askPersonalId && isModal) {
            stores.modals.isRequiredProfileInformationInquiryModalOpen.set(true);
        }
        if (!askPersonalId) {
            onExit();
        }
    }, [user]);

    async function onSubmit() {
        setIsLoading(true);
        try {
            const personalId = useFormMethods.getValues().personalId;
            await updateProfile({ personalId });
            onAfterSubmit();
        } catch (error: any) {
            if (!isEmpty(error.errors)) {
                const errors = getTranslatedBackendErrors(error.errors);
                useFormMethods.setError('personalId', { message: errors['personalId'].error });
            } else {
                setError(translate('Something went wrong', 'ui.common'));
            }
        }
        setIsLoading(false);
    }

    if (!isPersonalIdAsked) {
        return null;
    }

    return (
        <>
            <Ui2Form onSubmit={onSubmit} useFormMethods={useFormMethods}>
                <Ui2FormTextInput
                    name="personalId"
                    minLength={12}
                    maxLength={12}
                    placeholder={translate('ID code', 'ui.account')}
                    label={translate('ID code', 'ui.account')}
                    required
                    validator={getFieldValidators(['personalIdSwedish'])}
                    error={useFormMethods.formState.errors.personalId}
                />
                <UiButton type="button" isFormSubmitButton block color="primary" isLoading={isLoading}>
                    {translate('Confirm', 'ui.common')}
                </UiButton>
            </Ui2Form>
            {error && <UiAlert failure>{error}</UiAlert>}
        </>
    );
}
