import styled from 'styled-components';

export default styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 35rem;
    width: 100%;

    .progress-indicator {
        animation-duration: 0.2s;
        margin-bottom: 2.2rem;
        margin-top: ${({ theme }) => theme.layout.gutter};
        width: 80%;
    }
` as React.ComponentType<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>>;
