import uniqBy from 'lodash/uniqBy';
import { stores } from '../../stores';
import { casino } from '../../stores/casino';
import { getStoreValue } from '../../stores/store/utils';
import { TAG_MANAGER_EVENT, trackGoogleTagManagerEvent } from '../analytics';
import { storageSet } from '../storage';
import { isCasinoGameDisabled, navigateToCasinoGamePage } from './games';
import { CasinoGame, RecentlyPlayed } from './types';

export function storeRecentlyPlayedGames(recentlyPlayed: RecentlyPlayed[]) {
    const isAuthenticated = getStoreValue(stores.isAuthenticated);

    if (isAuthenticated) {
        storageSet('recentlyPlayed', recentlyPlayed ?? []);
    }

    const gamesByGroupId = getStoreValue<{ [key: number]: CasinoGame }>(casino.gamesByGroupId);
    const recentlyPlayedGames = uniqBy(recentlyPlayed, 'gameGroupId')
        .filter((recentlyPlayed) => gamesByGroupId[recentlyPlayed.gameGroupId]?.isPublic)
        .map((recentlyPlayed) => {
            return gamesByGroupId[recentlyPlayed.gameGroupId];
        });

    casino.recentlyPlayedGames.set(recentlyPlayedGames);
}

export function openGame(
    event: React.MouseEvent<HTMLElement>,
    game: CasinoGame | null,
    navigateTo: (path: string, state?: string | undefined) => void,
): void {
    event.preventDefault();
    if (!game || isCasinoGameDisabled(game)) {
        return;
    }
    casino.latestGameLaunchPosition.set({ launchSource: 'MY_CASINO' });

    trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.CASINO_MYCASINO_GAME_OPENED);
    navigateToCasinoGamePage(navigateTo, game);
}
