import styled from 'styled-components';

export default styled.div`
    .title {
        justify-content: center;
    }
    .text {
        text-align: left;
    }
`;
