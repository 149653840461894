import React, { useEffect, useState } from 'react';
import UiModal from '../../ui/modal/UiModal';
import UiButton from '../../ui/button/UiButton';
import { translate } from '../../../services/translate';
import Svg from '../../svg/Svg';
import { stores } from '../../../stores';
import { getRoute, useRouter } from '../../../services/router';
import { getPayAndPlayMessage } from '../../../services/auth';
import { storageGet } from '../../../services/storage';
import { Redirect } from 'react-router-dom';
import Snippet from '../../snippet/Snippet';
import { useStore } from '../../../hooks/useStore';
import { poker } from '../../../stores/poker/poker';

export default function SystemModalMessage() {
    const { error, pending, snippetKey, success } = useRouter().queryParams;

    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [type, setType] = useState('success');
    const messageFromQueryString = error || pending || success;
    const [isEssentialUserInformationInquiryModalOpen] = useStore(
        stores.modals.isEssentialUserInformationInquiryModalOpen,
    );
    const [payAndPlayAuthType] = useStore(stores.payAndPlayAuthType);
    const [payAndPlaySuccessMessageType, setPayAndPlaySuccessMessageType] = useStore(
        stores.payAndPlaySuccessMessageType,
    );
    const [, setIsClosePopup] = useStore(poker.isClosePopup);

    const MESSAGE_TYPE = {
        SUCCESS: 'success',
        ERROR: 'error',
        PENDING: 'pending',
    };

    const MESSAGE_COLOR = {
        [MESSAGE_TYPE.SUCCESS]: '#70C62A',
        [MESSAGE_TYPE.ERROR]: '#FF4136',
        [MESSAGE_TYPE.PENDING]: '#CDCDCC',
    };
    const IMAGE_TYPE = {
        [MESSAGE_TYPE.SUCCESS]: 'checkmark',
        [MESSAGE_TYPE.ERROR]: 'crossmark',
        [MESSAGE_TYPE.PENDING]: 'clock',
    };

    useEffect(() => {
        if (messageFromQueryString) {
            setMessage(messageFromQueryString as string);
            if (error) {
                setType(MESSAGE_TYPE.ERROR);
            } else if (pending) {
                setType(MESSAGE_TYPE.PENDING);
            } else {
                setType(MESSAGE_TYPE.SUCCESS);
            }
            setIsOpen(true);
        } else if (snippetKey) {
            setIsOpen(true);
        } else if (payAndPlayAuthType && payAndPlaySuccessMessageType && !isEssentialUserInformationInquiryModalOpen) {
            // Should refactor out PayNPlay related logic from here.
            setMessage(getPayAndPlayMessage(payAndPlaySuccessMessageType));
            setType(getMessageType(payAndPlaySuccessMessageType));
            setIsOpen(true);
        }
    }, [
        isEssentialUserInformationInquiryModalOpen,
        messageFromQueryString,
        payAndPlayAuthType,
        payAndPlaySuccessMessageType,
        snippetKey,
    ]);

    function getMessageType(payAndPlaySuccessMessageType) {
        if (payAndPlaySuccessMessageType === 'FAIL_BONUS') {
            return MESSAGE_TYPE.ERROR;
        }
        return MESSAGE_TYPE.SUCCESS;
    }

    function close() {
        setIsOpen(false);
        setMessage('');
        setPayAndPlaySuccessMessageType(undefined);
        setType('');
        setIsClosePopup(storageGet('closePopup'));
    }

    if (!message && !snippetKey) {
        return null;
    }

    // Backwards compatibility begins
    // For payments2 deploy-time only
    // For devcode, euteller, trustly & tsi till updated to new result page
    if (message && message.includes('your-deposit-')) {
        const path = {
            error: 'failed',
            success: 'completed',
        };
        return <Redirect to={`${getRoute('deposit-return')}/${path[type] || type}`} />;
    }
    // Backwards compatibility ends

    return (
        <UiModal
            open={isOpen}
            onClose={close}
            className="new-modal"
            icon={<Svg icon={IMAGE_TYPE[type]} size={4} color={MESSAGE_COLOR[type]} />}
            title={message}
            shouldDisplayCloseButton={false}
        >
            {snippetKey && <Snippet snippetKey={snippetKey as string} />}

            <UiButton onClick={close} block>
                {translate('ui.common.close')}
            </UiButton>
        </UiModal>
    );
}
