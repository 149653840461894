import React, { useEffect, useState } from 'react';
import { Country } from '@staycool/location';
import { LicenceRequirementSteps as Steps } from '../../../../../services/licence';
import { AuthProfileValidatorLimitsProps as Props } from '../../../../../services/responsible-gaming';
import { translate } from '../../../../../services/translate';
import { isUserFromCountry } from '../../../../../services/users/country';
import { stores } from '../../../../../stores';
import AuthProfileValidatorLimits from '../../limits/AuthProfileValidatorLimits';
import { useStore } from '../../../../../hooks/useStore';
import AuthProfileValidatorRequiredFields from '../../required-fields/AuthProfileValidatorRequiredFields';
import { LIMIT_TYPE } from '../../../../../services/responsible-gaming/types';

export default function AuthProfileValidatorLicenceRequirementMalta(props: Props) {
    const { onAfterSubmit, isModal } = props;
    const [step, setStep] = useState<Steps>(Steps.DEPOSIT_LIMIT);
    const [user] = useStore(stores.user);
    const [askDepositLimit] = useStore(stores.responsibleGaming.askDepositLimit);

    useEffect(() => {
        if (user?.isProfileReviewRequired) {
            setStep(Steps.REQUIRED_FIELDS);
        }
    }, [user?.isProfileReviewRequired]);

    const modals = {
        [Steps.DEPOSIT_LIMIT]: (
            <AuthProfileValidatorLimits
                {...props}
                key="deposit-limit"
                heading={translate('Deposit limits', 'ui.account')}
                onAfterSubmit={goToNextStep}
                isOfferOnly
                limitType={LIMIT_TYPE.DEPOSIT}
                snippetKey={`auth.update-${
                    isUserFromCountry(Country.NORWAY) ? 'norway' : 'malta'
                }-license-fields-deposit`}
            />
        ),
        [Steps.REQUIRED_FIELDS]: (
            <AuthProfileValidatorRequiredFields isModal={isModal} onAfterSubmit={goToNextStep} onExit={goToNextStep} />
        ),
    };

    function goToNextStep() {
        if (step === Steps.DEPOSIT_LIMIT && user?.isProfileReviewRequired) {
            setStep(Steps.REQUIRED_FIELDS);
        } else if (step === Steps.REQUIRED_FIELDS && askDepositLimit) {
            setStep(Steps.DEPOSIT_LIMIT);
        } else {
            onAfterSubmit();
        }
    }

    return modals[step];
}
