import styled from 'styled-components';
import UiGroup from '../../ui/group/UiGroup';

export default styled(UiGroup)`
    .submit-timer {
        display: inline;
    }

    input,
    .input-error {
        margin-bottom: 0;
    }
`;
