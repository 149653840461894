import React, { ButtonHTMLAttributes, PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import Wrapper from './styles';
import { impersonateWithGoogle, linkWithGoogle } from '../../../../../microservices/auth';
import {
    authenticateWithGoogle,
    getBackofficeLoginToken,
    handleAuthenticationFailure,
    handleAuthenticationSuccess,
} from '../../../../../services/auth';
import { translate } from '../../../../../services/translate';
import { logger } from '../../../../../services/logger';
import { LoginMethod } from '../../../../../services/auth/types';
import { useRouter } from '../../../../../services/router';
import { filterStyleProps } from '../../../../../styles/utils';
import { environment } from '../../../../../stores/environment/environment';
import { useStore } from '../../../../../hooks/useStore';
import { storageSet } from '../../../../../services/storage';
import { LocalStorage } from '../../../../../services/local-storage/types';

type ClientConfigError = google.accounts.oauth2.ClientConfigError;
type TokenResponse = google.accounts.oauth2.TokenResponse;
type TokenClient = google.accounts.oauth2.TokenClient;

interface Props extends PropsWithChildren, ButtonHTMLAttributes<HTMLButtonElement> {
    isLinking?: boolean;
    isRegistration?: boolean;
    onAuthenticationStart?: () => void;
    onAuthenticationSuccess?: () => void;
    onErrorHandler?: (message: string) => void;
    buttonComponent: (props: any) => ReactElement<any, any>;
}

export default function AuthLoginButtonGoogle({
    children,
    buttonComponent: ButtonComponent,
    isLinking = false,
    onAuthenticationStart = () => {},
    onAuthenticationSuccess = () => {},
    onErrorHandler = () => {},
    isRegistration,
    ...rest
}: Props) {
    const { navigateTo } = useRouter();
    const [{ GOOGLE_CLIENT_ID }] = useStore(environment);
    const [isLoading, setIsLoading] = useState(false);
    const [tokenClient, setTokenClient] = useState<TokenClient>();

    useEffect(() => {
        loadGoogleScript();

        return () => removeGoogleScript();
    }, []);

    useEffect(() => {
        if (!tokenClient) {
            return;
        }

        if (isLoading) {
            onAuthenticationStart();
            tokenClient.requestAccessToken();
        }
    }, [isLoading, tokenClient]);

    function loadGoogleScript() {
        const scriptElement = document.getElementsByTagName('script')[0];
        const fjs = scriptElement;
        let js = scriptElement;
        js = document.createElement('script');
        js.src = 'https://accounts.google.com/gsi/client';
        js.id = 'google-oauth-client';
        if (fjs && fjs.parentNode) {
            fjs.parentNode.insertBefore(js, fjs);
        } else {
            document.head.appendChild(js);
        }
        js.onload = initToken;
    }

    function removeGoogleScript() {
        const scriptElement = document.getElementById('google-oauth-client');

        scriptElement?.remove();
    }

    function initToken() {
        const scopes = ['profile', 'email'];
        if (isRegistration) {
            scopes.push(
                'https://www.googleapis.com/auth/user.phonenumbers.read',
                'https://www.googleapis.com/auth/user.gender.read',
                'https://www.googleapis.com/auth/user.birthday.read',
                'https://www.googleapis.com/auth/user.addresses.read',
            );
        }
        const token = window.google?.accounts?.oauth2?.initTokenClient({
            client_id: GOOGLE_CLIENT_ID,
            scope: scopes.join(' '),
            callback: onSuccess,
            error_callback: onFailure,
        });

        setTokenClient(token);
    }

    async function impersonate(accessToken: string, impersonationToken: string) {
        try {
            storageSet(LocalStorage.PREFER_LOGIN, LoginMethod.GOOGLE);
            const authenticationResponse = await impersonateWithGoogle(
                accessToken,
                impersonationToken,
                GOOGLE_CLIENT_ID,
            );
            return await handleAuthenticationSuccess(authenticationResponse, LoginMethod.GOOGLE);
        } catch (error: any) {
            error.message = error.code;
            handleAuthenticationFailure(error, LoginMethod.GOOGLE);
        }
    }

    async function link(accessToken: string) {
        try {
            storageSet(LocalStorage.PREFER_LOGIN, LoginMethod.GOOGLE);
            await linkWithGoogle(accessToken, GOOGLE_CLIENT_ID);
        } catch (response: any) {
            handleAuthenticationFailure(response, LoginMethod.GOOGLE);
        }
    }

    async function onSuccess({ access_token, error }: TokenResponse) {
        if (error === 'access_denied') {
            return onFailure({ message: error, type: 'popup_closed' } as unknown as ClientConfigError);
        }

        try {
            let response;
            const impersonationToken = getBackofficeLoginToken();
            if (impersonationToken) {
                response = await impersonate(access_token, impersonationToken);
            } else if (isLinking) {
                await link(access_token);
            } else {
                response = await authenticateWithGoogle(access_token);
            }
            setIsLoading(false);
            onAuthenticationSuccess();

            if (!isLinking && response && response.navigateTo && window.location.pathname !== response.navigateTo) {
                navigateTo(response.navigateTo);
            }
        } catch (error: any) {
            setIsLoading(false);
            onErrorHandler(translate(error.message, 'ui.account'));
        }
    }

    function onFailure({ message, type }: ClientConfigError) {
        logger.error('AuthLoginButtonGoogle', 'onFailure', message);

        setIsLoading(false);

        if (type === 'popup_closed') {
            onErrorHandler(
                translate(`Authentication with {{ loginMethod }} cancelled!`, 'auth.login', {
                    loginMethod: LoginMethod.GOOGLE,
                }),
            );

            return;
        }

        const errorMessage = translate(`Failed to authenticate with {{ loginMethod }}`, 'auth.login', {
            loginMethod: LoginMethod.GOOGLE,
        });

        if (message === 'popup_failed_to_open') {
            onErrorHandler(errorMessage);
            return;
        }

        const errorMessageWithErrorCode = `${errorMessage} "${message}"`;

        onErrorHandler(errorMessageWithErrorCode);
    }

    return (
        <Wrapper>
            <ButtonComponent onClick={() => setIsLoading(true)} isLoading={isLoading} {...filterStyleProps(rest)}>
                {children || 'Google'}
            </ButtonComponent>
        </Wrapper>
    );
}
