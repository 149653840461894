import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import { DependencyList, useEffect, useState } from 'react';
import { stores, useStoreWithSelector } from '../../stores';
import { ODDS_STATUS } from './constants';
import { ODDS_CHANGE } from './odds';
import { useSecondaryEffect, useTimeout } from '../hooks';
import { useSocketSubscribeUnsubscribe, useSocketTopicEvents } from '../../microservices/pusher';
import { useStore } from '../../hooks/useStore';

export function useOddsByOutcomeIds(outcomeIds, refresher: DependencyList = []) {
    return useStoreWithSelector(stores.sports.oddsByOutcomeId, (state) => pick(state, outcomeIds), refresher);
}

export function useBetslipOddsByOutcomeId(
    outcomeId: number,
    isLive: boolean,
    provider: number | null,
    isBetslip: boolean,
) {
    const [isOddsValueChanged, setIsOddsValueChanged] = useState(false);
    const [oddsValueChangeDirection, setOddsValueChangeDirection] = useState<any>();
    const [previousOdds, setPreviousOdds] = useState<any>();
    const [currentOdds] = useStoreWithSelector(stores.sports.oddsByOutcomeId, (state) => state[outcomeId], [outcomeId]);
    const [heartbeats] = useStore(stores.sports.heartbeat);
    const [sportsbookMaintenanceState] = useStore(stores.maintenance.sportsbookMaintenanceState);

    useEffect(() => {
        setIsOddsValueChanged(false);
        setOddsValueChangeDirection(null);
        setPreviousOdds(null);
    }, [outcomeId]);

    useEffect(() => {
        if (currentOdds) {
            if (previousOdds && currentOdds.value !== previousOdds.value) {
                setIsOddsValueChanged(true);

                setOddsValueChangeDirection(
                    currentOdds.value > previousOdds.value ? ODDS_CHANGE.INCREASE : ODDS_CHANGE.DECREASE,
                );
            }

            setPreviousOdds(currentOdds);
        }
    }, [currentOdds]);

    useTimeout(
        () => {
            if (oddsValueChangeDirection) {
                setIsOddsValueChanged(false);
                setOddsValueChangeDirection(null);
            }
        },
        6000,
        [!isBetslip && oddsValueChangeDirection],
    );

    const isOddsOpen = Boolean(currentOdds && currentOdds.status === ODDS_STATUS.OPEN);

    function checkIfOddsSuspended() {
        if (!currentOdds) {
            return true;
        }
        if (currentOdds.status !== 'OPEN' || Number.isNaN(currentOdds.value) || currentOdds.value <= 1) {
            return true;
        }

        if (sportsbookMaintenanceState.liveBet && isLive) {
            return true;
        }

        if (!isLive || !provider || isEmpty(heartbeats)) {
            return false;
        }
        const heartBeatUp = heartbeats?.[provider];
        return !heartBeatUp;
    }

    const isOddsSuspended = checkIfOddsSuspended();
    const currentOddsValue = currentOdds?.value;

    return {
        currentOdds,
        previousOdds,
        isOddsValueChanged,
        isOddsValueIncreased: oddsValueChangeDirection === ODDS_CHANGE.INCREASE,
        isOddsValueDecreased: oddsValueChangeDirection === ODDS_CHANGE.DECREASE,
        isOddsAvailable: Boolean(!isOddsSuspended && currentOddsValue && currentOddsValue > 1),
        isOddsOpen,
        isOddsSuspended,
        oddsValueChangeDirection,
        currentOddsValue,
    };
}

export function useMatchStatusUpdate(
    match: { id: number; status?: string; isFake?: boolean },
    cb?: ({ status, updated }) => void,
) {
    const [matchStatus, setMatchStatus] = useState(match.status);
    useSecondaryEffect(
        () => setMatchStatus(match.status),
        [match.id],
        (matchId) => Boolean(matchId),
    );
    useSocketTopicEvents(
        `match-status-update-${match.id}`,
        cb ||
            (({ status }) => {
                if (matchStatus !== status) {
                    setMatchStatus(status);
                }
            }),
        [match.id, cb, matchStatus],
    );
    return matchStatus;
}

export function useMatchStatusUpdateSubscribe(match: { id: number; status: string; isFake?: boolean }) {
    useSocketSubscribeUnsubscribe('public', {
        params: {
            service: 'sports',
            channel: `match-status-update-${match.id}`,
        },
        watchParams: [match.id],
        guardFunction: () => !!match.id && !match.isFake,
        resubscribeOnReconnect: true,
        unsubscribeIfOtherSubs: false,
    });

    return useMatchStatusUpdate(match);
}
