import React, { ButtonHTMLAttributes, PropsWithChildren, ReactElement, useState } from 'react';
import Wrapper from './styles';
import { authenticateWithApple, handleAuthenticationFailure } from '../../../../../services/auth';
import { translate } from '../../../../../services/translate';
import { LoginMethod } from '../../../../../services/auth/types';
import { useRouter } from '../../../../../services/router';
import { linkWithApple } from '../../../../../microservices/auth';
import { filterStyleProps } from '../../../../../styles/utils';
import { environment } from '../../../../../stores/environment/environment';
import { useStore } from '../../../../../hooks/useStore';
import { storageSet } from '../../../../../services/storage';
import { LocalStorage } from '../../../../../services/local-storage/types';
import AppleLogin from 'react-apple-login';

interface Props extends PropsWithChildren, ButtonHTMLAttributes<HTMLButtonElement> {
    isLinking?: boolean;
    onAuthenticationStart: () => void;
    onAuthenticationSuccess?: () => void;
    onErrorHandler?: (message: string) => void;
    buttonComponent: (props: any) => ReactElement<any, any>;
}

export default function AuthLoginButtonApple({
    children,
    buttonComponent: ButtonComponent,
    isLinking = false,
    onAuthenticationStart = () => {},
    onAuthenticationSuccess = () => {},
    onErrorHandler = () => {},
    ...rest
}: Props) {
    const { navigateTo } = useRouter();
    const [{ APPLE_CLIENT_ID }] = useStore(environment);
    const [isLoading, setIsLoading] = useState(false);

    async function link(code: string, idToken: string) {
        try {
            storageSet(LocalStorage.PREFER_LOGIN, LoginMethod.APPLE);
            await linkWithApple(code, idToken);
        } catch (response: any) {
            handleAuthenticationFailure(response, LoginMethod.APPLE);
        }
    }

    async function onSuccess({ authorization }) {
        const { code, id_token } = authorization;
        try {
            let response;
            if (isLinking) {
                await link(code, id_token);
            } else {
                storageSet(LocalStorage.PREFER_LOGIN, LoginMethod.APPLE);
                response = await authenticateWithApple(code, id_token);
            }
            setIsLoading(false);
            onAuthenticationSuccess();

            if (!isLinking && response && response.navigateTo && window.location.pathname !== response.navigateTo) {
                navigateTo(response.navigateTo);
            }
        } catch (error: any) {
            setIsLoading(false);
            onErrorHandler(translate(error.message, 'ui.account'));
        }
    }

    if (!APPLE_CLIENT_ID) {
        return null;
    }

    return (
        <Wrapper>
            <AppleLogin
                clientId={APPLE_CLIENT_ID}
                redirectURI={window.location.href}
                usePopup={false}
                callback={onSuccess}
                scope="name email"
                responseMode="form_post"
                responseType="code"
                render={({ onClick }) => (
                    <ButtonComponent
                        onClick={() => {
                            onAuthenticationStart();
                            setIsLoading(true);
                            if (onClick) {
                                onClick();
                            }
                        }}
                        isLoading={isLoading}
                        {...filterStyleProps(rest)}
                    >
                        {children || 'Apple'}
                    </ButtonComponent>
                )}
            />
        </Wrapper>
    );
}
