import React from 'react';
import { useProofOfIdVerificationContext } from '../../../../../../contexts/proof-of-id-verification/ProofOfIdVerificationContext';
import { Country } from '@staycool/location';
import { translate } from '../../../../../../services/translate';
import { isUserFromCountry } from '../../../../../../services/users/country';
import AccountVerificationOptionsList from '../../../options-list/AccountVerificationOptionsList';
import AccountVerificationProofOfIdFooter from '../../footer/AccountVerificationProofOfIdFooter';
import AccountVerificationProofOfIdHeader from '../../header/AccountVerificationProofOfIdHeader';

export default function AccountVerificationProofOfIdSteps1() {
    const { submissionState, updateSubmissionState } = useProofOfIdVerificationContext();
    const { documentType: selectedDocumentType } = submissionState;
    const documentTypeOptions = [
        'Passport',
        'ID card',
        isUserFromCountry(Country.CHILE) ? '' : 'Drivers License',
        'Residence Permit',
    ]
        .filter(Boolean)
        .map((documentType) => ({
            value: documentType,
            label: translate(documentType, 'ui.account.kyc.id'),
        }));

    return (
        <>
            <AccountVerificationProofOfIdHeader>
                <span>{translate('Choose the document type you would like to upload', 'ui.account.kyc')}</span>
            </AccountVerificationProofOfIdHeader>
            <AccountVerificationOptionsList
                options={documentTypeOptions}
                selectedOption={selectedDocumentType}
                onOptionSelected={(documentType) => updateSubmissionState({ documentType })}
            />
            <AccountVerificationProofOfIdFooter />
        </>
    );
}
