import React, { useState } from 'react';
import { translate } from '../../../../services/translate';
import UiButton from '../../../ui/button/UiButton';
import UiModal from '../../../ui/modal/UiModal';
import { shareBetslip } from '../../../../microservices/bets';
import Wrapper from './styles';
import Svg from '../../../svg/Svg';
import { toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getStoreValue } from '../../../../stores/store/utils';
import { stores } from '../../../../stores';
import { useStore } from '../../../../hooks/useStore';
import { media } from '../../../../stores/media/media';
import { logger } from '../../../../services/logger';
import { COMBO_MARKET_ID } from '../../../../services/sports/constants';
import {
    FacebookIcon,
    FacebookShareButton,
    FacebookMessengerIcon,
    FacebookMessengerShareButton,
    TelegramIcon,
    XIcon,
    TwitterShareButton,
    WhatsappIcon,
    TelegramShareButton,
    WhatsappShareButton,
} from 'react-share';
import { TicketType } from '@staycool/bets-types';
import { TAG_MANAGER_EVENT, trackGoogleTagManagerEvent } from '../../../../services/analytics';
import { environment } from '../../../../stores/environment/environment';
import { isTestUser } from '../../../../services/user';

interface Props {
    outcomeIds: number[];
    betType: TicketType;
    comboCardId?: number;
}

export default function SportBetslipShareButton({ outcomeIds, betType, comboCardId }: Props) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [betslipCode, setBetslipCode] = useState('');
    const outcomeIdByMarketId = getStoreValue(stores.sports.betSlipMarketIdToOutcomeId);
    const [{ isPhone }] = useStore(media);
    const [{ FACEBOOK_CLIENT_ID }] = useStore(environment);

    const [betSlipErrorByMarketId] = useStore(stores.sports.betSlipErrorByMarketId);
    const genericErrors = betSlipErrorByMarketId[String(COMBO_MARKET_ID)] || [];
    const [marketInfoById] = useStore(stores.sports.marketInfoById);
    const marketIds = Object.keys(outcomeIdByMarketId);

    const shouldDisplayShareButton =
        !['betbuilder', 'teaser'].includes(betType) &&
        marketIds.every((marketId) => marketInfoById[Number(marketId)]?.match_status === 'OPEN');

    const betslipUrlToShare = `${window.location.host}/shared-betslip?betslipCode=${betslipCode}`;
    const windowHeight = 800;
    const windowWidth = 1200;
    const windowPosition = 'windowCenter';
    const contentMessage = translate(`Feeling confident about my bet! Stay cool and good luck!`, 'ui.betslip');

    async function onShareBetslipButtonClick() {
        try {
            setIsLoading(true);
            const { code } = await shareBetslip(outcomeIds, betType, comboCardId);
            if (navigator.share) {
                navigator.share({
                    url: `/shared-betslip?betslipCode=${code}`,
                    title: contentMessage,
                });
            } else {
                setIsModalOpen(true);
                setBetslipCode(code);
            }
        } catch (err) {
            logger.error('SportBetslipShareButton', 'onShareBetslipButtonClick', err);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Wrapper>
            {shouldDisplayShareButton && (
                <UiButton
                    block
                    color="default"
                    size="default"
                    icon={<Svg icon="share" size={0.75} />}
                    className="betslip-share-button"
                    isLoading={isLoading}
                    disabled={Boolean(genericErrors.length)}
                    onClick={onShareBetslipButtonClick}
                >
                    {translate(`Share betslip`, 'ui.betslip')}
                </UiButton>
            )}

            <UiModal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                className="new-modal"
                mode={isPhone ? 'drawer' : 'default'}
                minWidth={320}
                maxWidth={510}
            >
                <Wrapper>
                    <div className="modal-container">
                        <span className="title">{translate(`Share Your Betslip`, 'ui.betslip')}</span>

                        <div className="social-media-container">
                            <div className="box">
                                <CopyToClipboard
                                    text={betslipUrlToShare}
                                    onCopy={() => {
                                        toast.success(translate('URL copied', 'ui.betslip'));
                                        trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.BETSLIP_LINK_SHARE_CLICKED);
                                    }}
                                >
                                    <div>
                                        <Svg className="copy-icon" icon="copy" size={2} />
                                        <div>{translate(`URL`, 'ui.betslip')}</div>
                                    </div>
                                </CopyToClipboard>
                            </div>

                            <div className="box">
                                <TwitterShareButton
                                    url={betslipUrlToShare}
                                    title={contentMessage}
                                    windowHeight={windowHeight}
                                    windowWidth={windowWidth}
                                    windowPosition={windowPosition}
                                    onClick={() =>
                                        trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.BETSLIP_TWITTER_SHARE_CLICKED)
                                    }
                                >
                                    <XIcon size={32} round />
                                    <div>X</div>
                                </TwitterShareButton>
                            </div>

                            {FACEBOOK_CLIENT_ID && isTestUser() && (
                                <div className="box">
                                    <FacebookMessengerShareButton
                                        url={betslipUrlToShare}
                                        appId={FACEBOOK_CLIENT_ID}
                                        content={contentMessage}
                                        onClick={() =>
                                            trackGoogleTagManagerEvent(
                                                TAG_MANAGER_EVENT.BETSLIP_FACEBOOK_MESSENGER_SHARE_CLICKED,
                                            )
                                        }
                                    >
                                        <FacebookMessengerIcon size={32} round />
                                        <div>Messenger</div>
                                    </FacebookMessengerShareButton>
                                </div>
                            )}

                            <div className="box">
                                <WhatsappShareButton
                                    url={betslipUrlToShare}
                                    title={contentMessage}
                                    windowHeight={windowHeight}
                                    windowWidth={windowWidth}
                                    windowPosition={windowPosition}
                                    onClick={() =>
                                        trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.BETSLIP_WHATS_APP_SHARE_CLICKED)
                                    }
                                >
                                    <WhatsappIcon size={32} round />
                                    <div>Whatsapp</div>
                                </WhatsappShareButton>
                            </div>
                            <div className="box">
                                <TelegramShareButton
                                    url={betslipUrlToShare}
                                    title={contentMessage}
                                    windowHeight={windowHeight}
                                    windowWidth={windowWidth}
                                    windowPosition={windowPosition}
                                    onClick={() =>
                                        trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.BETSLIP_TELEGRAM_SHARE_CLICKED)
                                    }
                                >
                                    <TelegramIcon size={32} round />
                                    <div>Telegram</div>
                                </TelegramShareButton>
                            </div>
                            <div className="box">
                                <FacebookShareButton
                                    url={betslipUrlToShare}
                                    windowHeight={windowHeight}
                                    windowWidth={windowWidth}
                                    windowPosition={windowPosition}
                                    onClick={() =>
                                        trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.BETSLIP_FACEBOOK_SHARE_CLICKED)
                                    }
                                >
                                    <FacebookIcon size={32} round />
                                    <div>Facebook</div>
                                </FacebookShareButton>
                            </div>
                        </div>
                    </div>
                </Wrapper>
            </UiModal>
        </Wrapper>
    );
}
