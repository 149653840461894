import { BetSlipMinimalMarket } from '@staycool/sbgate-types';
import groupBy from 'lodash/groupBy';
import { isSameMatchComboEligibleFromSets } from '../../microservices/bets';
import { stores } from '../../stores';
import { isFeatureAvailable } from '../feature';
import { logger } from '../logger';
import { FEATURE } from '../types';
import { validateSameMatchCombo } from './betslip';
import { addBetslipError } from './betslip-errors';
import { COMBO_NOT_ALLOWED_ERROR } from './constants';
import { getStoreValue } from '../../stores/store/utils';
import { BET_TYPE } from './types';

export async function handleSameMatchComboValidation(marketsByMatchId: Record<string, BetSlipMinimalMarket[]>) {
    try {
        if (isFeatureAvailable(FEATURE.SAME_MATCH_MARKET_SET)) {
            const marketsWithError: number[] = [];
            const marketByMatchEntries = Object.entries(marketsByMatchId);
            const filteredMarketsByMatchEntries = marketByMatchEntries.filter(([, sameMatchMarkets]) => {
                if (sameMatchMarkets.length < 2) {
                    return false;
                }
                const sameMatchMarketIds = sameMatchMarkets.map((mr) => mr.id);
                const marketsByMarketTypeId = groupBy(sameMatchMarkets, 'market_type_id');
                const multipleSameMarketTypeMarketIds = Object.values(marketsByMarketTypeId).find(
                    (markets) => markets.length > 1,
                );
                if (multipleSameMarketTypeMarketIds) {
                    marketsWithError.push(...sameMatchMarketIds);
                    return false;
                }

                const hasInplayMarket = sameMatchMarkets.some((mr) => mr.in_play);
                if (hasInplayMarket) {
                    marketsWithError.push(...sameMatchMarketIds);
                    return false;
                }
                return true;
            });
            for (const marketId of marketsWithError) {
                addBetslipError(marketId, COMBO_NOT_ALLOWED_ERROR);
            }
            if (!filteredMarketsByMatchEntries.length) {
                return;
            }
            const marketIdsByMatchId = Object.fromEntries(
                filteredMarketsByMatchEntries.map(([matchId, markets]) => [matchId, markets.map(({ id }) => id)]),
            );
            stores.sports.betSlipPlacingState.set((betSlipPlacingState) => ({
                ...betSlipPlacingState,
                isLoading: true,
            }));
            const isSameMatchComboEligibleByMatchId = await isSameMatchComboEligibleFromSets(marketIdsByMatchId);
            stores.sports.betSlipPlacingState.set((betSlipPlacingState) => ({
                ...betSlipPlacingState,
                isLoading: false,
            }));

            // need to make sure that betType is still combo because isSameMatchComboEligibleFromSets can take some time
            const { betType } = getStoreValue(stores.sports.betSlipUserState);
            if (![BET_TYPE.COMBO, BET_TYPE.SYSTEM].includes(betType)) {
                return;
            }

            for (const matchId in marketsByMatchId) {
                if (isSameMatchComboEligibleByMatchId[matchId] || marketsByMatchId[matchId].length < 2) {
                    continue;
                }
                for (const market of marketsByMatchId[matchId]) {
                    addBetslipError(market.id, COMBO_NOT_ALLOWED_ERROR);
                }
            }
        } else {
            await validateSameMatchCombo(marketsByMatchId);
        }
    } catch (error) {
        logger.error('SportsBetslipValidationService', 'handleSameMatchComboValidation', error);
    }
}
