import { stores } from '../../stores';
import { getStoreValue } from '../../stores/store/utils';
import { logger } from '../logger';
import {
    BET_TYPE,
    INITIAL_BETSLIP_USER_STATE,
    INITIAL_BETSLIP_PLACING_STATE,
    VIRTUAL_SPORTS_PROVIDERS,
} from './constants';
import { postComboBet, postSingleBet, postSystemBet } from '../../microservices/highlight-games';
import { getActiveCurrency, getAmountByStaticRate } from '../currency';
import {
    MAX_SELECTIONS_COMBO_ERROR,
    MAX_SELECTIONS_SYSTEM_ERROR,
    ODDS_CLOSED_ERROR,
    INSUFFICIENT_FUNDS_ERROR,
} from '../sports/constants';
import { calculateSystemTotalStake, getSystemCombinations } from './system-bet-helpers';
import round from 'lodash/round';
import cloneDeep from 'lodash/cloneDeep';
import isNumber from 'lodash/isNumber';
import { isFeatureAvailable } from '../feature';
import { getMinStake } from '../sports/limits';
import { virtualSports } from '../../stores/virtual-sports/virtual-sports';
import { Ticket } from '../../stores/virtual-sports/types';
import { FEATURE } from '../types';

export function resetBetslip() {
    virtualSports.betslipPlacingState.set(() => cloneDeep(INITIAL_BETSLIP_PLACING_STATE));
    virtualSports.betslipUserState.set(() => cloneDeep(INITIAL_BETSLIP_USER_STATE));
    virtualSports.betslipMarketIdToOutcomeId.set(() => ({}));
    virtualSports.betslipMarketInfoById.set(() => ({}));
    virtualSports.betslipOddsByOutcomeId.set(() => ({}));
}

export async function placeBet() {
    const user = getStoreValue(stores.user);
    const betSlipUserState = getStoreValue(virtualSports.betslipUserState);
    const betSlipPlacingState = getStoreValue(virtualSports.betslipPlacingState);
    const betSlipMarketIdToOutcomeId = getStoreValue(virtualSports.betslipMarketIdToOutcomeId);
    const oddsByOutcomeId = getStoreValue(virtualSports.betslipOddsByOutcomeId);

    if (!user) {
        return stores.modals.isLoginModalOpen.set(true);
    }
    if (!betSlipPlacingState.isConfirmed && isFeatureAvailable(FEATURE.BETSLIP_CONFIRM)) {
        return virtualSports.betslipPlacingState.set({ ...betSlipPlacingState, needsConfirm: true });
    }

    const { betType, stakeByMarketId, systemStakes } = betSlipUserState;
    const marketIds = Object.keys(betSlipMarketIdToOutcomeId);

    virtualSports.betslipPlacingState.set({ ...betSlipPlacingState, isLoading: true, needsConfirm: false });

    let ticket: Ticket | null = null;

    const endPlaceBet = () =>
        virtualSports.betslipPlacingState.set((prev) => ({ ...prev, receipt: ticket, betslipErrors }));

    const { isValid, betslipErrors = {} } = validateBetslip();

    if (!isValid) {
        return endPlaceBet();
    }

    try {
        if (betType === BET_TYPE.SINGLE) {
            const singleBet: {
                stake: any;
                odds_by_outcome_id: { [x: number]: any };
            }[] = [];

            for (const marketId of marketIds) {
                const outcomeId = betSlipMarketIdToOutcomeId[marketId];
                singleBet.push({
                    stake: Number(stakeByMarketId[marketId]).toFixed(2),
                    odds_by_outcome_id: {
                        [outcomeId]: oddsByOutcomeId[outcomeId],
                    },
                });
            }

            ticket = await postSingleBet(singleBet);
        } else if (betType === BET_TYPE.COMBO) {
            const comboBet = {
                stake: Number(stakeByMarketId.null).toFixed(2),
                odds_by_outcome_id: {},
            };

            for (const marketId of marketIds) {
                const outcomeId = betSlipMarketIdToOutcomeId[marketId];
                comboBet.odds_by_outcome_id[outcomeId] = oddsByOutcomeId[outcomeId];
            }

            ticket = await postComboBet(comboBet);
        } else if (betType === BET_TYPE.SYSTEM) {
            const tempStakes = { ...systemStakes };

            for (const systemStakeKey of Object.keys(tempStakes)) {
                tempStakes[systemStakeKey] = Number(tempStakes[systemStakeKey]).toFixed(2);
            }

            const systemBet = {
                system_stakes: tempStakes,
                odds_by_outcome_id: {},
            };

            for (const marketId of marketIds) {
                const outcomeId = betSlipMarketIdToOutcomeId[marketId];
                systemBet.odds_by_outcome_id[outcomeId] = oddsByOutcomeId[outcomeId];
            }
            ticket = await postSystemBet(systemBet);
        }
        if (ticket) {
            ticket.provider = VIRTUAL_SPORTS_PROVIDERS.HIGHLIGHT_GAMES;
        }
        virtualSports.betslipMarketIdToOutcomeId.set({});
        virtualSports.betslipUserState.set(cloneDeep(INITIAL_BETSLIP_USER_STATE));
    } catch (error: any) {
        if (error.validationErrors && Object.keys(error.validationErrors).length) {
            for (const validationError of Object.keys(error.validationErrors)) {
                betslipErrors[validationError] = error.validationErrors[validationError];
            }
        } else if (error.name) {
            betslipErrors[error.name] = error;
        } else {
            (betslipErrors as any).TECHNICAL_ERROR = 'TECHNICAL_ERROR';
        }

        logger.info('VirtualSportsBetslipService', 'placeBet', error);
    } finally {
        endPlaceBet();
    }
}

export function hasBetSlipChangedFromOdds(state) {
    if (!state) {
        return '';
    }

    const outcomeIds: number[] = Object.values(getStoreValue(virtualSports.betslipMarketIdToOutcomeId));

    return outcomeIds
        .map((outcomeId) => (state[outcomeId] ? state[outcomeId].odds_id : `loading_${outcomeId}`))
        .sort()
        .join();
}

export function getFixedStake(stake) {
    const cleanedStake = stake.replace(/[a-zA-Z]/g, '').replace(',', '.');
    const maxLengthNumber = Number(cleanedStake).toFixed(2);
    return cleanedStake.length > maxLengthNumber.length ? maxLengthNumber : cleanedStake;
}

export function calculateTotalOdds(marketId) {
    const oddsByOutcomeId = getStoreValue(virtualSports.betslipOddsByOutcomeId);
    const betSlipMarketIdToOutcomeId = getStoreValue(virtualSports.betslipMarketIdToOutcomeId);
    const marketIdToOutcomeId = marketId
        ? { [marketId]: betSlipMarketIdToOutcomeId[marketId] }
        : betSlipMarketIdToOutcomeId;
    const allOddsValues = Object.values(marketIdToOutcomeId)
        .map((outcomeId) => oddsByOutcomeId[outcomeId as number])
        .filter((oddsValue) => oddsValue);
    return round(
        allOddsValues.reduce((totalOdds, oddsValue) => totalOdds * oddsValue, 1),
        2,
    );
}

export function validateBetslip() {
    const betSlipUserState = getStoreValue(virtualSports.betslipUserState);
    const { betType, stakeByMarketId, systemStakes } = betSlipUserState;
    const betslipMarketInfoById = getStoreValue(virtualSports.betslipMarketInfoById);
    const betSlipMarketIdToOutcomeId = getStoreValue(virtualSports.betslipMarketIdToOutcomeId);
    const marketIds = Object.keys(betSlipMarketIdToOutcomeId);
    const potentialReturn = getStoreValue(virtualSports.potentialReturn);
    const betslipErrors = {};
    const numberOfBets = Object.values(betSlipMarketIdToOutcomeId).length;
    const gameWeekInfo = getStoreValue(virtualSports.gameWeekInfo);

    const validateNumberOfBets = (count) => {
        const betTypeToSelectionLimits = {
            [BET_TYPE.COMBO]: { max: 15, maxError: MAX_SELECTIONS_COMBO_ERROR },
            [BET_TYPE.SYSTEM]: { max: 10, maxError: MAX_SELECTIONS_SYSTEM_ERROR },
        };
        const { max, maxError } = betTypeToSelectionLimits[betType] || {};
        if (count > max) {
            betslipErrors[maxError] = maxError;
        }
    };

    const validateStatuses = () => {
        marketIds.forEach((marketId) => {
            const { gameWeek } = betslipMarketInfoById[marketId] || {};
            const { bettingStatus } = gameWeekInfo[gameWeek] || {};
            if (bettingStatus === 'SUSPENDED') {
                betslipErrors[marketId] = ODDS_CLOSED_ERROR;
            }
        });
    };

    const validateCombinations = () => {
        if (betType !== BET_TYPE.SINGLE) {
            let hasOutright = false;
            const groupedMarkets = {};

            for (const marketId of marketIds) {
                const selectedMarket = betslipMarketInfoById[marketId];
                const groupingId = selectedMarket.match_id || selectedMarket.outright_id || selectedMarket.event_id;
                if (!groupedMarkets[groupingId]) {
                    groupedMarkets[groupingId] = [];
                }
                groupedMarkets[groupingId].push(marketId);

                if (selectedMarket.singles_only) {
                    betslipErrors[marketId] = 'COMBO_NOT_ALLOWED';
                }

                if (selectedMarket.outright_id || selectedMarket.is_outright) {
                    hasOutright = true;
                }
            }

            if (hasOutright) {
                (betslipErrors as any).COMBO_NOT_ALLOWED = 'COMBO_NOT_ALLOWED';
            }

            for (const groupingId of Object.keys(groupedMarkets)) {
                const markets = groupedMarkets[groupingId];
                if (markets.length > 1) {
                    markets.forEach((marketId) => {
                        betslipErrors[marketId] = 'SAME_MATCH_IN_BETSLIP';
                    });
                }
            }
        }
    };

    const validateStakeAndPotentialReturn = () => {
        const wallet = getStoreValue(stores.wallet);
        const { balance_uc: userBalance } = wallet || {};
        const currency = getActiveCurrency();
        const minStake = getMinStake();
        let isStakeInputDirty = false;
        let summedStake = 0;
        if (betType === BET_TYPE.SINGLE) {
            for (const marketId of marketIds) {
                const stakeNumber = Number(stakeByMarketId[marketId]) || 0;
                if (stakeByMarketId[marketId] && !isStakeInputDirty) {
                    isStakeInputDirty = true;
                }
                if (stakeNumber < minStake) {
                    betslipErrors[marketId] = 'MIN_STAKE';
                }
                summedStake += stakeNumber;
            }
        } else if (betType === BET_TYPE.COMBO) {
            isStakeInputDirty = Boolean(stakeByMarketId.null);
            const stakeNumber = Number(stakeByMarketId.null) || 0;
            if (!stakeByMarketId.null || stakeNumber < minStake) {
                (betslipErrors as any).MIN_STAKE = 'MIN_STAKE';
            }
            summedStake += stakeNumber;
        } else if (betType === BET_TYPE.SYSTEM) {
            if (!Object.keys(systemStakes).length) {
                (betslipErrors as any).MIN_STAKE = 'MIN_STAKE';
            } else {
                const systemBets = getSystemCombinations(betSlipMarketIdToOutcomeId);
                summedStake = calculateSystemTotalStake(systemBets);
                for (const systemStakeKey of Object.keys(systemStakes)) {
                    if (systemStakes[systemStakeKey]) {
                        isStakeInputDirty = true;
                    }
                    if (!systemStakes[systemStakeKey] || Number(systemStakes[systemStakeKey]) < minStake) {
                        (betslipErrors as any).MIN_STAKE = 'MIN_STAKE';
                    }
                }
            }
        }

        if (isNumber(userBalance) && summedStake > userBalance) {
            (betslipErrors as any).INSUFFICIENT_FUNDS_ERROR = INSUFFICIENT_FUNDS_ERROR;
        }

        const MAX_POTENTIAL_RETURN_IN_EUR = 100 * 1000;
        const maxPotentialReturn = getAmountByStaticRate(MAX_POTENTIAL_RETURN_IN_EUR, currency);
        if (potentialReturn >= maxPotentialReturn) {
            (betslipErrors as any).TICKET_LIMIT = 'TICKET_LIMIT';
        }
        return isStakeInputDirty;
    };

    const validateBetsAreUnderCurrentProvider = () => {
        // this should never happen but this check is just in case when somebody beaks something in frontend
        const isCorrectProviderBet = (marketInfo) => Boolean(marketInfo.highlight_id);
        const hasCorrectProvider = marketIds.every((marketId) => isCorrectProviderBet(betslipMarketInfoById[marketId]));
        if (!hasCorrectProvider) {
            (betslipErrors as any).TECHNICAL_ERROR = 'TECHNICAL_ERROR';
        }
    };
    validateBetsAreUnderCurrentProvider();
    if (Object.keys(betslipErrors).length) {
        return { isValid: false, betslipErrors };
    }

    validateStatuses();
    if (Object.keys(betslipErrors).length) {
        return { isValid: false, betslipErrors };
    }

    validateNumberOfBets(numberOfBets);
    if (Object.keys(betslipErrors).length) {
        return { isValid: false, betslipErrors };
    }
    validateCombinations();
    if (Object.keys(betslipErrors).length) {
        return { isValid: false, betslipErrors };
    }

    const isStakeInputDirty = validateStakeAndPotentialReturn();
    if (!isStakeInputDirty) {
        return { isValid: false, betslipErrors: {} };
    }
    if (Object.keys(betslipErrors).length) {
        return { isValid: false, betslipErrors };
    }
    return { isValid: true, betslipErrors };
}
