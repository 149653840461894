import React from 'react';
import Wrapper from './styles';
import { MATCH_TYPE } from '../../../../services/sports/constants';
import { stores } from '../../../../stores';
import classNames from 'classnames';
import { useSportsUserSettings } from '../../../../services/sports/user-settings';
import TeamLogo from '../../../team-logo/TeamLogo';
import UiPlaceholder from '../../../ui/placeholder/UiPlaceholder';
import { media } from '../../../../stores/media/media';
import { useStore } from '../../../../hooks/useStore';
import { CategoryMatch } from '../../../../services/sports/types';
import { useSportsVisualSettings } from '../../../../services/sports/sport-match-visual-settings';

interface Props {
    isCompact?: boolean;
    match: CategoryMatch;
}

export default function SportMatchName({ match, isCompact }: Props) {
    const isNormalMatch = match.match_type !== MATCH_TYPE.OUTRIGHT;
    const { isAmericanLayout, isPreferringShortNames } = useSportsUserSettings({
        sportCategoryId: match.sport_category,
    });
    const [isMobileCompactView] = useStore(stores.sports.isMobileCompactView);
    const isAmericanCompactLayout = isAmericanLayout && isMobileCompactView && isCompact;
    const { isShowTeamIcon } = useSportsVisualSettings();
    const [{ isTablet }] = useStore(media);

    function isNameTooLong(name) {
        return name?.length > 20;
    }

    const awayTeamName = isPreferringShortNames
        ? match.away_team_short_name || match.away_team_name
        : match.away_team_name;
    const homeTeamName = isPreferringShortNames
        ? match.home_team_short_name || match.home_team_name
        : match.home_team_name;

    return (
        <Wrapper
            $isCompact={isCompact || isTablet}
            $isAmericanCompactLayout={isAmericanCompactLayout}
            $isAmericanLayout={isAmericanLayout}
        >
            {!isAmericanLayout && isNormalMatch && (
                <div className="match-teams">
                    <div className="team-home">
                        {isShowTeamIcon && match.home_team_logo_name && (
                            <img
                                className="test-team-logo"
                                src={`/assets/images/flags/1x1/${match.home_team_logo_name}.svg`}
                            />
                        )}
                        <div className="name">{homeTeamName || <UiPlaceholder />} </div>
                        {match.pitcherHome && <span className="pitcher-name">{match.pitcherHome}</span>}
                    </div>
                    {!isCompact && <span className="divider">-</span>}
                    <div className="team-away">
                        {isShowTeamIcon && match.away_team_logo_name && (
                            <img
                                className="test-team-logo"
                                src={`/assets/images/flags/1x1/${match.away_team_logo_name}.svg`}
                            />
                        )}
                        <div className="name">{awayTeamName || <UiPlaceholder />}</div>{' '}
                        {match.pitcherAway && <span className="pitcher-name">{match.pitcherAway}</span>}
                    </div>
                </div>
            )}

            {isAmericanLayout && isNormalMatch && (
                <div className="match-teams">
                    {
                        <div className={classNames('team-away', { 'small-size': isNameTooLong(awayTeamName) })}>
                            {match.away_team_logo_name && <TeamLogo teamName={match.away_team_logo_name} />}
                            <div className="team-name">
                                {awayTeamName || <UiPlaceholder />}
                                {match.pitcherAway && <span className="pitcher-name">{match.pitcherAway}</span>}
                            </div>
                        </div>
                    }
                    <div className={classNames('team-home', { 'small-size': isNameTooLong(homeTeamName) })}>
                        {match.home_team_logo_name && <TeamLogo teamName={match.home_team_logo_name} />}
                        <div className="team-name">
                            {homeTeamName || <UiPlaceholder />}
                            {match.pitcherHome && <span className="pitcher-name">{match.pitcherHome}</span>}
                        </div>
                    </div>
                </div>
            )}

            {!isNormalMatch && <div className="match-outright-name">{match.name}</div>}
        </Wrapper>
    );
}
