import React, { ReactNode } from 'react';
import { FieldError, RegisterOptions, useFormContext } from 'react-hook-form';
import { translate } from '../../../../services/translate';
import UiTextInput from '../../../ui/text-input/UiTextInput';
import { Validator } from '../../../../types/components/form/types';

interface Props extends Omit<React.HTMLProps<HTMLInputElement>, 'pattern'> {
    error?: FieldError;
    name: string;
    label?: string;
    type?: 'text' | 'email' | 'password' | 'number' | 'tel' | 'search';
    pattern?: RegExp;
    validator?: {
        validate: Validator;
    };
    dataTest?: string;
    startAdornment?: ReactNode;
    endAdornment?: ReactNode;
}
export default function Ui2FormTextInput({
    name,
    minLength,
    maxLength,
    pattern,
    validator,
    required = true,
    ...args
}: Props) {
    const { register } = useFormContext();

    const options: RegisterOptions = {
        required: { value: required, message: translate('Oops! You forgot this field', 'ui.registration') },
        validate: validator?.validate,
    };

    if (minLength) {
        options.minLength = {
            value: minLength,
            message: translate(`Oops! Minimum required field length is {{ minLength }} characters.`, 'ui.common', {
                minLength,
            }),
        };
    }

    if (maxLength) {
        options.maxLength = {
            value: maxLength,
            message: translate(`Oops! Maximum allowed field length is {{ maxLength }} characters.`, 'ui.common', {
                maxLength,
            }),
        };
    }

    if (pattern) {
        options.pattern = {
            value: pattern,
            message: translate('Oops! You are using invalid characters.', 'ui.common'),
        };
    }

    const { ref, ...rest } = register(name, options);

    return <UiTextInput registerReturn={rest} registerRef={ref} {...args} maxLength={maxLength} />;
}
