import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet';
import { DocumentCaptureContext } from '../../../../../contexts/document-capture/DocumentCaptureContext';
import { logger } from '../../../../../services/logger';
import { translate } from '../../../../../services/translate';
import UiButton from '../../../../ui/button/UiButton';
import UiAlert from '../../../../ui/alert/UiAlert';
import UiDotsLoader from '../../../../ui/dots-loader/UiDotsLoader';
import UiGroup from '../../../../ui/group/UiGroup';
import AccountVerificationDocumentCapturePreScreen from '../pre-screen/AccountVerificationDocumentCapturePreScreen';
import AccountVerificationDocumentCaptureQrCode from '../qr-code/AccountVerificationDocumentCaptureQrCode';
import AccountVerificationDocumentCaptureSdk from '../sdk/AccountVerificationDocumentCaptureSdk';
import Wrapper from './styles';
import ScriptLoader from '../../../../script-loader/ScriptLoader';
import Svg from '../../../../svg/Svg';
import { media } from '../../../../../stores/media/media';
import { useStore } from '../../../../../hooks/useStore';

interface Props {
    onCompleted?: () => void;
}

export default function AccountVerificationDocumentCaptureIdScan({ onCompleted }: Props) {
    const [isError, setIsError] = useState(false);
    const {
        setIsQrOpen,
        setIsPreScreenOpen,
        setIsScriptLoaded,
        isLoading,
        setIsLoading,
        isPreScreenOpen,
        isQrOpen,
        isDesktop,
        onClose,
    } = useContext(DocumentCaptureContext);
    const [{ isPhone }] = useStore(media);

    function handleError(error) {
        logger.error('AccountVerificationDocumentCaptureIdScan', 'handleError', error);
        setIsError(true);
    }

    function getErrorMessage() {
        return `${translate(
            'Technical error while loading IDScan, please contact customer support',
            'ui.account',
        )} ${translate('Please try again later.', 'ui.common')}`;
    }

    function onBackToGuide() {
        setIsQrOpen(false);
        setIsPreScreenOpen(true);
        setIsLoading(true);
    }

    return (
        <Wrapper
            className="new-modal"
            onClose={onClose}
            title={translate('Automatic document verification', 'ui.account')}
            icon={<Svg size={6} icon="mobile-id-scan" />}
            mode={isPhone ? 'drawer' : undefined}
            maxWidth={isPhone ? '' : 350}
            minWidth={isPhone ? '' : 350}
            shouldDisplayCloseButton={true}
        >
            <ScriptLoader
                url="/lib/GBG_WSDK_12.0.0/idscan-jcs.js"
                onError={handleError}
                onLoad={() => setIsScriptLoaded(true)}
            />
            <Helmet>
                <link rel="stylesheet" type="text/css" href="/lib/GBG_WSDK_12.0.0/styles.css" />
                <link rel="stylesheet" type="text/css" href="/assets/styles/gbgWSDKOverride.css" />
            </Helmet>
            {!isPreScreenOpen && (
                <UiGroup vertical>
                    {isLoading && <UiDotsLoader />}
                    {!isQrOpen && (
                        <>
                            <AccountVerificationDocumentCaptureSdk onCompleted={onCompleted} />
                            {isDesktop && !isLoading && (
                                <div className="id-scan-button-container">
                                    <UiButton block onClick={() => setIsQrOpen(!isQrOpen)}>
                                        {translate('Go to mobile flow', 'ui.account')}
                                    </UiButton>
                                </div>
                            )}
                        </>
                    )}
                    {isQrOpen && <AccountVerificationDocumentCaptureQrCode onBackToGuide={onBackToGuide} />}
                </UiGroup>
            )}
            {isPreScreenOpen && <AccountVerificationDocumentCapturePreScreen />}
            {isError && <UiAlert failure>{getErrorMessage()}</UiAlert>}
        </Wrapper>
    );
}
