import round from 'lodash/round';
import { stores } from '../../stores';
import { getStoreValue } from '../../stores/store/utils';
import { depositMoney, getMyCardCount } from '../../microservices/payments';
import { getLastActiveProductRoute, getRoute } from '../router';
import { ExistingPaymentMethod, PROVIDERS } from '../../services/payments/types';
import { DepositStatus } from './types';
import { translate } from '../translate';

export type Card = ExistingPaymentMethod & {
    id: string;
    card_number: string;
    description: string;
    masked_pan: string;
    number: string;
    payer_name: string;
    cardholder: string;
    expiration_date: string;
    expiration_month: string;
    expiration_year: string;
    expiry: string;
    expiry_date: string;
    expirationDate: string;
};

export const PROVIDER_UNAVAILABLE_MESSAGE = {
    PROVIDER_DOWN: "Provider's service seems to be down. Please try again later!",
    TOO_MANY_ATTEMPTS: 'Payment failed, you have made too many requests. Please try again later!',
};

export const LOGOS = {
    SEB: '/assets/images/payments/SEB.svg',
    SWEDBANK: '/assets/images/payments/Swedbank.png',
};

export const CHILE_BANKS = {
    '504': 'Banco BBVA Chile',
    '028': 'Banco Bice',
    '055': 'Banco Consorcio',
    '001': 'Banco de Chile',
    '016': 'Banco de Crédito e Inversiones',
    '507': 'Banco del Desarrollo',
    '012': 'Banco del Estado de Chile',
    '051': 'Banco Falabella',
    '009': 'Banco Internacional',
    '039': 'Banco Itaú',
    '053': 'Banco Ripley',
    '037': 'Banco Santander Chile',
    '027': 'Itaú Corpbanca',
    '014': 'Scotiabank Chile',
};

export const ECUADOR_BANKS = {
    '0001': 'Banco Central del Ecuador',
    '0010': 'Banco Pichincha C.A.',
    '0017': 'Banco de Guayaquil S.A',
    '0024': 'Banco City Bank',
    '0025': 'Banco Machala',
    '0029': 'Banco de Loja',
    '0030': 'Banco del Pacifico',
    '0032': 'Banco Internacional',
    '0034': 'Banco Amazonas',
    '0035': 'Banco del Austro',
    '0036': 'Produbanco / Promerica',
    '0037': 'Banco Bolivariano',
    '0039': 'Comercial de Manabi',
    '0042': 'Banco General Ruminahui S.A.',
    '0043': 'Banco del Litoral S.A.',
    '0059': 'Banco Solidario',
    '0060': 'Banco Procredit S.A.',
    '0061': 'Banco Capital',
    '0065': 'Banco Desarrollo de Los Pueblos S.A.',
    '0066': 'Banecuador B.P.',
    '0201': 'Banco Delbank S.A.',
};

export const PERU_SHARED_BANKS = [
    '002',
    '003',
    '007',
    '009',
    '011',
    '018',
    '023',
    '035',
    '038',
    '043',
    '049',
    '053',
    '054',
    '056',
    '800',
    '801',
    '802',
    '803',
    '805',
    '806',
    '808',
];

export const cryptoCurrencyByProvider = {
    [PROVIDERS.PAYHOUND_BITCOIN]: 'BTC',
    [PROVIDERS.PAYHOUND_USDT]: 'USDT',
};

export function cleanCardNumber(number: string): string {
    const regex = new RegExp(`[^0-9]+`, 'g');
    return String(number).replace(regex, '');
}

export function cleanExpirationDate(expirationDate: string): string {
    const regex = new RegExp(`[^0-9/]+`, 'g');
    return String(expirationDate).replace(regex, '');
}

export function depositWithProvider(params: {
    amount: number;
    deviceHash: string;
    methodId?: string;
    provider: string;
    providerParams?: any;
}) {
    const language = getStoreValue(stores.language);
    return depositMoney({ ...params, language });
}

export function fiatToCrypto(amount: number, providerCurrencyRate: number, provider: PROVIDERS) {
    const precision = provider === PROVIDERS.PAYHOUND_BITCOIN ? 8 : 2;
    return round(amount / providerCurrencyRate, precision);
}

export function formatCardNumber(number: string, options?: { clean?: boolean }): string {
    const clean = options?.clean;
    if (isApplePayAccountNumber(number)) {
        return number;
    }
    return clean ? cleanCardNumber(number) : number;
}

export function formatExpirationDate(expirationDate: string): string {
    return `${expirationDate.slice(0, 3)}${expirationDate.slice(5)}`;
}

export function expirationDateTo6Digits(expirationDate: string) {
    return `${expirationDate.slice(0, 3)}20${expirationDate.slice(3)}`;
}

export function validateExpirationDate(expirationDate: string): [boolean, string] {
    const [expirationMonth = '', expirationYear = ''] = expirationDate.split('/');
    const date = new Date();
    const currentMonth = date.getMonth() + 1;
    const currentYear = date.getFullYear();
    if (
        !expirationMonth ||
        !expirationYear ||
        !expirationMonth.match(/^\d{2}$/) ||
        !expirationYear.match(/^\d{4}$/) ||
        parseInt(expirationMonth) > 12
    ) {
        return [false, 'Invalid expiration date'];
    }
    if (
        parseInt(expirationYear) < currentYear ||
        (parseInt(expirationYear) === currentYear && parseInt(expirationMonth) < currentMonth)
    ) {
        return [false, 'Card has expired'];
    }
    return [true, ''];
}

export function getPaymentProviderImage(fileName) {
    const language = getStoreValue(stores.language);
    return fileName.replace('{{language}}', language);
}

export function getPaymentMethodImage(cardNumber = '') {
    if (cardNumber.startsWith('4')) {
        return `/assets/images/payments/visa-logo.svg`;
    }
    if (cardNumber.startsWith('5')) {
        return `/assets/images/payments/mastercard.svg`;
    }
    if (isApplePayAccountNumber(cardNumber)) {
        return '/assets/images/payments/applepay.svg';
    }
    return `/assets/images/payments/bank.png`;
}

export async function getIsMaxCardsReached({ raiseLimitBy = 0 }) {
    const { current, limit } = await getMyCardCount();
    if (!limit && limit !== 0) {
        return false;
    }
    return current >= limit + raiseLimitBy;
}

export function getDepositReturnRoute(status: DepositStatus) {
    return `${getRoute('deposit-return')}/${status}`;
}

function isApplePayAccountNumber(number: string) {
    return number.toLowerCase().startsWith('apple');
}

export const PAYMENTS_ERROR_CODE = {
    RETRY_WITH_FALLBACK_PROVIDER: 4612,
};

export function redirectDepositTo(state: DepositStatus) {
    const redirectTo: Record<DepositStatus, string> = {
        [DepositStatus.AUTO_REFUNDED_3RD_PERSON_METHOD]: `${getRoute(
            'deposit',
        )}?snippetKey=deposit-auto-refunded-3rd-person-method-pop-up`,
        [DepositStatus.AUTO_REFUNDED_NAME_MISMATCH]: `${getRoute(
            'deposit',
        )}?snippetKey=deposit-auto-refunded-name-mismatch-pop-up`,
        [DepositStatus.CANCELLED]: `${getRoute('deposit')}?error=${translate(
            'Your deposit was cancelled.',
            'ui.account',
        )}`,
        [DepositStatus.COMPLETED]: `${getLastActiveProductRoute()}?success=${translate(
            'Your deposit was successful.',
            'ui.account',
        )}`,
        [DepositStatus.FAILED]: `${getRoute('deposit')}?error=${translate(
            'Your deposit did not go through.',
            'ui.account',
        )}`,
        [DepositStatus.PENDING]: `${getLastActiveProductRoute()}?pending=${translate(
            'Your deposit is pending.',
            'ui.account',
        )}`,
    };

    return redirectTo[state];
}
