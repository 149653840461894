import React, { createContext, MutableRefObject, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { useDocumentUpload } from '../../services/kyc';
import { KycDocumentType, ProofOfIdStatus } from '../../services/kyc/types';
import { stores } from '../../stores';
import { useStore } from '../../hooks/useStore';

const submissionState = {
    areAllFourCornersVisible: false,
    areDetailsVisible: false,
    documentType: '',
    expiryDate: '',
    isBothSidesSelected: false,
    isDocumentNotScannedOrScreenshot: false,
    isDocumentReadable: false,
    isDocumentUnexpired: false,
};

interface InitialState {
    activeStep: number;
    documentUpload: ReturnType<typeof useDocumentUpload>;
    formContainerRef: MutableRefObject<HTMLDivElement | null>;
    isTwoSidedDocument: boolean;
    reset: () => void;
    setActiveStep: (step: number) => void;
    stepCompletionState: Record<number, boolean>;
    submissionState;
    updateSubmissionState: (state: Partial<typeof submissionState>) => void;
    acceptedMimeTypes: string;
}

const initialState = {
    activeStep: 0,
    documentUpload: {} as ReturnType<typeof useDocumentUpload>,
    formContainerRef: { current: null },
    isTwoSidedDocument: false,
    reset: () => {},
    setActiveStep: () => {},
    stepCompletionState: {},
    updateSubmissionState: () => {},
    acceptedMimeTypes: 'image/jpeg, image/png, image/tif, image/bmp, image/webp',
    submissionState,
};

const ProofOfIdVerificationContext = createContext<InitialState>(initialState);

interface Props {
    children: ReactNode;
    onCompleted?: () => void;
}

export function ProofOfIdVerificationContextProvider({ children, onCompleted }: Props) {
    const [activeStep, setActiveStep] = useState(initialState.activeStep);
    const [submissionState, setSubmissionState] = useState(initialState.submissionState);
    const [kycSettings] = useStore(stores.kyc.settings);
    const formContainerRef = useRef<HTMLDivElement>(null);
    const isTwoSidedDocument = submissionState.documentType !== 'Passport';
    const documentUpload = useDocumentUpload({
        documentType: KycDocumentType.ID,
        documentLimit: isTwoSidedDocument ? 2 : 1,
        declineReason: kycSettings.kycIdDeclineReason,
        status: kycSettings.proofOfId as ProofOfIdStatus,
    });
    const stepCompletionState = {
        1: !!submissionState.documentType,
        2: documentUpload.documents.length === documentUpload.documentLimit,
        3: [
            submissionState.areAllFourCornersVisible,
            submissionState.areDetailsVisible,
            submissionState.isDocumentNotScannedOrScreenshot,
            submissionState.isDocumentReadable,
            submissionState.isDocumentUnexpired,
            submissionState.expiryDate,
            !isTwoSidedDocument || submissionState.isBothSidesSelected,
        ].every(Boolean),
        4: !!documentUpload.uploadResultAlert,
    };

    useEffect(documentUpload.reset, [isTwoSidedDocument]);

    useEffect(
        () => setSubmissionState({ ...initialState.submissionState, documentType: submissionState.documentType }),
        [documentUpload.documents],
    );

    useEffect(() => {
        if (!activeStep || !formContainerRef.current) {
            return;
        }
        formContainerRef.current.scrollIntoView({ behavior: 'smooth' });
    }, [activeStep]);

    return (
        <ProofOfIdVerificationContext.Provider
            value={{
                ...initialState,
                activeStep,
                documentUpload,
                formContainerRef,
                isTwoSidedDocument,
                reset() {
                    setSubmissionState(initialState.submissionState);
                    setActiveStep(initialState.activeStep);
                    documentUpload.reset();
                    onCompleted?.();
                },
                setActiveStep,
                stepCompletionState,
                submissionState,
                updateSubmissionState(updates) {
                    setSubmissionState({ ...submissionState, ...updates });
                },
            }}
        >
            {children}
        </ProofOfIdVerificationContext.Provider>
    );
}

export const ProofOfIdVerificationContextConsumer = ProofOfIdVerificationContext.Consumer;

export function useProofOfIdVerificationContext() {
    return useContext<InitialState>(ProofOfIdVerificationContext);
}
