import { FoComboCardWithOdds } from '@staycool/sports-types/fo-combo-card';
import React from 'react';
import SportCard from '../../sport/card/SportCard';
import ComboCardsItemDetails from './details/ComboCardsItemDetails';
import ComboCardsItemFooter from './footer/ComboCardsItemFooter';
import ComboCardsItemHeader from './header/ComboCardsItemHeader';
import Wrapper from './styles';

interface Props {
    card: FoComboCardWithOdds;
}

export default function ComboCardsItem({ card }: Props) {
    return (
        <SportCard>
            <Wrapper>
                <ComboCardsItemHeader card={card} />
                <div className="combo-card-content">
                    <ComboCardsItemDetails card={card} />
                    <ComboCardsItemFooter card={card} />
                </div>
            </Wrapper>
        </SportCard>
    );
}
