import React, { InputHTMLAttributes, useState } from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import UiFormLabel from '../../../ui/form/label/UiFormLabel';
import UiAlert from '../../../ui/alert/UiAlert';
import Wrapper from './styles';
import WrapperCheckbox from './styles-checkbox';
import WrapperToggle from './styles-toggle';
import WrapperRadio from './styles-radio';
import { translate } from '../../../../services/translate';
import UiFormGroup from '../../../ui/form/group/UiFormGroup';
import { filterStyleProps } from '../../../../styles/utils';
import { Validator } from '../../../../types/components/form/types';

interface Props {
    className?: string;
    dataTest?: string;
    defaultValue?: any;
    disabled?: boolean;
    error?: Partial<{ message: string; type: number | string }>;
    id?: string;
    inputType?: 'checkbox' | 'radio' | 'select' | 'text' | 'textarea' | 'toggle';
    justify?: 'spaced' | 'center';
    label?: string;
    max?: number;
    maxLength?: number;
    min?: number;
    minLength?: number;
    name: `${string}` | `${string}.${string}` | `${string}.${number}`;
    pattern?: RegExp;
    placeholder?: string;
    radioNowrap?: boolean;
    radioOptions?: { value: any; label: string; defaultChecked?: boolean; editOptions?: any }[];
    radioRow?: boolean;
    required?: boolean;
    type?: 'email' | 'number' | 'password' | 'text' | 'tel';
    inputmode?: 'numeric' | 'tel' | 'url' | 'decimal' | 'email' | 'search';
    validator?: {
        validate: Validator;
    };
}

/**
 * @deprecated The component should be replaced with Ui2FormTextInput, Ui2FormCheckbox, Ui2FormToggle, Ui2FormRadio
 */
export default function Ui2FormInput({
    className,
    dataTest,
    defaultValue,
    disabled,
    error,
    id,
    inputType = 'text',
    justify,
    label,
    max,
    maxLength,
    min,
    minLength,
    name,
    pattern,
    placeholder,
    radioNowrap = false,
    radioOptions = [],
    radioRow = true,
    required = true,
    type,
    validator,
    ...restProps
}: Props & Omit<InputHTMLAttributes<any>, 'defaultValue' | 'pattern'>) {
    const [userInputValue, setUserInputValue] = useState(defaultValue);
    const { register } = useFormContext();
    const sharedProps: InputHTMLAttributes<any> & { 'data-test'?: string } = {
        'data-test': dataTest,
        disabled,
        placeholder,
        ...restProps,
    };

    const wrapperProps = { failure: true, className };

    if (inputType === 'toggle') {
        return (
            <WrapperToggle {...filterStyleProps(wrapperProps)} justify={justify}>
                {label && <UiFormLabel>{label}</UiFormLabel>}
                <label className="pseudo-toggle-container">
                    <input
                        {...sharedProps}
                        {...register(name, {
                            onChange: (e) => {
                                setUserInputValue(e.target.checked);
                            },
                        })}
                        type="checkbox"
                        checked={userInputValue}
                    />
                    <span className="pseudo-toggle" />
                </label>
            </WrapperToggle>
        );
    }

    if (inputType === 'checkbox') {
        return (
            <WrapperCheckbox {...filterStyleProps(wrapperProps)}>
                <UiFormLabel>
                    <input
                        {...sharedProps}
                        {...register(name, {
                            onChange: (e) => {
                                setUserInputValue(e.target.checked);
                            },
                        })}
                        type="checkbox"
                        checked={userInputValue}
                    />
                    <span className="pseudo-checkbox" />
                    {label && <span className="pseudo-label" dangerouslySetInnerHTML={{ __html: label }} />}
                </UiFormLabel>
            </WrapperCheckbox>
        );
    }

    const inputOptions: RegisterOptions = {
        required: { value: required, message: translate('Oops! You forgot this field', 'ui.registration') },
        validate: validator?.validate,
    };

    if (inputType === 'radio') {
        return (
            <WrapperRadio {...filterStyleProps(wrapperProps)}>
                <UiFormGroup row={radioRow} nowrap={radioNowrap}>
                    {label && <UiFormLabel>{label}</UiFormLabel>}

                    {radioOptions.map((option) => (
                        <label key={option.value}>
                            <input
                                {...sharedProps}
                                {...register(name, inputOptions)}
                                type="radio"
                                value={option.value}
                                defaultChecked={option.defaultChecked}
                            />

                            <span className="pseudo-radio" />
                            <span className="pseudo-label">{option.label}</span>
                            {option.editOptions}
                        </label>
                    ))}
                </UiFormGroup>
                {error && <UiAlert failure={true}>{error.message}</UiAlert>}
            </WrapperRadio>
        );
    }
    const propsInput: any = { ...sharedProps, id, max, min, type };
    const MAX_LENGTH_SUPPORTED_INPUT_TYPES = ['email', 'password', 'search', 'tel', 'text', 'url'];
    if (type && maxLength && MAX_LENGTH_SUPPORTED_INPUT_TYPES.includes(type)) {
        propsInput.maxLength = maxLength;
    } else if (maxLength) {
        inputOptions.maxLength = {
            value: maxLength,
            message: translate(`Oops! Maximum allowed field length is {{ maxLength }} characters.`, 'ui.common', {
                maxLength,
            }),
        };
    }
    if (minLength) {
        inputOptions.minLength = {
            value: minLength,
            message: translate(`Oops! Minimum required field length is {{ minLength }} characters.`, 'ui.common', {
                minLength,
            }),
        };
    }
    if (pattern) {
        inputOptions.pattern = {
            value: pattern,
            message: translate('Oops! You are using invalid characters.', 'ui.common'),
        };
    }

    return (
        <Wrapper {...filterStyleProps(wrapperProps)}>
            {label && <UiFormLabel>{label}</UiFormLabel>}
            <input defaultValue={defaultValue} {...register(name, inputOptions)} {...propsInput} autoComplete="off" />
            {error && <UiAlert failure={true}>{error.message}</UiAlert>}
        </Wrapper>
    );
}
