import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import UiBox from '../../../ui/box/UiBox';
import UiGroup from '../../../ui/group/UiGroup';
import AuthProfileValidatorSourceOfWealthForm from './form/AuthProfileValidatorSourceOfWealthForm';
import UiCloseButton from '../../../ui/close-button/UiCloseButton';
import Snippet from '../../../snippet/Snippet';
import { getSourceOfWealthForm, saveSourceOfWealthForm } from '../../../../microservices/users';
import { translate } from '../../../../services/translate';
import { logger } from '../../../../services/logger';

export default function AuthProfileValidatorSourceOfWealth() {
    const [isLoading, setIsLoading] = useState(true);
    const [sowForm, setSowForm] = useState<{ fieldId: number; label: string }[]>([]);
    const [isVisible, setIsVisible] = useState(false);
    const [isInfoExpanded, setIsInfoExpanded] = useState(false);

    useEffect(() => {
        initSourceOfWealthForm();
    }, []);

    async function initSourceOfWealthForm() {
        try {
            const sourceOfWealthForm = await getSourceOfWealthForm();
            setSowForm(sourceOfWealthForm.fields);
            setIsVisible(Boolean(sourceOfWealthForm.fields && sourceOfWealthForm.fields.length));
        } catch (error) {
            logger.error('AuthProfileValidatorSourceOfWealth', 'initSourceOfWealthForm', error);
        }
        setIsLoading(false);
    }

    async function handleOnSubmit(values: Record<number, string>) {
        setIsLoading(true);
        await saveSourceOfWealthForm(sowForm.map((item) => ({ ...item, value: values[item.fieldId] })));
        setIsLoading(false);
        setIsVisible(false);
    }

    if (!isVisible) {
        return null;
    }

    return (
        <Wrapper>
            <UiBox>
                <UiGroup vertical>
                    {isInfoExpanded ? (
                        <div>
                            <UiCloseButton
                                onClick={() => setIsInfoExpanded(!isInfoExpanded)}
                                className="close-button"
                            />
                            <Snippet snippetKey={'account.kyc.source-of-wealth'} />
                        </div>
                    ) : (
                        <>
                            <div className="truncated-info-text">
                                <Snippet snippetKey={'account.kyc.source-of-wealth'} />
                                <p>
                                    <button onClick={() => setIsInfoExpanded(!isInfoExpanded)}>
                                        {translate('Read more', 'ui.account')}
                                    </button>
                                </p>
                            </div>
                            <AuthProfileValidatorSourceOfWealthForm
                                formFields={sowForm}
                                handleOnSubmit={handleOnSubmit}
                                isLoading={isLoading}
                            />
                        </>
                    )}
                </UiGroup>
            </UiBox>
        </Wrapper>
    );
}
