import { styled } from 'styled-components';
import { mediaQuery } from '../../../../styles/utils';
import AccountVerificationBox from '../box/AccountVerificationBox';

export default styled(AccountVerificationBox)`
    .idenfy {
        width: 500px;
        max-width: 100%;
        min-height: 800px;
        border-radius: var(--ui-radius);

        &[data-hidden] {
            display: none;
        }

        @media ${mediaQuery.isLargerThanPhone} {
            min-height: 700px;
        }
    }

    .alert {
        margin-top: var(--spacing-16);
    }
`;
