import styled from 'styled-components';
import UiModal from '../../../ui/modal/UiModal';

export default styled(UiModal)`
    .message {
        text-align: center;
    }

    .proof-of-id-verification-box {
        margin: 0 auto;
        padding: 0;
        background-color: transparent;
        border-color: transparent;
    }
`;
