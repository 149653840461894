import { Country, Licence } from '@staycool/location';
import memoize from 'lodash/memoize';
import { parse, stringify } from 'query-string';
import { fetchPrematchTree } from '../microservices/sbgate';
import { updateProfile } from '../microservices/users';
import routes from '../routes.json';
import { stores } from '../stores';
import { casino } from '../stores/casino';
import { environment } from '../stores/environment/environment';
import { getStoreValue } from '../stores/store/utils';
import { LoginMethod } from './auth/types';
import { getClient, isOfficeIp } from './environment';
import { localeOfLanguage } from './language/constants';
import { FANTASY_LANGUAGE, LANGUAGE, LOCALE, Language, REAL_LANGUAGE } from './language/types';
import { getLicence } from './licence';
import { logger } from './logger';
import { NativeMessageEventType, sendNativeEvent } from './mobile-app';
import { getLastActiveProductRoute, getRoute, isActiveRoute } from './router';
import { betbuilderCultureByLanguage } from './sports/constants';
import { getFlatCategories } from './sports/sport-category';
import { storageGet } from './storage';
import { setLanguage } from './translation/language-switch';
import { ObjectValues } from './ts-utils';
import { isTestUser } from './user';
import { getUserCountry } from './users/country';
import { ClientName } from './utils/types';

export interface LanguageSelection {
    label: string;
    value: Language;
    locale: LOCALE;
    experimental?: ObjectValues<typeof ClientName>[];
    clients?: ObjectValues<typeof ClientName>[];
    licences: ObjectValues<typeof Licence>[];
}

export const FANTASY_TO_REAL_LANGUAGE: { [key in FANTASY_LANGUAGE]: REAL_LANGUAGE } = {
    [LANGUAGE.CHILEAN]: LANGUAGE.SPANISH,
    [LANGUAGE.PERUVIAN]: LANGUAGE.SPANISH,
    [LANGUAGE.CANADIAN]: LANGUAGE.ENGLISH,
    [LANGUAGE.ECUADORIAN]: LANGUAGE.SPANISH,
    [LANGUAGE.MEXICAN]: LANGUAGE.SPANISH,
    [LANGUAGE.US]: LANGUAGE.ENGLISH,
    [LANGUAGE.EUROPEAN]: LANGUAGE.ENGLISH,
};

const STORAGE_LANGUAGE_KEY = 'language';

export const languageByCountry = {
    [Country.CANADA]: LANGUAGE.CANADIAN,
    [Country.CHILE]: LANGUAGE.CHILEAN,
    [Country.ESTONIA]: LANGUAGE.ESTONIAN,
    [Country.FINLAND]: LANGUAGE.FINNISH,
    [Country.ICELAND]: LANGUAGE.ICELANDIC,
    [Country.PERU]: LANGUAGE.PERUVIAN,
    [Country.NORWAY]: LANGUAGE.EUROPEAN,
    [Country.SWEDEN]: LANGUAGE.SWEDISH,
    [Country.ECUADOR]: LANGUAGE.ECUADORIAN,
    [Country.MEXICO]: LANGUAGE.MEXICAN,
    [Country.USA]: LANGUAGE.US,
};

const languages: LanguageSelection[] = [
    {
        label: 'English',
        value: LANGUAGE.ENGLISH,
        locale: LOCALE.EN,
        licences: [
            Licence.ESTONIA,
            Licence.MALTA,
            Licence.MEXICO,
            Licence.SIMULATED_GAMING,
            Licence.SWEDEN,
            Licence.PERU,
        ],
    },
    {
        label: 'Canadian English',
        value: LANGUAGE.CANADIAN,
        locale: LOCALE.CA,
        licences: [Licence.ESTONIA, Licence.MALTA, Licence.MEXICO, Licence.SWEDEN],
    },
    {
        label: 'Svenska',
        value: LANGUAGE.SWEDISH,
        locale: LOCALE.SE,
        licences: [Licence.ESTONIA, Licence.MALTA, Licence.MEXICO, Licence.SWEDEN],
    },
    {
        label: 'Suomi',
        value: LANGUAGE.FINNISH,
        locale: LOCALE.FI,
        licences: [Licence.ESTONIA, Licence.MALTA, Licence.MEXICO, Licence.SWEDEN],
    },
    {
        label: 'Eesti',
        value: LANGUAGE.ESTONIAN,
        locale: LOCALE.EE,
        licences: [Licence.ESTONIA, Licence.MALTA, Licence.MEXICO, Licence.SWEDEN],
    },
    {
        label: 'Русский',
        value: LANGUAGE.RUSSIAN,
        locale: LOCALE.RU,
        licences: [Licence.ESTONIA, Licence.MALTA, Licence.MEXICO, Licence.SWEDEN],
    },
    {
        label: 'Íslenska',
        value: LANGUAGE.ICELANDIC,
        locale: LOCALE.IS,
        licences: [Licence.ESTONIA, Licence.MALTA, Licence.MEXICO, Licence.SWEDEN],
    },
    {
        label: 'Chilean Spanish',
        value: LANGUAGE.CHILEAN,
        locale: LOCALE.CL,
        licences: [Licence.ESTONIA, Licence.MALTA, Licence.MEXICO, Licence.SWEDEN],
    },
    {
        label: 'Peruvian Spanish',
        value: LANGUAGE.PERUVIAN,
        locale: LOCALE.PE,
        licences: [Licence.ESTONIA, Licence.MALTA, Licence.MEXICO, Licence.SWEDEN, Licence.PERU],
    },
    {
        label: 'Ecuadorian Spanish',
        value: LANGUAGE.ECUADORIAN,
        locale: LOCALE.EC,
        licences: [Licence.ESTONIA, Licence.MALTA, Licence.MEXICO, Licence.SWEDEN],
    },
    {
        label: 'Mexican Spanish',
        value: LANGUAGE.MEXICAN,
        locale: LOCALE.MX,
        licences: [Licence.ESTONIA, Licence.MALTA, Licence.MEXICO, Licence.SWEDEN],
    },
    {
        label: 'English US',
        value: LANGUAGE.US,
        locale: LOCALE.US,
        clients: [
            ClientName.DEMO,
            ClientName.IVC,
            ClientName.WYNNBET,
            ClientName.WYNNBET_NEVADA,
            ClientName.STATION,
            ClientName.FONTAINEBLEAU,
        ],
        licences: [
            Licence.ESTONIA,
            Licence.MALTA,
            Licence.MEXICO,
            Licence.SWEDEN,
            Licence.USA_NEVADA,
            Licence.USA_MISSISSIPPI,
        ],
    },
    {
        label: 'Español',
        value: LANGUAGE.SPANISH,
        locale: LOCALE.ES,
        experimental: [ClientName.COOLBET],
        clients: [ClientName.COOLBET, ClientName.STATION, ClientName.WYNNBET_NEVADA],
        licences: [
            Licence.ESTONIA,
            Licence.MALTA,
            Licence.MEXICO,
            Licence.SWEDEN,
            Licence.USA_NEVADA,
            Licence.USA_MISSISSIPPI,
        ],
    },
    {
        label: 'European En',
        value: LANGUAGE.EUROPEAN,
        locale: LOCALE.EU,
        licences: [Licence.ESTONIA, Licence.MALTA, Licence.MEXICO, Licence.SWEDEN],
    },
    {
        label: 'Enorca',
        value: LANGUAGE.ENGLISH,
        locale: LOCALE.EN,
        clients: [ClientName.ORCA],
        licences: [Licence.ESTONIA, Licence.MALTA, Licence.MEXICO, Licence.SWEDEN, Licence.USA_NEVADA],
    },
];

export const languagesByKey = languages.reduce((languagesByKey, language) => {
    // eslint-disable-next-line no-param-reassign
    languagesByKey[language.value] = language;
    return languagesByKey;
}, {});

function overrideLanguageForNorway() {
    const userLanguage = getStoreValue(stores.language);
    if (userLanguage === LANGUAGE.NORWEGIAN) {
        stores.language.set(LANGUAGE.EUROPEAN);
    }
    if (getUserCountry() === Country.NORWAY) {
        switch (userLanguage) {
            case LANGUAGE.ENGLISH:
                stores.language.set(LANGUAGE.EUROPEAN);
                break;
            case LANGUAGE.NORWEGIAN:
                stores.language.set(LANGUAGE.EUROPEAN);
                break;
        }
    }
}

async function translateUrlToLanguage({ pathname, targetLanguage }: { pathname: string; targetLanguage: Language }) {
    const [currentLanguage, ...urlSegments] = decodeURI(pathname).split('/').slice(1);
    let routeKeyPrefix = '';
    let routeKey;

    for (const urlSegment of urlSegments) {
        routeKey = findUrlSegmentKeyAndTranslatedValue({
            urlSegment,
            currentLanguage,
            routeKeyPrefix,
        });
        if (!routeKey) {
            break;
        }
        routeKeyPrefix = routeKey;
    }

    routeKey = routeKey || routeKeyPrefix;

    if (routeKey === 'sport') {
        let flatCategories = getStoreValue(stores.sports.flatCategories);
        if (!flatCategories) {
            return getRoute('sport', targetLanguage);
        }
        let category = flatCategories.filter((category) => category.fullSlug === urlSegments.slice(1).join('/'))[0];
        if (category) {
            const categoryId = category.id;
            try {
                flatCategories = getFlatCategories(await fetchPrematchTree(targetLanguage));
                category = flatCategories.filter((category) => category.id === categoryId)[0];
                return `${getRoute('sport', targetLanguage)}/${category.fullSlug}`;
            } catch (e) {
                logger.error('LanguageService', 'translateUrlToLanguage', e);
            }
        }
    }

    if (routeKey.includes('casino-v-2')) {
        const key = routeKey.replace('casino-v-2', 'casino');
        const categorySlug = urlSegments.slice(routeKey.split('.').length).join('/');
        const categoriesBySlug = getStoreValue(casino.categoriesBySlug);

        const currentCategory = categoriesBySlug[categorySlug];
        return `${getRoute(key, targetLanguage)}/${currentCategory?.id || categorySlug}`;
    }

    const currentArticleSlug = getStoreValue(stores.cms.currentArticleSlugByLanguage); // TODO: doesn't work during initial page load
    if (currentArticleSlug) {
        const translatedSlug = currentArticleSlug[targetLanguage] || currentArticleSlug[currentLanguage];
        if (routeKey) {
            return `${getRoute(routeKey, targetLanguage)}/${translatedSlug}`;
        }
        return `/${targetLanguage}/${translatedSlug}`;
    }

    const activeArticle: any = getStoreValue(stores.blog.activeArticle); // TODO: doesn't work during initial page load
    const activeArticleSlug = activeArticle && activeArticle.slug;
    if (activeArticleSlug) {
        const translatedSlug = activeArticleSlug[targetLanguage] || activeArticleSlug[currentLanguage];
        if (routeKey) {
            return `${getRoute(routeKey, targetLanguage)}/${translatedSlug}`;
        }
        return `/${targetLanguage}/${getRoute('blog', targetLanguage)}/${translatedSlug}`;
    }

    const untranslatedUrlSegments = urlSegments.slice(routeKey.split('.').length).join('/');

    if (routeKey) {
        const basePath = getRoute(routeKey, targetLanguage);
        return untranslatedUrlSegments ? `${basePath}/${untranslatedUrlSegments}` : basePath;
    }

    return getLastActiveProductRoute(targetLanguage);
}

export async function updateSiteLanguage(language: Language) {
    const { pathname } = window.location;
    const urlParams = window.location.search || '';
    const translatedUrl = await translateUrlToLanguage({ pathname, targetLanguage: language });

    const applicationType = getStoreValue(stores.applicationType);
    if (applicationType !== 'poker-download') {
        window.location.href = translatedUrl + urlParams;
    }
}

function findUrlSegmentKeyAndTranslatedValue({ urlSegment, currentLanguage, routeKeyPrefix }) {
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    const depth = routeKeyPrefix ? routeKeyPrefix.split('.').length + 1 : 1;
    return Object.keys(routes)
        .filter((key) => key.split('.').length === depth)
        .filter((key) => key.startsWith(routeKeyPrefix || ''))
        .find((routeKey) => routes[routeKey][currentLanguage] === urlSegment);
}

export async function initLanguage(history) {
    overrideLanguageForNorway();

    if (window.coolb2b?.language) {
        await setLanguage(window.coolb2b?.language);
        return;
    }
    const client = getClient();
    let language = getStoreValue(stores.language);

    const isPokerSubPage =
        isActiveRoute(getRoute('poker', LANGUAGE.ENGLISH), false) &&
        !isActiveRoute(getRoute('poker', LANGUAGE.ENGLISH));

    const foundLanguage = languages.find((languagesLanguage) => languagesLanguage.value === language);
    if (
        !foundLanguage ||
        (foundLanguage?.experimental?.includes(client) && !(isTestUser() || isOfficeIp() || isPokerSubPage))
    ) {
        window.location.href = `${window.location.origin}/en`;
    }

    await setLanguage(language);

    const languageFromQueryString = getLanguageFromQueryString();
    const user = getStoreValue(stores.user);

    if (languageFromQueryString) {
        if (isPokerSubPage && user && FANTASY_TO_REAL_LANGUAGE[user.language] === language) {
            // prefer user language, poker client does not support some languages
            // eslint-disable-next-line prefer-destructuring
            language = user.language;
            stores.language.set(language);
            await setLanguage(language);
        }

        const params = parse(window.location.search);
        delete params.lang;

        const translatedPath = await translateUrlToLanguage({
            pathname: window.location.pathname,
            targetLanguage: language,
        });

        history.replace(`${translatedPath}?${stringify(params)}`);
        return;
    } else if (window.location.pathname.startsWith('/--/')) {
        // Try to translate to previously used language with English fallback.
        // Useful when FO language is unknown.
        const params = parse(window.location.search);
        const pathnameInEnglish = window.location.pathname.replace('/--/', `/${LANGUAGE.ENGLISH}/`);
        const translatedPath = await translateUrlToLanguage({ pathname: pathnameInEnglish, targetLanguage: language });
        history.replace(`${translatedPath}?${stringify(params)}`);
        return;
    }

    if (user && user.language !== language && !isPokerSubPage) {
        updateUserLanguage(language);
    }

    sendNativeEvent({ type: NativeMessageEventType.PAGE_LOADED, language });

    const [languageFromUrl] = decodeURI(window.location.pathname).split('/').slice(1);
    if (language === FANTASY_LANGUAGE.EUROPEAN && languageFromUrl !== FANTASY_LANGUAGE.EUROPEAN) {
        const params = parse(window.location.search);
        const translatedPath = await translateUrlToLanguage({
            pathname: window.location.pathname,
            targetLanguage: language,
        });
        history.replace(`${translatedPath}?${stringify(params)}`);
    }
}

async function updateUserLanguage(language) {
    const isBackOfficeLogin = Boolean(storageGet(LoginMethod.BO_LOGIN));
    if (isBackOfficeLogin) {
        return;
    }

    try {
        await updateProfile({ language });
    } catch (error) {
        logger.error('LanguageService', 'updateUserLanguage', error);
    }
}

export function findLanguage() {
    const clientNameFromEnv = getStoreValue(environment).CLIENT_NAME;
    const languageSelection = getInitialLanguage();
    const licence = getLicence();
    const isLanguageAllowed =
        isOfficeIp() ||
        (languageSelection.licences.includes(licence) &&
            (!languageSelection.clients || languageSelection.clients.includes(clientNameFromEnv)));

    stores.language.set(isLanguageAllowed ? languageSelection.value : LANGUAGE.ENGLISH);
}

function getInitialLanguage(): LanguageSelection {
    return (
        getLanguageFromConfig() ||
        getLanguageFromUrl() ||
        getLastUsedLanguage() ||
        getIpCountryLanguage() ||
        getNavigatorLanguage() ||
        getDefaultLanguage()
    );
}

function getLastUsedLanguage() {
    const activeLanguageValue = storageGet(STORAGE_LANGUAGE_KEY);
    return getAvailableLanguagesMemoized().find((language) => language.value === activeLanguageValue);
}

function getIpCountryLanguage() {
    const ipCountry = getStoreValue(stores.ipCountry);
    return getAvailableLanguagesMemoized().find((language) => language.value === languageByCountry[ipCountry]);
}

function getNavigatorLanguage() {
    const locale = tryGetLocaleFromString(navigator.language);
    return getAvailableLanguagesMemoized().find((language) => language.locale === locale);
}

function tryGetLocaleFromString(languageString: string) {
    for (const language of Object.keys(localeOfLanguage)) {
        if (languageString.includes(language)) {
            return localeOfLanguage[language] as LOCALE;
        }
    }
    return null;
}

function getDefaultLanguage() {
    return getAvailableLanguagesMemoized()[0];
}

function getLanguageFromConfig() {
    if ((window as any).coolb2b?.language) {
        const lang = (window as any).coolb2b.language;
        return languages.find((language) => language.value === lang);
    }
    return false;
}

function getLanguageFromUrl() {
    return getLanguageFromQueryString() || getLanguageFromUrlPath();
}

function getLanguageFromQueryString() {
    const queryParams = parse(window.location.search) as { lang: string };
    return languages.find(({ value }) => value === (queryParams.lang && queryParams.lang.toLowerCase()));
}

export function getLanguageFromUrlPath() {
    const language = window.location.pathname.split('/')[1];
    return languages.find(({ value }) => value === language);
}

export function getActiveLocale() {
    return localeOfLanguage[getStoreValue(stores.language)];
}

export function getBetbuilderCulture(language: Language) {
    return betbuilderCultureByLanguage[language] || betbuilderCultureByLanguage['en'];
}

export const getAvailableLanguagesMemoized = memoize(getAvailableLanguages);

function getAvailableLanguages() {
    const userCountry = getUserCountry();
    const client = getClient();
    const licence = getLicence();

    return languages.filter((language) => {
        if (!language.licences.includes(licence)) {
            return false;
        } else if (language.experimental?.includes(client) && !(isTestUser() || isOfficeIp())) {
            return false;
        } else if (language.clients && !language.clients.includes(client)) {
            return false;
        } else if (userCountry !== Country.NORWAY && language.value === LANGUAGE.EUROPEAN) {
            return false;
        } else if (userCountry === Country.NORWAY && language.value === LANGUAGE.ENGLISH) {
            return false;
        }
        return true;
    });
}
