import React from 'react';
import { useStore } from '../../../../hooks/useStore';
import { logout } from '../../../../services/auth';
import { getRoute, isActiveRoute } from '../../../../services/router';
import { translate } from '../../../../services/translate';
import { stores } from '../../../../stores';
import { media } from '../../../../stores/media/media';
import AccountVerificationProofOfId from '../../../account/verification/proof-of-id/AccountVerificationProofOfId';
import Wrapper from './styles';

export default function AuthProfileValidatorProofOfIdModal() {
    const [{ shouldAskProofOfId }] = useStore(stores.kyc.settings);
    const [isProofOfIdModalOpen, setIsProofOfIdModalOpen] = useStore(stores.modals.isProofOfIdModalOpen);
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [{ isPhone }] = useStore(media);
    const isRequired = !shouldAskProofOfId;
    const isOnAccountVerificationPage = isActiveRoute(getRoute('account.verification'));

    function handleModalDismissal() {
        if (isRequired) {
            logout();
        }
        setIsProofOfIdModalOpen(false);
    }

    function handleOnCompleted() {
        if (!isRequired) {
            setIsProofOfIdModalOpen(false);
        }
    }

    return (
        <Wrapper
            title={translate('proof-of-id', 'ui.account')}
            className="new-modal"
            open={isProofOfIdModalOpen && isAuthenticated && (isRequired || !isOnAccountVerificationPage)}
            onClose={handleModalDismissal}
            shouldDisplayCloseButton
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth={isPhone ? '80vw' : 500}
            mode={isPhone ? 'drawer' : 'default'}
        >
            {shouldAskProofOfId && (
                <p className="message">
                    {translate('Your id has expired or is missing, please verify your id', 'ui.account')}
                </p>
            )}
            <AccountVerificationProofOfId
                isCompactView
                onCompleted={handleOnCompleted}
                title={undefined}
                badgeText={undefined}
                className="proof-of-id-verification-box"
            />
        </Wrapper>
    );
}
