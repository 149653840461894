import React, { useEffect, useState } from 'react';
import { useStore } from '../../../../hooks/useStore';
import { getIdenfyAuthToken, loadKycUserSettings } from '../../../../microservices/kyc';
import { getBadgeProps } from '../../../../services/kyc';
import { FANTASY_TO_REAL_LANGUAGE } from '../../../../services/language';
import { logger } from '../../../../services/logger';
import { translate } from '../../../../services/translate';
import { stores } from '../../../../stores';
import UiAlert from '../../../ui/alert/UiAlert';
import UiButton from '../../../ui/button/UiButton';
import Wrapper from './styles';
import AccountVerificationIdenfyModal from './modal/AccountVerificationIdenfyModal';
import { ProofOfAddressStatus } from '../../../../services/kyc/types';
import { filterStyleProps } from '../../../../styles/utils';

interface Props {
    onCompleted?: () => void;
}

export default function AccountVerificationIdenfy({ onCompleted, ...rest }: Props) {
    const [language] = useStore(stores.language);
    const [kycSettings] = useStore(stores.kyc.settings);
    const [isLoading, setIsLoading] = useState(false);
    const [authToken, setAuthToken] = useState<string>();
    const [isError, setIsError] = useState(false);
    const [isIdenfyInfoModalVisible, setIsIdenfyInfoModalVisible] = useState(false);
    const realLanguage = FANTASY_TO_REAL_LANGUAGE[language] || language;
    const title =
        kycSettings.proofOfAddress === ProofOfAddressStatus.REQUESTED
            ? translate('proof-of-id-and-address', 'ui.account')
            : translate('proof-of-id', 'ui.account');

    useEffect(() => {
        if (!authToken) {
            return;
        }

        function onMessage(event: MessageEvent<{ status: string; manualStatus: string }>) {
            const { origin, data } = event;
            if (origin !== 'https://ui.idenfy.com') {
                return;
            }
            const { status, manualStatus } = data;
            if (status !== 'waiting' && manualStatus !== 'waiting') {
                setTimeout(async () => {
                    await loadKycUserSettings();
                    onCompleted?.();
                }, 5000);
            }
        }

        window.addEventListener('message', onMessage);
        return () => window.removeEventListener('message', onMessage);
    }, [authToken]);

    async function loadAuthToken() {
        setIsLoading(true);
        setIsError(false);

        try {
            const token = await getIdenfyAuthToken();
            setAuthToken(token);
        } catch (error) {
            logger.error('AccountVerificationIdenfy', 'loadAuthToken', error);
            setIsError(true);
            setIsLoading(false);
        }
    }

    function openIdenfyInfoModal() {
        setIsIdenfyInfoModalVisible(true);
    }

    function startVerification() {
        setIsIdenfyInfoModalVisible(false);
        loadAuthToken();
    }

    function onClose() {
        setIsIdenfyInfoModalVisible(false);
    }

    return (
        <Wrapper
            title={title}
            data-test="proof-of-id-idenfy"
            {...filterStyleProps(getBadgeProps(kycSettings.proofOfId))}
            {...rest}
        >
            {(!authToken || isLoading) && (
                <UiButton onClick={openIdenfyInfoModal} isLoading={isLoading}>
                    {translate('Verify identity', 'ui.kyc')}
                </UiButton>
            )}

            <AccountVerificationIdenfyModal
                isOpen={isIdenfyInfoModalVisible}
                onSubmit={startVerification}
                onClose={onClose}
            />

            {isError && (
                <UiAlert failure className="alert">
                    {translate('Something went wrong', 'ui.common')}
                </UiAlert>
            )}
            {authToken && (
                <iframe
                    title="Idenfy Identity Verification"
                    className="idenfy"
                    allowFullScreen
                    allow="camera"
                    data-hidden={isLoading || null}
                    onLoad={() => setIsLoading(false)}
                    src={`https://ui.idenfy.com/?authToken=${authToken}&lang=${realLanguage}`}
                />
            )}
        </Wrapper>
    );
}
