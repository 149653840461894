import { getServiceUrl, httpGet } from '../services/api';
import { environment } from '../stores/environment/environment';
import { getStoreValue } from '../stores/store/utils';

const getUrl = (url) => getServiceUrl('ip', url);

export function fetchIpDetails(ip: string): Promise<IpDetails> {
    if (process.env.NODE_ENV === 'development') {
        return httpGet<IpDetails>(getUrl('details'));
    }

    const { PRO_IP_API_KEY } = getStoreValue(environment);

    return httpGet<IpDetails>(`https://pro.ip-api.com/json/${ip}`, { key: PRO_IP_API_KEY });
}

type IpDetails = {
    as: string;
    city: string;
    country: string;
    countryCode: string;
    isp: string;
    lat: number;
    lon: number;
    org: string;
    query: string;
    region: string;
    regionName: string;
    status: string;
    timezone: string;
    zip: string;
};
