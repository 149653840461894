import { FoComboCardWithOdds } from '@staycool/sports-types/fo-combo-card';
import React from 'react';
import DangerousHtml from '../../../../dangerous-html/DangerousHtml';

interface Props {
    matches: FoComboCardWithOdds['matches'];
}

export default function ComboCardsItemDetailsSingleSport({ matches }: Props) {
    return (
        <>
            {matches.map(({ markets, name }) => (
                <div key={name} className="dot-indicator">
                    <div>{name}</div>
                    {markets.map(({ id, outcome_name }) => (
                        <DangerousHtml key={id} content={outcome_name} />
                    ))}
                </div>
            ))}
        </>
    );
}
