import { getServiceUrl, httpPost } from '../services/api';
import { languageByCountry } from '../services/language';
import { isFeatureAvailable } from '../services/feature';
import { FEATURE } from '../services/types';

const getUrl = (url: string) => getServiceUrl(isFeatureAvailable(FEATURE.SMS2) ? 'sms2' : 'sms', url);

export async function sendRegistrationVerificationPinToSms({ email, phoneNumber, phonePrefix, country, userId }) {
    const url = getUrl('sms-verification/');

    const verificationLanguage = languageByCountry[country];
    const formattedPhoneNumber = `${phonePrefix.substring(1)}${String(phoneNumber).replace(/\b0+/g, '')}`;
    const response = await httpPost<{ pin_id: string }>(url, {
        email,
        phone: formattedPhoneNumber,
        language: verificationLanguage,
        userId,
    });
    return { pinId: response.pin_id };
}

export function sendRegistrationVerificationPinToEmail({ pin_id: pinId }) {
    const url = getUrl('registration-code-to-email/');
    return httpPost<{ status: string }>(url, { pin_id: pinId });
}

export function verifySmsPin({ pin, pinId, isDataDownload = false }) {
    const url = getUrl('pin-verification/');
    return httpPost<{ status: string }>(url, { pin, pin_id: pinId, isDataDownload });
}

export function verifyEmailPin({ pin, pinId }) {
    const url = getUrl('verify-with-email/');
    return httpPost<{ status: string }>(url, { pin, pin_id: pinId });
}

export function resendTwoFactorSmsPin({ smsTwoFactorPinId, isTwoFactorAuthentication = false }) {
    const url = getUrl('sms-verification-resend/');

    return httpPost<{ pin_id: string }>(url, {
        pin_id: smsTwoFactorPinId,
        is_2fa: isTwoFactorAuthentication,
    });
}

export async function sendRegistrationCodeToEmail({ email, phoneNumber, phonePrefix, country, userId, pin_id: pinId }) {
    const url = getUrl('email-verification/');
    const formattedPhoneNumber = `${phonePrefix.substring(1)}${String(phoneNumber).replace(/\b0+/g, '')}`;
    const response = await httpPost<{ pin_id: string }>(url, {
        email,
        phone: formattedPhoneNumber,
        country,
        userId,
        pin_id: pinId,
    });
    return { emailPinId: response.pin_id };
}
