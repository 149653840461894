import React from 'react';
import { useProofOfPaymentVerificationContext } from '../../../../../../contexts/proof-of-payment-verification/ProofOfPaymentVerificationContext';
import { translate } from '../../../../../../services/translate';
import AccountVerificationDocumentSelectionOneSided from '../../../document/selection/one-sided/AccountVerificationDocumentSelectionOneSided';
import AccountVerificationDocumentSelectionTwoSided from '../../../document/selection/two-sided/AccountVerificationDocumentSelectionTwoSided';
import AccountVerificationProofOfPaymentFooter from '../../footer/AccountVerificationProofOfPaymentFooter';
import AccountVerificationProofOfPaymentHeader from '../../header/AccountVerificationProofOfPaymentHeader';
import Wrapper from './styles';
import DangerousHtml from '../../../../../dangerous-html/DangerousHtml';
import { ProofOfPaymentVerificationOption } from '../../../../../../services/kyc/types';

export default function AccountVerificationProofOfPaymentSteps2() {
    const {
        documentUpload,
        isTwoSidedDocumentFlow,
        selectedDocument,
        selectedVerificationOption,
        selectingSide,
        setSelectedDocument,
    } = useProofOfPaymentVerificationContext();
    if (!selectedDocument) {
        return null;
    }
    const infoTextByVerificationOption = {
        [ProofOfPaymentVerificationOption.CARD_LOST]:
            'Upload a screenshot from the internet bank that shows your name and card number or recent deposit made to coolbet',
        [ProofOfPaymentVerificationOption.CARD_NO_NAME_OR_NUMBER]:
            'If your card does not have a card number or a name written on it, or it is a virtual card, please upload a screenshot from the internet bank that shows your name and card number or recent deposit made to coolbet',
        [ProofOfPaymentVerificationOption.BANK_SCREENSHOT]:
            'Upload a screenshot from the internet bank that shows your name, bank account number and the URL address',
        [ProofOfPaymentVerificationOption.BANK_STATEMENT]:
            'Upload your bank statement that shows your name and bank account number',
        [ProofOfPaymentVerificationOption.CRYPTO_WALLET_SCREENSHOT]:
            'Upload a screenshot of your crypto wallet address',
        [ProofOfPaymentVerificationOption.E_WALLET_SCREENSHOT]:
            'Upload a screenshot from your e-wallet that shows your name and e-wallet ID',
    };
    const infoTextForVerificationOption =
        selectedVerificationOption && infoTextByVerificationOption[selectedVerificationOption];
    const infoText = infoTextForVerificationOption
        ? translate(infoTextForVerificationOption, 'ui.account.kyc.pop')
        : selectedDocument.documentType === 'DEPOSIT'
        ? translate('Please upload proof of deposit', 'ui.account.kyc.pop')
        : undefined;
    return (
        <>
            <AccountVerificationProofOfPaymentHeader>
                {infoText && <DangerousHtml content={infoText} />}
            </AccountVerificationProofOfPaymentHeader>
            <Wrapper>
                {isTwoSidedDocumentFlow ? (
                    <>
                        <AccountVerificationDocumentSelectionTwoSided
                            documentUpload={documentUpload}
                            onlyForSide={
                                !!selectedDocument.frontSideDeclineReason !== !!selectedDocument.backSideDeclineReason
                                    ? selectingSide
                                    : undefined
                            }
                        />
                        {selectedDocument.selectedOption && (
                            <span
                                className="link-button"
                                onClick={() =>
                                    setSelectedDocument({
                                        ...selectedDocument,
                                        previouslySelectedOption: selectedDocument.selectedOption,
                                        selectedOption: undefined,
                                        backSideDeclineReason: undefined,
                                        frontSideDeclineReason: undefined,
                                    })
                                }
                            >
                                {translate('Choose a different way to verify this card', 'ui.account.kyc.pop')}
                            </span>
                        )}
                    </>
                ) : (
                    <AccountVerificationDocumentSelectionOneSided documentUpload={documentUpload} />
                )}
            </Wrapper>

            <AccountVerificationProofOfPaymentFooter />
        </>
    );
}
