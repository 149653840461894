import { getServiceUrl, httpGet, httpPost } from '../services/api';
import { logger } from '../services/logger';
import { TicketDetails } from '../services/sports/types';
import { HIGHLIGHT_SPORT, HIGHLIGHT_SPORT_NAME_TO_ID } from '../services/virtual-sports/constants';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import { Market, Ticket, VirtualSportEvent } from '../stores/virtual-sports/types';

const getUrl = (url) => getServiceUrl('highlight-games', url);

export async function getMatchMarkets(matchId: number) {
    const language = getStoreValue(stores.language);
    const url = getUrl(`market/${language}/${matchId}`);
    return httpGet<Market[]>(url);
}

export async function getCurrentRoundHighlight(sportName: HIGHLIGHT_SPORT): Promise<RoundHighlight> {
    const language = getStoreValue(stores.language);
    const sportId = HIGHLIGHT_SPORT_NAME_TO_ID[sportName];
    const url = getUrl(`events/current/${sportId}/${language}`);
    return httpGet<RoundHighlight>(url);
}

export async function getAdjacentRoundHighlight(scheduleId: number, gameWeek: number, sportName: HIGHLIGHT_SPORT) {
    const language = getStoreValue(stores.language);
    const sportId = HIGHLIGHT_SPORT_NAME_TO_ID[sportName];
    const url = getUrl(`events/schedule/${sportId}/${scheduleId}/${gameWeek}/${language}`);
    return httpGet<VirtualSportEvent[]>(url);
}

export function postSingleBet(singleBet: Bet[]): Promise<Ticket> {
    const url = getUrl(`bet/single`);

    return httpPost<any>(url, singleBet);
}

export function postComboBet(comboBet: Bet): Promise<Ticket> {
    const url = getUrl(`bet/combo`);

    return httpPost<any>(url, [comboBet]);
}

export function postSystemBet(systemBet: SystemBet): Promise<Ticket> {
    const url = getUrl(`bet/system`);

    return httpPost<any>(url, [systemBet]);
}

export async function getMyHighlightGamesTickets(
    limit: number,
    offset: number,
): Promise<{
    tickets: Ticket[];
    count: number;
}> {
    try {
        const url = getUrl('ticket/me');
        return await httpGet<{ tickets: Ticket[]; count: number }>(url, { limit, offset });
    } catch (error) {
        logger.error('HighlightGamesMicroservice', 'getMyHighlightGamesTickets', error);
        return { tickets: [], count: 0 };
    }
}

export async function getHighlightGamesTicketDetails(ticketId: number): Promise<TicketDetails | undefined> {
    try {
        const url = getUrl(`ticket/me/${ticketId}`);
        return await httpGet<TicketDetails>(url);
    } catch (error) {
        logger.error('HighlightGamesMicroservice', 'getHighlightGamesTicketDetails', error);
    }
}

export async function loadHighlightMaintenance() {
    try {
        const url = getUrl('maintenance/active/');
        const response = await httpGet<{ start_time: string; end_time: string; enabled: boolean }>(url);
        stores.maintenance.isVirtualSportsHighlightMaintenance.set(response.enabled);
    } catch (error) {
        logger.error('HighlightGamesMicroservice', 'loadHighlightMaintenance', error);
    }
}

type Bet = {
    stake: any;
    odds_by_outcome_id: {
        [x: number]: any;
    };
};

type RoundHighlight = {
    gameWeekCount: number;
    events: VirtualSportEvent[];
};

type SystemBet = {
    system_stakes: any;
    odds_by_outcome_id: any;
};
