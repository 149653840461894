import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { LoginMethod } from '../../../services/auth/types';
import { LocalStorage } from '../../../services/local-storage/types';
import { useSmartIdAuth } from '../../../services/smart-id';
import { storageSet } from '../../../services/storage';
import { translate } from '../../../services/translate';
import { getFieldValidators } from '../../../services/validators';
import Ui2Form from '../../ui-2/form/Ui2Form';
import Ui2FormTextInput from '../../ui-2/form/text-input/Ui2FormTextInput';
import UiButton from '../../ui/button/UiButton';
import UiDotsLoader from '../../ui/dots-loader/UiDotsLoader';
import UiGroup from '../../ui/group/UiGroup';
import Wrapper from './styles';

interface Props {
    onAuthenticationSuccess: () => void;
}

export default function AuthSmartId({ onAuthenticationSuccess }: Props) {
    const useFormMethods = useForm({ defaultValues: { personalId: '' } });
    const [isStarted, setIsStarted] = useState(false);
    const { startAuthentication, verificationCode, errorMessage } = useSmartIdAuth(onAuthenticationSuccess);

    useEffect(() => {
        if (errorMessage) {
            setIsStarted(false);
            useFormMethods.setError('personalId', { message: errorMessage });
            useFormMethods.setFocus('personalId');
        }
    }, [errorMessage]);

    function login({ personalId }: { personalId: string }) {
        useFormMethods.clearErrors();
        setIsStarted(true);
        startAuthentication(personalId);
        storageSet(LocalStorage.PREFER_LOGIN, LoginMethod.SMART_ID);
    }

    return (
        <Wrapper>
            {isStarted && !errorMessage ? (
                <>
                    <p>{translate('Verification process started, please check your Smart ID app', 'ui.account')}</p>
                    <div className="verification-code">{verificationCode ?? '_ _ _ _'}</div>
                    <UiDotsLoader />
                </>
            ) : (
                <Ui2Form onSubmit={login} useFormMethods={useFormMethods}>
                    <Ui2FormTextInput
                        type="tel"
                        name="personalId"
                        validator={getFieldValidators(['personalIdEstonian'])}
                        error={useFormMethods.formState.errors.personalId}
                        placeholder={translate('Personal identification', 'ui.account')}
                    />

                    <UiGroup vertical>
                        <UiButton type="button" color="primary" className="smart-id-next-button" isFormSubmitButton>
                            {translate('Continue', 'ui.account')}
                        </UiButton>
                    </UiGroup>
                </Ui2Form>
            )}
        </Wrapper>
    );
}
