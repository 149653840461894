import sortBy from 'lodash/sortBy';
import debounce from 'lodash/debounce';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
    FoGroup,
    FoSidebetsMarketGroup,
    FoSidebetsMarketGroupMarket,
    OddsByOutcomeIdStore,
    SidebetMarketsResponse,
} from './types';
import { fetchSidebetMarkets } from '../../microservices/sbgate';
import { loadOddsByMarketIds } from '../../microservices/sb-odds';
import { stores } from '../../stores';
import { getStoreValue } from '../../stores/store/utils';
import { MARKET_TYPE_FILTERS, MARKET_TYPE_VIEW_TYPES } from '../sport';
import { logger } from '../logger';
import { useSocketSubscribeUnsubscribe, useSocketTopicEvents } from '../../microservices/pusher';
import maxBy from 'lodash/maxBy';
import partition from 'lodash/partition';
import chunk from 'lodash/chunk';
import SportMatchMarketsExact from '../../components/sport/match/markets/exact/SportMatchMarketsExact';
import SportMatchMarketsHalfTimeFullTime from '../../components/sport/match/markets/half-time-full-time/SportMatchMarketsHalfTimeFullTime';
import { fakeSidebets } from './skeleton-mocks';
import { MATCH_TYPE } from './constants';
import { useMatchStatusUpdate, useOddsByOutcomeIds } from './hooks';
import random from 'lodash/random';
import min from 'lodash/min';
import { useSportsUserSettings } from './user-settings';
import uniq from 'lodash/uniq';
import { useSportsMatchContext } from './match-helpers';
import { SportSearchMatch } from '@staycool/sbgate-types';
import { media } from '../../stores/media/media';
import { useStore } from '../../hooks/useStore';

export const MarketTypeComponentByMarketType = {
    [MARKET_TYPE_VIEW_TYPES.EXACT_SCORE]: SportMatchMarketsExact,
    [MARKET_TYPE_VIEW_TYPES.HALF_TIME_FULL_TIME]: SportMatchMarketsHalfTimeFullTime,
};

const columnAmountByDevice = {
    isPhone: 1,
    isTablet: 2,
    isLaptop: 2,
    isDesktop: 3,
};

export const MarketTypeGroupIdBetbuilder = -2;
export const MarketTypeGroupIdAll = -1;
export const MarketTypeGroupIdPopular = 0;
const MarketTypesPopularCount = 13;

export function useMatchSidebets(
    matchId: number,
    selectedMarketTypeGroupId = MarketTypeGroupIdPopular,
    matchStatus?: string,
) {
    const { layout } = useSportsUserSettings();
    const defaultResponse = { sidebetResponse: fakeSidebets, matchId: 0, isLoading: false };
    const [{ sidebetResponse, matchId: prevMatchId, isLoading }, setSidebetResponse] = useState<{
        sidebetResponse: SidebetMarketsResponse | null;
        matchId: number;
        isLoading: boolean;
    }>(defaultResponse);
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [mtgUpdatedById, setMtgUpdatedById] = useState<Record<number, Date>>({});
    useOpenMatchIdRecording(matchId);
    const marketTypeGroupId = (selectedMarketTypeGroupId || 0) > 0 ? selectedMarketTypeGroupId : undefined;
    const limit = selectedMarketTypeGroupId === MarketTypeGroupIdPopular ? MarketTypesPopularCount : undefined;
    const interactionEffect = async () => {
        if (prevMatchId !== matchId) {
            setSidebetResponse(defaultResponse);
        }

        const timestamp = mtgUpdatedById[selectedMarketTypeGroupId] || undefined;
        try {
            setSidebetResponse((prev) => ({ ...prev, isLoading: true }));
            const sidebetResponse = await fetchSidebetMarkets({
                matchId,
                matchStatus,
                timestamp,
                marketTypeGroupId,
                limit,
            });
            setSidebetResponse({ sidebetResponse, matchId, isLoading: false });
            await checkMissingOdds(sidebetResponse);
        } catch (e) {
            logger.error('SportsSidebetsService', 'useMatchSidebets', e);
            setSidebetResponse({ sidebetResponse: null, matchId, isLoading: false });
        }
    };
    useEffect(() => {
        interactionEffect();
    }, [matchId, marketTypeGroupId, limit, layout, isAuthenticated]);
    useSidebetOddsUpdates(matchId);
    useSidebetMarketUpdates(
        setSidebetResponse,
        matchId,
        marketTypeGroupId,
        limit,
        sidebetResponse?.foGroups,
        setMtgUpdatedById,
    );
    return { sidebetResponse, isLoading };
}

export function getOddsValue(outcome, oddsByOutcomeId: OddsByOutcomeIdStore) {
    const odds = outcome && oddsByOutcomeId[outcome.id];
    return odds?.value;
}

export function getOutcomesSortFn(
    oddsByOutcomeId: OddsByOutcomeIdStore,
    market: FoSidebetsMarketGroupMarket,
): (outcome: { id: number; name: string }) => string | number {
    let sorting = (outcome) => getOddsValue(outcome, oddsByOutcomeId);
    const sortingField = market.outcomes_order_by;
    if (sortingField === 'outcomeCreated') {
        sorting = (outcome) => outcome?.id;
    }
    if (sortingField === 'outcomeName') {
        sorting = (outcome) => outcome?.name;
    }
    return sorting;
}

export function getSortString(isReverse) {
    return isReverse ? 'desc' : 'asc';
}

function useOpenMatchIdRecording(matchId) {
    useEffect(() => {
        stores.sports.currentlyOpenMatchIds.set((state) => [...state, matchId]);
        return () =>
            stores.sports.currentlyOpenMatchIds.set((state) => state.filter((matchId2) => matchId2 !== matchId));
    }, [matchId]);
}

/*
 * Display group filters according to sidebets and bet-builder availability
 * [All, Popular, BetBuilder, group#1, group#999, ...]
 * OR
 * [All, group#99, group#999]
 * OR
 * [BetBuilder, group#1]
 * if only one group is returned - it will be hidden in SportMatchMarketsMarketGroupFilter
 */
export function useFoGroupFilters(
    setCurrentMarketTypeGroup: (value: any) => void,
    foGroups: FoGroup[] | undefined,
    currentMarketTypeGroup = MarketTypeGroupIdPopular,
    matchId: number,
    isBetbuilderEnabled: boolean,
) {
    return useMemo(() => {
        if (!foGroups) {
            return foGroups;
        }
        const foFilters: FoGroup[] = [];
        if (foGroups.length > 1) {
            foFilters.push({ name: MARKET_TYPE_FILTERS.ALL_FILTER, id: MarketTypeGroupIdAll });
        }
        const hasPopularAndMoreThanOne =
            foGroups?.find((foGroup) => (foGroup.position ?? 999) <= MarketTypesPopularCount) && foGroups.length > 1;

        if (currentMarketTypeGroup === MarketTypeGroupIdPopular && !hasPopularAndMoreThanOne) {
            // Only one foGroup, set as current
            // Betbuilder group might be added
            setCurrentMarketTypeGroup(foGroups[0]?.id);
        }
        if (hasPopularAndMoreThanOne) {
            foFilters.push({ name: MARKET_TYPE_FILTERS.TOP_13_FILTER, id: MarketTypeGroupIdPopular } as FoGroup);
        }
        if (isBetbuilderEnabled) {
            foFilters.push({ name: MARKET_TYPE_FILTERS.BETBUILDER_FILTER, id: MarketTypeGroupIdBetbuilder } as FoGroup);
        }
        foFilters.push(...foGroups);
        return foFilters;
    }, [foGroups?.length, matchId]);
}

type SidebetsRefreshWSMessage = { updated?: Date; mtgIds?: number[] };

function getMinPos(mtgIds: number[], foGroups: FoGroup[]) {
    return min(mtgIds.map((mtgId) => foGroups.find((fg) => fg.id === mtgId)?.position || 9999)) || 9999;
}

async function checkMissingOdds(sidebetResponse: SidebetMarketsResponse) {
    const oddsByOutcomeId = getStoreValue(stores.sports.oddsByOutcomeId);
    let missingMarketIds = sidebetResponse.markets.flatMap(({ markets }) =>
        markets.map(({ id, outcomes }) => {
            const hasMissingOdds = outcomes.some(({ id }) => !oddsByOutcomeId[id]);
            return hasMissingOdds ? id : null;
        }),
    );
    missingMarketIds = uniq(missingMarketIds).filter((x) => x);
    return loadOddsByMarketIds(missingMarketIds);
}

function useSidebetMarketUpdates(
    marketTypesSetter: (value: any) => void,
    matchId: number,
    marketTypeGroupId?: number,
    limit?: number,
    foGroups?: FoGroup[],
    setMtgUpdatedById: (update: any) => void = () => {},
) {
    const { layout } = useSportsUserSettings();
    const getMarketsLiveDebounced = useMemo(
        () =>
            debounce(
                async ({ updated, mtgIds }: SidebetsRefreshWSMessage) => {
                    if (!foGroups) {
                        return;
                    }
                    if (mtgIds && !foGroups?.find((foGroup) => mtgIds.includes(foGroup.id))) {
                        return;
                    }
                    if (mtgIds) {
                        setMtgUpdatedById((o) => ({
                            ...o,
                            ...Object.fromEntries(mtgIds.map((id) => [id, updated])),
                            [MarketTypeGroupIdAll]: updated,
                            [MarketTypeGroupIdPopular]: updated,
                        }));
                    }
                    if (marketTypeGroupId && !mtgIds?.includes(marketTypeGroupId)) {
                        return;
                    }
                    if (limit && mtgIds && getMinPos(mtgIds, foGroups) > limit) {
                        return;
                    }
                    try {
                        const sidebetResponse = await fetchSidebetMarkets({
                            matchId,
                            timestamp: updated,
                            marketTypeGroupId,
                            limit,
                        });
                        marketTypesSetter({ matchId, sidebetResponse });
                        await checkMissingOdds(sidebetResponse);
                    } catch (error) {
                        logger.error('SportsSidebetsService', 'useSidebetMarketUpdates', error);
                    }
                },
                random(random(0, 100) <= 3 ? 200 : 700, 1400),
                { maxWait: 1500 },
            ),
        [matchId, marketTypeGroupId, foGroups, limit],
    );
    useSocketSubscribeUnsubscribe('public', {
        params: {
            service: 'sports',
            channel: `match-${matchId}-market-update`,
        },
        watchParams: [matchId],
        resubscribeOnReconnect: true,
    });
    useSocketTopicEvents(`market-update-${matchId}-mtg`, getMarketsLiveDebounced, [
        matchId,
        layout,
        getMarketsLiveDebounced,
    ]);
    useMatchStatusUpdate({ id: matchId }, getMarketsLiveDebounced);
}

export function getUniqueMarketName(
    market: FoSidebetsMarketGroupMarket,
    uniqueSequences: { key: string; translation: string }[],
    isLineMarket: boolean,
) {
    const teamOrPlayers = (market.team_names || market.player_names || []).join(' - ');
    const sequences = (uniqueSequences || [])
        .map(({ key, translation }) => `${translation}: ${market.sequence[key]}`)
        .join(', ');
    const line = isLineMarket ? market.line : '';
    return `${sequences} ${teamOrPlayers} ${line}`;
}

export function useSmartMarketUniqueNameByMarketId(
    showUniqeName: number | boolean,
    markets: FoSidebetsMarketGroupMarket[],
    uniqueSequences: { key: string; translation: string }[],
    isLineMarket: boolean,
) {
    return useMemo(() => {
        if (!showUniqeName) {
            return {};
        }
        const marketUniquNames = markets.map((market) => {
            return [market.id, getUniqueMarketName(market, uniqueSequences, isLineMarket)];
        });
        return Object.fromEntries(marketUniquNames);
    }, [showUniqeName, markets]);
}

function useSidebetOddsUpdates(matchId: number) {
    useSocketSubscribeUnsubscribe('public', {
        params: { service: 'odds', channel: `match-odds-${matchId}` },
        watchParams: [matchId],
        resubscribeOnReconnect: true,
    });
}

function getBigColumns(bigMarkets: FoSidebetsMarketGroup[], deviceType) {
    if (!bigMarkets.length) {
        return 1;
    }
    const columnAmountMax = { isPhone: 1, isTablet: 2, isLaptop: 2, isDesktop: 3 }[deviceType];
    if (columnAmountMax === 1) {
        return 1;
    }
    const maxMarkets = maxBy(bigMarkets, (marketType) => marketType.markets.length);
    if ((maxMarkets?.markets?.length || 0) > 2) {
        return Math.max(1, columnAmountMax - 1);
    }
    return columnAmountMax;
}

export function useMarketTypeSplitter(
    filteredMarketTypes: FoSidebetsMarketGroup[],
    deviceType: string,
    matchType?: string | null,
) {
    const isPhone = deviceType === 'isPhone';
    const [smallMarketTypes, bigMarketTypes] = useMemo(
        () =>
            partition(
                filteredMarketTypes,
                (marketType) =>
                    marketType.markets.length < 2 ||
                    marketType.view_type === 'line' ||
                    MarketTypeComponentByMarketType[marketType.view_type] ||
                    marketType.markets[0]?.outcomes?.length < 3,
            ),
        [filteredMarketTypes],
    );
    const bigColumnAmount = useMemo(() => getBigColumns(bigMarketTypes, deviceType), [bigMarketTypes, deviceType]);

    const isOutrightWithBigMt = bigMarketTypes.length && matchType === MATCH_TYPE.OUTRIGHT;
    const smallColumnAmount = isOutrightWithBigMt ? bigColumnAmount : columnAmountByDevice[deviceType];
    const useOnlySmallColumns = bigColumnAmount === smallColumnAmount;

    const bigMarketTypesSplit = useMemo(() => {
        return bigMarketTypes.flatMap((marketType) =>
            chunk(marketType.markets, isPhone ? 2 : 8 / bigColumnAmount).map((newMarkets) => ({
                ...marketType,
                markets: newMarkets,
            })),
        );
    }, [bigColumnAmount, bigMarketTypes, isPhone, deviceType]);

    const smallColumnMarkets = useMemo(
        () =>
            useOnlySmallColumns
                ? sortBy(smallMarketTypes.concat(bigMarketTypesSplit), (mt) =>
                      filteredMarketTypes.findIndex((mt2) => mt2.market_type_id === mt.market_type_id),
                  )
                : smallMarketTypes,
        [bigColumnAmount, deviceType, smallMarketTypes, bigMarketTypesSplit],
    );

    return {
        smallColumnAmount,
        smallColumnMarkets,
        bigColumnAmount,
        bigMarketTypesSplit: !useOnlySmallColumns && bigMarketTypesSplit,
    };
}

export function useSortedMarkets(markets: FoSidebetsMarketGroupMarket[], isLineMarket: boolean) {
    const firstMarket = markets[0];
    const outcomesOrderBy = firstMarket.outcomes_order_by || 'odds';
    const isSortByOdds = !isLineMarket && outcomesOrderBy === 'odds';
    const outcomeIds = useMemo(
        () => isSortByOdds && markets.flatMap((market) => market.outcomes.map((o) => o.id)),
        [isSortByOdds && markets],
    );
    const [odds] = useOddsByOutcomeIds(outcomeIds, [outcomeIds]);
    return useMemo(() => {
        if ((!isSortByOdds && outcomesOrderBy === 'odds') || outcomesOrderBy === 'outcomeCreated') {
            return markets;
        }
        return sortBy(markets, (market) =>
            outcomesOrderBy === 'outcomeName' ? market.outcomes[0].name : getOddsValue(market.outcomes[0], odds),
        );
    }, [markets, odds]);
}

export function unaccent(word) {
    return word?.normalize('NFD').replace(/[\u0300-\u036f]/g, '') || '';
}

export function useSidebetSearch(
    marketsByMarketType: FoSidebetsMarketGroup[],
    currentMarketTypeGroup: number,
    setCurrentMarketTypeGroup: (n: number) => void,
) {
    const { sidebetsSearch } = useSportsMatchContext();
    const [searchedSidebetMarkets, setSearchedSidebetMarkets] = useState<FoSidebetsMarketGroup[]>();
    useEffect(() => {
        (() => {
            if (!sidebetsSearch) {
                if (searchedSidebetMarkets) {
                    setSearchedSidebetMarkets(undefined);
                }
                return;
            }
            const wordSplitTerm = unaccent(sidebetsSearch)
                .split(/[\s/]/)
                .filter((x) => x.trim());
            // max 5ms avg 1ms on 120 markets i7, be careful if adding anything to this fn
            const filteredMarkets = marketsByMarketType.filter((marketGroup) => {
                const normalized = unaccent(marketGroup.translatedName?.toLowerCase());

                return (
                    wordSplitTerm.every((w) => normalized.includes(w)) ||
                    marketGroup.markets.some((market) =>
                        market.outcomes?.find(({ name }) => unaccent(name?.toLowerCase()).includes(sidebetsSearch)),
                    )
                );
            });
            setSearchedSidebetMarkets(filteredMarkets);
            if (currentMarketTypeGroup !== MarketTypeGroupIdAll) {
                setCurrentMarketTypeGroup(MarketTypeGroupIdAll);
            }
        })();
    }, [sidebetsSearch, marketsByMarketType, currentMarketTypeGroup]);
    return searchedSidebetMarkets;
}

export function useLocalSearchMarketHighLight(marketGroup: FoSidebetsMarketGroup) {
    const { sidebetsSearch } = useSportsMatchContext();
    const [highLightMatch, setHighLightedName] = useState<string>();

    useEffect(() => {
        if (!sidebetsSearch) {
            return;
        }
        const [matchedOutcome] = marketGroup.markets?.flatMap(({ outcomes }) =>
            outcomes?.find(({ name }) => unaccent(name?.toLowerCase()).includes(sidebetsSearch)),
        );
        setHighLightedName(matchedOutcome?.result_key);
    }, [sidebetsSearch]);

    return highLightMatch;
}

export function useGlobalSearchMarketHighlightScroll(
    marketTypeId: number,
    markets: FoSidebetsMarketGroupMarket[],
    highLightMatchFn: (searchSelected: SportSearchMatch, foundMarket: FoSidebetsMarketGroupMarket) => string | null = (
        s,
    ) => s.player_team_name,
) {
    const [{ isPhone }] = useStore(media);
    const ref = useRef<HTMLDivElement>(null);
    const [searchSelected, setSearchSelected] = useStore(stores.sports.searchMatchSelected);
    const [highLightMatch, setHighLightedName] = useState<string | null>();

    useEffect(() => {
        const singleMarketType = searchSelected?.markets_info?.[0];
        if (!singleMarketType) {
            return;
        }
        const singleMarketTypeId = singleMarketType?.market_type_id;
        if (singleMarketTypeId !== marketTypeId && !markets.some((m) => m.market_type_id === singleMarketTypeId)) {
            return;
        }
        const marketFound = markets.find((m) => m.id === singleMarketType.id);
        if (!marketFound) {
            return;
        }
        setHighLightedName(highLightMatchFn(searchSelected, marketFound));
        setSearchSelected(null);
    }, [searchSelected]);

    useEffect(() => {
        if (!ref.current || !highLightMatch) {
            return;
        }
        window.scrollTo({
            top: window.pageYOffset + ref.current?.getBoundingClientRect?.().top - 200,
            behavior: isPhone ? 'auto' : 'smooth',
        });
    }, [highLightMatch, ref.current]);

    return { ref, highLightMatch };
}
