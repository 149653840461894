import { createGlobalStore } from './store/utils';

const initialStore = {
    level: {
        level: 0,
        percent: 0,
    },
    tier: '',
    id: '',
};

export const loyaltyStore = createGlobalStore({ ...initialStore }, 'loyaltyStore');
