import React from 'react';
import { useStore } from '../../../hooks/useStore';
import { isFeatureAvailable } from '../../../services/feature';
import { FEATURE } from '../../../services/types';
import { stores } from '../../../stores';
import AuthProfileValidatorEssentialRequirement from './essential-requirement/AuthProfileValidatorEssentialRequirement';
import AuthProfileValidatorLicenceRequirement from './licence-requirement/AuthProfileValidatorLicenceRequirement';
import AuthProfileValidatorPeruTermsAndConditionsAcceptanceModal from './peru-terms-and-conditions-acceptance-modal/AuthProfileValidatorPeruTermsAndConditionsAcceptanceModal';
import AuthProfileValidatorProofOfIdModal from './proof-of-id-modal/AuthProfileValidatorProofOfIdModal';
import AuthProfileValidatorSourceOfWealthFiles from './source-of-wealth-files/AuthProfileValidatorSourceOfWealthFiles';
import AuthProfileValidatorSourceOfWealth from './source-of-wealth/AuthProfileValidatorSourceOfWealth';
import AuthProfileValidatorTermsAndConditionsAcceptanceModal from './terms-and-conditions-acceptance-modal/AuthProfileValidatorTermsAndConditionsAcceptanceModal';

export default function AuthProfileValidator() {
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [isEssentialUserInformationInquiryModalOpen] = useStore(
        stores.modals.isEssentialUserInformationInquiryModalOpen,
    );
    const [isTermsAndConditionsAcceptanceModalOpen] = useStore(stores.modals.isTermsAndConditionsAcceptanceModalOpen);
    const [isPeruTermsAndConditionsAcceptanceModalOpen] = useStore(
        stores.modals.isPeruTermsAndConditionsAcceptanceModalOpen,
    );
    const [isProofOfIdModalOpen] = useStore(stores.modals.isProofOfIdModalOpen);

    if (!isFeatureAvailable(FEATURE.AUTH_PROFILE_VALIDATOR) || !isAuthenticated) {
        return null;
    }

    return (
        <>
            <AuthProfileValidatorEssentialRequirement />
            {!isEssentialUserInformationInquiryModalOpen &&
                !isTermsAndConditionsAcceptanceModalOpen &&
                !isPeruTermsAndConditionsAcceptanceModalOpen &&
                !isProofOfIdModalOpen && <AuthProfileValidatorLicenceRequirement isModal />}
            <AuthProfileValidatorSourceOfWealth />
            <AuthProfileValidatorSourceOfWealthFiles />
            <AuthProfileValidatorTermsAndConditionsAcceptanceModal />
            <AuthProfileValidatorPeruTermsAndConditionsAcceptanceModal />
            <AuthProfileValidatorProofOfIdModal />
        </>
    );
}
