import React, {
    createContext,
    MutableRefObject,
    ReactNode,
    useContext,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { getRequestedPaymentDocuments } from '../../microservices/kyc';
import { useDocumentUpload } from '../../services/kyc';
import {
    KycDocumentType,
    ProofOfPaymentStatus,
    ProofOfPaymentVerificationOption,
    RequestedDocument,
} from '../../services/kyc/types';
import { stores } from '../../stores';
import { useStore } from '../../hooks/useStore';

const submissionState = {
    areBothSidesSelected: false,
    areFirst6AndLast4DigitsVisible: false,
    areImagesReadable: false,
    areMiddleDigitsHidden: false,
    isBankAccountNumberVisible: false,
    isCardHolderNameVisible: false,
    isCardNumberOrRecentDepositVisible: false,
    isCryptoWalletAddressVisible: false,
    isCvcCovered: false,
    isDepositAmountVisible: false,
    isDepositDateVisible: false,
    isEWalletAccountNumberOrEmailVisible: false,
    isExpiryDateVisible: false,
    isFullNameVisible: false,
    isIdDocumentValidAndReadable: false,
};

const commonBankRequiredSubmissionFields: (keyof typeof submissionState)[] = [
    'isBankAccountNumberVisible',
    'isFullNameVisible',
];

const commonCardRequiredSubmissionFields: (keyof typeof submissionState)[] = [
    'isCardNumberOrRecentDepositVisible',
    'isFullNameVisible',
];

const requiredSubmissionFieldsByVerificationOption: Record<
    ProofOfPaymentVerificationOption,
    (keyof typeof submissionState)[]
> = {
    [ProofOfPaymentVerificationOption.BANK_SCREENSHOT]: commonBankRequiredSubmissionFields,
    [ProofOfPaymentVerificationOption.BANK_STATEMENT]: commonBankRequiredSubmissionFields,
    [ProofOfPaymentVerificationOption.CARD_FRONT_AND_BACK]: [
        'areBothSidesSelected',
        'areFirst6AndLast4DigitsVisible',
        'areImagesReadable',
        'areMiddleDigitsHidden',
        'isCardHolderNameVisible',
        'isCvcCovered',
        'isExpiryDateVisible',
    ],
    [ProofOfPaymentVerificationOption.CARD_LOST]: commonCardRequiredSubmissionFields,
    [ProofOfPaymentVerificationOption.CARD_NO_NAME_OR_NUMBER]: commonCardRequiredSubmissionFields,
    [ProofOfPaymentVerificationOption.CRYPTO_WALLET_SCREENSHOT]: [
        'isCryptoWalletAddressVisible',
        'isIdDocumentValidAndReadable',
    ],
    [ProofOfPaymentVerificationOption.E_WALLET_SCREENSHOT]: [
        'isEWalletAccountNumberOrEmailVisible',
        'isFullNameVisible',
    ],
};

interface InitialState {
    activeStep: number;
    documentListScrollPosition: number;
    documentUpload: ReturnType<typeof useDocumentUpload>;
    formContainerRef: MutableRefObject<HTMLDivElement | null>;
    isLoading: boolean;
    isSecondStepSkipped: boolean;
    isTwoSidedDocumentFlow: boolean;
    selectedDocument: RequestedDocument | undefined;
    requestedDocuments: RequestedDocument[];
    requiredSubmissionFields: (keyof typeof submissionState)[];
    selectedVerificationOption: ProofOfPaymentVerificationOption | undefined;
    selectingSide: 'front' | 'back' | undefined;
    stepCompletionState: Record<number, boolean>;
    reset: () => void;
    setActiveStep: (step: number) => void;
    setDocumentListScrollPosition: (position: number) => void;
    setSelectedDocument: (document: RequestedDocument) => void;
    setSelectedVerificationOption: (option: ProofOfPaymentVerificationOption) => void;
    updateSubmissionState: (state: Partial<typeof submissionState>) => void;
    submissionState: typeof submissionState;
}

const initialState: InitialState = {
    activeStep: 0,
    documentListScrollPosition: 0,
    documentUpload: {} as ReturnType<typeof useDocumentUpload>,
    formContainerRef: { current: null },
    isLoading: true,
    isSecondStepSkipped: false,
    isTwoSidedDocumentFlow: false,
    requestedDocuments: [],
    requiredSubmissionFields: [],
    selectedDocument: undefined,
    selectedVerificationOption: '' as ProofOfPaymentVerificationOption,
    selectingSide: undefined,
    stepCompletionState: {},
    reset: () => {},
    setActiveStep: () => {},
    setSelectedDocument: () => {},
    setDocumentListScrollPosition: () => {},
    setSelectedVerificationOption: () => {},
    updateSubmissionState: () => {},
    submissionState,
};

const ProofOfPaymentVerificationContext = createContext<InitialState>(initialState);

export const ProofOfPaymentVerificationContextConsumer = ProofOfPaymentVerificationContext.Consumer;

export function ProofOfPaymentVerificationContextProvider({ children }: { children?: ReactNode }) {
    const formContainerRef = useRef<HTMLDivElement>(null);
    const [documentListScrollPosition, setDocumentListScrollPosition] = useState(
        initialState.documentListScrollPosition,
    );
    const [activeStep, setActiveStep] = useState(initialState.activeStep);
    const [requestedDocuments, setRequestedDocuments] = useState<RequestedDocument[]>(initialState.requestedDocuments);
    const [selectedDocument, setSelectedDocument] = useState<RequestedDocument | undefined>(
        initialState.selectedDocument,
    );
    const [kycSettings] = useStore(stores.kyc.settings);
    const [selectedVerificationOption, setSelectedVerificationOption] = useState<
        ProofOfPaymentVerificationOption | undefined
    >(initialState.selectedVerificationOption);
    const [isLoading, setIsLoading] = useState(initialState.isLoading);
    const [submissionState, setSubmissionState] = useState(initialState.submissionState);
    const declineReason = kycSettings.kycPaymentDeclineReason as string | undefined;
    const status = kycSettings.proofOfPaymentStatus as ProofOfPaymentStatus;
    const requiredSubmissionFields = useMemo(() => {
        const fields: (keyof typeof submissionState)[] = [];
        if (!selectedDocument) {
            return fields;
        }
        const fieldsToOmit: (keyof typeof submissionState)[] = [];
        if (selectedVerificationOption) {
            fields.push(...requiredSubmissionFieldsByVerificationOption[selectedVerificationOption]);
        }
        if (selectedDocument.documentType === 'DEPOSIT') {
            fields.push('isDepositAmountVisible', 'isDepositDateVisible');
        }
        if (selectedDocument.frontSideDeclineReason && !selectedDocument.backSideDeclineReason) {
            fieldsToOmit.push('areBothSidesSelected', 'isCvcCovered');
        } else if (selectedDocument.backSideDeclineReason && !selectedDocument.frontSideDeclineReason) {
            fieldsToOmit.push(
                'areBothSidesSelected',
                'areFirst6AndLast4DigitsVisible',
                'isExpiryDateVisible',
                'isCardHolderNameVisible',
            );
        }
        return fields.filter((field) => !fieldsToOmit.includes(field));
    }, [selectedDocument, selectedVerificationOption]);
    const isSecondStepSkipped =
        !!selectedDocument &&
        (['CRYPTO_WALLET', 'E_WALLET', 'DEPOSIT'].includes(selectedDocument.documentType) ||
            !!selectedDocument.selectedOption);
    const isTwoSidedDocumentFlow = selectedVerificationOption === ProofOfPaymentVerificationOption.CARD_FRONT_AND_BACK;
    const documentUpload = useDocumentUpload({
        documentType: KycDocumentType.PAYMENT,
        documentLimit: !requestedDocuments.length
            ? undefined
            : isTwoSidedDocumentFlow &&
              !!selectedDocument?.backSideDeclineReason === !!selectedDocument?.frontSideDeclineReason
            ? 2
            : 1,
        status,
        declineReason,
    });
    const selectingSide = useMemo(() => {
        if (!(selectedDocument?.backSideDeclineReason || selectedDocument?.frontSideDeclineReason)) {
            return;
        }
        if (selectedDocument.frontSideDeclineReason && selectedDocument.backSideDeclineReason) {
            if (documentUpload.documents.find((document) => document.side === 'front')) {
                return 'back';
            }
            return 'front';
        }
        if (selectedDocument.frontSideDeclineReason) {
            return 'front';
        }
        if (selectedDocument.backSideDeclineReason) {
            return 'back';
        }
    }, [selectedDocument, documentUpload.documents]);
    const stepCompletionState = {
        0: !!selectedDocument,
        1: !!selectedVerificationOption,
        2: documentUpload.documents.length === documentUpload.documentLimit,
        3: !!requiredSubmissionFields.length && requiredSubmissionFields.every((field) => !!submissionState[field]),
        4: !!documentUpload.uploadResultAlert,
    };

    async function loadRequestedPaymentDocuments() {
        setIsLoading(true);
        try {
            const requestedDocuments = await getRequestedPaymentDocuments();
            setRequestedDocuments(requestedDocuments);
        } finally {
            setIsLoading(false);
        }
    }

    function reset() {
        documentUpload.reset();
        setActiveStep(0);
        loadRequestedPaymentDocuments();
    }

    function updateSubmissionState(update: Partial<typeof submissionState>) {
        setSubmissionState((submissionState) => ({ ...submissionState, ...update }));
    }

    useEffect(() => {
        if ([ProofOfPaymentStatus.APPROVED, ProofOfPaymentStatus.NOT_CHECKED].includes(status)) {
            setIsLoading(false);
            return;
        }
        loadRequestedPaymentDocuments();
    }, []);

    useEffect(() => {
        if (activeStep === 0) {
            setSelectedDocument(undefined);
        } else {
            formContainerRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [activeStep]);

    useEffect(() => {
        documentUpload.reset();
        setSubmissionState(initialState.submissionState);
    }, [selectedVerificationOption, selectedDocument]);

    useLayoutEffect(() => {
        if (selectedDocument) {
            setActiveStep(isSecondStepSkipped ? 2 : 1);
            if (selectedDocument.selectedOption) {
                setSelectedVerificationOption(selectedDocument.selectedOption);
            } else if (selectedDocument.documentType === 'E_WALLET') {
                setSelectedVerificationOption(ProofOfPaymentVerificationOption.E_WALLET_SCREENSHOT);
            } else if (selectedDocument.documentType === 'CRYPTO_WALLET') {
                setSelectedVerificationOption(ProofOfPaymentVerificationOption.CRYPTO_WALLET_SCREENSHOT);
            } else {
                setSelectedVerificationOption(undefined);
            }
        } else {
            setSelectedVerificationOption(undefined);
        }
    }, [selectedDocument]);

    useEffect(() => {
        setSubmissionState(initialState.submissionState);
    }, [documentUpload.documents]);

    return (
        <ProofOfPaymentVerificationContext.Provider
            value={{
                activeStep,
                documentListScrollPosition,
                documentUpload,
                formContainerRef,
                isLoading,
                isSecondStepSkipped,
                isTwoSidedDocumentFlow,
                requestedDocuments,
                requiredSubmissionFields,
                reset,
                selectedDocument,
                selectedVerificationOption,
                selectingSide,
                setActiveStep,
                setDocumentListScrollPosition,
                setSelectedDocument,
                setSelectedVerificationOption,
                stepCompletionState,
                submissionState,
                updateSubmissionState,
            }}
        >
            {children}
        </ProofOfPaymentVerificationContext.Provider>
    );
}

export function useProofOfPaymentVerificationContext() {
    return useContext<InitialState>(ProofOfPaymentVerificationContext);
}
