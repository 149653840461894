import { Licence } from '@staycool/location';
import React from 'react';
import UiBox from '../../../../components/ui/box/UiBox';
import { useStore } from '../../../../hooks/useStore';
import { getLicence } from '../../../../services/licence';
import { loadAskLimit } from '../../../../services/responsible-gaming';
import { getRoute, isActiveRoute } from '../../../../services/router';
import { loadProfile } from '../../../../services/user';
import { stores } from '../../../../stores';
import AuthProfileValidatorLicenceRequirementEstonia from './estonia/AuthProfileValidatorLicenceRequirementEstonia';
import AuthProfileValidatorLicenceRequirementMalta from './malta/AuthProfileValidatorLicenceRequirementMalta';
import AuthProfileValidatorLicenceRequirementMexico from './mexico/AuthProfileValidatorLicenceRequirementMexico';
import Wrapper from './styles';
import AuthProfileValidatorLicenceRequirementSweden from './sweden/AuthProfileValidatorLicenceRequirementSweden';
import AuthProfileValidatorLicenceRequirementPeru from './peru/AuthProfileValidatorLicenceRequirementPeru';
import { getStoreValue } from '../../../../stores/store/utils';

interface Props {
    isModal?: boolean;
    onExit?: () => void;
}

export default function AuthProfileValidatorLicenceRequirement({ isModal = false, onExit = () => {} }: Props) {
    const licence = getLicence();
    const isRequiredProfileInformationInquiryModalOpen = getStoreValue(
        stores.modals.isRequiredProfileInformationInquiryModalOpen,
    );
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [user] = useStore(stores.user);

    if (!isAuthenticated || !user || !licence || (isModal && isActiveRoute(getRoute('registration')))) {
        return null;
    }

    const validators = {
        [Licence.ESTONIA]: AuthProfileValidatorLicenceRequirementEstonia,
        [Licence.MALTA]: AuthProfileValidatorLicenceRequirementMalta,
        [Licence.SWEDEN]: AuthProfileValidatorLicenceRequirementSweden,
        [Licence.MEXICO]: AuthProfileValidatorLicenceRequirementMexico,
        [Licence.PERU]: AuthProfileValidatorLicenceRequirementPeru,
    };

    const Validator = validators[licence];

    if (!Validator) {
        return null;
    }

    const ValidatorElement = <Validator isModal={isModal} onAfterSubmit={onAfterSubmit} />;

    async function onAfterSubmit() {
        stores.modals.isRequiredProfileInformationInquiryModalOpen.set(false);
        await Promise.all([loadProfile(), loadAskLimit()]);
        onExit();
    }

    if (isModal && isRequiredProfileInformationInquiryModalOpen) {
        return (
            <Wrapper>
                <UiBox>{ValidatorElement}</UiBox>
            </Wrapper>
        );
    }

    return ValidatorElement;
}
