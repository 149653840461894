import { FoComboCardMatch, FoComboCardWithOdds } from '@staycool/sports-types/fo-combo-card';
import React, { useEffect, useState } from 'react';
import { groupMatchesBySportName } from '../../../../../services/sports/combo-cards';
import DangerousHtml from '../../../../dangerous-html/DangerousHtml';

interface Props {
    matches: FoComboCardWithOdds['matches'];
}

export default function ComboCardsItemDetailsMultipleSport({ matches }: Props) {
    const [matchesPerSport, setMatchesPerSport] = useState<Record<string, FoComboCardMatch[]>>({});

    useEffect(() => {
        const matchesGroupedBySportName = groupMatchesBySportName(matches);
        setMatchesPerSport(matchesGroupedBySportName);
    }, []);

    return (
        <>
            {Object.keys(matchesPerSport).map((key) =>
                matchesPerSport[key].map(({ id, markets, name }) => (
                    <div key={id} className="dot-indicator">
                        <div>{name}</div>
                        {markets.map(({ id, outcome_name }) => (
                            <DangerousHtml key={id} content={outcome_name} />
                        ))}
                    </div>
                )),
            )}
        </>
    );
}
