import styled from 'styled-components';
import { mediaQuery } from '../../../../styles/utils';

type StyledProps = {
    $isCompact?: boolean;
};

export default styled.div<StyledProps>`
    cursor: pointer;
    margin-bottom: var(--spacing-32);
    .sports-settings {
        ${({ $isCompact }) =>
            !$isCompact &&
            `
            display: flex;
            justify-content: space-between;
            align-items: center;

            background-color: var(--surface-level-2-bg);
            border: 2px solid var(--surface-level-2-line-color);
            border-radius: var(--ui-radius);
            padding: 0.8rem;
        `};

        svg {
            color: var(--font-color-secondary);
        }
        .settings-state {
            display: flex;
            align-items: center;
            flex: 1;
            color: var(--font-color-subtle);
            line-height: 1;
            > *:not(:last-child) {
                margin-right: 0.8rem;
                @media ${mediaQuery.isLaptop} {
                    margin-right: 0.4rem;
                }
            }
        }

        .title {
            font-size: 0.7rem;
        }
        .separator {
            color: var(--font-color-faded);
        }

        .info {
            color: var(--font-color-secondary);
            font-size: 0.8rem;
        }
    }
`;
