import { WithLanguageLayout } from '@staycool/sbgate-types';
import { FoMatch } from '@staycool/sports-types';
import { ObjectValues } from '../ts-utils';

export const BonusType = {
    Multiplier: 'multiplier',
    FreeBet: 'free_bet',
    FreeBetV2: 'free_bet_v2',
    RiskFreeBet: 'risk_free_bet',
} as const;

export const BONUS_STATUS = {
    ELIGIBLE: 'ELIGIBLE',
    BONUS_SUBMITTED: 'BONUS_SUBMITTED',
    CODE_REGISTERED: 'CODE_REGISTERED',
    NEED_DEPOSIT: 'NEED_DEPOSIT',
    BONUS_GIVEN: 'BONUS_GIVEN',
    ALREADY_GOT_THIS_BONUS: 'ALREADY_GOT_THIS_BONUS',
    ALREADY_ENTERED: 'ALREADY_ENTERED',
    NO_BONUS_FOUND: 'NO_BONUS_FOUND',
    SUBMIT_ERROR: 'SUBMIT_ERROR',
    ALREADY_HAS_ACTIVE_BONUS_CASINO: 'ALREADY_HAS_ACTIVE_BONUS_CASINO',
    ALREADY_HAS_ACTIVE_BONUS_SB: 'ALREADY_HAS_ACTIVE_BONUS_SB',
    SECURITY_MATCH_FOUND: 'SECURITY_MATCH_FOUND',
    MOBILE_APP_ONLY: 'MOBILE_APP_ONLY',
    VALIDATION_FAILED: 'VALIDATION_FAILED',
    SUBMISSION_FAILED: 'SUBMISSION_FAILED',
    BONUS_NOT_ELIGIBLE_FOR_DEPOSIT_WITH_THIS_PROVIDER: 'BONUS_NOT_ELIGIBLE_FOR_DEPOSIT_WITH_THIS_PROVIDER',
} as const;

export const BONUS_PRODUCT = {
    CASINO: 'CASINO',
    SPORTSBOOK: 'SPORTSBOOK',
    POKER: 'POKER',
} as const;

export const BONUS_LOCK_STATUS = {
    ACTIVE: 'active',
    CANCELLED: 'cancelled',
} as const;

export const BONUS_TYPE = {
    DEPOSIT: 'deposit',
    PENDING: 'pending',
    CAMPAIGN: 'campaign',
    FREE_MONEY: 'free_money',
    RISK_FREE_BET: 'risk_free_bet',
} as const;

export interface BonusBet {
    wagering_ratio?: number;
    wagering_min_odds?: number;
    wagering_duration?: number;
    amount?: number;
    code: string;
    id: string;
    matches: number[];
    categories: number[] | null;
    odds_value: number;
    sb_product: BonusBetProduct;
    minAmount: number;
    bet_type: BonusBetType | null;
    multiplierSettings?: { maxNetWin: number; multiplier: number };
    article?: string;
    bonusType?: ObjectValues<typeof BonusType>;
    quantity?: number;
    expires_at?: Date;
    userBonusId?: string;
}

export interface BonusBetsResponse {
    free_bet: BonusBet[];
    free_bet_v2: BonusBet[];
    risk_free_bet: BonusBet[];
    multiplier: BonusBet[];
}

export type RequestMatchList = Pick<
    WithLanguageLayout<FoMatch.FoMatchListFilteredCampaigns>,
    'offset' | 'limit' | 'country' | 'locale' | 'layout' | 'language' | 'province'
>;
export type UserViewBonus = {
    bonus_type: string;
    bonus_name?: string;
    article?: any;
    description?: any;
    bonus_amount: number;
    created: string;
    deposit: string;
    product: 'ALL' | 'CASINO' | 'SPORTSBOOK' | 'POKER' | 'VIRTUAL_SPORTS';
    status: string;
    user_id: string;
    poker_details?: any;
    id?: string;
    lock?: any;
    cancelAmount?: number;
    bonus_internal_name: string;
    bonus_id: string;
    wagering_ratio: string;
    wagering_min_odds: string;
};
type DepositProviderPolicy = 'whitelist' | 'blacklist' | null;

export interface Campaign {
    id: string;
    name_external: string;
    description: Record<string, string>;
    terms_and_conditions: string;
    images: Record<string, string>;
    segment: string | null;
    display: {
        order: number;
        logged: string;
        placement: string[];
        show_in_featured_section: boolean;
        show_countdown_timer?: boolean;
    };
    article_id: string;
    code: string;
    bonus_type: ObjectValues<typeof BonusType>;
    products: {
        sportsbook: boolean;
        casino: boolean;
        poker: boolean;
        virtual_sports: boolean;
    };
    status?: {
        active: boolean;
        public: boolean;
    };
    start_time: string;
    end_time: string;
    article?: {
        id: string;
        category: string;
        published: string;
        mobile: boolean;
        slug: Record<string, string>;
    };
    deposit: {
        ratio: number;
        first_deposit: Date;
        forbiddenProviders: string[];
        depositProviderPolicy: DepositProviderPolicy;
    };
    is_taken_by_user?: boolean;
    participants?: number;
}

export interface CampaignGroups {
    all: string;
    newAndFeatured: string;

    [key: string]: string;
}

enum BonusBetType {
    SINGLE = 'single',
    COMBO = 'combo',
}

enum BonusBetProduct {
    LIVEBET = 'LIVE',
    PREMATCH = 'PREMATCH',
}
