import React from 'react';
import Wrapper from './styles';
import { useLoyalty } from '../../hooks/useLoyalty';
import { stores } from '../../stores';
import { useStore } from '../../hooks/useStore';

export default function LoyaltyMeter() {
    const [user] = useStore(stores.user);
    const { level, progress } = useLoyalty(user?.id);

    if (!level) {
        return null;
    }

    return (
        <Wrapper>
            <span className="progress-level">{level}</span>
            <svg
                className="progress-bar"
                width="38"
                height="38"
                viewBox="0 0 38 38"
                style={{ transform: 'rotate(-90deg)', ['--progress' as string]: progress }}
            >
                <circle className="background" cx="19" cy="19" r="16" fill="none" strokeWidth="6" />
                <circle
                    className="foreground"
                    cx="19"
                    cy="19"
                    r="16"
                    fill="none"
                    strokeWidth="6"
                    pathLength="100"
                    strokeDasharray="100"
                />
            </svg>
        </Wrapper>
    );
}
