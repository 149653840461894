import React, { useEffect, useMemo, useRef } from 'react';
import Wrapper from './styles';
import SportLobbyCategories from '../../components/sport/lobby-categories/SportLobbyCategories';
import { getRoute } from '../../services/router';
import SportSidebarLive from '../../components/sport/sidebar/live/SportSidebarLive';
import SportSidebarSoon from '../../components/sport/sidebar/soon/SportSidebarSoon';
import Navigator from '../../components/navigator/Navigator';
import { stores } from '../../stores';
import VirtualSportsBetslip from '../../components/virtual-sports/betslip/VirtualSportsBetslip';
import VirtualSportsSidebar from '../../components/virtual-sports/sidebar/VirtualSportsSidebar';
import SportSidebarRight from '../../components/sport/sidebar/right/SportSidebarRight';
import MainLayout from '../main/MainLayout';
import { isFeatureAvailable } from '../../services/feature';
import { loadBlockedProductsByPlayerCountry } from '../../microservices/sbgate';
import SportNavigationB from '../../components/sport/navigation-b/SportNavigationB';
import SportPhoneNavigation from '../../components/sport/phone/navigation/SportPhoneNavigation';
import SportPhoneNavigationV2 from '../../components/sport/phone/navigation-v-2/SportPhoneNavigationV2';
import SportPhoneNavigationC from '../../components/sport/phone/navigation-c/SportPhoneNavigationC';
import ComboCards from '../../components/combo-cards/ComboCards';
import { media } from '../../stores/media/media';
import { useStore } from '../../hooks/useStore';
import { FEATURE } from '../../services/types';
import { isTestUser } from '../../services/user';
import SportLobbyCategoriesV2Mobile from '../../components/sport/lobby-categories-v-2/mobile/SportLobbyCategoriesV2Mobile';

interface Props {
    children: any;
}

export default function SportLayout({ children }: Props) {
    const [{ isDesktop, isLaptop, isTablet, isPhone }] = useStore(media);
    const leftSidebarComponents = useMemo(() => getLeftSidebarComponents(), []);
    const rightSidebarComponents = useMemo(() => getRightSidebarComponents(), []);
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [isNavigationHidden] = useStore(stores.sports.isNavigationHidden);
    const isMainNavigationV2 = isFeatureAvailable(FEATURE.SPORT_MAIN_NAVIGATION_V2);
    const isLayoutB = !isFeatureAvailable(FEATURE.SPORT_LAYOUT_A);
    const betSlipRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!isAuthenticated) {
            loadBlockedProductsByPlayerCountry();
        }
    }, [isAuthenticated]);

    function getLeftSidebarComponents() {
        return [
            { route: getRoute('sport.live'), component: SportSidebarLive },
            isFeatureAvailable(FEATURE.VIRTUAL_SPORTS) && {
                route: getRoute('virtual-sports'),
                component: VirtualSportsSidebar,
            },
            {
                route: getRoute('sport'),
                component: SportSidebarSoon,
            },
        ];
    }

    function getRightSidebarComponents() {
        return [
            { route: getRoute('sport.recommendations'), component: SportSidebarRight, exact: true },
            { route: getRoute('sport.live'), component: SportSidebarRight, exact: true },
            { route: getRoute('sport.incoming-bets'), component: SportSidebarRight, exact: true },
            isFeatureAvailable(FEATURE.VIRTUAL_SPORTS) && {
                route: getRoute('virtual-sports'),
                component: VirtualSportsBetslip,
            },
            { route: `${getRoute('sport.contests')}/:contestId`, component: SportSidebarRight },
            { route: `${getRoute('sport')}/:sportId`, component: SportSidebarRight },
        ];
    }

    return (
        <MainLayout>
            <Wrapper $isLayoutB={isLayoutB}>
                <div className="sport-content">
                    {(isDesktop || isLaptop) && !isLayoutB && (
                        <div className="sport-menu">
                            <Navigator routes={leftSidebarComponents} noRedirect />
                        </div>
                    )}

                    <div className="main-content">
                        {!isLayoutB && !isMainNavigationV2 && <SportLobbyCategories />}
                        {!isLayoutB && isMainNavigationV2 && (isPhone || isTablet) && <SportLobbyCategoriesV2Mobile />}
                        {/* TODO: check if this has to be updated according to new sportsbook design */}
                        {isLayoutB && !isNavigationHidden && <SportNavigationB />}
                        <ComboCards />
                        <div className="sport-matches">{children}</div>
                    </div>

                    {(isDesktop || isLaptop) && (
                        <div className="sport-sidebar" id="sport-sidebar" ref={betSlipRef}>
                            <Navigator routes={rightSidebarComponents} noRedirect />
                        </div>
                    )}
                </div>
                {isFeatureAvailable(FEATURE.SPORT_PHONE_NAVIGATION_C)
                    ? (isPhone || isTablet) && <SportPhoneNavigationC />
                    : (isPhone || isTablet) &&
                      (isFeatureAvailable(FEATURE.BETSLIP_V2) && isTestUser() ? (
                          <SportPhoneNavigationV2 />
                      ) : (
                          <SportPhoneNavigation />
                      ))}
            </Wrapper>
        </MainLayout>
    );
}
