import React from 'react';

const defaultValue = {
    isMarketTitleVisible: true,
    isShowSportCategoryIcon: true,
    isShowTeamIcon: false,
};

export const SportsVisualSettingsContext =
    React.createContext<{ isMarketTitleVisible: boolean; isShowSportCategoryIcon: boolean; isShowTeamIcon?: boolean }>(
        defaultValue,
    );

export const useSportsVisualSettings = () => React.useContext(SportsVisualSettingsContext);
