import React, { MouseEvent, useState } from 'react';
import { getRoute, isActiveRoute, isActiveRouteBySlug } from '../../../../../services/router';
import { PreMatchTreeChildren } from '../../../../../services/sports/types';
import SportMenuV2NavLink from '../../../menu-v-2/nav-link/SportMenuV2NavLink';
import SportMenuV2Drawer from '../../../menu-v-2/drawer/SportMenuV2Drawer';
import UiButton from '../../../../ui/button/UiButton';
import Svg from '../../../../svg/Svg';
import { stores } from '../../../../../stores';
import { TAG_MANAGER_EVENT, trackGoogleTagManagerEvent } from '../../../../../services/analytics';
import { toggleMatchOrCategoryInFavorites } from '../../../../../services/user-favorites';
import { useStore } from '../../../../../hooks/useStore';
import Wrapper from './styles';

interface Props {
    category: PreMatchTreeChildren;
}

export default function SportTreeMenuV2Item({ category }: Props) {
    const { name, icon, fullSlug, depth, slug, is_sports_wrapper, children } = category;
    const [isOpen, setIsOpen] = useState(Boolean(isActiveRouteBySlug(slug, category)));
    const [userFavorites] = useStore(stores.sports.userFavorites);
    const isFavorite = (userFavorites.categories ?? []).includes(category.id);
    const isFlag = depth === 3;
    const url = `${getRoute('sport')}/${fullSlug}`;

    function handleCategoryClick(event: MouseEvent<HTMLLinkElement>) {
        if (is_sports_wrapper) {
            event.preventDefault();
        }

        setIsOpen(true);
        stores.sports.isSportSideMenuOpen.set(false);
        trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.SPORT_MENU_ADVANCED_CLICKED);
    }

    function handleFavoriteClick(event: MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        event.stopPropagation();

        toggleMatchOrCategoryInFavorites({ category: category.id }, null);
    }

    function renderPostfix() {
        if (!depth || depth < 4) {
            return <SportMenuV2Drawer isOpen={isOpen} onDrawerClick={setIsOpen} />;
        }

        return (
            <UiButton
                size="small"
                color="ghost"
                icon={<Svg icon="star" color={isFavorite ? 'var(--sport-menu-icon-color-active)' : undefined} />}
                selected={false}
                onClick={handleFavoriteClick}
            />
        );
    }

    return (
        <Wrapper>
            <SportMenuV2NavLink
                to={url}
                icon={isFlag ? icon : `${icon}-ico`}
                label={name}
                isActive={isActiveRoute(url, true)}
                isFlag={isFlag}
                postfix={renderPostfix()}
                onClick={handleCategoryClick}
            />
            {isOpen && children && Boolean(children.length) && (
                <div className="sport-tree-submenu">
                    {children.map((category) => (
                        <SportTreeMenuV2Item key={category.id} category={category} />
                    ))}
                </div>
            )}
        </Wrapper>
    );
}
