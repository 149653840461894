import React from 'react';
import Wrapper from './styles';
import { HIGHLIGHT_SPORT } from '../../../services/virtual-sports/constants';
import { translate } from '../../../services/translate';
import { getRoute, isActiveRoute } from '../../../services/router';
import UiGroup from '../../ui/group/UiGroup';
import { isFeatureAvailable, isFeatureIPBlocked } from '../../../services/feature';
import UiButton from '../../ui/button/UiButton';
import { FEATURE } from '../../../services/types';
import SportLobbyCategoriesV2 from '../../sport/lobby-categories-v-2/SportLobbyCategoriesV2';

interface Props {
    onCloseDrawer: () => void;
}

export default function VirtualSportsSidebar({ onCloseDrawer = () => {} }: Props) {
    const isMainNavigationV2 = isFeatureAvailable(FEATURE.SPORT_MAIN_NAVIGATION_V2);

    const isNew = Date.now() < Date.UTC(2020, 6, 1);
    const linksHighlight = [
        {
            isFeatureAvailable: isFeatureAvailable(FEATURE.VS_HIGHLIGHT_GAMES_SOCCERBET),
            label: translate('Soccerbet', 'ui.vs'),
            route: `${getRoute('virtual-sports')}/${HIGHLIGHT_SPORT.SOCCERBET}`,
        },
        {
            isFeatureAvailable: isFeatureAvailable(FEATURE.VS_HIGHLIGHT_GAMES_IIHF),
            label: translate('Ice Hockey', 'ui.vs'),
            route: `${getRoute('virtual-sports')}/${HIGHLIGHT_SPORT.IIHF}`,
        },
    ];

    return (
        <Wrapper>
            <div className="virtual-sport-menu">
                {isMainNavigationV2 && <SportLobbyCategoriesV2 />}

                {isFeatureAvailable(FEATURE.VS_HIGHLIGHT_GAMES) && !isFeatureIPBlocked(FEATURE.VS_HIGHLIGHT_GAMES) && (
                    <div>
                        <h2>
                            {translate('highlight-games-products', 'ui.vs')}
                            {isNew && <span className="badge">{translate('New', 'ui.common')}</span>}
                        </h2>
                        <UiGroup vertical className="virtual-sport-sidebar" onClick={onCloseDrawer}>
                            {linksHighlight
                                .filter((link) => link.isFeatureAvailable)
                                .map(
                                    (link) =>
                                        link && (
                                            <UiButton
                                                block
                                                url={link.route}
                                                key={link.route}
                                                selected={isActiveRoute(link.route, false)}
                                            >
                                                {link.label}
                                            </UiButton>
                                        ),
                                )}
                        </UiGroup>
                    </div>
                )}
            </div>
        </Wrapper>
    );
}
