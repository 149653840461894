import { DatabaseSport, PayoutOddsFO } from '@staycool/sports-types';
import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';
import { getServiceUrl, httpGet, httpPost } from '../services/api';
import { Country } from '@staycool/location';
import { Language } from '../services/language/types';
import { logger } from '../services/logger';
import {
    ParlayCard,
    ParlayCardWithMarket,
    SportEventTypeObject,
    TeaserValidationMatchMarketResponse,
} from '../services/sports/types';
import { getUserCountry } from '../services/users/country';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import isEmpty from 'lodash/isEmpty';
import { getSportsLayout } from '../services/layout/utils';
import { SportsLayout } from '../services/layout/types';

const getUrl = (url) => getServiceUrl('sports', url);

export function fetchInPlayData(matchIds: number[]) {
    const url = getUrl(`in-play/find`);
    const filter = { matchIds };
    return httpPost<InplayMatchData[]>(url, filter);
}

export function fetchHeartbeats() {
    return httpGet<Record<string, boolean>>(getUrl('heartbeat'));
}

export async function getBetbuilderClientMarketInfo(coreMarketId: number, coreOutcomeId: number) {
    try {
        const url = getUrl('core-mapping/get-client-ids-by-core-ids');
        return await httpPost<{ clientMarketId: number; clientOutcomeId: number }>(url, {
            coreMarketId,
            coreOutcomeId,
        });
    } catch (e) {
        logger.error('SportsMicroservice', 'getBetbuilderClientMarketInfo', e);
    }
}

export async function getTeasersPayoutOdds(leagueCategoryIds: number[]) {
    try {
        return await httpPost<PayoutOddsFO>(getUrl('payout/get-payout-by-categories'), {
            categoryIds: orderBy(uniq(leagueCategoryIds)),
        });
    } catch (e) {
        return {} as PayoutOddsFO;
    }
}

type FindTeaserOutcomesResponse = Record<
    number,
    {
        teaser_outcome_id: number;
        is_open: boolean;
    }
>;
export async function findTeaserMarkets(outcomeIds: number[], teaserPoints: number) {
    return httpPost<FindTeaserOutcomesResponse>(getUrl('teasers/find-teaser-outcomes'), { outcomeIds, teaserPoints });
}

export async function fetchMatchMarketsAll(matchIds: number[], marketTypeIds?: number[]) {
    return httpPost<TeaserValidationMatchMarketResponse>(getUrl('teasers/match-market-info'), {
        matchIds,
        marketTypeIds,
    });
}

export async function getRandomCombo(
    hoursBefore: number,
    limit: number,
    sports: number[],
    tags: string[],
    oddsFrom: number,
    oddsTo: number,
) {
    const language = getStoreValue(stores.language);
    const country = getUserCountry() as Country;
    const layout = getSportsLayout();
    const params: RandomComboParams = {
        country,
        language,
        limit,
        sports: sports.join(','),
        tags: tags.join(','),
        oddsFrom,
        oddsTo,
        layout,
    };

    if (hoursBefore > 0) {
        params.hoursBefore = hoursBefore;
    }

    return await httpGet<number[]>(getUrl(`random-combo`), params);
}

export async function getParlayCards() {
    try {
        const parlayCards = await httpGet<ParlayCard[]>(getUrl('parlay-card'));
        stores.sports.parlayCards.set(parlayCards);
    } catch (e) {
        logger.error('SportsMicroservice', 'getParlayCards', e);
    }
}

export async function getParlayCard(parlayCardId: number): Promise<ParlayCardWithMarket | undefined> {
    try {
        const language = getStoreValue(stores.language);
        return await httpGet<ParlayCardWithMarket>(getUrl(`parlay-card/${parlayCardId}`), { language });
    } catch (e) {
        logger.error('SportsMicroservice', 'getParlayCard', e);
    }
}

export async function getAmericanSportCategoriesIds() {
    try {
        const sportCategoryIds = await httpGet<number[]>(
            `${getUrl('category/configuration')}/american-sports-category-ids`,
        );
        stores.sports.americanSportCategoryIds.set(sportCategoryIds);
    } catch (e) {
        logger.error('SportsMicroservice', 'getAmericanSportCategoriesIds', e);
        const existingSportCategoryIds = getStoreValue(stores.sports.americanSportCategoryIds);
        if (!existingSportCategoryIds?.length) {
            stores.sports.americanSportCategoryIds.set([]);
        }
    }
}

export async function getSportEventTypes() {
    if (!isEmpty(getStoreValue(stores.sports.sportEventTypes))) {
        return getStoreValue(stores.sports.sportEventTypes);
    }
    try {
        const sports = await httpGet<DatabaseSport[]>(getUrl('sport/event-types'));
        const result = {} as SportEventTypeObject;
        sports.forEach((s: DatabaseSport) => {
            if (!result.hasOwnProperty(s.event_type as string)) {
                result[s.event_type as string] = [];
            }
            result[s.event_type as string].push(s.id);
        });
        stores.sports.sportEventTypes.set(result);
    } catch (err) {
        logger.error('SportsMicroservice', 'getSportEventTypes', err);
    }
}

type InplayMatchData = {
    match_id: number;
    status?: string;
    current_phase?: string;
    current_server?: string;
    home_team_games?: number;
    away_team_games?: number;
    home_team_points?: string | number;
    away_team_points?: string | number;
    bo_user_id: number;
    totalErrors?: { away: number; home: number };
    totalHits?: { away: number; home: number };
    totalRuns?: { away: number; home: number };
    custom?: any;
    scoreboard?: any;
    created_at?: string;
    updated_at?: string;
};

type RandomComboParams = {
    country: Country;
    province?: string | null;
    language: Language;
    limit: number;
    sports: string;
    tags: string;
    oddsFrom: number;
    oddsTo: number;
    layout: SportsLayout;
    hoursBefore?: number;
};
