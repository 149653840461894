import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import {
    cancelBankIdAuthenticationSession,
    pollBankIdAuthenticationSession,
    startBankIdAuthenticationSession,
} from '../../../microservices/auth';
import { handleAuthenticationFailure, handleAuthenticationSuccess } from '../../../services/auth';
import {
    BankIdAuthenticationSessionResponse,
    BankIdAuthenticationSessionStatus,
    LoginMethod,
    RegistrationFlow,
} from '../../../services/auth/types';
import { isMobilePlatform } from '../../../services/geocomply/geocomply';
import { useInterval } from '../../../services/hooks';
import { logger } from '../../../services/logger';
import { getRoute, useRouter } from '../../../services/router';
import { translate } from '../../../services/translate';
import { stores } from '../../../stores';
import UiAlert from '../../ui/alert/UiAlert';
import UiButton from '../../ui/button/UiButton';
import UiDotsLoader from '../../ui/dots-loader/UiDotsLoader';
import Wrapper from './styles';

export default function AuthBankId({ onSuccess }: { onSuccess: () => void }) {
    const TRANSLATION_BY_USER_MESSAGE = {
        RFA6: translate('You cancelled the verification process', 'ui.account'),
        RFA9: translate('Please check your BankID app', 'ui.account'),
        RFA13: translate('Start your bank ID app', 'ui.account'),
        RFA15B: translate('Start your bank ID app', 'ui.account'),
        RFA17A: translate('Bank ID session timed out', 'ui.account'),
    };
    const { navigateTo } = useRouter();
    const promiseRef = useRef(Promise.resolve());
    const [isLoading, setIsLoading] = useState(false);
    const [isPolling, setIsPolling] = useState(false);
    const [status, setStatus] = useState<BankIdAuthenticationSessionStatus>();
    const [message, setMessage] = useState<string>();
    const [session, setSession] = useState<BankIdAuthenticationSessionResponse>();
    const [qrCodeCounter, setQrCodeCounter] = useState(0);
    const [isQrCodeLoaded, setIsQrCodeLoaded] = useState(false);
    const isFailed = status === BankIdAuthenticationSessionStatus.FAILED;
    const isPending = status === BankIdAuthenticationSessionStatus.PENDING;
    const shouldShowQrCode = !!session && isPending;

    useEffect(() => {
        promiseRef.current = promiseRef.current.finally(start);
        return () => {
            promiseRef.current = promiseRef.current.finally(cancel);
        };
    }, [session]);

    async function start() {
        if (session) {
            return;
        }
        setIsLoading(true);
        try {
            const response = await startBankIdAuthenticationSession();
            setStatus(response.status);
            setSession(response);
            setMessage(TRANSLATION_BY_USER_MESSAGE.RFA13);
            if (isMobilePlatform()) {
                openApp(response);
            }
        } catch (error) {
            setStatus(BankIdAuthenticationSessionStatus.FAILED);
            setMessage(translate('Technical Error', 'ui.common'));
            logger.error('AuthBankId', 'start', error);
        } finally {
            setIsLoading(false);
        }
    }

    async function cancel() {
        if (!session) {
            return;
        }
        setIsLoading(true);
        try {
            await cancelBankIdAuthenticationSession(session.id);
        } finally {
            reset();
            setIsLoading(false);
        }
    }

    const pollInterval = isPending ? 2000 : 0;
    useInterval(poll, pollInterval, [pollInterval]);

    async function poll() {
        if (!session || isPolling) {
            return;
        }
        setIsPolling(true);
        try {
            const { status, userMessage, loginData, signupData } = await pollBankIdAuthenticationSession(session.id);
            setStatus(status);
            setMessage((userMessage && TRANSLATION_BY_USER_MESSAGE[userMessage]) || '');

            if (signupData) {
                stores.registration.form.set((form) => {
                    Object.assign(form, signupData);
                    form.eVerificationMethod = RegistrationFlow.BANK_ID;
                    form.sessionId = session.id;
                });
                stores.modals.isLoginModalOpen.set(false);
                navigateTo(getRoute('registration'));
            } else if (loginData) {
                try {
                    const result = await handleAuthenticationSuccess(loginData, LoginMethod.BANK_ID);
                    if (result?.navigateTo) {
                        navigateTo(result.navigateTo);
                    }
                } catch (error: any) {
                    setMessage(error.message);
                }
            }

            if (status === BankIdAuthenticationSessionStatus.FINISHED) {
                onSuccess();
            }
        } catch (error: any) {
            if (error.code) {
                setStatus(BankIdAuthenticationSessionStatus.FAILED);
                try {
                    handleAuthenticationFailure(error, LoginMethod.BANK_ID);
                } catch (error: any) {
                    setMessage(error.message);
                }
            } else {
                logger.error('AuthBankId', 'poll', error);
            }
        } finally {
            setIsPolling(false);
        }
    }

    const qrCodeCounterInterval = shouldShowQrCode ? 2000 : 0;
    useInterval(incrementQrCodeCounter, qrCodeCounterInterval, [qrCodeCounterInterval]);

    function incrementQrCodeCounter() {
        setQrCodeCounter(qrCodeCounter + 1);
    }

    function openApp({ autoStartToken }: BankIdAuthenticationSessionResponse) {
        window.location.assign(`bankid:///?autostarttoken=${autoStartToken}&redirect=null`);
    }

    function reset() {
        setSession(undefined);
        setMessage(undefined);
        setStatus(undefined);
        setIsQrCodeLoaded(false);
        setQrCodeCounter(0);
    }

    return (
        <Wrapper>
            {isLoading && <UiDotsLoader />}
            {message && (
                <UiAlert info={!isFailed} failure={isFailed} center>
                    {message}
                </UiAlert>
            )}
            {shouldShowQrCode && (
                <>
                    <img
                        id="qr-code"
                        className={classNames('qr-code', !isQrCodeLoaded && 'loading')}
                        alt="BankID QR code"
                        src={`${session.qrCodeLink}&t=${qrCodeCounter}`}
                        onLoad={() => setIsQrCodeLoaded(true)}
                    />
                    {!isQrCodeLoaded && (
                        <div className="qr-code">
                            <UiDotsLoader />
                        </div>
                    )}
                    <UiButton color="primary" onClick={() => openApp(session)}>
                        {translate('Open BankID app', 'ui.account')}
                    </UiButton>
                </>
            )}
            {isFailed && (
                <UiButton
                    onClick={() => {
                        reset();
                        if (!session) {
                            start();
                        }
                    }}
                >
                    {translate('Retry', 'ui.common')}
                </UiButton>
            )}
        </Wrapper>
    );
}
