import { Jackpot, JackpotWinEventData, PotId } from '../types/casino-thrilltech-jackpot/types';
import { createStores } from './store/utils';

export const casinoThrilltechJackpotStore = createStores({
    availableJackpots: [] as Jackpot[],
    jackpotsDataById: {} as Record<string, Record<PotId, number>>,
    leaderboard: {},
    webSocket: null as unknown as WebSocket | null,
    webSocketUrl: '',
    currentWin: undefined as unknown as JackpotWinEventData | undefined,
});
