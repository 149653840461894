import React, { useEffect, useState } from 'react';
import { translate } from '../../../../../services/translate';
import { stores } from '../../../../../stores';
import { getActiveEligibleBonus } from '../../../../../services/sports/bonus-bets';
import ReactTooltip from 'react-tooltip';
import Svg from '../../../../svg/Svg';
import UiAnimate from '../../../../ui/animate/UiAnimate';
import { formattedAmountWithCurrency } from '../../../../../services/currency';
import { useStore } from '../../../../../hooks/useStore';
import { BonusType } from '../../../../../services/bonuses/types';

interface Props {
    maxWin: number;
    stake: number;
}

export default function SportBetslipStakeInputExtraWin({ maxWin, stake }: Props) {
    const [bonusBetSelection] = useStore(stores.sports.bonusBetsSelection);
    const [extraWin, setExtraWin] = useState(0);
    const [bonusMaxNetWin, setBonusMaxNetWin] = useState(0);

    useEffect(() => {
        const bonus = getActiveEligibleBonus();
        if (bonusBetSelection[BonusType.Multiplier] && bonus && bonus.multiplierSettings) {
            setExtraWin(
                Math.min(
                    Math.round((maxWin - stake) * bonus.multiplierSettings.multiplier * 100) / 100,
                    bonus.multiplierSettings.maxNetWin,
                ),
            );
            setBonusMaxNetWin(bonus.multiplierSettings.maxNetWin);
        } else {
            setExtraWin(0);
            setBonusMaxNetWin(0);
        }
    }, [bonusBetSelection, maxWin, stake]);

    if (!bonusBetSelection[BonusType.Multiplier] || !extraWin || !stake) {
        return null;
    }
    return (
        <UiAnimate animationIn="fadeIn" animationOut="fadeOut" isVisible={true}>
            <p className="extra-win">
                <span className="stake-input-label">
                    {translate('Extra win', 'ui.betslip')} (max: {formattedAmountWithCurrency(bonusMaxNetWin)}) →
                </span>
                <Svg icon="info" data-tip data-for="extraWinInfo" className="info-icon" />
                <span className="stake-input-value">{formattedAmountWithCurrency(extraWin)}</span>
            </p>

            <ReactTooltip id="extraWinInfo" type="light" place="top" className="tooltip">
                <span>{translate('ui.betslip.extra-win-tooltip')}</span>
            </ReactTooltip>

            <p className="total-win">
                <span className="stake-input-label">{translate('Total potential win', 'ui.betslip')} →</span>
                <span className="stake-input-value">{formattedAmountWithCurrency(maxWin + extraWin)}</span>
            </p>
        </UiAnimate>
    );
}
