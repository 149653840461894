import styled from 'styled-components';
import { lighten, darken } from 'polished';

export default styled.div`
    .virtual-sport-menu {
        position: relative;
        background: ${({ theme }) => darken(0.01, theme.box.background)};
        padding: 1rem;
        box-shadow: var(--ui-shadow) inset 1px 1px 4px -2px ${({ theme }) => lighten(0.2, theme.box.background)};
        border-radius: var(--ui-radius);
        margin-bottom: ${({ theme }) => theme.layout.gutter};
        h2 {
            position: sticky;
            top: 0;
            z-index: 2;
            font-size: 0.8rem;
            text-transform: uppercase;
            color: var(--font-color-secondary);
            background-color: var(--surface-level-1-bg);
            font-weight: normal;
            letter-spacing: 1px;
            padding: 0.5rem 1.2rem;
        }
    }

    .sports-categories-title {
        padding: 1rem;
        font-size: 0.8rem;
        text-transform: uppercase;
        color: var(--color-highlight);
        font-weight: normal;
        letter-spacing: 1px;
        margin: 0.5rem 0;
    }
    .how-it-works {
        padding-left: 1rem;
    }
    .badge {
        background-color: ${({ theme }) => theme.colors.red};
        border-radius: 100px;
        padding: 0.2rem;

        color: white;
        text-transform: uppercase;
        font-size: 0.6rem;
        letter-spacing: 1px;

        top: -5px;
        right: 0;
        position: absolute;
    }
`;
