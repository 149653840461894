import { Ticket } from '../../stores/virtual-sports/types';

export const VIRTUAL_SPORTS_PROVIDERS = {
    HIGHLIGHT_GAMES: 'highlight-games',
    BETRADAR: 'betradar',
};

export enum HIGHLIGHT_SPORT {
    SOCCERBET = 'soccerbet',
    IIHF = 'iihf',
}

export const HIGHLIGHT_SPORT_NAME_TO_ID = {
    [HIGHLIGHT_SPORT.SOCCERBET]: 1,
    [HIGHLIGHT_SPORT.IIHF]: 2,
};

export const HIGHLIGHT_SPORT_ICON = {
    [HIGHLIGHT_SPORT.SOCCERBET]: 'football',
    [HIGHLIGHT_SPORT.IIHF]: 'hockey',
};

export const VIRTUAL_SPORT_TYPE = {
    BALL_GAME: 'ball-game',
    HL: 'highlight',
};

export const HIGHLIGHT_SPORT_PLAYER_SOURCE = {
    [HIGHLIGHT_SPORT.SOCCERBET]: {
        apiurl: 'https://bintu.nanocosmos.de',
        streamid: 'ee7cb250-a1e6-4b63-b214-c38a8afd3970',
        entries: {
            h5live: {
                rtmp: {
                    url: 'rtmp://bintu-play.nanocosmos.de:1935/play',
                    streamname: 'StlqJ-gFL6V',
                },
                server: {
                    websocket: 'wss://bintu-h5live.nanocosmos.de:443/h5live/stream.mp4',
                    progressive: 'https://bintu-h5live.nanocosmos.de:443/h5live/http/stream.mp4',
                    hls: 'https://bintu-h5live.nanocosmos.de:443/h5live/http/playlist.m3u8',
                },
            },
        },
    },
    [HIGHLIGHT_SPORT.IIHF]: {
        apiurl: 'https://bintu.nanocosmos.de',
        streamid: '29a9c345-99c3-4e8b-bca8-d7af3ca5226a',
        entries: {
            h5live: {
                rtmp: {
                    url: 'rtmp://bintu-play.nanocosmos.de:1935/play',
                    streamname: 'StlqJ-S5iJl',
                },
                server: {
                    websocket: 'wss://bintu-h5live.nanocosmos.de:443/h5live/stream.mp4',
                    progressive: 'https://bintu-h5live.nanocosmos.de:443/h5live/http/stream.mp4',
                    hls: 'https://bintu-h5live.nanocosmos.de:443/h5live/http/playlist.m3u8',
                },
            },
        },
    },
};

type SportConfiguration = Record<
    HIGHLIGHT_SPORT,
    {
        endpoint: string;
        feedName?: string;
        iFrameHeight?: string;
        iFrameWidth?: string;
        videoHeight?: number;
        videoWidth?: number;
        onDataPush?: string;
        type: string;
    }
>;

export const SPORT_CONFIGURATION: SportConfiguration = {
    [HIGHLIGHT_SPORT.SOCCERBET]: {
        endpoint: HIGHLIGHT_SPORT.SOCCERBET,
        videoHeight: 612,
        videoWidth: 762,
        type: VIRTUAL_SPORT_TYPE.HL,
    },
    [HIGHLIGHT_SPORT.IIHF]: {
        endpoint: HIGHLIGHT_SPORT.IIHF,
        videoHeight: 480,
        videoWidth: 640,
        type: VIRTUAL_SPORT_TYPE.HL,
    },
};

export const BET_TYPE = {
    SINGLE: 'single',
    COMBO: 'combo',
    SYSTEM: 'system',
};
export const SYSTEM_BET_TYPE_BY_SYSTEM_KEY = {
    1: 'SINGLE',
    2: 'DOUBLE',
    3: 'TREBLE',
    4: 'FOURFOLD',
    5: 'FIVEFOLD',
    6: 'SIXFOLD',
    7: 'SEVENFOLD',
    8: 'EIGHTFOLD',
    9: 'NINEFOLD',
    10: 'TENFOLD',
};
export const INITIAL_BETSLIP_PLACING_STATE = {
    betslipErrors: {} as any,
    receipt: {} as Ticket,
    isLoading: false,
    needsConfirm: false,
    isConfirmed: false,
    isPlaceBetButtonDisabled: true,
    needConfirm: false,
};
export const INITIAL_BETSLIP_USER_STATE = {
    betType: BET_TYPE.SINGLE,
    stakeByMarketId: {} as any,
    systemStakes: {} as any,
    disableSystemIndividualUpdate: false as boolean,
};

export const PAGINATION_MODES = {
    OPEN: 'OPEN FOR BETTING',
    RESULTED: 'RESULTED WEEKS',
};

const SOCCERBET_MARKET_TYPES = [
    {
        id: 'MatchResult',
        view_type: 'simple',
        position: 1,
        group: 'winner',
        outcomes: [{ result_key: 'home' }, { result_key: 'x' }, { result_key: 'away' }],
    },
    {
        id: 'UnderOverPointTotalGoals',
        position: 2,
        view_type: 'line',
        group: 'totals',
        outcomes: [{ result_key: 'o' }, { result_key: 'u' }],
    },
    {
        id: 'BothTeamsToScore',
        position: 3,
        view_type: 'simple',
        group: 'winner',
        outcomes: [{ result_key: 'g' }, { result_key: 'ng' }],
    },
    {
        id: 'DoubleChance',
        position: 4,
        view_type: 'simple',
        group: 'winner',
        outcomes: [{ result_key: '1x' }, { result_key: '12' }, { result_key: 'x2' }],
    },
    {
        id: 'MatchResultAndUnderOverPointTotalGoals',
        position: 5,
        view_type: '1x2_line',
        group: 'totals',
        outcomes: [{ result_key: '' }],
    },
    {
        id: 'MatchResultAndBothTeamsToScore',
        position: 6,
        view_type: 'list',
        group: 'winner',
        outcomes: [{ result_key: '' }],
    },
    { id: 'AwayTeamExactGoals', position: 7, view_type: 'list', group: 'totals', outcomes: [{ result_key: '' }] },
    { id: 'HomeTeamExactGoals', position: 8, view_type: 'list', group: 'totals', outcomes: [{ result_key: '' }] },
    {
        id: 'UnderOverPointHomeTeamGoals',
        position: 9,
        view_type: 'line',
        group: 'totals',
        outcomes: [{ result_key: 'o' }, { result_key: 'u' }],
    },
    {
        id: 'UnderOverPointAwayTeamGoals',
        position: 10,
        view_type: 'line',
        group: 'totals',
        outcomes: [{ result_key: 'o' }, { result_key: 'u' }],
    },
    { id: 'ExactGoals', position: 11, view_type: 'list', group: 'totals', outcomes: [{ result_key: '' }] },
    { id: 'TotalGoalsTo', position: 12, view_type: 'from_to_line', group: 'totals', outcomes: [{ result_key: '' }] },
    { id: 'CorrectScore', position: 13, view_type: 'exact_score', group: 'totals', outcomes: [{ result_key: '' }] },
];

const IIHF_MARKET_TYPES = [
    {
        id: 'IceMatchResNT',
        view_type: 'simple',
        position: 1,
        group: 'winner',
        outcomes: [{ result_key: 'a' }, { result_key: 'x' }, { result_key: 'b' }],
    },
    {
        id: 'IceOUPTotalGoalsOT',
        position: 2,
        view_type: 'line',
        group: 'totals',
        outcomes: [{ result_key: 'over' }, { result_key: 'under' }],
    },
    {
        id: 'IceBothTeamsToScore',
        position: 3,
        view_type: 'simple',
        group: 'winner',
        outcomes: [{ result_key: 'no' }, { result_key: 'yes' }],
    },
    {
        id: 'IceMatchResOT',
        position: 4,
        view_type: 'simple',
        group: 'winner',
        outcomes: [{ result_key: 'a' }, { result_key: 'b' }],
    },
    {
        id: 'IceGameLinesNT',
        position: 5,
        view_type: 'multi_line',
        group: 'winner',
        outcomes: [{ result_key: '' }],
    },
    {
        id: 'IceTeamBExactGoalsOT',
        position: 6,
        view_type: 'list',
        group: 'totals',
        outcomes: [{ result_key: '' }],
    },
    {
        id: 'IceTeamAExactGoalsOT',
        position: 7,
        view_type: 'list',
        group: 'totals',
        outcomes: [{ result_key: '' }],
    },
    {
        id: 'IceExactGoalsOT',
        position: 8,
        view_type: 'list',
        group: 'winner',
        outcomes: [{ result_key: '' }],
    },
    {
        id: 'IceMatchResOUPTotalGoalsNT',
        position: 9,
        view_type: '1x2_line',
        group: 'winner',
        outcomes: [
            { result_key: 'a & over' },
            { result_key: 'b & under' },
            { result_key: 'a & over' },
            { result_key: 'b & under' },
            { result_key: 'x & over' },
            { result_key: 'x & under' },
        ],
    },
    {
        id: 'IceMatchResOUPTotalGoalsOT',
        position: 10,
        view_type: '1x2_line',
        group: 'winner',
        outcomes: [
            { result_key: 'a & over' },
            { result_key: 'b & under' },
            { result_key: 'a & over' },
            { result_key: 'b & under' },
        ],
    },
    {
        id: 'IceTeamToWinMostPeriod',
        position: 11,
        view_type: '1x2_line',
        group: 'winner',
        outcomes: [{ result_key: 'a' }, { result_key: 'b' }, { result_key: 'x' }],
    },
    {
        id: 'IceFirstTeamToScore',
        position: 12,
        view_type: 'simple',
        group: 'winner',
        outcomes: [{ result_key: 'a' }, { result_key: 'b' }],
    },
    {
        id: 'IceHighestScorePeriodNT',
        position: 13,
        view_type: 'simple',
        group: 'winner',
        outcomes: [{ result_key: '1' }, { result_key: '2' }, { result_key: '3' }, { result_key: 'x' }],
    },
    {
        id: 'IceOUGoalsTeamATeamBOT',
        position: 14,
        view_type: '1x2_line',
        group: 'winner',
        outcomes: [
            { result_key: 'a over' },
            { result_key: 'a under' },
            { result_key: 'b under' },
            { result_key: 'b under' },
        ],
    },
    {
        id: 'IceCorrectScoreOT',
        position: 15,
        view_type: 'exact_score',
        group: 'totals',
        outcomes: [{ result_key: '' }],
    },
];

export const HIGHLIGHT_MARKET_TYPES_BY_SPORT_KIND = {
    [HIGHLIGHT_SPORT.SOCCERBET]: SOCCERBET_MARKET_TYPES,
    [HIGHLIGHT_SPORT.IIHF]: IIHF_MARKET_TYPES,
};

export const MS_TO_BETTING_ENDED = 10_000;
export const MS_TO_MATCH_ENDED = -60_000;
