import React, { useEffect } from 'react';
import Wrapper from './styles';
import Svg from '../../svg/Svg';
import { stores } from '../../../stores';
import { useComponentFocused } from '../../../services/hooks';
import UiButton from '../../ui/button/UiButton';
import { useStore } from '../../../hooks/useStore';
import CrmDropdown from '../dropdown/CrmDropdown';
import { ONSITE_MESSAGE_TAB } from '../../../microservices/crm/types';
import { getRoute, isActiveRoute } from '../../../services/router';

export default function CrmInboxIcon() {
    const [messagesByType] = useStore(stores.crm.messagesByType);
    const [isInboxOpen, setIsInboxOpen] = useStore(stores.crm.isInboxOpen);
    const [inboxRef, isFocused] = useComponentFocused(false);

    const allMessages = messagesByType[ONSITE_MESSAGE_TAB.ALL];
    const url = getRoute('inbox');
    const inboxConfig = allMessages.messages.length
        ? { onClick: () => isFocused && setIsInboxOpen(!isInboxOpen) }
        : { url };

    useEffect(() => {
        if (!isFocused) {
            setIsInboxOpen(false);
        }
    }, [isFocused]);

    return (
        <Wrapper>
            <UiButton
                size="small"
                className="header-button"
                badge={allMessages.unreadMessagesTotal}
                icon={<Svg icon="envelope-ico" className="icon" size={1.125} />}
                selected={isActiveRoute(url, false)}
                {...inboxConfig}
            />
            {isInboxOpen && (
                <div ref={inboxRef}>
                    <CrmDropdown />
                </div>
            )}
        </Wrapper>
    );
}
