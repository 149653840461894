import remove from 'lodash/remove';
import React, { useEffect, useState } from 'react';
import { withErrorBoundary } from '../../../higher-order-components/error-boundary';
import { getRoute } from '../../../services/router';
import { validateBetslip, resetBetslip } from '../../../services/virtual-sports/betslip';
import { BET_TYPE, INITIAL_BETSLIP_USER_STATE } from '../../../services/virtual-sports/constants';
import { translate } from '../../../services/translate';
import { stores } from '../../../stores';
import Wrapper from './styles';
import UiBox from '../../ui/box/UiBox';
import UiButton from '../../ui/button/UiButton';
import UiNavLink from '../../ui/nav-link/UiNavLink';
import VirtualSportsBetslipButtonAndErrors from './button-and-errors/VirtualSportsBetslipButtonAndErrors';
import VirtualSportsBetSlipHeader from './header/VirtualSportsBetslipHeader';
import VirtualSportsBetslipMarket from './market/VirtualSportsBetslipMarket';
import VirtualSportsBetslipMode from './mode/VirtualSportsBetslipMode';
import VirtualSportsBetslipReceipt from './receipt/VirtualSportsBetslipReceipt';
import VirtualSportsBetslipStakeInput from './stake-input/VirtualSportsBetslipStakeInput';
import VirtualSportsBetslipSystemStakeInput from './system-stake-input/VirtualSportsBetslipSystemStakeInput';
import VirtualSportsTicketList from '../ticket/list/VirtualSportsTicketList';
import { isFeatureAvailable } from '../../../services/feature';
import cloneDeep from 'lodash/cloneDeep';
import FourOFour from '../../four-o-four/FourOFour';
import isEmpty from 'lodash/isEmpty';
import UiAnimate from '../../ui/animate/UiAnimate';
import { formattedAmountWithCurrency } from '../../../services/currency';
import { virtualSports } from '../../../stores/virtual-sports/virtual-sports';
import { BETSLIP_MODE } from '../../../stores/virtual-sports/types';
import { media } from '../../../stores/media/media';
import { useStore } from '../../../hooks/useStore';
import { FEATURE } from '../../../services/types';
import SportBetslipUiEmpty from '../../sport/betslip/ui/empty/SportBetslipUiEmpty';

function VirtualSportsBetslip() {
    const [userState, setUserState] = useStore(virtualSports.betslipUserState);
    const [betSlipPlacingState, setBetSlipPlacingState] = useStore(virtualSports.betslipPlacingState);
    const [betSlipMarketIdToOutcomeId] = useStore(virtualSports.betslipMarketIdToOutcomeId);
    const [betSlipMarketIds, setBetSlipMarketIds] = useState<string[]>([]); // for persisting the order of markets
    const [betSlipMode, setBetSlipMode] = useStore(virtualSports.betslipMode);
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [wallet] = useStore(stores.wallet);
    const numberOfBets = Object.values(betSlipMarketIdToOutcomeId).length;
    const { betType, stakeByMarketId } = userState;
    const { receipt } = betSlipPlacingState;
    const [{ isPhone, isTablet }] = useStore(media);
    const [gameWeekInfo] = useStore(virtualSports.gameWeekInfo);

    useEffect(() => {
        const marketIds = Object.keys(betSlipMarketIdToOutcomeId);
        const addedMarkets = marketIds.filter((marketId) => !betSlipMarketIds.includes(marketId));
        betSlipMarketIds.push(...addedMarkets);
        const removedMarkets = remove(betSlipMarketIds, (marketId) => !marketIds.includes(marketId));
        if (addedMarkets.concat(removedMarkets).length) {
            setBetSlipMarketIds(betSlipMarketIds);
            setBetSlipPlacingState(() => ({ receipt: null, betslipErrors: {} }));
            setBetSlipMode(BETSLIP_MODE.BETSLIP);
        }
        if (addedMarkets.length && betSlipMarketIds.length === 2) {
            setUserState((userState) => ({ ...userState, betType: BET_TYPE.COMBO }));
        } else if (removedMarkets.length) {
            const newBetType = { 1: BET_TYPE.SINGLE, 2: BET_TYPE.COMBO }[betSlipMarketIds.length];
            if (newBetType && newBetType !== betType) {
                setUserState((userState) => ({ ...userState, betType: newBetType }));
            }
        }
        const { isValid, betslipErrors } = validateBetslip();
        setBetSlipPlacingState((state) => ({ ...state, betslipErrors, isPlaceBetButtonDisabled: !isValid }));
    }, [betSlipMarketIdToOutcomeId]);

    useEffect(() => {
        if (!isFeatureAvailable(FEATURE.VS_HIGHLIGHT_GAMES)) {
            resetBetslip();
            return;
        }
        if (!isAuthenticated) {
            setUserState(cloneDeep(INITIAL_BETSLIP_USER_STATE));
        }

        if (!numberOfBets) {
            if (!isAuthenticated) {
                setBetSlipMode(BETSLIP_MODE.BETSLIP);
            } else {
                setBetSlipMode(BETSLIP_MODE.HISTORY);
            }
        }
    }, [isAuthenticated]);

    useEffect(() => {
        const { isValid, betslipErrors } = validateBetslip();
        setBetSlipPlacingState((state) => ({ ...state, betslipErrors, isPlaceBetButtonDisabled: !isValid }));
    }, [gameWeekInfo]);

    const marketIds = betSlipMarketIds;

    if ((isPhone || isTablet) && !isFeatureAvailable(FEATURE.VS_HIGHLIGHT_GAMES)) {
        const message = translate('Highlight Games Virtual Sports is not available in your country', 'ui.sportsbook');
        return <FourOFour translatedCustomMessage={message} />;
    }

    function getTotalSinglesStake() {
        const allSinglesStakes = Object.keys(betSlipMarketIdToOutcomeId).map((marketId) => {
            const stake = stakeByMarketId[marketId];
            return stake;
        });

        const totalStake = allSinglesStakes.reduce((acc: number, value) => acc + parseFloat(value || 0), 0);

        return totalStake.toFixed(2);
    }

    return (
        <Wrapper className="sports-betslip">
            {!isPhone && (
                <VirtualSportsBetslipMode
                    betslipMode={betSlipMode}
                    onBetslipModeChanged={setBetSlipMode}
                    numberOfBets={numberOfBets}
                />
            )}
            {isEmpty(betSlipMarketIdToOutcomeId) && isEmpty(receipt) && <SportBetslipUiEmpty />}
            {(isPhone || isTablet) && isAuthenticated && wallet && (
                <div className="balance">
                    <div className="balance-label">{translate('Balance', 'ui.common')} </div>

                    <div className="balance-amount">{formattedAmountWithCurrency(wallet.balance_uc)}</div>
                </div>
            )}

            {(!isEmpty(receipt) || !isEmpty(betSlipMarketIdToOutcomeId)) && betSlipMode === BETSLIP_MODE.BETSLIP && (
                <UiBox isCompactView className="sports-betslip-container animated fadeIn">
                    {isEmpty(receipt) && <VirtualSportsBetSlipHeader />}

                    {receipt && <VirtualSportsBetslipReceipt />}

                    {!receipt &&
                        marketIds.map((marketId) => (
                            <UiAnimate animationIn="flipInX" animationOut="fadeOut" isVisible={true} key={marketId}>
                                <VirtualSportsBetslipMarket marketId={marketId} />
                            </UiAnimate>
                        ))}

                    {!receipt && marketIds.length > 0 && (
                        <div>
                            {betType === BET_TYPE.COMBO && <VirtualSportsBetslipStakeInput marketId={null} />}
                            {betType === BET_TYPE.SYSTEM && <VirtualSportsBetslipSystemStakeInput />}
                            {betType === BET_TYPE.SINGLE && Object.keys(betSlipMarketIdToOutcomeId).length > 1 && (
                                <div className="total-stake-singles">
                                    {translate('Total stakes', 'ui.sportsbook')} → <span>{getTotalSinglesStake()}</span>
                                </div>
                            )}
                            <VirtualSportsBetslipButtonAndErrors />
                        </div>
                    )}
                </UiBox>
            )}

            {betSlipMode === BETSLIP_MODE.HISTORY && (
                <>
                    <UiNavLink to={getRoute('bet-history.virtual-sports')} className="show-all-tickets">
                        <UiButton block>{translate('Show All Tickets', 'ui.sportsbook')}</UiButton>
                    </UiNavLink>
                    <div className="ticket-list">
                        <VirtualSportsTicketList isCompactView />
                    </div>
                </>
            )}
        </Wrapper>
    );
}

export default withErrorBoundary(React.memo(VirtualSportsBetslip));
