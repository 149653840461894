import { FoComboCardWithOdds } from '@staycool/sports-types/fo-combo-card';
import classNames from 'classnames';
import React from 'react';
import { useClearAllModal } from '../../../../hooks/betslip-v2';
import { useStore } from '../../../../hooks/useStore';
import { getMarketInfoByOutcomeId } from '../../../../microservices/sbgate';
import { calculateAndFormatBoostedOdds, convertOdds } from '../../../../services/odds-format';
import { clearBetslipSelection } from '../../../../services/sports/betslip';
import { removeSelectionFromBetslip } from '../../../../services/sports/combo-cards';
import { initialBetSlipPlacingState } from '../../../../services/sports/constants';
import { BET_TYPE, BetslipMode } from '../../../../services/sports/types';
import { stores } from '../../../../stores';
import UiButton from '../../../ui/button/UiButton';
import Wrapper from './styles';
import { useOddsByOutcomeIds } from '../../../../services/sports/hooks';

interface Props {
    card: FoComboCardWithOdds;
}

export default function ComboCardsItemFooter({ card }: Props) {
    const [cardsInBetslip, setCardsInBetslip] = useStore(stores.sports.comboCard.cardsInBetslip);
    const [betSlipMarketIdToOutcomeId, setBetSlipMarketIdToOutcomeId] = useStore(
        stores.sports.betSlipMarketIdToOutcomeId,
    );
    const [oddsByOutcomeId] = useOddsByOutcomeIds(
        card.matches.flatMap(({ markets }) => markets.map(({ outcome_id }) => outcome_id)),
    );
    const [, setBetSlipUserState] = useStore(stores.sports.betSlipUserState);
    const [, setBetSlipPlacingState] = useStore(stores.sports.betSlipPlacingState);
    const [{ receiptById }] = useStore(stores.sports.betSlipPlacingState);
    const cardIdsWithReceipt = Object.keys(receiptById);
    const cardAlreadyInBetslip = Boolean(
        cardsInBetslip.find(({ id }) => id === card.id && !cardIdsWithReceipt.includes(card.id.toString())),
    );

    const clearAllModal = useClearAllModal();

    function clearPreviousSelections() {
        if (Object.values(betSlipMarketIdToOutcomeId).length > 0 && !cardAlreadyInBetslip) {
            clearAllModal.show(handleComboCardSelection);
        } else {
            handleComboCardSelection();
        }
    }

    async function handleComboCardSelection() {
        if (!cardsInBetslip.length) {
            clearBetslipSelection(BetslipMode.Betslip, false, true);
        }

        setBetSlipPlacingState(initialBetSlipPlacingState);
        if (!cardAlreadyInBetslip) {
            setBetSlipUserState((prev) => ({
                ...prev,
                userBetTypeSelection: BET_TYPE.COMBO_CARD,
                betType: BET_TYPE.COMBO_CARD,
            }));
            setCardsInBetslip([card]);

            const markets = card.matches.flatMap(({ markets }) =>
                markets.map(({ id, outcome_id }) => ({ [id]: outcome_id })),
            );
            const marketIdToOutcomeId = Object.assign({}, ...markets);
            const outcomeIds = Object.values(marketIdToOutcomeId);
            setBetSlipMarketIdToOutcomeId(marketIdToOutcomeId);
            await Promise.all(outcomeIds.map(getMarketInfoByOutcomeId));
        } else {
            removeSelectionFromBetslip(card.id);
        }
    }

    function displayOdds(card) {
        const { original_odds } = card;
        const boostedOdds = calculateAndFormatBoostedOdds(card, oddsByOutcomeId);
        const displayOriginalOdds = boostedOdds > original_odds;

        return displayOriginalOdds ? (
            <>
                <span className="original-odds">{convertOdds(original_odds)}</span>
                <span className="arrow">{' » '}</span>
                <span className="boosted-odds">{convertOdds(boostedOdds)}</span>
            </>
        ) : (
            <span className="boosted-odds">{convertOdds(boostedOdds)}</span>
        );
    }

    return (
        <Wrapper>
            <UiButton
                className={classNames('selection-btn', { active: cardAlreadyInBetslip })}
                color="primary"
                size="small"
                block
                onClick={clearPreviousSelections}
            >
                {displayOdds(card)}
            </UiButton>
        </Wrapper>
    );
}
