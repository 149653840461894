import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useStore } from '../../../../hooks/useStore';
import { sendTwoFactorEmailPin, verifyTwoFactorPin } from '../../../../microservices/auth';
import { isDefaultEmailRegistrationCountry, startLoginTwoFactorAuth } from '../../../../services/auth';
import { getRoute, useRouter } from '../../../../services/router';
import { translate } from '../../../../services/translate';
import { finishLoginWithToken, getErrorMessage } from '../../../../services/two-factor';
import { stores } from '../../../../stores';
import Ui2FormTextInput from '../../../ui-2/form/text-input/Ui2FormTextInput';
import Ui2Form from '../../../ui-2/form/Ui2Form';
import UiAlert from '../../../ui/alert/UiAlert';
import UiButton from '../../../ui/button/UiButton';
import UiCountdown from '../../../ui/countdown/UiCountdown';
import UiGroup from '../../../ui/group/UiGroup';
import Wrapper from '../../verification/styles';

interface Props {
    onSuccess?: () => void;
}

interface FormValues {
    pin: string;
}

export default function AuthTwoFactorEmail({ onSuccess }: Props) {
    const { navigateTo } = useRouter();
    if (!onSuccess) {
        onSuccess = () => {
            navigateTo(getRoute('sport.recommendations'));
        };
    }

    const [error, setError] = useState('');
    const [isVerifying, setIsVerifying] = useState(false);
    const [isResending, setIsResending] = useState(false);
    const [isEmailSent, setIsEmailSent] = useState(true);

    const [isSendCodeToSmsButtonVisible, setIsSendCodeToSmsButtonVisible] = useState(false);
    const [email] = useStore(stores.twoFactor.email);
    const [country] = useStore(stores.twoFactor.country);
    const [reason] = useStore(stores.twoFactor.reason);
    const [smsResendFailedReason] = useStore(stores.twoFactor.smsResendFailedReason);
    const useFormMethods = useForm<FormValues>({
        defaultValues: {
            pin: '',
        },
    });
    const [timeoutEndTime, setTimeoutEndTime] = useState<number>();
    const [isSuccess, setIsSuccess] = useState(true);

    async function onSubmit({ pin }: FormValues) {
        setError('');
        setIsSuccess(false);
        setIsVerifying(true);
        try {
            const { token } = await verifyTwoFactorPin({ pin, email });
            finishLoginWithToken(token);
            setIsVerifying(false);
            stores.twoFactor.isTwoFactorEmailActive.set(false);
            stores.modals.isLoginModalOpen.set(false);
            onSuccess && onSuccess();
        } catch (error: any) {
            error.message = error.code;
            setError(getErrorMessage(error));
            setIsVerifying(false);
        }
    }

    async function tryResendTwoFactorEmailPin() {
        setIsResending(true);
        setError('');
        setIsSuccess(false);

        try {
            await sendTwoFactorEmailPin(email);
            setIsEmailSent(true);
            setIsSuccess(true);
        } catch (error: any) {
            setIsEmailSent(false);
            error.message = error.code;
            setError(getErrorMessage(error));
        }

        useFormMethods.reset();
        setIsResending(false);
        setIsSendCodeToSmsButtonVisible(true);
        addTimeoutForSubmit();
    }

    async function startSmsVerification() {
        setIsResending(true);
        setError('');
        setIsSuccess(false);
        try {
            email && (await startLoginTwoFactorAuth(email));
            setIsResending(false);
            setIsSuccess(true);
        } catch (error) {
            setError(getErrorMessage(error));
            setIsResending(false);
        }
        addTimeoutForSubmit();
    }

    function addTimeoutForSubmit() {
        const resendTimeoutInSeconds = 30;
        setTimeoutEndTime(new Date().setSeconds(new Date().getSeconds() + resendTimeoutInSeconds));
        setTimeout(() => {
            setTimeoutEndTime(undefined);
        }, resendTimeoutInSeconds * 1000);
    }

    return (
        <Wrapper vertical layoutGutterInRem="0.5rem">
            {smsResendFailedReason && <UiAlert warning>{translate(smsResendFailedReason, 'ui.registration')}</UiAlert>}

            {isEmailSent && isSuccess && (
                <UiAlert success>
                    <strong>{translate('Verification Email sent', 'ui.common')}</strong>{' '}
                    {reason && translate(reason, 'ui.registration')}
                </UiAlert>
            )}

            {error && <UiAlert failure>{error}</UiAlert>}

            <Ui2Form onSubmit={onSubmit} useFormMethods={useFormMethods}>
                <UiGroup vertical layoutGutterInRem="0.5rem">
                    <Ui2FormTextInput
                        name="pin"
                        maxLength={6}
                        error={useFormMethods.formState.errors.pin}
                        type="number"
                        placeholder={translate('Enter PIN here', 'ui.gdpr')}
                    />
                    <UiButton type="button" color="primary" block isFormSubmitButton isLoading={isVerifying}>
                        {translate('Verify & Login', 'ui.registration')}
                    </UiButton>
                </UiGroup>
            </Ui2Form>

            {isEmailSent && (
                <UiButton
                    block
                    onClick={tryResendTwoFactorEmailPin}
                    isLoading={isResending}
                    disabled={Boolean(timeoutEndTime)}
                >
                    {translate('Resend Email', 'ui.common')} {}
                    {timeoutEndTime && (
                        <UiCountdown endDate={timeoutEndTime} className="submit-timer">
                            {({ seconds }) => <>({seconds})</>}
                        </UiCountdown>
                    )}
                </UiButton>
            )}

            {isSendCodeToSmsButtonVisible && isDefaultEmailRegistrationCountry(country) && (
                <UiButton
                    block
                    onClick={startSmsVerification}
                    isLoading={isResending}
                    disabled={Boolean(timeoutEndTime)}
                >
                    {translate('Send SMS', 'ui.common')} {}
                    {timeoutEndTime && (
                        <UiCountdown endDate={timeoutEndTime} className="submit-timer">
                            {({ seconds }) => <>({seconds})</>}
                        </UiCountdown>
                    )}
                </UiButton>
            )}
        </Wrapper>
    );
}
