import { parse } from 'query-string';
import { useContext, useState } from 'react';
import { __RouterContext } from 'react-router';
import { matchPath } from 'react-router-dom';
import cmsArticleRoutes from '../cms-article-routes.json';
import routes from '../routes.json';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import { PRODUCT } from '../types/common';
import { isFeatureAvailable } from './feature';
import { storageGet, storageSet } from './storage';
import { FEATURE } from './types';
import { isSIM } from './environment';

export function useRouter() {
    const router = useContext<Router>(__RouterContext);

    return {
        location: router.location,
        history: router.history,
        navigateTo: router.history.push,
        params: (router.match && router.match.params) || {},
        queryParams: parse(router.history.location.search),
        goBack: router.history.goBack,
        pathname: router.location.pathname,
        fullUrl: window.location.href,
    };
}

export function getArticlePath(articleSlug) {
    return `${getRoute('cms')}/${articleSlug}`;
}

export function getArticleRoute(articleRouteKey: string, baseRouteKey?: string) {
    if (!cmsArticleRoutes[articleRouteKey]) {
        throw new Error(`Article route ${articleRouteKey} not found in cms-article-routes.json`);
    }

    const translatedArticleRoute = translateArticleKey(articleRouteKey);
    return baseRouteKey ? `${getRoute(baseRouteKey)}/${translatedArticleRoute}` : withLanguage(translatedArticleRoute);
}

export function getRoute(key: string, language?: string) {
    if (!routes[key]) {
        throw new Error(`Route ${key} not found in routes.json`);
    }

    const routeParts = key.split('.');

    let translatedRoute = translateKey(routeParts[0], language);
    let currentTranslation = routeParts[0];
    routeParts.shift();

    routeParts.forEach((part) => {
        currentTranslation += `.${part}`;
        translatedRoute += `/${translateKey(currentTranslation, language)}`;
    });
    return withLanguage(translatedRoute, language);
}

export function translateArticleKey(articleKey) {
    const language = getStoreValue(stores.language);
    return cmsArticleRoutes[articleKey][language];
}

function translateKey(key, languageOverride) {
    const language = languageOverride || getStoreValue(stores.language);
    return routes[key][language];
}

function withLanguage(translatedRoute: string, languageOverride?: string) {
    const language = languageOverride || getStoreValue(stores.language);
    return `/${language}/${translatedRoute}`;
}

export function isActiveRoute(route, exact = true) {
    const path = `${window.coolb2b?.basePath || ''}${route}`;
    return Boolean(
        matchPath(window.location.pathname, {
            path,
            exact,
        }),
    );
}

export function isActiveRouteBySlug(slug, child?) {
    if (slug === 'winter-sports' && child) {
        return child.children.find((child) => isActiveRouteBySlug(child.slug));
    }
    return window.location && window.location.pathname && window.location.pathname.split('/').includes(slug);
}

export function useRoutes(allRoutes) {
    const [routes] = useState(allRoutes.filter(Boolean));

    return routes;
}

export function setLastActiveProduct(product) {
    storageSet('lastActiveProduct', product);
}

export function getLastActiveProductRoute(language?: string) {
    const lastActiveProduct = storageGet<string>('lastActiveProduct');

    const routeByProduct = {
        [PRODUCT.CASINO]: isSIM() ? 'sim.games' : 'casino',
        [PRODUCT.DEPOSIT]: 'deposit',
        [PRODUCT.MOBILE_LANDING]: 'mobile-landing',
        [PRODUCT.POKER]: 'poker',
        [PRODUCT.SPORTSBOOK]: 'sport',
        [PRODUCT.VIRTUAL_SPORTS]: 'virtual-sports',
    };

    const route =
        routeByProduct[lastActiveProduct] || (isFeatureAvailable(FEATURE.WELCOME_PAGE_ROUTE) ? 'welcome' : 'sport');
    return getRoute(route, language);
}

export function getHomeRoute() {
    return getLastActiveProductRoute();
}

type Router = {
    history: RouterHistory;
    location: RouterLocation;
    match: {
        path: string;
        url: string;
        params: Record<string, any>;
        isExact: boolean;
    };
    staticContext?: unknown;
};
type RouterLocation = {
    pathname: string;
    search: string;
    hash: string;
    state: null;
    key: string;
};

type RouterHistory = {
    action: string;
    block: (prompt) => void;
    createHref: (location) => void;
    go: (n) => void;
    goBack: () => void;
    goForward: () => void;
    length: number;
    listen: (callback: (location: Location, action: Action) => void) => UnlistenFunction;
    location: RouterLocation;
    push: (path: string, state?: string) => void;
    replace: (path: string, state?: string) => void;
};

type UnlistenFunction = () => void;

enum Action {
    Pop = 'POP',
    Push = 'PUSH',
    Replace = 'REPLACE',
}
