import UiGroup from '../../../../ui/group/UiGroup';
import { translate } from '../../../../../services/translate';
import React from 'react';
import { getActiveCurrency } from '../../../../../services/currency';
import UiButton from '../../../../ui/button/UiButton';
import { useForm } from 'react-hook-form';
import Ui2FormTextInput from '../../../../ui-2/form/text-input/Ui2FormTextInput';
import Ui2FormCheckbox from '../../../../ui-2/form/checkbox/Ui2FormCheckbox';
import Ui2Form from '../../../../ui-2/form/Ui2Form';

interface Props {
    formFields: {
        fieldId: number;
        label: string;
    }[];
    isLoading: boolean;
    handleOnSubmit: (values: Record<number, string>) => void;
}

export default function AuthProfileValidatorSourceOfWealthForm({ formFields, isLoading, handleOnSubmit }: Props) {
    const form = useForm<Record<number, string> & { hasUserConfirmedFormValidity: boolean }>({
        defaultValues: {
            hasUserConfirmedFormValidity: false,
        },
    });
    const currency = getActiveCurrency();

    function isMonetaryField(fieldId) {
        const MONTHLY_GAMBLING_SPENDING_INTENTIONS_FIELD_ID = 4;
        const MONTHLY_NET_INCOME_FIELD_ID = 3;
        return [MONTHLY_GAMBLING_SPENDING_INTENTIONS_FIELD_ID, MONTHLY_NET_INCOME_FIELD_ID].includes(fieldId);
    }

    return (
        <Ui2Form useFormMethods={form} onSubmit={handleOnSubmit}>
            {formFields.map(({ fieldId, label }) => {
                const formattedFieldId = fieldId.toString();
                const isCurrency = isMonetaryField(fieldId);

                return (
                    <UiGroup key={formattedFieldId}>
                        <Ui2FormTextInput
                            name={formattedFieldId}
                            type={isCurrency ? 'number' : 'text'}
                            label={translate(label, 'ui.account')}
                            className="sow-field"
                            maxLength={120}
                            required
                            endAdornment={isCurrency && <span className="currency-badge">{currency}</span>}
                            error={form.formState.errors[formattedFieldId]}
                        />
                    </UiGroup>
                );
            })}
            <Ui2FormCheckbox
                name="hasUserConfirmedFormValidity"
                label={translate('I confirm that the above information is correct and true', 'ui.account')}
                required
                error={form.formState.errors.hasUserConfirmedFormValidity}
            />
            <UiButton type="button" color="primary" isFormSubmitButton isLoading={isLoading}>
                {translate('Submit', 'ui.account')}
            </UiButton>
        </Ui2Form>
    );
}
