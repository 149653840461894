import { PeruProvince } from '@staycool/location';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { updateProfile } from '../../../../microservices/users';
import { getFormattedLicenceName } from '../../../../services/licence';
import { logger } from '../../../../services/logger';
import { translate } from '../../../../services/translate';
import { loadProfile } from '../../../../services/user';
import { stores } from '../../../../stores';
import Snippet from '../../../snippet/Snippet';
import UiButton from '../../../ui/button/UiButton';
import Wrapper from './styles';
import { useStore } from '../../../../hooks/useStore';
import Ui2Form from '../../../ui-2/form/Ui2Form';
import Ui2FormTextInput from '../../../ui-2/form/text-input/Ui2FormTextInput';
import Ui2FormSelect from '../../../ui-2/form/select/Ui2FormSelect';
import isEmpty from 'lodash/isEmpty';
import UiAlert from '../../../ui/alert/UiAlert';

interface Props {
    isModal?: boolean;
    onAfterSubmit: () => void;
    onExit?: () => void;
}

export default function AuthProfileValidatorRequiredFields({
    isModal = false,
    onAfterSubmit = () => {},
    onExit = () => {},
}: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const industryIdsWithoutJobTitle = [1002];
    const industryIdsWithPreviousJobTitle = [1001, 1003];
    const [user] = useStore(stores.user);
    const countries = user?.countries || [];
    const industries = user?.industries || [];

    const useFormMethods = useForm({
        defaultValues: {
            ...user?.requiredFields,
            fullName: `${user?.firstName} ${user?.lastName}`,
        },
    });
    const selectedIndustry = useFormMethods.watch('industryId');
    const isJobTitleDisplayed =
        selectedIndustry !== undefined && !industryIdsWithoutJobTitle.includes(Number(selectedIndustry));
    const jobTitleLabel = industryIdsWithPreviousJobTitle.includes(Number(useFormMethods.getValues().industryId))
        ? translate('Previous job title', 'ui.account')
        : translate('Job title', 'ui.account');

    useEffect(() => {
        if (isEmpty(user?.requiredFields)) {
            return onExit();
        }

        if (isModal) {
            stores.modals.isRequiredProfileInformationInquiryModalOpen.set(true);
        }
    }, [user?.requiredFields]);

    async function save() {
        setIsLoading(true);

        try {
            const { birthPlace, industryId, jobTitle, nationality, province, employerName, sourceOfFunds } =
                useFormMethods.getValues();
            await updateProfile({
                ...(industryId && {
                    industryId,
                    ...(jobTitle && { jobTitle }),
                }),
                ...(birthPlace && { birthPlace }),
                ...(nationality && { nationality }),
                ...(province && { province }),
                ...(employerName && { employerName }),
                ...(sourceOfFunds && { sourceOfFunds }),
            });
            await loadProfile();
            onAfterSubmit();
        } catch (error) {
            logger.error('AuthProfileValidatorRequiredFields', 'save', error);
            setError(translate('Failed to update fields', 'ui.common'));
        }

        setIsLoading(false);
    }

    if (!user?.requiredFields || isEmpty(user.requiredFields)) {
        return null;
    }

    return (
        <Wrapper>
            <Ui2Form onSubmit={save} useFormMethods={useFormMethods}>
                {user?.isProfileReviewRequired ? (
                    <>
                        <Snippet snippetKey={`auth.${getFormattedLicenceName()}-occupation-review-header`} />
                        <Ui2FormTextInput
                            name="fullName"
                            dataTest="user-fullname"
                            disabled
                            label={translate('Full name (please contact support to change)', 'ui.account')}
                        />
                    </>
                ) : (
                    <Snippet snippetKey={`auth.${getFormattedLicenceName()}-occupation-header`} />
                )}
                {'industryId' in user.requiredFields && (
                    <Ui2FormSelect
                        name="industryId"
                        data-test="industry"
                        label={translate('Industry', 'ui.account')}
                        options={industries.map(({ id, title }) => ({
                            label: translate(title, 'ui.account'),
                            value: id,
                        }))}
                        required
                        error={useFormMethods.formState.errors.industryId}
                    />
                )}
                {isJobTitleDisplayed && 'jobTitle' in user.requiredFields && (
                    <Ui2FormTextInput
                        name="jobTitle"
                        dataTest="job-title"
                        minLength={3}
                        label={jobTitleLabel}
                        required
                        error={useFormMethods.formState.errors.jobTitle}
                    />
                )}
                {'nationality' in user.requiredFields && (
                    <Ui2FormSelect
                        name="nationality"
                        data-test="nationality"
                        label={translate('Nationality', 'ui.account')}
                        options={countries.map(({ iso2, title }) => ({
                            label: title,
                            value: iso2,
                        }))}
                        required
                        error={useFormMethods.formState.errors.nationality}
                    />
                )}
                {'birthPlace' in user.requiredFields && (
                    <Ui2FormSelect
                        name="birthPlace"
                        data-test="birthPlace"
                        label={translate('Birth place', 'ui.account')}
                        options={countries.map(({ iso2, title }) => ({
                            label: title,
                            value: iso2,
                        }))}
                        required
                        error={useFormMethods.formState.errors.birthPlace}
                    />
                )}
                {'province' in user.requiredFields && (
                    <Ui2FormSelect
                        name="province"
                        data-test="province"
                        label={translate('Province', 'ui.account')}
                        placeholder={translate('Province', 'ui.account')}
                        options={Object.values(PeruProvince).map((province) => ({
                            label: province,
                            value: province,
                        }))}
                        required
                        error={useFormMethods.formState.errors.province}
                    />
                )}
                {'employerName' in user.requiredFields && (
                    <Ui2FormTextInput
                        name="employerName"
                        data-test="employer-name"
                        label={translate('Employer name', 'ui.account')}
                        placeholder={translate('Employer name', 'ui.account')}
                        minLength={3}
                        required
                        error={useFormMethods.formState.errors.employerName}
                    />
                )}
                {'sourceOfFunds' in user.requiredFields && (
                    <Ui2FormTextInput
                        name="sourceOfFunds"
                        data-test="Source-of-funds"
                        label={translate('Source of funds', 'ui.account')}
                        placeholder={translate('Source of funds', 'ui.account')}
                        minLength={3}
                        required
                        error={useFormMethods.formState.errors.sourceOfFunds}
                    />
                )}
                <UiButton
                    type="button"
                    data-test="set-required-fields"
                    isFormSubmitButton
                    block
                    color="primary"
                    isLoading={isLoading}
                >
                    {translate('Continue', 'ui.account')}
                </UiButton>
            </Ui2Form>
            {error && <UiAlert failure>{error}</UiAlert>}
        </Wrapper>
    );
}
