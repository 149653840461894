import { getStoreValue } from '../stores/store/utils';
import { environment } from '../stores/environment/environment';
import { isFeatureAvailable } from './feature';
import { FEATURE } from './types';
import { storageGet } from './storage';

export const getIsBetbuilderMatch = ({
    betbuilder_id,
    openbet_id,
}: {
    betbuilder_id: number | null;
    openbet_id: string | null;
}) => {
    const isOpenbetBetbuilderAvailable = Boolean(storageGet('development.custom-betbuilder'));
    return Boolean(isOpenbetBetbuilderAvailable ? openbet_id : betbuilder_id);
};

export function isBetBuilderFeatureAvailable() {
    const { BETBUILDER_WIDGET_URL, BETBUILDER_WIDGET_KEY } = getStoreValue(environment);
    return isFeatureAvailable(FEATURE.BETBUILDER) && Boolean(BETBUILDER_WIDGET_URL && BETBUILDER_WIDGET_KEY);
}
