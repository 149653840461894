import styled from 'styled-components';

type StyledProps = {
    $size?: number;
};

export default styled.img<StyledProps>`
    height: ${({ $size }) => ($size ? `${$size}px` : '14px')};
    width: ${({ $size }) => ($size ? `${$size}px` : '14px')};
    max-width: initial;
`;
