import styled from 'styled-components';
import { hideScrollBar } from '../../../styles/utils';

export default styled.div`
    position: absolute;
    right: -154px;
    top: 50px;
    width: 346px;
    background-color: var(--color-body-bg);
    border-radius: var(--spacing-8);
    box-shadow: rgba(0, 0, 0, 0.48) 0px 3px 6px -4px, rgba(0, 0, 0, 0.32) 0px 6px 16px 0px,
        rgba(0, 0, 0, 0.2) 0px 9px 28px 8px;
    z-index: 110;

    &:before {
        bottom: 100%;
        right: calc(50% - 6px);
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid var(--color-body-bg);
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    & .dropdown-content {
        height: 260px;
        position: relative;
        overflow: auto;
        ${hideScrollBar};

        & .dropdown-content-empty {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: var(--font-color-secondary);
        }
    }

    & .dropdown-view-all-button {
        padding: var(--spacing-8) var(--spacing-16);
        border-top: 1px solid var(--surface-level-2-bg);
    }
`;
