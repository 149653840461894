import React, { useState } from 'react';
import Wrapper from './styles';
import { translate } from '../../services/translate';
import UiModal from '../ui/modal/UiModal';
import UiBox from '../ui/box/UiBox';
import UiAlert from '../ui/alert/UiAlert';
import { logger } from '../../services/logger';
import { getRoute } from '../../services/router';
import { FacebookShareButton } from 'react-share';
import UiCloseButton from '../ui/close-button/UiCloseButton';
import { getImageToShareByTicketId, getUrl } from '../../microservices/cb-sharepic';
import UiLinearProgress from '../ui/linear-progress/UiLinearProgress';
import { stringify } from 'query-string';
import { SHARE_TYPE } from '../../services/share';
import { Ticket } from '../../services/sports/types';
import Svg from '../svg/Svg';
import { environment } from '../../stores/environment/environment';
import { useStore } from '../../hooks/useStore';
import { isFeatureAvailable } from '../../services/feature';
import { FEATURE } from '../../services/types';

interface Props {
    ticket?: Ticket;
    matchId?: number;
    type: string;
    title?: string;
    description?: string;
}

export default function Share({ ticket, matchId, type, title, description }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [facebookShareUrl, setFacebookShareUrl] = useState('');
    const [facebookShareImageUrl, setFacebookShareImageUrl] = useState('');
    const [{ ROOT_URL }] = useStore(environment);

    async function startSharing() {
        setIsLoading(true);
        setIsError(false);
        try {
            const itemId = ticket ? ticket.id : matchId;
            const { url } = await getImageToShareByTicketId(itemId, type);
            setFacebookShareUrl(getFacebookShareUrl(url, getUrlToShare(itemId)));
            setFacebookShareImageUrl(url);
        } catch (error) {
            logger.error('Share', 'startSharing', error);
            setIsError(true);
        }
        setIsLoading(false);
    }

    function getFacebookShareUrl(imageUrl, urlToShare) {
        const urlParams = {
            title: title || translate('title', 'seo.sports'),
            description: description || translate('desc', 'seo.sports'),
            image: imageUrl,
            url: urlToShare,
        };
        return `${ROOT_URL}${getUrl('shared-image')}?${stringify(urlParams)}`;
    }

    function getUrlToShare(itemId) {
        const urlParams = {
            sharedTicketId: itemId,
        };
        if (type === SHARE_TYPE.BET_STATS) {
            const route = getRoute('sport.match');
            return `${ROOT_URL}${route}/${itemId}`;
        }
        if (type === SHARE_TYPE.TICKET) {
            const route = getRoute('sport.recommendations');
            return `${ROOT_URL}${route}?${stringify(urlParams)}`;
        }
    }

    function resetShareState() {
        setFacebookShareUrl('');
        setFacebookShareImageUrl('');
    }

    if (!isFeatureAvailable(FEATURE.SPORT_TICKET_SHARING)) {
        return null;
    }

    return (
        <Wrapper className="share-bet">
            {!isLoading && (
                <div onClick={startSharing} className="share-button">
                    <Svg icon="facebook" size={1.2} />
                    {translate('Share on facebook', 'ui.share')}
                </div>
            )}
            {facebookShareUrl && (
                <UiModal onClose={resetShareState} maxWidth={700}>
                    <UiBox isCompactView>
                        <div className="share-modal">
                            <UiCloseButton className="close-button" onClick={resetShareState} />
                            <img src={facebookShareImageUrl} alt="" className="generated-image" />
                            <FacebookShareButton url={facebookShareUrl}>
                                <div className="share-button">{translate('share-now', 'ui.share')}</div>
                            </FacebookShareButton>
                        </div>
                    </UiBox>
                </UiModal>
            )}
            {isLoading && (
                <UiLinearProgress
                    fakeDuration={8000}
                    label={translate('creating-image----', 'ui.share')}
                    showPercentage
                />
            )}
            {isError && (
                <UiAlert className="alert" warning>
                    {translate('share-failed--try-again', 'ui.share')}
                </UiAlert>
            )}
        </Wrapper>
    );
}
