import React, { useState } from 'react';
import { useStore } from '../../../../hooks/useStore';
import { acceptTermsAndConditions } from '../../../../microservices/users';
import { logout } from '../../../../services/auth';
import { getLicence } from '../../../../services/licence';
import { logger } from '../../../../services/logger';
import { translateArticleKey } from '../../../../services/router';
import { translate } from '../../../../services/translate';
import { stores } from '../../../../stores';
import Cms from '../../../cms/Cms';
import Snippet from '../../../snippet/Snippet';
import UiAlert from '../../../ui/alert/UiAlert';
import UiBox from '../../../ui/box/UiBox';
import UiButton from '../../../ui/button/UiButton';
import UiCloseButton from '../../../ui/close-button/UiCloseButton';
import UiHeading from '../../../ui/heading/UiHeading';
import UiModal from '../../../ui/modal/UiModal';
import Wrapper from './styles';

export default function AuthProfileValidatorTermsAndConditionsAcceptanceModal() {
    const [isTermsAndConditionsAcceptanceModalOpen, setIsTermsAndConditionsAcceptanceModalOpen] = useStore(
        stores.modals.isTermsAndConditionsAcceptanceModalOpen,
    );
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [isLoading, setIsLoading] = useState(false);
    const [isTermsVisible, setIsTermsVisible] = useState(false);
    const [error, setError] = useState('');
    const [user, setUser] = useStore(stores.user);

    function handleModalDismissal() {
        logout();
        setIsTermsAndConditionsAcceptanceModalOpen(false);
    }

    async function tryAcceptTermsAndConditions() {
        if (!user) {
            return;
        }
        setIsLoading(true);
        setError('');

        try {
            await acceptTermsAndConditions();
            setIsTermsAndConditionsAcceptanceModalOpen(false);
            setUser({ ...user, acceptTermsAndConditions: true });
        } catch (error: any) {
            logger.error(
                'AuthProfileValidatorTermsAndConditionsAcceptanceModal',
                'tryAcceptTermsAndConditions',
                error.message,
            );
            setError(translate('Failed to accept terms and conditions', 'ui.common'));
        }

        setIsLoading(false);
    }

    const fitToScreeSizeHack = {
        overflow: 'auto',
        maxHeight: '100vh',
    };

    return (
        <Wrapper open={isTermsAndConditionsAcceptanceModalOpen && isAuthenticated} onClose={handleModalDismissal}>
            <UiBox>
                <UiCloseButton className="close-button" onClick={handleModalDismissal} />

                <UiHeading title={translate('We have updated our Terms and Conditions', 'ui.common')} />

                <div className="terms-text">
                    {user && user.country && (
                        <Snippet snippetKey={`auth.updated-terms-and-conditions-${user.country.toLowerCase()}`} />
                    )}
                </div>

                <UiButton block color="primary" onClick={tryAcceptTermsAndConditions} isLoading={isLoading}>
                    {translate('Accept Terms and Conditions', 'ui.common')}
                </UiButton>
                <br />
                <div className="link terms-read-more" onClick={() => setIsTermsVisible(true)}>
                    {translate('Read More', 'ui.account')}
                </div>

                {error && <UiAlert failure>{error}</UiAlert>}
            </UiBox>

            <UiModal open={isTermsVisible}>
                <UiBox style={fitToScreeSizeHack}>
                    <UiCloseButton onClick={() => setIsTermsVisible(false)} className="close-button" />

                    <Cms pageSlug={translateArticleKey(`support-terms-and-conditions-${getLicence().toLowerCase()}`)} />
                </UiBox>
            </UiModal>
        </Wrapper>
    );
}
