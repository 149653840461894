import styled, { css } from 'styled-components';
import { mediaQuery } from '../../../../styles/utils';

type StyledProps = {
    $collapsed?: boolean;
    $isAmericanLayout?: boolean;
    $isB2B?: boolean;
};

export default styled.div<StyledProps>`
    position: relative;
    .sidebets-layout {
        margin: var(--spacing-8) 0;
    }
    .spinner {
        margin-top: 6rem;
    }
    .info-box {
        position: relative;
        background: var(--info-box-bg);
        border: 1px solid var(--info-box-border);
        padding: 20px;
        border-radius: var(--ui-radius);
        color: var(--info-box-text-color);
        font-size: 12px;
        margin: var(--spacing-8) 0 0 0;
        line-height: 140%;
        @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
            margin: 0;
        }
        .info-box-title {
            font-size: var(--font-14);
            text-transform: uppercase;
            margin-bottom: var(--spacing-4);
        }
        .close-button {
            top: var(--spacing-8);
            right: var(--spacing-8);
            svg {
                color: var(--info-box-text-color);
            }
        }
    }

    .market-filters {
        display: flex;
        align-items: center;
        .filters {
            display: flex;
            width: 100%;
            align-items: center;
            flex-wrap: wrap;
            padding-top: var(--spacing-8);
            @media ${mediaQuery.isPhone} {
                overflow: auto;
                flex-wrap: nowrap;
                overflow-x: scroll;
            }
            @media ${mediaQuery.isTablet} {
                overflow-x: scroll;
            }
            > button {
                margin: 0 var(--spacing-4) var(--spacing-4) 0;
                .arrow {
                    &:before {
                        content: '';
                        position: absolute;
                        margin-top: 0;
                        top: -12px;
                        left: 50%;
                        border: solid 7px transparent;
                        border-top-color: var(--info-box-border);
                        z-index: 1;
                        transform: translate(-50%, 0);
                    }
                }
            }
        }

        .sidebets-search-input {
            flex: 1;
            input {
                height: 40px;
                margin: 0;
            }
        }
        .close-button {
            margin-top: 14px;
        }
    }

    .sidebet {
        box-shadow: var(--ui-shadow);
        padding-bottom: ${({ $collapsed }) => ($collapsed ? '0' : 'var(--spacing-24)')}!important;
        border-radius: var(--ui-radius);
        border: var(--match-sidebet-border);
        background-color: var(--match-sidebet-background);
        &.highlight {
            border-color: var(--color-highlight);
        }
    }
    .sidebet-name {
        position: relative;

        font-size: 0.875rem;
        color: var(--match-sidebet-name-color);
        font-weight: var(--match-name-weight);
        ${({ theme }) => theme.optimizeFontRendering};
        text-align: center;

        padding: 1rem 2rem;
        animation: fadeIn 200ms linear;

        width: 100%;
        .toggle-collapse {
            position: absolute;
            right: var(--spacing-12);
            top: 50%;
            transform: translate(0, -50%);

            display: flex;
            align-items: center;
            justify-content: center;

            color: var(--font-color-subtle);
        }
        .name {
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;
        }
        &:first-letter {
            text-transform: capitalize;
        }

        cursor: pointer;
    }
    .sidebet-outcomes {
        position: relative;

        display: flex;
        align-items: flex-end;
        justify-content: center;

        color: var(--match-sidebet-outcome-color);
        &.line {
            align-items: center;
            .sidebet-outcome.line {
                max-width: 50px;
                min-width: 50px;

                color: var(--match-sidebet-outcome-color);
                @media ${mediaQuery.isPhone} {
                    max-width: 65px;
                    min-width: 65px;
                }
            }
        }
        &.list {
            align-items: center;
        }

        .sidebet-outcome {
            font-size: 0.75rem;
            padding: 0.2rem;
            text-align: center;
            width: 100px;
            max-width: 100%;
            @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
                overflow: auto;
            }
            &.line-market {
                width: ${({ $isAmericanLayout }) => ($isAmericanLayout ? '140px' : '100px')};
                @media ${mediaQuery.isPhone} {
                    width: ${({ $isAmericanLayout }) => ($isAmericanLayout ? '150px' : '')};
                }
            }
            &.best-line {
                ${({ $isB2B }) =>
                    $isB2B &&
                    css`
                        button {
                            border: 1px dotted var(--color-highlight);
                            color: var(--color-highlight);
                        }
                    `}
            }
            &.highlight {
                color: var(--color-highlight);
            }
            .sidebet-name {
                font-size: 0.875rem;
                padding: 2rem 0;
                @media ${mediaQuery.isPhone} {
                    margin-left: 0.3rem;
                    margin-right: -0.1rem;
                }
            }
            &.market-name {
                padding: 0;
            }
            &.list {
                flex-grow: 1;
                text-align: left;
                width: auto;
            }
            &.table-outcome-name {
                display: flex;
                align-items: center;
                padding: 0 0.2rem;
                min-height: 2.9rem;
                @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
                    min-height: 3.21rem;
                }
                > div {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;

                    min-width: 50px;
                    width: max-content;
                    max-width: 100%;
                }
            }
            &.line {
                flex: 1;
                text-align: right;
                padding: 0.4rem;
            }
            > button {
                font-size: 1rem;
                @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
                    font-size: 1.1rem;
                }
                width: 85px;
            }
        }
    }
    .sidebet-exact > .sidebet-outcomes {
        display: block;
    }

    .sidebet-exact {
        display: flex;
        justify-content: center;
    }
    .sidebet-outcome-name {
        padding: 0.4rem;
        @media ${mediaQuery.isPhone} {
            margin-top: 0;
            max-width: 100%;
            overflow: hidden;
        }
    }

    .restricted-width {
        width: 85%;
        @media ${mediaQuery.isPhone} {
            width: 90%;
        }
    }

    .table-like {
        display: flex;
        flex-direction: column;
        align-items: center;

        > .sidebet-name {
            position: relative;
            width: 100%;
            .toggle-collapse {
                position: absolute;
                right: var(--spacing-12);
                top: 50%;
                transform: translate(0, -50%);

                display: flex;
                align-items: center;
                justify-content: center;

                color: var(--font-color-subtle);
            }
        }
    }

    .overflow-auto {
        // this is used to force flexbox to fit the inner elements (not actual scrollbars)
        overflow: hidden;
    }
    .display-all {
        display: flex;
        justify-content: center;
        width: 100%;
    }
    .display-all-line-markets {
        font-size: 0.6rem;
        color: var(--font-color-subtle);
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: var(--font-weight-medium);
        padding-bottom: 0.4rem;
        margin-top: 0.8rem;
        border-bottom: 1px dotted;
        cursor: pointer;
        &:hover {
            color: var(--font-link-color);
        }
    }

    .button-container {
        display: flex;
        justify-content: center;
    }
`;
