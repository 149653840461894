import React, { useEffect } from 'react';
import Wrapper from './styles';
import UiBox from '../../ui/box/UiBox';
import { translate } from '../../../services/translate';
import moment from 'moment';
import { logout } from '../../../services/auth';
import UiCloseButton from '../../ui/close-button/UiCloseButton';
import { useStore } from '../../../hooks/useStore';
import { responsibleGaming } from '../../../stores/responsible-gaming/responsible-gaming';

export default function ResponsibleGamingLoginDurationLimitExceededAlert() {
    const [loginDurationLimitEndDate, setLoginDurationLimitEndDate] = useStore(
        responsibleGaming.loginDurationLimitEndDate,
    );

    useEffect(() => {
        if (loginDurationLimitEndDate) {
            logout();
        }
    }, [loginDurationLimitEndDate]);

    function handleClose() {
        setLoginDurationLimitEndDate(null);
    }

    if (!loginDurationLimitEndDate) {
        return null;
    }

    return (
        <Wrapper open onClose={handleClose}>
            <UiBox light>
                <UiCloseButton onClick={handleClose} className="close-button" />

                {translate('Login duration has been exceeded', 'ui.responsible-gaming', {
                    durationLimitExpiryTime: moment(loginDurationLimitEndDate).format('DD.MM.YYYY HH:mm'),
                })}
            </UiBox>
        </Wrapper>
    );
}
