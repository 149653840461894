import { Country } from '@staycool/location';
import { getServiceUrl, httpGet, httpPost, httpPut } from '../services/api';
import {
    AuthenticationResponse,
    BankIdAuthenticationSessionResponse,
    BankIdAuthenticationSessionStatus,
    RegistrationFields,
    SmartIdSessionResult,
    SmartIdSessionState,
} from '../services/auth/types';
import { getOS } from '../services/device';
import { Language } from '../services/language/types';
import { logger } from '../services/logger';
import { User } from '../services/types';
import { Currency } from '../services/wallet/types';
import { registration } from '../stores/registration';

const getUrl = (url) => getServiceUrl('auth', url);

export function changeUserPassword(params: { newPassword: string; oldPassword: string }) {
    const url = getUrl('password/change');
    return httpPost<void>(url, params);
}

export function renewUserPassword(params: { newPassword: string; oldPassword: string; email: string }) {
    const url = getUrl('password/renew');
    return httpPost<void>(url, params);
}

export async function sendTwoFactorEmailPin(email) {
    const url = getUrl(`send-2fa-code-with-email/`);

    return httpPost(url, {
        email,
    });
}

export function appleLogin(code: string, idToken: string) {
    const url = getUrl('login/apple');
    return httpPost<AuthenticationResponse>(url, {
        idToken,
        code,
    });
}

export function emailLogin(params: { email: string; password: string }) {
    const url = getUrl('login');
    return httpPost<AuthenticationResponse>(url, params);
}

export function facebookLogin(fbAccessToken: string) {
    const url = getUrl('login/facebook');
    return httpPost<AuthenticationResponse>(url, {}, { headers: { 'fb-access-token': fbAccessToken } });
}

export async function getHasPassword() {
    const url = getUrl('has-password');
    return httpGet<boolean>(url);
}

export function googleLogin(params: { clientId: string; code: string; redirectUri: string }) {
    const url = getUrl('login/google');
    return httpPost<AuthenticationResponse>(url, params);
}

export function tokenLogin(params: { token: string }) {
    const url = getUrl('login/token');
    return httpPost<AuthenticationResponse>(url, params);
}

export function linkWithApple(code: string, idToken: string) {
    const url = getUrl('link/apple');
    return httpPost<AuthenticationResponse>(url, {
        idToken,
        code,
    });
}

export function linkWithGoogle(code: string, GOOGLE_CLIENT_ID: string) {
    const url = getUrl('link/google');
    const redirectUri = window.location.origin;

    return httpPost<AuthenticationResponse>(url, {
        clientId: GOOGLE_CLIENT_ID,
        code,
        redirectUri,
    });
}

export async function impersonateWithGoogle(code: string, impersonationToken: string, GOOGLE_CLIENT_ID: string) {
    const url = getUrl('login/impersonate');
    const redirectUri = window.location.origin;

    return httpPost<AuthenticationResponse>(url, {
        clientId: GOOGLE_CLIENT_ID,
        code,
        redirectUri,
        impersonationToken,
    });
}

export function linkWithFacebook(accessToken: string) {
    const url = getUrl('link/facebook');
    const headers = {
        'fb-access-token': accessToken,
    };
    return httpPost<AuthenticationResponse>(url, {}, { headers });
}

export async function update2faStatus(updateData) {
    return httpPut(getUrl('is-2fa-enabled/'), updateData);
}

export async function requestResetPassword(email: string, pin?: number) {
    return httpPost(getUrl('password/request-reset'), { email, pin });
}

export async function verifyResetPasswordToken({ token }) {
    return httpPost(getUrl('password/reset-token-verify'), { token });
}

export function getUserSignupProperty() {
    const url = getUrl('signup-property');
    return httpGet<{ signupProperty: string }>(url);
}

export async function resetPassword({ token, password }) {
    return httpPost(getUrl('password/reset'), { token, password });
}

export async function signup(signupData: Omit<RegistrationFields, 'birthDate'> & { birthDate: string }) {
    const url = getUrl('signup/');
    return httpPost<{ email: string; id: string; token: string; verified: boolean }>(url, signupData);
}

export async function tryLogout(logoutReason) {
    try {
        const url = getUrl('logout');
        const queryParameters = logoutReason ? { reason: logoutReason } : {};
        await httpGet(url, queryParameters);
    } catch (error) {
        logger.error('AuthMicroservice', 'tryLogout', error);
    }
}

export function fetchRegistrationCountries() {
    return httpGet<{
        countryCurrencies: Record<Country, Currency[]>;
        registrationCountries: { code: Country; currency: Currency; name: string; phonePrefix: string }[];
    }>(getUrl('registration-countries/'));
}

export async function getAuthData() {
    return httpGet<{
        displayName: string;
        apple: string;
        facebook: string;
        google: string;
        lastActivity?: string;
        is_2faEnabled: boolean;
        is_2faEnabledOnce: boolean;
    }>(getUrl('auth-data'));
}

export async function emailCheck(email: string) {
    const url = getUrl('email-check');
    return httpGet(url, { email });
}

export async function signupBtob({
    acceptTermsAndConditions,
    email,
    password,
}: {
    acceptTermsAndConditions: boolean;
    email: string;
    password: string;
}) {
    const url = getUrl('signup/basic');
    return httpPost<SignupBtobResponse>(url, {
        acceptTermsAndConditions,
        email,
        password,
    });
}

export function sendTwoFactorCode(email: string) {
    const url = getUrl('login/send-two-factor-code/');
    return httpPost<AuthenticationResponse>(url, { email });
}

export function verifyTwoFactorPin(body: { pin: string; pinId?: string; email?: string }) {
    const url = getUrl('verify-2fa/');
    return httpPost<{ token: string }>(url, body, { device: getOS() } as any);
}

export function startSmartIdAuthentication(personalId: string) {
    const url = getUrl('smart-id/authentication');
    return httpPost<{ sessionId: string; verificationCode: string }>(url, { personalId });
}

export function pollSmartIdSession(sessionId: string) {
    const url = getUrl('smart-id/poll');
    return httpPost<SmartIdPollResponse>(url, { sessionId });
}

export function startBankIdAuthenticationSession() {
    const url = getUrl('bank-id/authentication');
    return httpPost<BankIdAuthenticationSessionResponse>(url);
}

export function pollBankIdAuthenticationSession(sessionId: string) {
    const url = getUrl('bank-id/authentication/poll');
    return httpPost<BankIdAuthenticationSessionPollResponse>(url, { sessionId });
}

export function cancelBankIdAuthenticationSession(sessionId: string) {
    const url = getUrl('bank-id/authentication/cancel');
    return httpPost(url, { sessionId });
}

export function startFtnAuthentication(language: Language, type?: string) {
    const url = getUrl('finnish-trust-network/authorize');
    return httpGet<string>(url, { language, type });
}

export function authenticateWithFtn(code: string, language: Language) {
    const url = getUrl('finnish-trust-network/authenticate');
    return httpPost<{ action: string; data: Record<string, any> }>(url, { code, language });
}

export function minimalUserSignup(body: MinimalUserSignupBody) {
    const url = getUrl('signup/minimal-user');
    return httpPost<MinimalUserSignupResponse>(url, body);
}

interface BankIdAuthenticationSessionPollResponse {
    status: BankIdAuthenticationSessionStatus;
    userMessage?: string;
    signupData?: Partial<typeof registration['form']['state']>;
    loginData?: AuthenticationResponse;
}

type SignupBtobResponse = {
    id: User['id'];
    emailId: User['email'];
    verified: User['verified'];
    closed: User['closed'];
    inPerson: boolean;
    token: string;
};

interface MinimalUserSignupBody {
    email: string;
    alias: string;
    password: string;
    acceptTermsAndConditions: boolean;
    socialMediaRegistrationToken?: string;
}

interface MinimalUserSignupResponse {
    id: User['id'];
    email: User['email'];
    token: string;
}

type SmartIdPollResponse = {
    state: SmartIdSessionState;
    endResult?: SmartIdSessionResult;
    signupData?: Partial<typeof registration['form']['state']>;
    loginData?: AuthenticationResponse;
};
