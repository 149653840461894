import React from 'react';
import Wrapper from './styles';
import { translate } from '../../../../services/translate';
import UiAlert from '../../../ui/alert/UiAlert';
import UiGroup from '../../../ui/group/UiGroup';
import { stores } from '../../../../stores';
import UiButton from '../../../ui/button/UiButton';
import { useStore } from '../../../../hooks/useStore';
import Ui2Form from '../../../ui-2/form/Ui2Form';
import Ui2FormTextInput from '../../../ui-2/form/text-input/Ui2FormTextInput';
import { useForm } from 'react-hook-form';

interface ChangePhoneNumberValues {
    phonePrefix: string;
    phoneNumber: string;
}

interface Props extends Partial<ChangePhoneNumberValues> {
    onPhoneChanged: (values: ChangePhoneNumberValues) => void;
}

export default function AuthVerificationChangePhoneNumber({ phonePrefix, phoneNumber, onPhoneChanged }: Props) {
    const [isRegistrationPhoneVerification] = useStore(stores.verification.isRegistrationPhoneVerification);

    const form = useForm<ChangePhoneNumberValues>({
        mode: 'onBlur',
        defaultValues: {
            phonePrefix,
            phoneNumber,
        },
    });

    return (
        <Wrapper>
            <UiAlert info>
                {translate(
                    isRegistrationPhoneVerification ? 'Change number!' : 'Insert your phone number!',
                    'ui.registration',
                )}{' '}
                {translate(
                    isRegistrationPhoneVerification
                        ? 'Update your number to get a new verification SMS'
                        : 'Insert your number to get a verification SMS',
                    'ui.registration',
                )}
            </UiAlert>
            <Ui2Form useFormMethods={form} onSubmit={onPhoneChanged}>
                <UiGroup expand>
                    <Ui2FormTextInput
                        name="phonePrefix"
                        disabled
                        required
                        className="select-prefix"
                        error={form.formState.errors.phonePrefix}
                    />
                    <Ui2FormTextInput
                        name="phoneNumber"
                        required
                        type="tel"
                        placeholder={translate('Mobile number', 'ui.account')}
                        maxLength={16}
                        error={form.formState.errors.phoneNumber}
                    />
                </UiGroup>
                <UiButton type="button" color="primary" block isFormSubmitButton>
                    {translate('Send SMS', 'ui.registration')}
                </UiButton>
            </Ui2Form>
        </Wrapper>
    );
}
