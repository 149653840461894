import trim from 'lodash/trim';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Development from '../../components/development/Development';
import DevelopmentSettings from '../../components/development/settings/DevelopmentSettings';
import GlobalModal from '../../components/global-modal/GlobalModal';
import MobileApp from '../../components/mobile-app/MobileApp';
import Navigator from '../../components/navigator/Navigator';
import Seo from '../../components/seo/Seo';
import { getRootRoutes } from '../../pages';
import { useMediaQueryHooks } from '../../services/browser';
import { getLastActiveProductRoute, useRouter, useRoutes } from '../../services/router';
import { translate } from '../../services/translate';
import { GlobalStyles } from '../../theme/global';
import Wrapper from './styles';
import { isMobileApp } from '../../services/mobile-app';
import { isWithLicence } from '../../services/licence';
import { stores } from '../../stores';
import AppNotAccessible from '../../components/app-not-accessible/AppNotAccessible';
import { Country, Licence } from '@staycool/location';
import { useStore } from '../../hooks/useStore';
import { LivechatContext } from '../../contexts/livechat/LivechatContext';
import DesktopBlocked from '../../components/desktop-blocked/DesktopBlocked';
import { isFeatureAvailable } from '../../services/feature';
import { FEATURE } from '../../services/types';
import { getDeviceCategory } from '../../services/device';
import { Platform } from '../../services/casino/types';
import { isOfficeIp } from '../../services/environment';

export default function AppPage() {
    const preloader = document.getElementById('preloader');
    if (preloader) {
        preloader.style.display = 'none';
    }
    const { location } = useRouter();
    const [ipCountry] = useStore(stores.ipCountry);
    const routes = useRoutes(getRootRoutes());
    const checkForCmsContent = location.pathname.split('/').length > 2;
    useMediaQueryHooks();
    const [appType] = useStore(stores.appType);

    if (!isOfficeIp() && isMobileApp() && isWithLicence(Licence.MEXICO) && ipCountry !== Country.MEXICO) {
        return <AppNotAccessible />;
    }

    if (
        isFeatureAvailable(FEATURE.BLOCK_DESKTOP) &&
        getDeviceCategory(appType) === Platform.DESKTOP &&
        !isMobileApp() &&
        !isOfficeIp()
    ) {
        return (
            <>
                <MobileApp />
                <DesktopBlocked />
            </>
        );
    }

    const trimmedPathName = trim(window.location.pathname, '/');
    if (trimmedPathName.split('/').length <= 1) {
        return <Redirect to={getLastActiveProductRoute()} />;
    }

    return (
        <Wrapper>
            <LivechatContext>
                <GlobalModal>
                    <GlobalStyles />
                    {process.env.NODE_ENV === 'development' && <Development />}
                    {process.env.NODE_ENV === 'development' && <DevelopmentSettings />}
                    <Seo title={translate('seo.home.title')} description={translate('seo.home.desc')} />
                    <Navigator routes={routes} checkForCmsContent={checkForCmsContent} root />
                    <ToastContainer theme="dark" />
                    <MobileApp />
                </GlobalModal>
            </LivechatContext>
        </Wrapper>
    );
}
