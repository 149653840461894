import memoize from 'lodash/memoize';
import { getOddsFormat } from './sports/user-settings';

import { SportsOddsFormat } from './types';
import round from 'lodash/round';
import { OddsByOutcomeIdStore } from './sports/types';

export const convertOdds = (odds) => {
    const format = getOddsFormat();
    return converters[format](odds);
};

const converters = {
    [SportsOddsFormat.DECIMAL]: (x) => parseFloat(x || 1).toFixed(2),
    [SportsOddsFormat.AMERICAN]: memoize((odds) => convertDecimalToAmerican(odds)),
};

export const getPreciseOdds = (odds) => {
    const format = getOddsFormat();
    const preciseOdds = {
        [SportsOddsFormat.DECIMAL]: odds,
        [SportsOddsFormat.AMERICAN]: convertAmericanToDecimal(odds),
    };
    return preciseOdds[format];
};

const convertAmericanToDecimal = (americanOdds: string): number => {
    if (americanOdds === '-') {
        return 1;
    }
    const parsedAmericanOdds = parseInt(americanOdds, 10);
    const preciseOdds = parsedAmericanOdds > 0 ? parsedAmericanOdds / 100 + 1 : 100 / Math.abs(parsedAmericanOdds) + 1;
    return parseFloat(preciseOdds.toFixed(8));
};

export const convertDecimalToAmerican = (odds: string): string => {
    const parsedOdds = parseFloat(odds) || 1;
    if (parsedOdds === 1) {
        return '-';
    }
    if (parsedOdds >= 2) {
        return `+${Math.round((parsedOdds - 1) * 100)}`;
    }
    return `${Math.round(-100 / (parsedOdds - 1))}`;
};

// TODO: Update card type once outcome_odds as added and sports-types is bumped (currenty does not contain 'outcome_odds')
export function calculateAndFormatBoostedOdds(card: any, oddsByOutcomeId: Pick<OddsByOutcomeIdStore, any>) {
    const { matches, odds_boost } = card;
    const marketOutcomes = matches.flatMap(({ markets }) => markets);
    const numberOfOutcomes = marketOutcomes.length;

    const totalBoostedOdds =
        marketOutcomes.reduce((total, { outcome_id, outcome_odds }) => {
            let outcomeOdds = oddsByOutcomeId?.[outcome_id]?.value ?? outcome_odds;
            if (odds_boost && odds_boost !== 0) {
                const netSelectionOdds = outcomeOdds - 1;
                const boostedNetSelectionOdds = round(netSelectionOdds * Math.pow(odds_boost, 1 / numberOfOutcomes), 3);
                outcomeOdds = boostedNetSelectionOdds + 1;
            }
            return total * outcomeOdds;
        }, 1) ?? 0;

    return round(totalBoostedOdds, 3);
}
