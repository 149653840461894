import styled from 'styled-components';

export default styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.layout.gutter};

    .qr-code {
        width: 100%;
        max-width: 300px;
        aspect-ratio: 1;

        &.loading {
            display: none;
        }
    }

    div.qr-code {
        display: grid;
        place-items: center;
    }

    img#qr-code.qr-code:not(.loading) {
        display: block !important;
    }

    .next-button {
        margin-left: auto;
    }
`;
