import { translate } from '../../../../services/translate';
import { stores } from '../../../../stores';
import React, { useEffect } from 'react';
import { validateBetslip, resetBetslip } from '../../../../services/virtual-sports/betslip';
import Wrapper from './styles';
import UiButton from '../../../ui/button/UiButton';
import { virtualSports } from '../../../../stores/virtual-sports/virtual-sports';
import { useStore } from '../../../../hooks/useStore';
import { BET_TYPE } from '../../../../services/sports/types';

export default function VirtualSportsBetslipHeader() {
    const [betSlipMarketIdToOutcomeId] = useStore(virtualSports.betslipMarketIdToOutcomeId);
    const [betSlipUserState, setBetSlipUserState] = useStore(virtualSports.betslipUserState);
    const [, setBetSlipPlacingState] = useStore(virtualSports.betslipPlacingState);
    const [isInactivityModalVisible] = useStore(stores.modals.isInactivityModalVisible);
    const marketsCount = Object.keys(betSlipMarketIdToOutcomeId).length;
    const { betType } = betSlipUserState;
    const setBetType = (betType) => () => {
        setBetSlipUserState((state) => ({ ...state, betType }));
        const { isValid, betslipErrors } = validateBetslip();
        setBetSlipPlacingState((state) => ({ ...state, betslipErrors, isPlaceBetButtonDisabled: !isValid }));
    };

    useEffect(() => {
        if (isInactivityModalVisible) {
            resetBetslip();
        }
    }, [isInactivityModalVisible]);

    return (
        <Wrapper>
            <div className="bet-type-controls">
                <UiButton size="small" onClick={setBetType(BET_TYPE.SINGLE)} selected={betType === BET_TYPE.SINGLE}>
                    {translate('Single', 'ui.sportsbook')}
                </UiButton>
                <UiButton
                    size="small"
                    disabled={marketsCount < 2}
                    onClick={setBetType(BET_TYPE.COMBO)}
                    selected={betType === BET_TYPE.COMBO}
                >
                    {translate('Combo', 'ui.sportsbook')}
                </UiButton>
                <UiButton
                    size="small"
                    disabled={marketsCount < 3}
                    onClick={setBetType(BET_TYPE.SYSTEM)}
                    selected={betType === BET_TYPE.SYSTEM}
                >
                    {translate('System', 'ui.sportsbook')}
                </UiButton>
            </div>
            {Boolean(marketsCount) && (
                <button className="clear-selections" onClick={resetBetslip}>
                    {translate('Clear selections', 'ui.sportsbook')}
                </button>
            )}
        </Wrapper>
    );
}
