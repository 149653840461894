export const PRODUCT = {
    ALL: 'all',
    CASINO: 'casino',
    CASINO_RACE: 'casino-race',
    DEPOSIT: 'deposit',
    MOBILE_LANDING: 'mobile-landing',
    RACEBOOK: 'racebook',
    SPORTSBOOK: 'sportsbook',
    POKER: 'poker',
    TOURNAMENTS: 'tournaments',
    VIRTUAL_SPORTS: 'virtual_sports',
    VS_HIGHLIGHT_GAMES: 'VS_HIGHLIGHT_GAMES',
} as const;

export const snippetAndDescriptionByProduct = {
    [PRODUCT.CASINO]: {
        snippet: 'casino.disabled.games.country-block',
        description: 'PRODUCT.CASINO',
    },
    [PRODUCT.CASINO_RACE]: {
        snippet: 'casino.disabled.casino-race.country-block',
        description: 'PRODUCT.CASINO_RACE',
    },
    [PRODUCT.POKER]: {
        snippet: 'casino.disabled.poker.country-block',
        description: 'PRODUCT.POKER',
    },
    [PRODUCT.VIRTUAL_SPORTS]: {
        snippet: 'casino.disabled.virtual-sport.country-block',
        description: 'PRODUCT.VIRTUAL_SPORTS',
    },
    [PRODUCT.VS_HIGHLIGHT_GAMES]: {
        snippet: 'casino.disabled.virtual-sport.country-block',
        description: 'PRODUCT.VS_HIGHLIGHT_GAMES',
    },
    [PRODUCT.SPORTSBOOK]: {
        snippet: 'sportsbook.disabled.country-block',
        description: 'PRODUCT.SPORTSBOOK',
    },
} as const;
