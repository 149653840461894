import React, { useState, useEffect } from 'react';
import AuthProfileValidatorLicenceRequirementSwedenDepositLimits from './deposit-limits/AuthProfileValidatorLicenceRequirementSwedenDepositLimits';
import UiAlert from '../../../../ui/alert/UiAlert';
import AuthProfileValidatorLicenceRequirementSwedenPersonalId from './personal-id/AuthProfileValidatorLicenceRequirementSwedenPersonalId';
import { parse } from 'query-string';
import AuthProfileValidatorRequiredFields from '../../required-fields/AuthProfileValidatorRequiredFields';
import { LicenceRequirementSteps as Steps } from '../../../../../services/licence';
import { stores } from '../../../../../stores';
import { useStore } from '../../../../../hooks/useStore';

interface Props {
    onAfterSubmit: () => Promise<void>;
    isModal?: boolean;
}

export default function AuthProfileValidatorLicenceRequirementSweden(props: Props) {
    const { onAfterSubmit, isModal } = props;
    const [step, setStep] = useState<Steps>(Steps.PERSONAL_ID);
    const [headerError, setHeaderError] = useState('');

    const modals = {
        [Steps.PERSONAL_ID]: (
            <AuthProfileValidatorLicenceRequirementSwedenPersonalId
                {...props}
                onAfterSubmit={goToNextStep}
                onExit={goToNextStep}
            />
        ),
        [Steps.DEPOSIT_LIMIT]: (
            <AuthProfileValidatorLicenceRequirementSwedenDepositLimits {...props} onAfterSubmit={goToNextStep} />
        ),
        [Steps.REQUIRED_FIELDS]: <AuthProfileValidatorRequiredFields isModal={isModal} onAfterSubmit={onAfterSubmit} />,
    };
    const [user] = useStore(stores.user);

    useEffect(() => {
        setHeaderErrorMessage();
    }, []);

    useEffect(() => {
        if (user?.isProfileReviewRequired) {
            setStep(Steps.REQUIRED_FIELDS);
        }
    }, [user?.isProfileReviewRequired]);

    function goToNextStep() {
        if (step === Steps.PERSONAL_ID) {
            setStep(Steps.DEPOSIT_LIMIT);
        } else if (step === Steps.DEPOSIT_LIMIT && user?.isProfileReviewRequired) {
            setStep(Steps.REQUIRED_FIELDS);
        } else {
            onAfterSubmit();
        }
    }

    function setHeaderErrorMessage() {
        const { errorMessage } = parse(window.location.search);
        if (errorMessage) {
            setHeaderError(errorMessage as string);
        }
    }

    return (
        <>
            {headerError && <UiAlert failure>{headerError}</UiAlert>}
            {modals[step]}
        </>
    );
}
