import cloneDeep from 'lodash/cloneDeep';
import {
    INITIAL_BETSLIP_PLACING_STATE,
    INITIAL_BETSLIP_USER_STATE,
    HIGHLIGHT_SPORT,
} from '../../services/virtual-sports/constants';
import { createStores } from '../store/utils';
import { BETSLIP_MODE } from './types';
import { CurrentHighlighGamesRound, GameWeekInfo } from '../../services/virtual-sports/types';

export const virtualSports = createStores({
    betslipMode: BETSLIP_MODE.HISTORY,
    betslipPlacingState: cloneDeep(INITIAL_BETSLIP_PLACING_STATE),
    betslipMarketIdToOutcomeId: {},
    betslipUserState: cloneDeep(INITIAL_BETSLIP_USER_STATE),
    betslipMarketInfoById: {},
    betslipOddsByOutcomeId: {},
    potentialReturn: 0,
    receipt: {},
    isBetslipEmpty: true,
    roundData: {
        [HIGHLIGHT_SPORT.IIHF]: {} as CurrentHighlighGamesRound,
        [HIGHLIGHT_SPORT.SOCCERBET]: {} as CurrentHighlighGamesRound,
    },
    gameWeekInfo: {} as { [key: number]: GameWeekInfo },
    gameWeekCount: 0,
});
