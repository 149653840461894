import React from 'react';
import { useStore } from '../../hooks/useStore';
import { isB2B } from '../../services/environment';
import { isFeatureAvailable } from '../../services/feature';
import { FEATURE } from '../../services/types';
import { stores } from '../../stores';
import AnalyticsPageView from '../analytics/page-view/AnalyticsPageView';
import SystemAnalytics from './analytics/SystemAnalytics';
import SystemB2B from './b-2-b/SystemB2B';
import SystemBannerMessage from './banner-message/SystemBannerMessage';
import SystemCasino from './casino/SystemCasino';
import SystemClock from './clock/SystemClock';
import SystemCrm from './crm/SystemCrm';
import SystemDelayedEvents from './delayed-events/SystemDelayedEvents';
import SystemFingerprint from './fingerprint/SystemFingerprint';
import SystemGeocomply from './geocomply/SystemGeocomply';
import SystemLogin from './login/SystemLogin';
import SystemMissingTranslations from './missing-translations/SystemMissingTranslations';
import SystemModalMessage from './modal-message/SystemModalMessage';
import SystemPoker from './poker/SystemPoker';
import SystemResponsibleGaming from './responsible-gaming/SystemResponsibleGaming';
import SystemRouteListener from './route-listener/SystemRouteListener';
import SystemSession from './session/SystemSession';
import SystemSport from './sport/SystemSport';
import SystemUser from './user/SystemUser';
import SystemWallet from './wallet/SystemWallet';
import { Helmet } from 'react-helmet';

export default function System() {
    const [applicationType] = useStore(stores.applicationType);

    return applicationType === 'poker-download' ? (
        <>
            <SystemSession />
            <SystemUser />
            <SystemResponsibleGaming />

            {!isB2B() && <SystemAnalytics />}
            <SystemPoker />
            <SystemModalMessage />
            <SystemFingerprint />
        </>
    ) : (
        <>
            <SystemSession />
            <SystemUser />
            <SystemRouteListener />
            <SystemResponsibleGaming />
            <SystemWallet />
            <SystemLogin />
            <SystemClock />
            {isFeatureAvailable(FEATURE.SYSTEM_ANALYTICS) && (
                <>
                    <AnalyticsPageView />
                    <SystemAnalytics />
                </>
            )}
            <SystemPoker />
            {isFeatureAvailable(FEATURE.BANNER_MESSAGE) && <SystemBannerMessage />}
            <SystemCasino />
            <SystemModalMessage />
            <SystemMissingTranslations />
            <SystemFingerprint />
            <SystemCrm />
            {isFeatureAvailable(FEATURE.SPORTSBOOK) && <SystemSport />}
            <SystemDelayedEvents />
            <SystemGeocomply />
            <SystemB2B />
            <Helmet>
                <link rel="canonical" href={window.location.href.replace(/\?.*$/, '').replace(/\/$/, '')} />
            </Helmet>
        </>
    );
}
