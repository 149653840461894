import React, { useState } from 'react';
import { useDocumentUpload } from '../../../../../../services/kyc';
import AccountVerificationDocumentInput from '../../input/AccountVerificationDocumentInput';
import AccountVerificationDocumentPreview from '../../preview/AccountVerificationDocumentPreview';
import UiAlert from '../../../../../ui/alert/UiAlert';
import { translate } from '../../../../../../services/translate';
import AccountVerificationSourceOfFundsModal from '../../../source-of-funds/modal/AccountVerificationSourceOfFundsModal';
import UiButton from '../../../../../ui/button/UiButton';

interface Props {
    documentUpload: ReturnType<typeof useDocumentUpload>;
}

export default function AccountVerificationDocumentSelectionOneSided({ documentUpload }: Props) {
    const [isTryingToUploadSameDocuments, setIsTryingToUploadSameDocuments] = useState(false);
    const [isSourceOfFundsModalVisible, setIsSourceOfFundsModalVisible] = useState(false);
    const [isSourceOfFundsModalViewed, setIsSourceOfFundsModalViewed] = useState(false);

    function openSourceOfFundsModal() {
        setIsSourceOfFundsModalVisible(true);
    }

    function acceptPopInstruction() {
        setIsSourceOfFundsModalVisible(false);
        setIsSourceOfFundsModalViewed(true);
    }

    function onClose() {
        setIsSourceOfFundsModalVisible(false);
    }

    function handleOnDrop([file]: File[]) {
        setIsTryingToUploadSameDocuments(false);
        if (!file) {
            return;
        }
        if (documentUpload.documents.some((document) => document.name === file.name)) {
            return setIsTryingToUploadSameDocuments(true);
        }
        documentUpload.addDocuments([file]);
    }
    return (
        <>
            <AccountVerificationSourceOfFundsModal
                isOpen={isSourceOfFundsModalVisible}
                onSubmit={acceptPopInstruction}
                onClose={onClose}
            />
            {isSourceOfFundsModalViewed ? (
                <AccountVerificationDocumentInput
                    multiple={documentUpload.documentLimit > 1}
                    onDrop={handleOnDrop}
                    selectButtonText={documentUpload.selectButtonText}
                    isDisabled={documentUpload.isSelectingDocumentsDisabled}
                />
            ) : (
                <UiButton color="primary" block onClick={openSourceOfFundsModal}>
                    {translate('Upload documents', 'ui.account.kyc')}
                </UiButton>
            )}
            {isTryingToUploadSameDocuments && (
                <UiAlert failure>{translate('Please upload unique documents only.', 'ui.account.kyc')}</UiAlert>
            )}
            <AccountVerificationDocumentPreview
                documents={documentUpload.documents}
                itemHeight={128}
                itemWidth={128}
                onRemoveDocument={documentUpload.removeDocument}
            />
        </>
    );
}
