import { FoComboCardWithOdds } from '@staycool/sports-types/fo-combo-card';
import React from 'react';
import { checkIsCardSingleSport } from '../../../../services/sports/combo-cards';
import ComboCardsItemDetailsMultipleSport from './multiple-sport/ComboCardsItemDetailsMultipleSport';
import ComboCardsItemDetailsSingleSport from './single-sport/ComboCardsItemDetailsSingleSport';
import Wrapper from './styles';

interface Props {
    card: FoComboCardWithOdds;
}

export default function ComboCardsItemDetails({ card: { matches, type } }: Props) {
    const isSingleSportCard = checkIsCardSingleSport(matches);
    return (
        <Wrapper $accentColor={type.bg_color}>
            {isSingleSportCard ? (
                <ComboCardsItemDetailsSingleSport matches={matches} />
            ) : (
                <ComboCardsItemDetailsMultipleSport matches={matches} />
            )}
        </Wrapper>
    );
}
