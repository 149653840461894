import React, { useEffect, useMemo, useState } from 'react';
import SportOdds from '../../../odds/SportOdds';
import { MATCH_STATUS } from '../../../../../services/sports/constants';
import { withErrorBoundary } from '../../../../../higher-order-components/error-boundary';
import SportOddsSpacer from '../../../odds/spacer/SportOddsSpacer';
import {
    getUniqueMarketName,
    useGlobalSearchMarketHighlightScroll,
    useSmartMarketUniqueNameByMarketId,
    useSortedMarkets,
} from '../../../../../services/sports/sidebets';
import classNames from 'classnames';
import {
    CategoryMatchMarket,
    FoSidebetsMarketGroupMarket,
    SportMatchSidebetMarketProps as Props,
} from '../../../../../services/sports/types';
import Wrapper from './styles';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import { translate } from '../../../../../services/translate';
import { stores, useStoreWithSelector } from '../../../../../stores';
import { findBestLine } from '../../../../../services/sports/top-markets';
import Svg from '../../../../svg/Svg';
import { useSportsUserSettings } from '../../../../../services/sports/user-settings';
import isNumber from 'lodash/isNumber';
import { replaceSequencesInName } from '../../../../../services/sports/market';

function SportMatchMarketsSmart({
    marketType: {
        markets,
        translatedName: marketTypeName,
        uniqueSequences,
        placeholder_count: placeholderCount,
        view_type: viewType,
        market_type_id: marketTypeId,
        result_keys: rawResultKeys,
    },
    match,
    collapsed,
    isBetbuilderMarkets = false,
}: Props) {
    const ROWS_LIMIT = 5;
    const firstMarket = markets[0];
    const [marketName, setMarketName] = useState('');

    useEffect(() => {
        const marketName = firstMarket?.translatedName || firstMarket?.name;
        let displayMarketName = replaceSequencesInName(
            !hasMultipleMarkets ? marketName : marketTypeName.replace(/^-|-$/, '') || marketName,
            firstMarket?.sequence,
        );
        if (isLineMarket && isAmericanLayout && placeholderCount > 0) {
            displayMarketName = `${getUniqueMarketName(firstMarket, [], false)} ${marketTypeName.replace(/^-|-$/, '')}`;
        }
        setMarketName(displayMarketName);
    }, [firstMarket]);

    const { sport_category: sportCategoryId, status: matchStatus, betting_end: matchBettingEnd } = match;
    const { isAmericanLayout } = useSportsUserSettings({ sportCategoryId });
    const hasMultipleMarkets = markets.length > 1;
    const isLineMarket = ['line', 'generic_line'].includes(viewType);
    const isUniqueNameShown =
        (!isAmericanLayout || !isLineMarket) &&
        hasMultipleMarkets &&
        (placeholderCount || isLineMarket || Boolean(uniqueSequences?.length));

    const resultKeys = useMemo(() => {
        return rawResultKeys?.some((resultKey) => /\[.+\]/.test(resultKey)) && hasMultipleMarkets
            ? rawResultKeys.map((resultKey) => resultKey.replace(/\[.+\]/, ''))
            : firstMarket?.outcomes?.map((oc) => oc.name || '') || [];
    }, [firstMarket, rawResultKeys]);

    const marketGroupedNameById = useSmartMarketUniqueNameByMarketId(
        isUniqueNameShown,
        markets,
        uniqueSequences,
        isLineMarket,
    );
    const sortedMarkets = useSortedMarkets(markets, isLineMarket);
    const outcomeIds = useMemo(
        () => markets.map((lineMarket) => lineMarket.outcomes.map((outcome) => outcome.id)).flat(),
        [markets],
    );
    const [marketOddsByOutcomeId] = useStoreWithSelector(
        stores.sports.oddsByOutcomeId,
        (state) => pick(state, outcomeIds),
        [outcomeIds as never],
    );

    const [displayedMarket, setDisplayedMarket] = useState(sortedMarkets[Math.round(sortedMarkets.length / 2) - 1]);
    const [isAllLinesVisible, setIsAllLinesVisible] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(Boolean(collapsed));

    const { ref, highLightMatch } = useGlobalSearchMarketHighlightScroll(marketTypeId, markets, (_, marketFound) =>
        getUniqueMarketName(marketFound, uniqueSequences, isLineMarket),
    );

    useEffect(() => {
        if (!isEmpty(marketOddsByOutcomeId)) {
            setDisplayedMarket(
                findBestLine(
                    sortedMarkets as unknown as CategoryMatchMarket[],
                    viewType,
                    marketOddsByOutcomeId,
                    match.prefer_half_lines,
                ) as unknown as FoSidebetsMarketGroupMarket,
            );
        }
    }, [marketOddsByOutcomeId, markets]);

    useEffect(() => {
        setIsCollapsed(Boolean(collapsed));
    }, [collapsed]);

    function getMarketLineComponent({ id: marketId }: FoSidebetsMarketGroupMarket) {
        const marketGroupedName = marketGroupedNameById[marketId];
        const isHighlighted = highLightMatch === marketGroupedName;

        return (
            <div
                className={classNames('sidebet-outcome list table-outcome-name', {
                    highlight: isHighlighted,
                })}
                key={marketId}
                ref={isHighlighted ? ref : null}
            >
                <div>{marketGroupedName}</div>
            </div>
        );
    }

    function getMarketOddsComponent(market: FoSidebetsMarketGroupMarket, resultKey: string, resultKeyIdx: number) {
        const outcome =
            market.outcomes.find((o) => o.result_key === resultKey || o.name === resultKey) ||
            market.outcomes[resultKeyIdx];
        const isLineMarket = viewType === 'line';
        const isBestLine = isLineMarket && displayedMarket.id === market.id;

        if (!outcome) {
            return (
                <div className="sidebet-outcome" key={market.id}>
                    <SportOddsSpacer />
                </div>
            );
        }

        return (
            <div
                className={classNames('sidebet-outcome', {
                    'line-market': isLineMarket,
                    'best-line': isBestLine,
                })}
                key={market.id}
            >
                <SportOdds
                    light
                    outcomeId={outcome.id}
                    market={{
                        view_type: viewType,
                        in_play: matchStatus === MATCH_STATUS.LIVE,
                        betting_end: matchBettingEnd,
                        ...market,
                    }}
                    isAmericanLayout={isAmericanLayout}
                    isBetbuilderOdds={isBetbuilderMarkets}
                />
            </div>
        );
    }

    function getMarketComponent(markets: FoSidebetsMarketGroupMarket[]) {
        const bestMarketIndex = markets.indexOf(displayedMarket);
        const isMarketsPlayerRelated = markets.every(({ player_names }) => Boolean(player_names?.length));

        const shouldDisplayFirstFiveMarkets =
            [0, 1].includes(bestMarketIndex) || markets.length === 5 || isMarketsPlayerRelated;
        const linesToDisplay = (
            shouldDisplayFirstFiveMarkets
                ? [0, 1, 2, 3, 4]
                : [markets.length - 1].includes(bestMarketIndex)
                ? [bestMarketIndex - 4, bestMarketIndex - 3, bestMarketIndex - 2, bestMarketIndex - 1, bestMarketIndex]
                : [bestMarketIndex - 2, bestMarketIndex - 1, bestMarketIndex, bestMarketIndex + 1, bestMarketIndex + 2]
        ).filter((line) => isNumber(line) && line >= 0);

        const marketsToDisplay = isAllLinesVisible
            ? markets
            : linesToDisplay.map((line) => markets[line]).filter(Boolean);

        return (
            <>
                {isUniqueNameShown && (
                    <div className="overflow-auto">
                        {marketsToDisplay.map((market) => getMarketLineComponent(market))}
                    </div>
                )}
                {resultKeys.map((resultKey, resultKeyIdx) => (
                    <div key={resultKey || resultKeyIdx} className="overflow-auto">
                        <div
                            className={classNames('sidebet-outcome', {
                                'line-market': viewType === 'line',
                            })}
                        >
                            <div className="sidebet-outcome-name">{resultKey.replace(/\//g, ' / ')}</div>
                        </div>
                        {marketsToDisplay.map((market) => getMarketOddsComponent(market, resultKey, resultKeyIdx))}
                    </div>
                ))}
            </>
        );
    }

    return (
        <Wrapper collapsed={isCollapsed}>
            <div className="market-container">
                <div className={classNames('sidebet table-like', { highlight: highLightMatch })}>
                    <div className="sidebet-name" onClick={() => setIsCollapsed(!isCollapsed)}>
                        <div className="name">{marketName}</div>
                        <div className="toggle-collapse">
                            <Svg icon={isCollapsed ? 'plus-sign' : 'minus-round'} size={0.75} />️
                        </div>
                    </div>
                    {!isCollapsed && (
                        <>
                            <div className="sidebet-outcomes restricted-width">{getMarketComponent(sortedMarkets)}</div>
                            {hasMultipleMarkets && markets.length > ROWS_LIMIT && (
                                <div
                                    className="display-all-line-markets"
                                    onClick={() => setIsAllLinesVisible(!isAllLinesVisible)}
                                >
                                    {isAllLinesVisible
                                        ? translate('Display less', 'ui.sportsbook')
                                        : `${translate('Display all', 'ui.sportsbook')} ${
                                              markets.length - ROWS_LIMIT > 0 && ` (${markets.length - ROWS_LIMIT})`
                                          }`}
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </Wrapper>
    );
}

export default withErrorBoundary(
    React.memo(
        SportMatchMarketsSmart,
        (prev, next) => prev.marketType === next.marketType && prev.collapsed === next.collapsed,
    ),
);
