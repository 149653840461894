import styled from 'styled-components';

export default styled.button`
    border: none;
    padding: 0;
    background-color: transparent;
    color: inherit;
    cursor: pointer;
    font-size: 0.7rem;
    text-transform: uppercase;
    margin-bottom: 1.6rem;
    svg {
        margin: 0 auto 0.5rem auto;
    }
    &:hover {
        color: ${({ theme }) => theme.colors.primary};
    }
`;
