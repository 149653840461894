import { stores } from '../stores';
import { getPromotionsPageLastVisit, getUserMarketCode } from './user';
import { translate } from './translate';
import { storageGet } from './storage';
import { isFeatureAvailable } from './feature';
import { LANGUAGE, Language } from './language/types';
import { Market } from '@staycool/location';
import { getStoreValue } from '../stores/store/utils';
import { PRODUCT } from '../types/common';
import { environment } from '../stores/environment/environment';
import { useStore } from '../hooks/useStore';
import { FEATURE } from './types';
import { Campaign, CampaignGroups } from './bonuses/types';
import { ObjectValues } from './ts-utils';

export function handleDynamicPromotionsCount() {
    const promotionsPageLastVisit = getPromotionsPageLastVisit();
    const promotions = getStoreValue(stores.promotions);
    let promotionsCount = 0;

    if (promotions && promotions.length) {
        for (const promotion of promotions) {
            if (new Date(promotion.start_time).getTime() > promotionsPageLastVisit) {
                promotionsCount += 1;
            }
        }
    }

    stores.dynamicPromotionsCount.set((promotionsCount || undefined) as any);
}
export const PROMO_TYPE = {
    NEW_AND_FEATURED: 'newAndFeatured',
    PARTICIPATING: 'participating',
} as const;

export function getPromotionCategoriesToDisplay(
    isAuthenticated: boolean,
    isVirtualSportsAvailable?: boolean,
): CampaignGroups {
    const ALL_PROMOTION_CATEGORIES = {
        [PRODUCT.ALL]: translate('All', 'ui.common'),
        [PROMO_TYPE.NEW_AND_FEATURED]: translate('New & Featured', 'ui.casino'),
        [PRODUCT.SPORTSBOOK]: translate('sport', 'ui.common'),
        [PRODUCT.CASINO]: translate('Casino', 'ui.common'),
        [PRODUCT.POKER]: translate('Poker', 'ui.common'),
        [PRODUCT.VIRTUAL_SPORTS]: translate('virtual-sports', 'ui.common'),
        [PROMO_TYPE.PARTICIPATING]: translate('participating-filter', 'ui.bonuses'),
    };
    const lastActiveProduct = storageGet<ObjectValues<typeof PRODUCT>>('lastActiveProduct') || PRODUCT.SPORTSBOOK;
    const blockedCategories = new Set();
    if (!isVirtualSportsAvailable || !isFeatureAvailable(FEATURE.VIRTUAL_SPORTS)) {
        blockedCategories.add(PRODUCT.VIRTUAL_SPORTS);
    }
    if (!isAuthenticated) {
        blockedCategories.add(PROMO_TYPE.PARTICIPATING);
    }
    if (!isFeatureAvailable(FEATURE.SPORTSBOOK)) {
        blockedCategories.add(PRODUCT.SPORTSBOOK);
    }
    if (!isFeatureAvailable(FEATURE.POKER)) {
        blockedCategories.add(PRODUCT.POKER);
    }
    if (!isFeatureAvailable(FEATURE.CASINO)) {
        blockedCategories.add(PRODUCT.CASINO);
    }

    return {
        [PRODUCT.ALL]: ALL_PROMOTION_CATEGORIES[PRODUCT.ALL],
        [PROMO_TYPE.NEW_AND_FEATURED]: ALL_PROMOTION_CATEGORIES[PROMO_TYPE.NEW_AND_FEATURED],
        [lastActiveProduct]: ALL_PROMOTION_CATEGORIES[lastActiveProduct],
        ...Object.keys(ALL_PROMOTION_CATEGORIES).reduce((map, category) => {
            if (!blockedCategories.has(category)) {
                map[category] = ALL_PROMOTION_CATEGORIES[category];
            }
            return map;
        }, {}),
    };
}

export function getFilteredPromotions(product, promotions) {
    let filteredPromotions = promotions;

    if (product !== PRODUCT.ALL) {
        filteredPromotions = promotions.filter((promotion) =>
            Boolean(promotion.display && promotion.display.placement && promotion.display.placement.includes(product)),
        );
    }
    return filteredPromotions;
}

export function getPromotionsGroupedByCategory(promotions: Campaign[], categories: CampaignGroups) {
    const promotionsGroupedByProduct = Object.keys(categories).reduce((map, category) => {
        // eslint-disable-next-line no-param-reassign
        map[category] = [];
        return map;
    }, {});

    for (const promotion of promotions) {
        if (promotion.display) {
            const isFeatured = promotion.products && Object.values(promotion.products).every(Boolean);
            if (!isFeatured) {
                promotion.display.placement.forEach(
                    (plc) => promotionsGroupedByProduct[plc] && promotionsGroupedByProduct[plc].push(promotion),
                );
            }
            if (promotion.display.show_in_featured_section || isFeatured) {
                promotionsGroupedByProduct[PROMO_TYPE.NEW_AND_FEATURED].push(promotion);
            }
        }

        if (categories[PROMO_TYPE.PARTICIPATING] && promotion.is_taken_by_user) {
            promotionsGroupedByProduct[PROMO_TYPE.PARTICIPATING].push(promotion);
        }
    }
    return promotionsGroupedByProduct;
}

export function usePromotionArticlePath(promotionArticleSlug) {
    const [language] = useStore(stores.language);
    const translationLanguage = getPromotionsLanguage(language);
    return `/${translationLanguage}/${promotionArticleSlug[translationLanguage]}`;
}

export function usePromotionImagePath(promotionImages: Record<string, string>, fallbackLanguage?: Language) {
    const [language] = useStore(stores.language);

    return `${getStoreValue(environment).CMS_IMAGES}${promotionImages[fallbackLanguage || language]}?fm=pjpg`;
}

export function getPromotionsLanguage(language: Language) {
    const market = getUserMarketCode();

    const isUserFromMexico = market === Market.MEXICO;
    const translationLanguage = isUserFromMexico ? LANGUAGE.MEXICAN : language;

    return translationLanguage;
}
