import { getGeneratedUserData } from '../../microservices/users';
import { RegistrationFlow } from '../auth/types';

export async function generateUserData(flow, country) {
    const generatedData = await getGeneratedUserData(country);

    if (flow === RegistrationFlow.PAY_AND_PLAY) {
        return {
            email: generatedData.email,
            alias: generatedData.alias,
            phoneNumber: generatedData.phoneNumber,
            phonePrefix: generatedData.phonePrefix,
            password: generatedData.password,
        };
    }

    if (flow === RegistrationFlow.EMAIL) {
        return generatedData;
    }
}
